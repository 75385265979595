import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { TicketContacts } from '../ticket.interface';
import { filter, switchMap, take } from 'rxjs';
import {
	GenericDropdown,
	GenericDropdownWithEmail,
} from '@app/shared/interfaces/generic.interface';
import { getInitials } from '@app/shared/functions/user-initials.functions';
import { ViewTicketService } from '../view-ticket.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { QuestProfileModule } from '@app/shared/components/quest-profile/quest-profile.module';
import { select, Store } from '@ngrx/store';
import { companyIdSelector } from '@app/core/store/user/user.selector';

@Component({
	selector: 'modal-add-manage-users',
	templateUrl: './add-manage-users-modal.component.html',
	styleUrls: ['./add-manage-users-modal.component.scss'],
	standalone: true,
	imports: [CommonModule, SharedModule, QuestProfileModule],
})
export class AddManageUsersModalComponent implements OnInit, OnChanges {
	// inject
	private _http = inject(HttpClient);
	private _cd = inject(ChangeDetectorRef);
	private _viewTicket = inject(ViewTicketService);
	private store = inject(Store);

	// Input / Output
	//@Input() _companyId:number = 0;
	@Input() isOpenModal = false;
	@Input() users: TicketContacts[] = [];
	@Input() ticketId: number;
	@Output() close = new EventEmitter<boolean>();
	@Output() addDelete = new EventEmitter<any>();

	// private props
	private _noProfileCtr = 0;

	//public props
	defaultProfileImg = 'assets/images/profile-pic/profilePic.png';
	contactFc = new FormControl<string[]>([]);
	externalContactFc = new FormControl<string[]>([]);
	//contactFc2 = new FormControl<number[]>([]);
	isLoading = false;
	contact: GenericDropdownWithEmail[] = [];
	userContactOptions: any[] = [];

	constructor() {}

	ngOnInit() {
		this.store
			.pipe(
				select(companyIdSelector),
				take(1),
				switchMap((companyId) => this._getUserDropDownByCompanyId(companyId))
			)
			.subscribe((v) => {
				this.contact = v;
				if (this.users.length > 0) this._filterContact(this.users);
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['users']) {
			if (this.contact.length > 0)
				this._filterContact(changes['users'].currentValue);

			this._noProfileCtr = 0;
			this.users = (changes['users'].currentValue as any[]).map((v: any) => {
				let { firstName, lastName, email, noProfileCtr } = this._getAvatar(v);
				return {
					...v,
					initials: getInitials({ firstName, lastName, email: email ?? '' }),
					noProfileCtr: !v.imagePath ? noProfileCtr : '',
				};
			});
		}
	}

	private _getAvatar(v: any) {
		let { firstName, lastName, email, id } = v;
		let noProfileCtr = 0;
		return this._viewTicket.addAvatar({
			firstName,
			lastName,
			email,
			userId: id,
			noProfileCtr,
			comingFrom: 'reply',
		});
	}

	private _filterContact(users: TicketContacts[]) {
		const usersId = users.map((v) => v.email);
		this.contact = this.contact.filter((v) => !usersId.includes(v.email));

		if (Array.isArray(this.contact) && this.contact.length) {
			this.userContactOptions = this.contact
				.filter((v) => v.name.trim() && v.email)
				.map((resp: { id: number; name: string | number; email: string }) => ({
					value: resp.email,
					display: resp.name,
					selected: false,
				}));
		}

		this._cd.markForCheck();
	}

	closeModal() {
		this.isOpenModal = false;
		this.close.emit(false);
	}

	addDeleteUser(email: string | null = null, index: number | null = null) {
		let emailList = [...this.users];

		if (!['', null].includes(email)) {
			let index = emailList.findIndex((v) => v.email === email);
			emailList.splice(index, 1);
		}

		let internalUser: string[] = emailList
			.filter((v) => v.isInternalUser)
			.map((v) => v.email);
		let externalUser: string[] = emailList
			.filter((v) => !v.isInternalUser)
			.map((v) => v.email);

		if (this.contactFc.value && this.contactFc.value.length > 0) {
			internalUser = [...internalUser, ...this.contactFc.value];
		}

		if (
			this.externalContactFc.value &&
			this.externalContactFc.value.length > 0
		) {
			externalUser = [...externalUser, ...this.externalContactFc.value];
		}

		let reqBody = {
			id: this.ticketId,
			internalUsers: internalUser.filter((v) => v !== null),
			externalUsers: externalUser.filter((v) => v !== null),
		};

		this._addRemoveContacts(this.ticketId, reqBody).subscribe((v) => {
			this.contactFc.setValue([]);
			this.contactFc.patchValue([]);
			this.contactFc.reset([]);
			this.externalContactFc.setValue([]);
			this.externalContactFc.patchValue([]);
			this.externalContactFc.reset([]);
			this.addDelete.emit(v);
		});
	}

	get isEnableAddUser() {
		if (this.contactFc.value) return this.contactFc.value.length > 0;
		return false;
	}

	get isEnableExternalUser() {
		if (this.externalContactFc.value)
			return this.externalContactFc.value.length > 0;
		return false;
	}

	private _getUserDropDown(searchText?: string) {
		return this._http.get<GenericDropdownWithEmail[]>(
			`${environment.apiBaseUrl}Users/GetDropdown?search=${searchText || ''}`
		);
	}

	private _getUserDropDownByCompanyId(companyId: number) {
		return this._http.get<GenericDropdownWithEmail[]>(
			`${environment.apiBaseUrl}Users/getDropdownByCompanyId/${companyId}`
		);
	}

	private _addRemoveContacts(
		ticketId: number,
		user: { internalUsers: string[]; externalUsers: string[] }
	) {
		return this._http.post(
			`${environment.apiBaseUrl}Tickets/AddOrRemoveContacts/${ticketId}`,
			user
		);
	}

	get activeUsers() {
		return this.users.length;
	}
}
