<!-- <div class="wrapper logout light-logout">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="token-img text-center">
                    <img src="assets/images/others/dark-logoutimg.png" class="img-fluid" alt="post" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="logout-wrap heading">

                    <h3>Connection Time Out</h3>
                    <p>You have been idle for too long and will be automatically logged out after 2 minutes to protect your data.</p>
                    <ul class="list-unstyled d-flex d-inline-flex">
                        <li><a [mat-dialog-close]="true" class="btn cancel-btn empty-btn common-btn">Stay Signed In</a></li>
                        <li><a [mat-dialog-close]="false" class="btn logout-btn common-btn">Log Out</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="verify-section verify-wrap logout">
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <div class="token-wrap heading">
                  <h3>Connection Time Out</h3>
                  <p>You have been idle for too long and will be automatically logged out after 2 minutes to protect your data.</p>
                  <form>
                      <div class="row">
                          <ul>
                              <li>
                                  <a id="mapper_staySignedIn" [mat-dialog-close]="true" type="button" class="btn verify-btn common-btn black-btn">Stay Signed In</a>
                              </li>
                              <li>
                                  <a id="mapper_logout" [mat-dialog-close]="false" class="btn verify-btn common-btn" >Log Out</a>
                              </li>
                          </ul>
                      </div>
                  </form>
              </div>
          </div>

          <div class="col-md-6">
              <div class="token-img">
                    <img image-error-detection [component]="'connection-timed-out-dialog.component.html'"
                    src="assets/images/others/dark-logoutimg.png" alt="post" />
              </div>
          </div>
      </div>
  </div>
</div>
