<div class="notifications">
  <div class="notifications__content">
    <div class="notifications__content--all font-b1">
      <switch-input
        [(ngModel)]="isSelectAll"
        [checked]="defaultSettings"
        (ngModelChange)="onChangeToggleButton()"
        [disabled]="!isCurrentUser">
      </switch-input>

      All Notifications
    </div>

    <div class="notifications__content--tickets" *ngFor="let setting of notificationSettings; let settingIndex = index;">
      <div class="ticket-row">
        <h3 class="ticket-row__title font-h3">{{ setting.name }}</h3>

        <div class="ticket-row__column row-title font-b1" *ngIf="settingIndex === 0">
          <span
            [tooltipContentV2]="{ message: 'Notifications delivered directly to your Desktop or Mobile device (if the app is installed)' }"
            [tooltipPosition]="'top'"
            [textAlign]="'center'">
            Push
          </span>

          <span
            [tooltipContentV2]="{ message: 'Notifications that appear within Quest on any device' }"
            [tooltipPosition]="'top'"
            [textAlign]="'center'">
            In-App
          </span>

          <span
            [tooltipContentV2]="{ message: 'Notifications that are sent via email to your inbox' }"
            [tooltipPosition]="'top'"
            [textAlign]="'center'">
            Email
          </span>
        </div>
      </div>

      <ng-container *ngFor="let subSetting of setting.subSettings; let subSettingIndex = index;">
        <div class="ticket-row row-1" [class.row-2]="subSettingIndex % 2 !== 0">
          <h3 class="ticket-row__item font-b1">{{ subSetting.name }}</h3>

          <div class="ticket-row__column font-b1">
            <span class="font-b1">
              <ng-container *ngIf="subSetting.hasNotification; else disabledCheckBoxRef">
                <checkbox-input
                  (ngModelChange)="updateNotificationSetting()"
                  [(ngModel)]="subSetting.isSendNotification"
                  [disabled]="!isCurrentUser">
                </checkbox-input>
              </ng-container>
            </span>

            <span class="font-b1">
              <ng-container *ngIf="subSetting.hasPushNotification; else disabledCheckBoxRef">
                <checkbox-input
                  (ngModelChange)="updateNotificationSetting()"
                  [(ngModel)]="subSetting.isSendPushNotification"
                  [disabled]="!isCurrentUser">
                </checkbox-input>
              </ng-container>
            </span>

            <span class="font-b1">
              <ng-container *ngIf="subSetting.hasEmail; else disabledCheckBoxRef">
                <checkbox-input
                  (ngModelChange)="updateNotificationSetting()"
                  [(ngModel)]="subSetting.isSendEmail"
                  [disabled]="!isCurrentUser">
                </checkbox-input>
              </ng-container>
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="notifications__content--button">
      <button id="mapper_notifSave" class="btn-2 btn-2-primary" *ngIf="isCurrentUser" (click)="onClickSave()">Save</button>
    </div>
  </div>
</div>

<ng-template #disabledCheckBoxRef>
  <mat-icon svgIcon="checkbox-disabled" style="stroke:#AFB0B3; width: 20px; height: 20px;"></mat-icon>
</ng-template>
