<modal-v2
	*ngIf="newFeatureInfoService.campaign$ | async as campaign"
	[modalWidth]="530"
>
	<ng-template #modalV2Header>
		<div class="new-feature-info_header q-d-flex q-ai-center">
			<div
				class="new-feature-info_header_icon q-d-flex q-jc-center q-ai-center"
			>
				<app-local-figma-icon-img
					[filename]="'info-circle-blue'"
					[width]="24"
					[height]="24"
					[hasSpaceOnRight]="false"
				></app-local-figma-icon-img>
			</div>

			<div class="new-feature-info_header_title font-h3">
				{{ campaign.header }}
				<ng-container *ngIf="!campaign.header"
					>New Features in Quest<sup>TM</sup></ng-container
				>
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Body>
		<div class="new-feature-info_body q-d-flex q-f-column q-ai-start q-g-16">
			<div id="imrf" class="gjs-parent-lory">
				<div id="itj1" class="gjs-lory-frame">
					<div id="ii4c" class="gjs-lory-slides">
						<div
							*ngFor="let feature of campaign.features; let i = index"
							class="gjs-lory-slide"
						>
							<div
								class="new-feature-info_content q-d-flex q-f-column q-ai-start q-g-32"
								style="user-select: none"
							>
								<div class="new-feature-info_text-bold font-h4">
									{{ feature.name }}
								</div>

								<div
									class="formatted-notes"
									[innerHtml]="feature.description"
								></div>

								<img
									[src]="feature.imagePath"
									class="new-feature-info_image"
									style="width: 100%; pointer-events: none"
								/>
							</div>
						</div>
					</div>
				</div>
				<span id="istu" class="gjs-lory-prev"></span>
				<span id="ineq5" class="gjs-lory-next"></span>
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Footer>
		<div
			*ngIf="dotsBtn.length"
			class="lory-slider-btn q-d-flex q-jc-end q-ai-center q-g-16 q-as-stretch position-relative q-w-100"
		>
			<div class="lory-slider-btn_dot">
				<div class="lory-slider-btn_container q-d-flex q-ai-start q-g-8">
					<ng-container *ngFor="let dot of dotsBtn">
						<a
              id="mapper_new-featureClick"
							href="javascript:void(0)"
							class="q-pointer"
							(click)="clickDot(dot)"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="13"
								viewBox="0 0 12 13"
								fill="none"
							>
								<circle
									cx="6"
									cy="6.27142"
									r="6"
									[attr.fill]="dot.isActive ? '#9C9DA0' : '#D9D9D9'"
								/>
							</svg>
						</a>
					</ng-container>
				</div>
			</div>

			<div
				class="lory-slider-btn_action q-d-flex q-jc-end q-ai-center q-g-16 q-as-stretch position-relative"
			>
				<button
					id="mapper_newFeatureSkipAll"
					*ngIf="dotsBtn.length > 1"
					class="btn-2 btn-2-tertiary"
					(click)="
						onClickSkip(
							campaign.isPreview ? 0 : campaign.id,
							!isLastClickOnNext
						)
					"
				>
					Skip All
				</button>
				<button
					id="mapper_newFeatureNextSlide"
					class="btn-2 btn-2-primary"
					(click)="
						isLastClickOnNext
							? onClickSkip(campaign.isPreview ? 0 : campaign.id, false)
							: nextSlide()
					"
				>
					{{ isLastClickOnNext ? 'Got It' : 'Next' }}
				</button>
			</div>
		</div>
	</ng-template>
</modal-v2>
