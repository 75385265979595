<div class="modal-body">
  <div class="ErrorModule-content">
    <p class="font-size-15" *ngIf="data.templateData && data.templateData['status'] === 'complete'">Mark this recommendation as complete if you were able to take care of this on your own!</p>
    <p class="font-size-15" *ngIf="data.templateData && data.templateData['status'] === 'incomplete'">You previously marked this recommendation as complete. If you would like to change this status back, click the “Mark Incomplete” button below.</p>
    <div class="transparent-card">
      <div class="inline-container">
        <p><span class="mr">Recommendation:</span> Enable MFA for all users</p>
      </div>
      <div class="inline-container flex-item">
        <ng-container *ngIf="data.templateData && data.templateData['status'] === 'complete'"><p class="mr">Status: </p><quest-status style="margin-right: 16px;" [status]="'incomplete'"></quest-status><img [src]="'/assets/icons/right-arrow.png'" alt=""><quest-status style="margin-left: 16px;" [status]="'completed'"></quest-status></ng-container>
        <ng-container *ngIf="data.templateData && data.templateData['status'] === 'incomplete'"><p class="mr">Status: </p><quest-status style="margin-right: 16px;" [status]="'completed'"></quest-status><img [src]="'/assets/icons/right-arrow.png'" alt=""><quest-status style="margin-left: 16px;" [status]="'incomplete'"></quest-status></ng-container>
      </div>
      <div class="inline-container">
        <p style="display: flex;align-items: center;"><span class="mr">Approver:</span> <span style="margin-right: 8px;">Pepper Pots</span> <img [src]="'/assets/icons/pepper.png'" alt=""></p>
      </div>
      <div class="inline-container">
        <ng-container *ngIf="data.templateData && data.templateData['status'] === 'incomplete'"><p class="note warn" > <span class="mr-1">Your new score would be 75 - Fair</span> <img [src]="'/assets/icons/down-trend-warn.png'" class="time" alt=""></p></ng-container>
        <ng-container *ngIf="data.templateData && data.templateData['status'] === 'complete'"><p class="note" > <span class="mr-1">Your new score would be 85 - Good</span> <img [src]="'/assets/icons/increase.png'" class="time" alt=""></p></ng-container>
      </div>
    </div>
    <div class="textarea-container" *ngIf="data.templateData && data.templateData['status'] === 'complete'">
      <h3>Tell us about how you covered this recommendation.</h3>
      <textarea placeholder="Our company uses..."></textarea>
    </div>
    <p style="font-size: 12px;"><span style="font-weight: 700;">Please be aware:</span> This may pose a risk to your company and Sourcepass’ understanding of your security ecosystem if this is not completed accurately. This should only be done if you can verify that this recommendation has been implemented. </p>
  </div>
</div>
<div class="modal-footer no-top-border q-g-16" [class.flex-start]="!data.options && data.altMessage">
   <app-tertiary-button
    *ngIf="data.options && data.options.noText"
    [btnText]="'Back'"
    (click)="closeModal(false)"
  >
  </app-tertiary-button>

  <button
    id="changeStatus"
    class="imp-disabled-op"
    *ngIf="data.options && data.options.yesText"
    type="button"
    [class.btn-2-primary]="
      data.type == 'success' ||
      data.type == 'info' ||
      data.type == 'warn'
    "
    [class.btn-2-destructive]="data.type == 'danger'"
    class="btn-2"
    (click)="closeModal(true)"
  >
  <ng-container *ngIf="data.templateData && data.templateData['status'] === 'complete'">  Mark Complete
    <img [src]="'/assets/icons/check.png'" class="time" alt=""></ng-container>
  <ng-container *ngIf="data.templateData && data.templateData['status'] === 'incomplete'">Mark Incomplete</ng-container>

  </button>
</div>
