<footer id="footer-section" class="footer" [class.is-public]="isPublic">

	<div class="footer__profile">
		<span *ngIf="!isPublic" class="footer__profile--label font-btn">
			<div class="footer_profile-ico">
				<mat-icon svgIcon="user-circle" style="stroke: #444548; width: 16px; height: 16px;"></mat-icon>
			</div>
			<div class="footer_profile-content">Profile:</div>
		</span>
		<span *ngIf="!isPublic" class="footer__profile--name font-btn">
			{{ getUser() }}
		</span>
	</div>

	<!-- <div
		class="footer__impersonate"
		*ngIf="userService.user?.impersonatingBy"
	>
		<span class="footer__impersonate--label">Impersonating:</span>
		<span class="footer__impersonate--name">{{ getImpersonate() }}</span>
	</div> -->

	<div class="footer__company">
		<div class="footer_link-container">
			<div class="footer_link-container_left">
				<span
					class="footer__company--copyright q-pointer"
					routerLink="/dashboard"
				>
					&copy; 2024 Sourcepass
				</span>
			</div>

			<div class="footer_link-container_right">
				<span *ngIf="!isPublic" class="footer__company--release">
					<a
          id="mapper_releaseNotes"
						href="javascript:void(0);"
						routerLink="/release-notes"
					>
						Release Notes
					</a>
				</span>
				<span class="footer__company--terms">
					<a
          id="mapper_openTerms"
						href="javascript:void(0);"
						(click)="openTermsConditionsModal(true)"
					>
						Portal Terms of Use
					</a>
				</span>
				<span class="footer__company--privacy">
					<a
            id="mapper_priv"
						href="https://sourcepass.com/legal/privacy-policy/"
						target="_blank"
					>
						Privacy Policy
					</a>
				</span>
			</div>
		</div>
	</div>
</footer>

<!-- <footer class="footer pt-3">
	<div class="container-fluid">
		<div class="row align-items-center justify-content-lg-between">
			<div class="col-lg-12">
				<div class="copyright text-center text-sm text-muted text-lg-start">
					<ng-container *ngIf="userService.user?.role; else loadingRole">
						<strong
							class="text-sm"
							[class.blink-soft]="userService.user?.impersonatingBy"
							*ngIf="userService.isAuthenticated"
						>
							{{ getUser() }}
						</strong>
						Copyright &copy;
						<a routerLink="/dashboard"><span>Sourcepass Inc.</span></a>
						<strong> | </strong>
						<a
							href="javascript:void(0);"
							(click)="openTermsConditionsModal(true)"
						>
							Quest&trade; Portal Terms of Use
						</a>
						<strong> | </strong>
						<a
							href="https://sourcepass.com/legal/privacy-policy/"
							target="_blank"
						>
							Privacy Policy
						</a>
					</ng-container>
					<ng-template #loadingRole> User Account is Loading... </ng-template>
				</div>
			</div>
		</div>
	</div>
</footer> -->

<!-- Terms and conditions pop-up modal -->
<div
	class="modal fade terms-conditions-modal show"
	id="TermsConditionsModal"
	tabindex="-1"
	aria-labelledby="TermsConditionsModalLabel"
	style="display: block"
	aria-modal="true"
	role="dialog"
	*ngIf="isOpenModal"
>
	<div class="switchCompanyModal-overlay"></div>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title text-sm" id="TermsConditionsModalCenterTitle">
					{{ termsTitle }}
				</h5>
				<button
        id="mapper_footerTerms"
					type="button"
					class="close"
					data-bs-dismiss="modal"
					aria-label="Close"
					(click)="openTermsConditionsModal(false)"
				>
					<span aria-hidden="true">
						<app-figma-icon-img
							[iconName]="'x'"
							[isForButton]="true"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="TermsConditions-content">
					<div class="description" [innerHTML]="htmlWithCss | safeHtml"></div>
				</div>
			</div>
			<div class="modal-footer">
				<button
        id="mapper_footercancelTerms"
					type="button"
					class="btn cancel-btn btn-gradient-primary addnewapp-btn btn-sm export mb-0 mt-sm-0 mt-1"
					style="
						background-color: #e9ecef !important;
						color: #000 !important;
						letter-spacing: 0.5px;
					"
					(click)="openTermsConditionsModal(false)"
				>
					Cancel
				</button>
			</div>
		</div>
	</div>
</div>
