export interface IActivePayments {
  id: number;
  companyName: string;
  isSubmitted: true;
  isCanceled: boolean;
  nextPayment: string;
  recurringAmount: number;
  frequency: string;
  paymentsLeft: number;
  startdate: string;
  dateCompleted: string;
  paymentAmount: number;
  totalAmount: number;
  remainingBalance: number;
  remarks: string;

  isChecked?: boolean;
}

export interface IArchivedPayments {
  id: number;
  companyName: string;
  isCompleted: boolean;
  isCanceled: boolean;
  recurringAmount: number;
  frequency: string;
  paymentsMade: number;
  startdate: string;
  dateCompleted: string;
  startingBalance: number;
}

export interface CompanyDetails {
  billingContact: BillingContact | null;
  pastDue: number;
  availableCredits: number;
}

export interface BillingContact {
  id: number;
  name: string;
  title: string;
  email: string;
  phone: string;
  companyName?: string;
}

export interface IPaymentArrangementData {
  id?: number | null;
  companyId?: number | null;
  billingContactId?: number | null;
  paymentMethodId?: string  | null;
  initialPayment?: number | null;
  paymentDate?: string | null;
  frequencyId?: number | null;
  startDate?: string | null;
  paymentCycles?: number | null;
  recurringAmount?: number | null;
  isSubmitted?: boolean | null;
  invoiceAssignment?: IInvoiceAssignment[];
}

export interface IInvoiceAssignment {
  invoiceId: number;
  balanceDue: number;
}

export interface IInvoice {
  id?: number;
  invoiceNumber?: string;
  invoiceDate?: string;
  dueDate?: string | null;
  billAmount?: number;
  currentBalance?: number;
  status?: string;
  color?: string;
  fontColor?: string;
  isAdded?: boolean;
  balanceDue?: number;
  isSettled?: boolean;
}

export interface IActivePaymentDetails {
  startingArrangementBalance: number;
  totalPaid: number;
  remainingArrangementBalance: number;
  billingContact: BillingContact;
  invoices: IInvoice[];
  isSubmitted: boolean;
  isCanceled: boolean;
}

export interface IPaymentInfo {
  setup?: ISetup | null;
  paymentSchedules?: IPaymentSchedules[];
}

export interface ISetup {
  id: number;
  paymentMethodId: string;
  companyId: number;
  companyName: string;
  billingContactId: number;
  firstName: string;
  email: string;
  initialPayment: number;
  paymentDate: string;
  frequencyId: number;
  frequency: string;
  startDate: string;
  paymentCycles: number;
  recurringAmount: number;
  isCanceled: boolean;
  isSubmitted: boolean;
  isCompleted: boolean;
}

export interface IPaymentSchedules {
  id: number;
  scheduledDate: string;
  amount: number;
}

export interface IPaymentArrangementFormData {
  id?: number | null;
  isEdit?: boolean;
  totalPaymentAmount?: number | null;
  data?: IPaymentArrangementData | null;
  availableCredits?: number;
  initialPayment?: number;
  recurringAmount?: number;
  netBalance?: number;
  paymentCycles?: number;
  frequency?: string;
  step?: number;
  startDateFrom?: string;
  endDateTo?: string;
}

export interface IPaymentSetupForm {
  billingInfo?: IActivePaymentDetails | null;
  paymentInfo?: IPaymentInfo | null;
}

export interface ITemporarySchedule {
  id: number;
  scheduledDate: string;
  amount: number;
}