import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { NameId } from '@app/shared/interfaces/support.interface';
import { nonZeroValidator } from '@app/shared/utilities/helper';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { PaymentArrangementsService } from '../../payment-arrangements/payment-arrangements.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { IPaymentArrangementFormData, IPaymentInfo, IPaymentSchedules, ITemporarySchedule } from '../../payment-arrangements/payment-arrangements.interface';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'payment-section',
  templateUrl: './payment-section.component.html',
  styleUrls: ['./payment-section.component.scss'],
})
export class PaymentSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onUpdateContent: EventEmitter<void> = new EventEmitter<void>();
  frequencies: NameId[] = [];
  form: FormGroup;
  id: number;
  legalConfirmationTemplate: string = '';
  isEdit: boolean = false;
  paymentSchedules: ITemporarySchedule[] = [];
  isLoadingSchedules: boolean = false;
  searchTimeout: any;

  paymentArrangementService = inject(PaymentArrangementsService);

  private _notifier = inject(NotificationService);
  private _fb = inject(FormBuilder);
  private _clipboard = inject(Clipboard);
  private _cd = inject(ChangeDetectorRef);
  private _datePipe = inject(DatePipe);
  private _route = inject(ActivatedRoute);
  private _$unsubscribe: Subject<void> = new Subject<void>();

  get defaultFormVal() {
    return {
      initialPayment: null,
      recurringAmount: null,
      frequencyId: 1,
      paymentDate: '',
      startDate: '',
      paymentCycles: null,
      legalConfirmationContent: ''
    }
  }

  get isSubmitted() {
    return this.paymentArrangementService.data.isSubmitted as boolean;
  }

  ngOnInit(): void {
    this.isEdit = !!Number(this._route.snapshot.paramMap.get('id'));

    this._getFrequencies();
    this._initForm();
  }
  
  private _initForm() {
    this.form = this._fb.group({
      initialPayment: [null],
      recurringAmount: [null, [Validators.required, nonZeroValidator]],
      frequencyId: ['', Validators.required],
      paymentDate: [''],
      startDate: ['', Validators.required],
      paymentCycles: [null, [Validators.required, nonZeroValidator]],
      legalConfirmationContent: [this.legalConfirmationTemplate]
    });
  }

  ngAfterViewInit(): void {
    
  }

  copyToClipboard(content: string) {
    this._clipboard.copy(content);
  }

  convertIntoDecimal(controlName: string) {
    if (this.form.controls[controlName].value !== null && this.form.controls[controlName].value !== '') {
      this.form.controls[controlName].setValue(Number(this.form.controls[controlName].value).toFixed(2));
    }
  }

  private _getFrequencies() {
    this.paymentArrangementService.getFrequencies()
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (res) => {
        this.frequencies = res;

        if (this.frequencies.length) {
          this.form.controls['frequencyId'].setValue(this.frequencies[0].id);
        }
      },
      error: () => {
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
      }
    });
  }

  onBreakDown(delayTime: number = 0) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.form.controls['paymentDate'].markAsUntouched();
      if (this.form.controls['initialPayment'].value > 0) {
        this.form.controls['paymentDate'].setValidators([Validators.required]);
      } else {
        this.form.controls['paymentDate'].clearValidators();
      }
  
      this.form.controls['paymentDate'].updateValueAndValidity();
      
      const index = this.frequencies.findIndex(x => x.id === this.form.controls['frequencyId'].value);
      if (index !== -1) { this.paymentArrangementService.formData.frequency = this.frequencies[index].name; }
    
      this.onChangeFrequency();
      this._cd.detectChanges();
		}, delayTime);
  }

  clearForm() {
    this.form.reset();
    this.form.patchValue(this.defaultFormVal);
  }

  onChangeFrequency() {
    if (this.paymentArrangementService.formData.step !== 3) { return; }

    this.isLoadingSchedules = true; 

    const params = {
      balance: this.paymentArrangementService.formData.totalPaymentAmount,
      initialPayment: this.form.controls['initialPayment'].value,
      recurringAmount: this.form.controls['recurringAmount'].value,
      paymentCycles: this.form.controls['paymentCycles'].value,
      frequencyId: this.form.controls['frequencyId'].value,
      startDate: this.form.controls['startDate'].value
    };

    this.paymentArrangementService.getTemporarySchedule(params)
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (res) => {
        this.paymentSchedules = res;
        this._updateScroll();

        if (this.isEdit) {
          this.paymentArrangementService.formData.startDateFrom = this.paymentArrangementService.getFirstAndLastDate(this.paymentSchedules).startDateFrom;
          this.paymentArrangementService.formData.endDateTo = this.paymentArrangementService.getFirstAndLastDate(this.paymentSchedules).endDateTo;
        }

        /* [Start]::Calculations */ 
        this.paymentArrangementService.formData.initialPayment = Number(this.form.controls['initialPayment'].value !== null ? this.form.controls['initialPayment'].value : 0);
        this.paymentArrangementService.formData.recurringAmount = this.paymentArrangementService.calculateRecurringPayments(this.paymentSchedules.map(x => x.amount));
        this.paymentArrangementService.formData.netBalance = Number(this.paymentArrangementService.formData.totalPaymentAmount) - (Number(this.paymentArrangementService.formData.initialPayment) + Number(this.paymentArrangementService.formData.recurringAmount));
        /* [End]::Calculations */

        this.onUpdateContent.emit();
        this.isLoadingSchedules = false;
        this._cd.detectChanges();
      },
      error: () => {
        this.isLoadingSchedules = false;
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
        this._cd.detectChanges();
      }
    })
  }

  private _updateScroll() {
    setTimeout(() => {
      const element: any = document.querySelector('#payment-schedule .scroll-v2');
      element?.scrollHeight > element?.clientHeight ? element?.classList.add('q-pr-8') : element?.classList.remove('q-pr-8');
    }, 0);
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
