<label
	class="checkbox-input-container font-b1 font-h-normal"
	[class.reverse]="labelPosition == 'before'"
	[class.is-disabled]="disabled"
>
	<input
		class="checkbox-input"
		type="checkbox"
		[disabled]="disabled"
		[ngModel]="checked"
		(ngModelChange)="onModelChange($event)"
	/>
	<div class="checkbox-input-v2">
		<svg *ngIf="!checked && !indeterminate" class="not-checked" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<rect x="1" y="1" width="18" height="18" rx="2" stroke="red" stroke-width="2"/>
		</svg>
		<svg *ngIf="!checked && indeterminate" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<rect width="20" height="20" rx="3" fill="red"/>
			<path d="M6 10L14 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		<svg *ngIf="checked" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<rect width="20" height="20" rx="3" fill="red"/>
			<path d="M5 10L8.33333 14L15 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	</div>
	<ng-content></ng-content>
</label>
