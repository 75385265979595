<div
  class="date-picker-container"
  (click)="datepicker.open()"
>
  <input
    [id]="id"
    [class]="inputClass"
    class="font-b1"
    matInput
    [matDatepicker]="datepicker"
    errorTooltip
    placeholder="MM/DD/YYYY"
    [min]="min"
    [max]="max"
    [disabled]="disabled"
    [(ngModel)]="value"
    #input="ngModel"
  >

  <mat-icon svgIcon="calendar-date"></mat-icon>

  <mat-datepicker-toggle
    matIconSuffix
    [for]="datepicker"
  ></mat-datepicker-toggle>
  <mat-datepicker
    #datepicker
    panelClass="date-picker"
    (closed)="onCloseCalendar($event)"
  >
    <mat-datepicker-actions>
      <div>
        <button
        id="mapper_clearDate"
          mat-button
          (click)="datepicker.close();onClearDate()"
        >Clear</button>
        <button
        id="mapper_todayDate"
          mat-button
          (click)="datepicker.close();onToday()"
        >Today</button>
      </div>
      <button
      id="mapper_applyDate"
        mat-raised-button
        color="primary"
        matDateRangePickerApply
        (click)="onApply()"
      >Apply</button>
    </mat-datepicker-actions>
  </mat-datepicker>
</div>
