<div id="company-details-form">
  <form [formGroup]="form" class="q-grayed-box q-d-grid q-g-24">
    <div class="q-d-flex q-ai-start q-g-24 q-as-stretch">
      <div class="col-group q-d-flex q-ai-start q-g-24 q-as-stretch q-w-100">
        <div class="company-form q-d-grid q-g-16 q-f-1-0-0 q-as-stretch">
          <div class="q-d-flex q-f-column q-ai-start q-g-8">
            <label class="font-b1">Company</label>

            <select-input
              [showSearchIcon]="true"
              [options]="companies"
              [optionKeys]="{ value: 'id', label: 'name' }"
              inputClass="form-control"
              placeholder="Search Company"
              class="input-class"
              formControlName="companyId"
              (onChange)="handleCompanyDetailsChange()">
            </select-input>
          </div>

          <div class="q-d-flex q-ai-start q-g-24">
            <span class="font-b1">Past Due:</span>

            <h4 class="q-d-flex q-jc-center q-ai-center q-f-1-0-0" *ngIf="!isLoadingCompanies && companyDetails">
              {{ companyDetails.pastDue | currency }}
            </h4>

            <div class="skeleton q-w-100 q-d-flex q-jc-center q-ai-center q-f-1-0-0" *ngIf="isLoadingCompanies"></div>
          </div>
        </div>

        <div
          class="billing-details q-p-24 q-w-100"
          [class.disabled-contact]="form.controls['alternateContactId'].value">
          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Billing Contact:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingCompanies">
              <ng-container *ngIf="companyDetails">
                {{ companyDetails.billingContact?.name }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingCompanies"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Title:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingCompanies">
              <ng-container *ngIf="companyDetails">
                {{ companyDetails.billingContact?.title }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingCompanies"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Email Address:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingCompanies">
              <ng-container *ngIf="companyDetails">
                {{ companyDetails.billingContact?.email }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingCompanies"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Phone Number:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingCompanies">
              <ng-container *ngIf="companyDetails">
                {{ companyDetails.billingContact?.phone }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingCompanies"></div>
          </div>

          <div class="q-error-content" *ngIf="form.controls['billingContactId'].invalid && form.controls['billingContactId'].touched">
            Billing Contact Required
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="q-w-100">
      <button
        id="mapper_initUser"
        class="btn-2 btn-2-tertiary"
        *ngIf="!isAlternativeContactToggle"
        (click)="initUsers()">

        <mat-icon svgIcon="user-edit" class="svg-icon"></mat-icon>
          Use Alternate Contact
        </button>
    </div>

    <div class="q-d-flex q-ai-start q-g-24 q-as-stretch q-w-100" *ngIf="isAlternativeContactToggle">
      <div class="col-group q-d-flex q-ai-start q-g-24 q-as-stretch q-w-100">
        <div class="company-form q-d-flex q-f-column q-ai-start q-g-16 q-f-1-0-0 q-as-stretch">
          <div class="q-d-flex q-f-column q-ai-start q-g-8">
            <div class="q-d-flex q-ai-end q-g-8 q-w-100">
              <div class="q-d-grid q-g-8 q-w-100">
                <label class="font-b1">Use Alternate Contact</label>
                <select-input
                  [showSearchIcon]="true"
                  [options]="userDropdownOptions"
                  [optionKeys]="{ value: 'id', label: 'name' }"
                  inputClass="form-control"
                  placeholder="Search Users"
                  class="input-class"
                  formControlName="alternateContactId"
                  (onChange)="handleAlternativeContactChange()">
                </select-input>
              </div>

              <div>
                <button id="mapper_alternateContact" class="btn-2 btn-2-destructive" (click)="onRemoveAlternativeContact()" [disabled]="isSubmitted">
                  <mat-icon svgIcon="trash-01" class="svg-icon"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="billing-details q-p-24 q-w-100">
          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Billing Contact:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingAlternateContact">
              <ng-container *ngIf="alternateContactDetails">
                {{ alternateContactDetails.billingContact?.name }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingAlternateContact"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Title:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingAlternateContact">
              <ng-container *ngIf="alternateContactDetails">
                {{ alternateContactDetails.billingContact?.title }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingAlternateContact"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Email Address:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingAlternateContact">
              <ng-container *ngIf="alternateContactDetails">
                {{ alternateContactDetails.billingContact?.email }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingAlternateContact"></div>
          </div>

          <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
            <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Phone Number:</p>

            <p class="font-b1 q-f-1-0-0" *ngIf="!isLoadingAlternateContact">
              <ng-container *ngIf="alternateContactDetails">
                {{ alternateContactDetails.billingContact?.phone }}
              </ng-container>
            </p>

            <div class="skeleton q-w-100" *ngIf="isLoadingAlternateContact"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
