<modal-v2>
	<ng-template #modalV2Header>
		<div class="sms-mobile-update_group">
			<div class="sms-mobile-update_group_icon">
				<app-local-figma-icon-img
					[filename]="'info-circle-blue'"
					[width]="24"
					[height]="24"
				></app-local-figma-icon-img>
			</div>

			<div class="sms-mobile-update_group_title font-h3">
				Opt-in for SMS Updates on Quest Mobile
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Body>
		<form class="sms-mobile-update_body" [formGroup]="modalForm">
			<div class="sms-mobile-update_body_paragraph font-b1">
				We are super excited about the release of the upcoming Quest<span
					class="symbol symbol-tm"
				></span>
				mobile app! You're invited to enter your phone number below to receive
				SMS messages in order to stay in the know about Quest<span
					class="symbol symbol-tm"
				></span>
				mobile!
			</div>

			<div class="sms-mobile-update_body_form-group">
				<div class="sms-mobile-update_body_form-group_label font-b1">
					Phone Number
				</div>

				<phone-input formControlName="phoneNumber"> </phone-input>
			</div>

			<div class="sms-mobile-update_body_form-group">
				<div
					class="sms-mobile-update_body_form-group_label font-b1 line-height-27"
				>
					What type of device do you have?
				</div>

				<div class="sms-mobile-update_body_form-group_input-radio">
					<radio-input
						[id]="deviceType.ios"
						[label]="deviceType.ios"
						formControlName="typeOfDevice"
						[name]="'mobileType'"
						[value]="deviceType.ios"
					>
					</radio-input>

					<radio-input
						[id]="deviceType.android"
						[label]="deviceType.android"
						formControlName="typeOfDevice"
						[name]="'mobileType'"
						[value]="deviceType.android"
					>
					</radio-input>
				</div>
			</div>

			<div class="sms-mobile-update_body_note font-b3">
				<b class="font-weight-700">Please Note:</b> By signing up, you agree to
				receiving recurring messages. Standard message and data rates apply.
				Reply STOP to unsubscribe at anytime.
			</div>
		</form>
	</ng-template>

	<ng-template #modalV2Footer>
		<app-tertiary-button [btnText]="'Skip'" (click)="skip()">
		</app-tertiary-button>

		<app-tertiary-button [btnText]="'Remind Me Later'" (click)="remindMe()">
		</app-tertiary-button>

		<button
			type="submit"
			class="btn-2 btn-2-primary"
			(click)="signUp()"
			id="mapper_smsSignUp"
		>
			Sign Up
		</button>
	</ng-template>
</modal-v2>
