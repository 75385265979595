export enum SPAdminRoutes {
	GLOBAL_DIRECTORY = '/contacts',
	COMPANY_DIRECTORY = '/companies/contact-setting/directory/',
	INVOICES_ADMIN = '/billing-orders/invoices',
	GLOBAL_APPLICATION_CATEGORY = '/system/application/categories',
	COMPANY_APPLICATION_CATEGORY = '/companies/application/categories/',
	GLOBAL_APPLICATION = '/system/application/applications',
	COMPANY_APPLICATION = '/companies/application/applications/',
	GLOBAL_KB = '/service-and-support/knowledge-base/topics',
	COMPANY_KB = '/companies/knowledge-base/topics/',
	GLOBAL_KB_CATEGORY = '/system/knowledge-base/categories',
	COMPANY_KB_CATEGORY = '/companies/knowledge-base/categories/',
	DASHBOARD_SETUP = '/system/dashboard-setup',
	GLOBAL_REQUEST_FORM = '/service-and-support/request-forms',
	COMPANY_REQUEST_FORM = '/companies/service-and-support/request-forms/',
	GLOBAL_EMAIL_TEMPLATE = '/communications/email-templates',
	COMPANY = '/companies',
	GLOBAL_NOTIFICATION = '/communications/notification-center/territory/edit-notification/',
	GLOBAL_NOTIFICATION_VIEW = '/communications/notification-center/territory/view-notification/',
	GLOBAL_NOTIFICATION_FILTER_BY_COMPANY = '/communications/notification-center/filter-by-company/',
	COMPANY_NOTIFICATION = '/companies/contact-setting/edit-notification/',
	GLOBAL_SUPPORT_CATEGORY = '/system/service-and-support/categories',
	COMPANY_SUPPORT_CATEGORY = '/companies/service-and-support/categories/',
	COMMUNICATIONS_TEMPLATE_STYLES_FONTS = '/communications/email-template-styles/fonts',
	COMMUNICATIONS_TEMPLATE_STYLES_BUTTONS = '/communications/email-template-styles/buttons',
	REPORTS_USER_LOGIN_REPORT = '/reports/user-login-report',
	REPORTS_EMAIL_NOTIF_REPORT = '/reports/email-notification-report',
	REPORTS_AUDIT_TRAIL_REPORT = '/reports/audit-trail-report',
	REPORTS_FINANCE_REPORT = '/reports/finance-report',
	REPORTS_NETSUITE_REPORT = '/reports/netsuite-credits-report',
	REPORTS_AUTOPAY_REPORT = '/reports/autopay-report',
	REPORTS_PORTAL_USAGE = '/reports/portal-usage',
}

export enum ClientAdminRoutes {
	DIRECTORY = '/contacts',
	APPLICATION_CATEGORY = '/application/categories',
	APPLICATION = '/application/applications',
	KB = '/service-and-support/knowledge-base/topics',
	KB_CATEGORY = '/knowledge-base/categories',
	REQUEST_FORM = '/service-and-support/request-forms',
	SUPPORT_CATEGORY = '/service-and-support/categories',
}

export enum UserRoutes {
	TICKETS = '/service-and-support/tickets',
	ORDERS = '/billing-and-orders/orders',
	QUOTES = '/billing-and-orders/quotes',
	ASSETS = '/company/assets',
	INVOICES_USER = '/billing-and-orders/invoices',
	INVOICES_ADMIN = '/billing-orders/invoices',
	DIRECTORY = '/company/contacts/directory',
	APPLICATION_CATEGORY = '/company/applications/manage-categories',
	APPLICATION = '/company/applications/manage-applications',
	DASHBOARD = '/dashboard',
}
