import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';

export enum NotificationType {
	Tickets = 1,
	BillingOrders,
	Quest,
}

export enum NotificationTimeType {
	Today = 1,
	Yesterday,
	ThisWeek,
	ThisMonth,
	Older,
}

export enum NotificationCategory {
	Tickets = 2,
	Orders,
	Invoices = 7,
	Quest,
	Approvals,
	Arrangements,
}

export interface Notification {
	id: number;
	refId: number;
	title: string;
	message: string;
	categoryId: NotificationCategory;
	isRead: boolean;
	createdDate: string;
	createdBy: string;
	ids?: number[];
}

export interface NotificationFormatted extends Notification {
	type: NotificationType;
	url: string;
	queryParams?: any;
}

export interface NotificationTime {
	name: string;
	notifications: NotificationFormatted[];
}

export interface NotificationResult extends ResponseResult<Notification[]> {
	ticketUnreadCount?: number;
	billingOrderUnreadCount?: number;
	questUnreadCount?: number;
}
