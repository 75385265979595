<div id="view-arrangement-details" class="card-2 q-mb-24">
  <div class="card-header-2 q-ai-center q-jc-start">
    <h3 class="q-mr-auto">View Arrangement Details</h3>

    <export-btn *ngIf="!isAdmin" (onExport)="export($event)"></export-btn>

    <a class="btn-2 btn-2-secondary" [routerLink]="invoicePath +'/payment-arrangements/active-payment-arrangements'">
      <mat-icon svgIcon="chevron-left"></mat-icon>
      Back
    </a>

    <ng-container *ngIf="isAdmin">
      <a
        href="javascript:void(0)"
        class="q-pointer q-d-flex q-ai-center"
        [matMenuTriggerFor]="actionButtonsRef">
        <app-figma-icon-img
          [iconName]="'dots-vertical'"
          [width]="24"
          [height]="24"
          [isForButton]="true"
          [hasSpaceOnRight]="false">
        </app-figma-icon-img>
      </a>

      <mat-menu #actionButtonsRef="matMenu" class="v2-menu-action-button">
        <button id="mapper_expCSV" mat-menu-item (click)="export(applicationType.CSV)">
          <div class="menu-item-group">
            <div class="menu-item-icon">
              <mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
            </div>
            <div class="menu-item-name font-b3">Export As CSV</div>
          </div>
        </button>

        <button id="mapper_expXLS" mat-menu-item (click)="export(applicationType.EXCEL)">
          <div class="menu-item-group">
            <div class="menu-item-icon">
              <mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
            </div>
            <div class="menu-item-name font-b3">Export As XLSX</div>
          </div>
        </button>

        <ng-container *ngIf="!isLoading">
          <button id="mapper_editArr" mat-menu-item [routerLink]="'/billing-orders/payment-arrangements/setup-payment-arrangement/edit/' + id" [disabled]="paymentDetails.isSubmitted">
            <div class="menu-item-group">
              <div class="menu-item-icon">
                <mat-icon svgIcon="edit-02" class="menu-item-svg"></mat-icon>
              </div>
              <div class="menu-item-name font-b3">Edit Arrangement</div>
            </div>
          </button>

          <button id="mapper_cancelArr" mat-menu-item class="btn-2-destructive" (click)="cancel()" [disabled]="paymentDetails.isCanceled">
            <div class="menu-item-group" style="background: transparent !important;">
              <div class="menu-item-icon">
                <mat-icon svgIcon="x-close" class="menu-item-svg"></mat-icon>
              </div>
              <div class="menu-item-name font-b3">Cancel Arrangement</div>
            </div>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </div>

  <div class="skeleton q-w-100" *ngIf="isLoading; else displayedData"></div>

  <ng-template #displayedData>
    <h3>{{ paymentDetails.billingContact.companyName }}</h3>

    <div class="q-d-flex q-ai-center q-g-24 q-as-stretch">
      <div class="grayed-box-details q-p-24">
        <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
          <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Billing Contact:</p>
          <p class="font-b1 q-f-1-0-0">{{ paymentDetails.billingContact.name }}</p>
        </div>

        <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
          <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Title:</p>
          <p class="font-b1 q-f-1-0-0">{{ paymentDetails.billingContact.title }}</p>
        </div>

        <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
          <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Email Address:</p>
          <p class="font-b1 q-f-1-0-0">{{ paymentDetails.billingContact.email }}</p>
        </div>

        <div class="q-d-flex q-jc-end q-ai-center q-g-16 q-f-1-0-0">
          <p class="font-b1 q-d-flex q-ai-center q-as-stretch">Phone Number:</p>
          <p class="font-b1 q-f-1-0-0">({{ paymentDetails.billingContact.phone }})</p>
        </div>
      </div>

      <div class="q-d-flex q-f-column q-ai-start q-g-8">
        <div class="q-widget-panel danger q-d-flex q-p-16 q-jc-between q-ai-center">
          <span class="font-btn">Starting Arrangement Balance</span>
          <h4>{{ paymentDetails.startingArrangementBalance | currency }}</h4>
        </div>

        <div class="q-widget-panel success q-d-flex q-p-16 q-jc-between q-ai-center">
          <span class="font-btn">Total Paid</span>
          <h4>{{ paymentDetails.totalPaid | currency }}</h4>
        </div>

        <div class="q-widget-panel default q-d-flex q-p-16 q-jc-between q-ai-center">
          <span class="font-btn">Remaining Arrangement Balance</span>
          <h4>{{ paymentDetails.remainingArrangementBalance | currency }}</h4>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="scroll-v2">
    <div class="table-container">
      <div class="table-wrapper">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="invoiceDate"
          matSortDisableClear="true"
          (matSortChange)="sortChangeEvent($event)">

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Invoice #
            </th>

            <td mat-cell *matCellDef="let row">{{ row.invoiceNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef>
              Invoice Date
            </th>

            <td mat-cell *matCellDef="let row">
              {{ row.invoiceDate ? (row.invoiceDate | customDate) : '—' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Due Date
            </th>

            <td mat-cell *matCellDef="let row">{{ row.dueDate ? (row.dueDate | customDate) : '—' }}</td>
          </ng-container>

          <ng-container matColumnDef="billAmount">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-end">Bill Amount</div>
            </th>

            <td mat-cell *matCellDef="let row">
              <div class="text-end">{{ row.billAmount | currency }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="currentBalance">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-end">Balance Due</div>
            </th>
    
            <td mat-cell *matCellDef="let row">
              <div class="text-end">{{ row.currentBalance | currency }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>

            <td mat-cell *matCellDef="let row">
              <div class="q-d-flex q-ai-center q-g-10 q-as-stretch">
                <div class="q-d-flex">
                  <app-badge
                    [label]="row.status ?? 'Loading ...'"
                    [type]="row.color ?? '#000'"
                    [fontColor]="row.fontColor ?? '#fff'">
                  </app-badge>
                </div>
                <mat-icon
                  *ngIf="row.remarks"
                  svgIcon="alert-triangle"
                  class="alert-triangle-icon"
                  [tooltipContentV2]="{ message: row.remarks}">
                </mat-icon>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns">
          </tr>

          <tr class="mat-row" *matNoDataRow>
            <table-skeleton
              [isLoading]="tableSub && !tableSub.closed"
              [colCount]="displayedColumns.length"
              [pageSize]="7">
            </table-skeleton>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<app-audit-trail
  [url]="historyUrl.PAYMENT_ARRANGEMENTS"
  history_category="default"
  [id] = "id">
</app-audit-trail>
