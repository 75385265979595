import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	inject,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { ModalTooltipComponent } from '../../modal-utils/tooltip/tooltip.component';
import { NewTableSharedModule } from '@app/shared/new-table-shared/new-table-shared.module';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { TotalTicketsFilter } from '@app/shared/interfaces/total-tickets.interface';
import { DashboardReportService } from '@app/modules/dashboard/pages/dashboard/dashboard-report.service';
import { SharedMessagingModule } from '@app/shared/components/messaging/shared-messaging.module';
import { ClientMessageService } from '@app/modules/client-message/client-message.service';
import { catchError, Subscription } from 'rxjs';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { ticketData } from './dummy-data.const';
import {
	ITicket,
	UserTicketPreviousAndNext,
} from '@app/shared/components/messaging/view-ticket/ticket.interface';
import { PdfTicketComponentModal } from '../pdf-ticket/pdf-ticket.component';
import { NotificationMessages } from '@app/shared/constants';
import { DashboardReportTab } from '@app/modules/dashboard/pages/dashboard/dashboard-report.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { SupportModule } from '@app/modules/service-and-support/support.module';
import { TicketDetailsModule } from '@app/modules/service-and-support/pages/ticket/ticket-details/ticket-details.module';

@Component({
	selector: 'app-total-tickets',
	templateUrl: './total-tickets.component.html',
	styleUrls: ['./total-tickets.component.scss'],
	standalone: true,
	imports: [
		SharedMessagingModule,
		SharedModule,
		ModalTooltipComponent,
		NewTableSharedModule,
		PdfTicketComponentModal,
		TicketDetailsModule,
	],
})
export class TotalTicketsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: IDataModal;
	navData: UserTicketPreviousAndNext | null;
	DashboardReportTab = DashboardReportTab;
	searchFilters: any;
	dataModalService = inject(DataModalService);
	dashboardReport = inject(DashboardReportService);
	toastMessageService = inject(ToastMessageService);
	ticketDetails: any;
	currentState: string = 'ticketList';
	_cd = inject(ChangeDetectorRef);
	_ticketService = inject(TicketService);
	_toastMessageService = inject(ToastMessageService);
	message = {
		m1: 'Results are limited to the first 100 tickets. Use the scroll bar to view more tickets or download the file to view all tickets.',
		m2: '',
	};

	ticket: any;
	ticketList: any[];
	subscription: Subscription = new Subscription();
	pdfData: { pdfUrl: string; pdfId: number };
	constructor() {}

	ngOnInit(): void {
		this.subscription.add(
			this.dashboardReport.tableData$.subscribe(
				(data) => (this.ticketList = data)
			)
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
		this.dashboardReport.resetTotalTickets();
	}

	onEmitState(state: string) {
		this.currentState = state;
	}

	fetchNewData() {
		const filters: IPageState = this._getFilters();

		this.searchFilters = filters;
	}

	nextPrevTicket(ticketEvt: { id: number }) {
		this.fetchTicket(ticketEvt.id);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.fetchNewData();
			this._cd.detectChanges();
		}, 0);
	}

	onEmitPDFData(evt: any) {
		this.pdfData = evt;
	}

	download() {
		this.dashboardReport
			.downloadTicket(this.data.templateData!['pdfUrl'], {
				...this._getFilters(),
				exportType: 1,
			})
			.subscribe({
				next: (res: any) => {
					const link = document.createElement('a');
					link.href = res;
					link.setAttribute('download', 'Quotes');
					document.body.appendChild(link);
					link.click();

					// const link = document.createElement('a');
					// 		link.href = resp;
					// 		link.setAttribute('download', 'Quotes');
					// 		document.body.appendChild(link);
					// 		link.click();
				},
				error: () => {
					this.toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
				complete: () => {
					this.toastMessageService.showSuccessMessage(
						NotificationMessages.Export
					);
				},
			});
	}

	private _getFilters(): TotalTicketsFilter {
		return this.data.templateData!['filters'];
		// return {
		// 	page: 1,
		// 	pageSize: 10,
		// 	order: 'desc',
		// 	column: 'ticketNo',
		// 	query: '',
		// 	month: this.data.templateData!['month'],
		// 	isVIP: this.data.templateData!['isVIP'],
		// };
	}

	capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	openLink(row: { [prop: string]: any }) {
		this.currentState = 'ticketDetails';
		this._cd.detectChanges();
		this.ticket = null;
		this.fetchTicket(row['ticketId']);
		// call ticket
	}

  fetchTicket(ticketId: number) {
    this.ticket = null;
    this._ticketService.getTicket(ticketId).subscribe((data) => {
      this.ticket = { ...data };
      this.ticketDetails = {
        serviceTimes: {
          isClientAdmin: true,
          acknowledgementTime: data.ticket.acknowledgementTime,
          responseTime: data.ticket.responseTime,
          resolutionTime: data.ticket.resolutionTime,
        },
        timeline: {
          lastUpdated: data.ticket.lastUpdated,
          dateCreated: data.notes[0]?.startDate ?? 'N/A',
          dateDifference: (() => {
            // Calculate the difference in milliseconds
            let dateCreated = new Date(data.notes[0]?.startDate!);
            let today = new Date();
            if (
              !isNaN(dateCreated.getTime()) &&
              !isNaN(dateCreated.getTime())
            ) {
              const diffInMs = Math.abs(
                today.getTime() - dateCreated.getTime()
              );

							// Convert milliseconds to minutes
							const diffInMinutes = Math.floor(diffInMs / 1000 / 60);

							return diffInMinutes;
						}
						return 0;
					})(),
				},
				ticketProperties: {
					id: data.ticket.id,
					origin: data.ticket.boardName,
					category: (() => {
						const regexPattern = /Category:\s*([^/<]+)/;
						const match = data.notes[0]?.note.match(regexPattern);
						if (match && match[1]) {
							return match[1].trim(); // Trim to remove any leading or trailing whitespace
						}
						return null;
					})(),
					subCategory: (() => {
						const regexPattern = /Category:\s*[^/<]+\s*\/\s*([^<]+)/;

						const match = data.notes[0]?.note.match(regexPattern);
						if (match && match[1]) {
							return match[1].trim();
						}
						return null;
					})(),
				},
				requestedFor: data.contacts.filter((user) => user.isRequestedFor),
				teamMembers: data.contacts.filter((user) => user.isTeamMember),
				assignedTech: data.contacts.filter((user) => user.isAssignedTech),
				pastTech: data.contacts.filter((user) => user.isPastTechnician),
				externalUsers: data.contacts.filter((user) => user.isExternalUser),
				contacts: data.contacts,
			};
			this.navData = null;
			this.getNextAndPrev(this.ticket);
		});
	}

	getNextAndPrev(ticket: any) {
		this.dashboardReport
			.getNextAndPrevious(
				ticket?.ticket?.id,
				this.dashboardReport.currentFilters
			)
			.subscribe({
				next: (res) => {
					this.navData = res as UserTicketPreviousAndNext;
				},
				error: (e: HttpErrorResponse) => {
					this._toastMessageService.showErrorMessage(e.message);
				},
			});
	}

	goBack() {
		this.currentState = 'ticketList';
		this._cd.detectChanges();
	}
}
