<div class="role-mod">
	<div
		class="role-mod__header"
		[ngClass]="{ 'no-permission-radius': !isShowPermissions }"
	>
		<ng-container [ngTemplateOutlet]="title"></ng-container>
	</div>
	<div *ngIf="isShowPermissions" class="role-mod__body">
		<div class="role-mod__body--list">
			<module-list-component></module-list-component>
		</div>
		<div class="role-mod__body--permission">
			<module-permission-component></module-permission-component>
		</div>
	</div>
</div>

<ng-template #title>
	<form [formGroup]="formGroup">
		<div class="role-mod__header--title q-mb-24">
			<span>
				<ng-container *ngIf="mode === 'view'">
					<h3>{{ formGroup.get('name')?.value || 'New Role' }}</h3>
					<div
						[class.disabled-element-event]="
							rolesAndPermission.isDisabledPermissionButton
						"
					>
						<div
							*ngIf="rolesAndPermission.isDisabledPermissionButton"
							class="tooltip-message-wrapper"
							[tooltipContentV2]="{
								message:
									'This is global role contact administrator to change permission.'
							}"
						></div>

						<mat-icon
							*ngIf="!isAdmin || isModeAdd"
							class="q-pointer"
							[svgIcon]="'edit-02'"
							(click)="editName()"
						></mat-icon>
					</div>
				</ng-container>
				<ng-container *ngIf="mode === 'edit'">
					<input
						formControlName="name"
						type="text"
						placeholder="Type new role name"
						(blur)="saveName()"
						(keyup.enter)="saveName()"
						#roleName
					/>
				</ng-container>

				<mat-icon
					*ngIf="user.isProdDev && !rolesAndPermission.isDisabledPermissionButton && !isBaseRole"
					[svgIcon]="
						formGroup.controls.enableDeleteProtection.value
							? 'lock-01'
							: 'lock-unlocked-01'
					"
					(click)="toggleDelProtection()"
					[tooltipContentV2]="{
						innerHtml: formGroup.controls.enableDeleteProtection.value
							? tooltipEndabled
							: tooltipDisabled
					}"
					class="q-pointer"
				></mat-icon>
			</span>
			<div class="right">
				<div class="cascade" *ngIf="isOverride" (click)="switchCascade()">
					<div class="cascade__pair">
						<span>Cascade</span>
						<mat-icon
							[svgIcon]="'info-circle'"
							[tooltipContentV2]="{ innerHtml: tooltip }"
						></mat-icon>
					</div>
					<app-svg [name]="getSwitchCascade()"></app-svg>
				</div>
				<div class="admin-role" (click)="switchAdmin()" *ngIf="isShowAdminRole">
					<span>Admin Role</span>
					<app-svg [name]="getSwitch()"></app-svg>
				</div>
			</div>
		</div>
		<div
			class="role-mod__header--description"
			[class.disabled-element-event]="
				rolesAndPermission.isDisabledPermissionButton
			"
		>
			<span>Description</span>
			<div
				*ngIf="rolesAndPermission.isDisabledPermissionButton"
				class="tooltip-message-wrapper"
				[tooltipContentV2]="{
					message:
						'This is global role contact administrator to change permission.'
				}"
			></div>

			<input
				formControlName="description"
				type="text"
				placeholder="Write a short explanation about this role"
				(blur)="saveDescription()"
				(keyup.enter)="saveDescription()"
			/>
		</div>
		<div
			*ngIf="isModeAdd && !isCreateHeaderSaved"
			class="role-mod__header--button"
		>
			<button
        id="mapper_saveHeaderPerm"
				(click)="saveHeaderOnly()"
				class="btn-2 btn-2-primary"
				[disabled]="rolesAndPermission.isEditPageLoading$ | async"
			>
				Add Permission
			</button>
		</div>
	</form>
</ng-template>
