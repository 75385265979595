import { Directive, ElementRef, Renderer2, OnInit, AfterViewInit } from '@angular/core';

@Directive({
  selector: 'button'
})
export class UpdateButtonIdDirective implements OnInit,AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    console.log('test')

    const buttonId = this.el.nativeElement.id;


    if (buttonId && !buttonId.startsWith('mapper_')) {
      // If the id doesn't have the 'mapper_' prefix, add it
      const newId = 'mapper_' + buttonId;
      this.renderer.setAttribute(this.el.nativeElement, 'id', newId);
    }
  }


  ngAfterViewInit(): void {
    console.log('Directive applied');
    // Get the current id of the button element
    let buttonId = this.el.nativeElement.id;

    // Check if the id has the 'mapper_' prefix
    if (!buttonId.startsWith('mapper_')) {
      const newId = 'mapper_' + buttonId;
      this.renderer.setAttribute(this.el.nativeElement, 'id', newId);
    }
  }
}
