<div id="active-payment-arrangements" class="q-d-grid q-g-24">
  <div class="card-header-2 q-mt-24 q-ai-center q-jc-start">
    <h3 class="q-mr-auto">Active Payment Arrangements</h3>
  
    <ng-container *ngIf="isAdmin">
      <search-input
        [formControl]="queryString"
        (keyup)="queryChange(500)"
        (keyup.enter)="queryChange()"
        placeholder="Search Companies"
        size="small"
        [class.disabled]="tableSub && !tableSub.closed">
      </search-input>
    
      <a 
        href="javascript:void(0)" 
        class="btn-2 btn-2-success" 
        routerLink="/billing-orders/payment-arrangements/setup-payment-arrangement/add" 
        *ngIf="isAdmin">
        Set Up Arrangement
      </a>
    </ng-container>
  </div>
  
  <div class="table-container">
    <div class="table-wrapper scroll-v2">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="nextPayment"
        matSortDisableClear="true"
        (matSortChange)="sortChangeEvent($event)">
  
        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </th>
  
          <td mat-cell *matCellDef="let row">
            <div class="q-d-flex q-ai-center q-g-10 q-as-stretch">
              <div class="company-name">{{ row.companyName }}</div>
              <div class="q-d-flex q-jc-end q-w-100">
                <mat-icon 
                  *ngIf="row.remarks" 
                  svgIcon="alert-triangle" 
                  class="alert-triangle-icon" 
                  [tooltipContentV2]="{ message: row.remarks}">
                </mat-icon>
              </div>
              <div class="q-d-flex q-jc-end q-w-100">
                <app-badge
                  [label]="getStatus(row.isCanceled ? 3 : row.isSubmitted ? 2 : 1).name ?? ''"
                  [type]="getStatus(row.isCanceled ? 3 : row.isSubmitted ? 2 : 1).backgroundColor ?? ''"
                  [fontColor]="getStatus(row.isCanceled ? 3 : row.isSubmitted ? 2 : 1).fontColor ?? ''">
                </app-badge>
              </div>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="nextPayment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Next Payment
          </th>
  
          <td mat-cell *matCellDef="let row">{{ row.nextPayment | customDate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="recurringAmount">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            Recurring Amount
          </th>
  
          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.recurringAmount | currency }}</div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Frequency
          </th>
  
          <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
        </ng-container>
  
        <ng-container matColumnDef="paymentsLeft">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            <div class="text-end">Payments Left</div>
          </th>
  
          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.paymentsLeft }}</div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="dateCompleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date Complete
          </th>
  
          <td mat-cell *matCellDef="let row">{{ row.dateCompleted | customDate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="remainingBalance">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            Remaining Balance
          </th>
  
          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.remainingBalance | currency }}</div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="q-pointer" [routerLink]="invoicePath+'/payment-arrangements/view-arrangement-details/' + row.id">
        </tr>
  
        <tr class="mat-row" *matNoDataRow>
          <table-skeleton
            *ngIf="paginator"
            [isLoading]="tableSub && !tableSub.closed"
            [colCount]="displayedColumns.length"
            [pageSize]="paginator.size">
          </table-skeleton>
        </tr>
      </table>
    </div>
  </div>
  
  <div class="table-paginator-container">
    <div class="paginator-group">
      <table-paginator
        [totalItems]="totalItems"
        [pageSizes]="pageSizes"
        (pageChange)="changePage()">
      </table-paginator>
    </div>
  
    <div class="item-per-page-group">
      <app-item-per-page 
        (sizeChange)="updateSize()"
        [totalItems]="totalItems">
      </app-item-per-page>
    </div>
  </div>
</div>