<div class="methods">
	<div class="methods-header">
		<div class="methods-head1">
			<div class="methods-title">Payment Method</div>
		</div>

		<a
			href="javascript:void(0)"
			[routerLink]="
				isAdmin
					? '/billing-orders/invoices/add-card-details'
					: '/billing-and-orders/invoices/add-card-details'
			"
			class="btn-2 btn-2-tertiary"
			id="mapper_gs-add-card"
		>
			Add Card
		</a>
		<a
			href="javascript:void(0)"
			[routerLink]="
				isAdmin
					? '/billing-orders/invoices/add-us-bank-account'
					: '/billing-and-orders/invoices/add-us-bank-account'
			"
			class="btn-2 btn-2-tertiary"
			id="mapper_gs-add-bank"
		>
			Add Bank
		</a>
	</div>

	<div class="methods-option">
		<div *ngIf="cardOption.length" class="methods-container">
			<div class="methods-container-title">Card</div>

			<ng-container *ngFor="let card of cardOption; let i = index">
				<div *ngIf="card.id" class="methods-select-option">
					<input
						type="radio"
						[id]="'card-' + i"
						class="methods-form q-pointer"
						name="paymentType"
						[formControl]="paymentType"
						[value]="card.id"
						(change)="onChangeMethodType()"
					/>

					<label [for]="'card-' + i" class="methods-form-label">
						<span class="methods-logo">
							<app-card-logos [branName]="card.name"> </app-card-logos>
						</span>
						<span class="methods-container-no">****{{ card?.last4 }}</span>
						<span class="methods-container-expiry">
							({{ card?.expMonth }}/{{ card?.expYear }})
						</span>
					</label>
				</div>

				<div
					class="methods-border-line"
					*ngIf="i + 1 !== cardOption.length"
				></div>
			</ng-container>
		</div>

		<div *ngIf="bankOption.length" class="methods-container">
			<div class="methods-container-title">Bank Account</div>

			<ng-container *ngFor="let bank of bankOption; let i = index">
				<ng-container *ngIf="bank.status !== 'new'">
					<div class="methods-select-option">
						<input
							type="radio"
							[id]="'bank-' + i"
							class="methods-form q-pointer"
							name="paymentType"
							[formControl]="paymentType"
							[value]="bank.id"
							(change)="onChangeMethodType()"
						/>

						<label
							[for]="'bank-' + i"
							class="methods-form-label"
							style="gap: 0"
						>
							<span class="methods-logo">
								<app-figma-icon-img
									[iconName]="'bank'"
									[hasSpaceOnRight]="true"
								></app-figma-icon-img>
							</span>
							<span class="methods-container-no">
								<span class="uppercase">{{ bank?.name }}</span> ****{{
									bank?.last4
								}}
							</span>
						</label>
					</div>

					<div
						class="methods-border-line"
						*ngIf="i + 1 !== bankOption.length"
					></div>
				</ng-container>
			</ng-container>
		</div>

		<!-- New Payment Method -->
		<div class="methods-container">
			<div class="methods-container-title">One-Time Payment</div>
			<div class="methods-select-option mt-2" id="mapper_gs-one-time-payment">
				<input
					type="radio"
					id="stripe-sandbox"
					class="methods-form q-pointer"
					name="paymentType"
					[formControl]="paymentType"
					[value]="'one-time-payment'"
					(change)="onChangeMethodType()"
				/>

				<label
					for="stripe-sandbox"
					class="methods-form-label d-flex align-items-center"
					style="gap: 0"
				>
					<span class="methods-logo">
						<app-figma-icon-img
							[iconName]="'credit-card-02'"
							[hasSpaceOnRight]="true"
						></app-figma-icon-img>
					</span>
					<span class="methods-container-no">
						<span class="uppercase">Credit/Debit Card</span>
					</span>
				</label>
			</div>

			<form
				*ngIf="paymentType.value === 'one-time-payment'"
				[formGroup]="cardDetailsForm"
				(ngSubmit)="onSaveToPaymentMethods()"
				class="one-time-payment-container mt-3"
			>
				<div class="card-information-container">
					<div class="card-info-title">Card Information</div>

					<div class="card-info-group">
						<div class="card-info-label">Card Number</div>
						<div
							class="credit-card-form position-relative"
							[class.visibility-hidden]="isLoadingCardInput">

							<input
								type="text"
								class="card-element-form visibility-hidden"
								formControlName="isValidCardNumber"
								errorTooltip
								[etControl]="cardDetailsForm.get('isValidCardNumber')!"
								[etIsTouched]="cardDetailsForm.get('isValidCardNumber')!.touched && hasInvalidClassName"
								[etShow]="cardDetailsForm.get('isValidCardNumber')!.touched && !!cardDetailsForm.get('isValidCardNumber')!.errors && hasInvalidClassName"
								[etMessage]="[
									{
										error: 'required',
										message: cardNumberValidationMessage
									}
								]">

							<div id="payment-element" class="credit-debit-form position-relative"></div>
						</div>
					</div>

					<div class="card-info-group">
						<label
							for="cardholder-name"
							class="card-info-label required"
							for="cardholder-name"
						>
							Name on Card
						</label>
						<input
							type="text"
							id="cardholder-name"
							class="form-control"
							formControlName="cardHolderName"
							placeholder="John Hancock"
							onfocus="focused(this)"
							onfocusout="defocused(this)"
							autocomplete="off"
							errorTooltip
							[etControl]="cardDetailsForm.get('cardHolderName')!"
							[etIsTouched]="cardDetailsForm.get('cardHolderName')!.touched"
							[etShow]="
								cardDetailsForm.get('cardHolderName')!.touched &&
								!!cardDetailsForm.get('cardHolderName')!.errors
							"
							[etMessage]="[
								{
									error: 'required',
									message: cardValidationMessages.CardHolderNameRequired
								}
							]"
						/>
					</div>

					<div class="card-info-billing-container mt-4">
						<div class="card-info-title required">Billing Address</div>

						<div class="card-info-billing-group">
							<label for="street-1" class="card-info-billing-label required"
								>Address Line 1</label
							>
							<input
								type="text"
								id="street-1"
								class="form-control"
								formControlName="addressLine1"
								placeholder="1776 Liberty Lane"
								onfocus="focused(this)"
								onfocusout="defocused(this)"
								autocomplete="off"
								errorTooltip
								[etControl]="cardDetailsForm.get('addressLine1')!"
								[etIsTouched]="cardDetailsForm.get('addressLine1')!.touched"
								[etShow]="
									cardDetailsForm.get('addressLine1')!.touched &&
									!!cardDetailsForm.get('addressLine1')!.errors
								"
								[etMessage]="[
									{
										error: 'required',
										message: cardValidationMessages.IncorrectCardAddress
									}
								]"
							/>
						</div>

						<div class="card-info-billing-group">
							<label for="street-2" class="card-info-billing-label required">
								Address Line 2 (Optional)
							</label>
							<input
								type="text"
								id="street-2"
								class="form-control"
								formControlName="addressLine2"
								placeholder="Suite 12"
								onfocus="focused(this)"
								onfocusout="defocused(this)"
							/>
						</div>

						<div class="card-info-billing-group-container">
							<div class="card-info-billing-group-half">
								<label
									for="city"
									class="card-info-billing-group-half-label required"
								>
									City
								</label>
								<input
									type="text"
									id="city"
									class="form-control"
									formControlName="addressCity"
									placeholder="Boston"
									onfocus="focused(this)"
									onfocusout="defocused(this)"
									autocomplete="off"
									errorTooltip
									[etControl]="cardDetailsForm.get('addressCity')!"
									[etIsTouched]="cardDetailsForm.get('addressCity')!.touched"
									[etShow]="
										cardDetailsForm.get('addressCity')!.touched &&
										!!cardDetailsForm.get('addressCity')!.errors
									"
									[etMessage]="[
										{
											error: 'required',
											message: cardValidationMessages.IncorrectCardCity
										}
									]"
								/>
							</div>

							<div class="card-info-billing-group-half">
								<label
									for="state"
									class="card-info-billing-group-half-label required"
								>
									State/Province
								</label>
								<select
									id="state"
									class="form-control"
									formControlName="addressState"
									autocomplete="off"
									errorTooltip
									[etControl]="cardDetailsForm.get('addressState')!"
									[etIsTouched]="cardDetailsForm.get('addressState')!.touched"
									[etShow]="
										cardDetailsForm.get('addressState')!.touched &&
										!!cardDetailsForm.get('addressState')!.errors
									"
									[etMessage]="[
										{
											error: 'required',
											message: cardValidationMessages.IncorrectCardState
										}
									]"
								>
									<option value="" selected>- Select State -</option>
									<ng-container *ngFor="let state of stateList">
										<option [value]="state.abbreviation">
											{{ state.name }} ({{ state.abbreviation }})
										</option>
									</ng-container>
								</select>
							</div>
						</div>

						<div class="card-info-billing-group-container">
							<div class="card-info-billing-group-half">
								<label
									for="country"
									class="card-info-billing-group-half-label required"
								>
									Country
								</label>
								<input
									type="text"
									id="country"
									class="form-control"
									formControlName="addressCountry"
									autocomplete="off"
									readonly
								/>
							</div>

							<div class="card-info-billing-group-half"></div>
						</div>
					</div>
				</div>

				<div class="card-info-footer-container w-100">
					<a
            id="mapper_SavePaymentMethod"
						href="javascript:void(0)"
						class="btn-2 btn-2-tertiary"
						(click)="onSaveToPaymentMethods()"
					>
						<div class="d-flex align-items-center justify-content-center">
							<span>
								<app-figma-icon-img
									[iconName]="'credit-card-plus'"
									[hasSpaceOnRight]="false"
									[isForButton]="true"
								></app-figma-icon-img>
							</span>
							&nbsp;&nbsp;
							<span>Save to Payment Methods</span>
						</div>
					</a>
				</div>
			</form>
		</div>
	</div>
</div>
