<div id="invoice-assignment" class="q-grayed-box q-d-grid q-g-24">
  <div class="q-d-grid q-g-24">
    <h3>Available Invoices</h3>

    <div class="q-d-flex q-ai-start q-g-16">
      <button
        id="mapper_addInvoiceAssign"
        type="button"
        class="btn-2 btn-2-tertiary"
        [disabled]="!dataList.length || isSubmitted"
        (click)="add()">
        <quest-icon name="plus"></quest-icon>
        Add
      </button>

      <button
        id="mapper_removeInvoiceAssign"
        type="button"
        class="btn-2 btn-2-tertiary"
        [disabled]="!dataList.length || isSubmitted"
        (click)="remove()">
        <quest-icon name="trash-01"></quest-icon>
        Remove
      </button>
    </div>
  </div>

  <div class="scroll-v2 scrollbar-track-white">
    <div class="table-container">
      <div class="table-wrapper">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="invoiceDate"
          matSortDisableClear="true"
          (matSortChange)="sortChangeEvent($event)">

          <ng-container matColumnDef="id">
            <th *matHeaderCellDef>
              <div class="d-flex q-ai-center q-jc-start q-pl-10">
                <checkbox-input
                  [(ngModel)]="isCheckedAll"
                  (change)="checkAllRecords($event)"
                  [disabled]="isSubmitted">
                </checkbox-input>
              </div>
            </th>

            <td mat-cell *matCellDef="let row">
              <checkbox-input
                (change)="onRangeSelected($event, row)"
                [checked]="isActiveRow(row.id)"
                [disabled]="row.status !== 'Outstanding' || isSubmitted">
              </checkbox-input>
            </td>
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Invoice #
            </th>

            <td mat-cell *matCellDef="let row">{{ row.invoiceNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef>
              Invoice Date
            </th>

            <td mat-cell *matCellDef="let row">
              <div>{{ row.invoiceDate | customDate }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Due Date
            </th>

            <td mat-cell *matCellDef="let row">{{ row.dueDate | customDate }}</td>
          </ng-container>

          <ng-container matColumnDef="billAmount">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-end">Bill Amount</div>
            </th>

            <td mat-cell *matCellDef="let row">
              <div class="text-end">{{ row.billAmount | currency }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="currentBalance">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-end">Balance Due</div>
            </th>

            <td mat-cell *matCellDef="let row" class="text-end">{{ row.currentBalance | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>

            <td mat-cell *matCellDef="let row">
              <div class="q-d-flex q-ai-center q-g-10 q-as-stretch">
                <div class="q-d-flex">
                  <app-badge
                    [label]="row.status ?? 'Loading ...'"
                    [type]="row.color ?? '#000'"
                    [fontColor]="row.fontColor ?? '#fff'">
                  </app-badge>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="isAdded">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-end">Added</div>
            </th>

            <td mat-cell *matCellDef="let row" class="text-end">
              <div class="q-d-flex q-jc-end"><quest-icon [style.visibility]="isActiveRow(row.id) ? 'visible' : 'hidden'" name="check"></quest-icon></div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns" [class.active-2]="isActiveRow(row.id)">
          </tr>

          <tr class="mat-row" *matNoDataRow>
            <table-skeleton
              [isLoading]="tableSub && !tableSub.closed"
              [colCount]="displayedColumns.length"
              [pageSize]="5">
            </table-skeleton>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="q-d-flex q-g-24 col-group">
    <div class="q-widget-panel danger q-d-flex q-p-16 q-jc-between q-ai-center q-g-48">
      <span class="font-btn">Balance under Payment Arrangement</span>
      <h4>{{ paymentArrangementService.formData.totalPaymentAmount | currency }}</h4>
    </div>

    <div class="q-widget-panel default q-d-inline-table q-w-100" *ngIf="selectedInvoices.length">
      <h3>{{ selectedInvoices.length }} {{ selectedInvoices.length > 1 ? 'Invoices' : 'Invoice' }} Added</h3>
      <small class="font-b3">
        <ng-container *ngFor="let inv of selectedInvoices; let isLast = last;">{{ inv.invoiceNumber }}{{ isLast ? '' : ', '}}</ng-container>
      </small>
    </div>
  </div>
</div>
