import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { clone } from '../utilities/helper';

import { ApplicationsService } from '@app/modules/applications/applications.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { DashboardCardService } from '@app/modules/dashboard-card/dashboard-card.service';
import { EmailTemplateParentService } from '@app/modules/email-template/services/email-template-parent.service';
import { InventoryService } from '@app/modules/inventory/inventory.service';
import { InvoiceService } from '@app/modules/finance/pages/invoices/store/invoice.service';
import { KnowledgeBaseService } from '@app/modules/knowledge-base/knowledge-base.service';
import { NotificationCenterService } from '@app/modules/notification-center/notification-center.service';
import { NotificationService } from '@app/core/services/notification.service';
import { QuotesOrdersService } from '@app/modules/quotes-and-orders/quotes-and-orders.service';
import { SupportService } from '@app/modules/service-and-support/support.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { FontsTemplateSettingsService } from '@app/modules/email-template-styles/fonts-template-settings/fonts-template-settings.service';
import { ButtonsTemplateSettingsService } from '@app/modules/email-template-styles/buttons-template-settings/buttons-template-settings.service';
import { UserService } from '@app/core/services/user.service';

import {
	InvoiceTab,
	NotificationMessages,
	Permission,
	UserTypes,
} from '../constants';

import {
	ClientAdminRoutes,
	SPAdminRoutes,
	UserRoutes,
} from './new-table-shared.enum';
import { DashboardReportTab } from '@app/modules/dashboard/pages/dashboard/dashboard-report.enum';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IDataSource, ITableConfig } from './new-table-shared.interface';

import { appCategoryListUpdateAction } from '@app/modules/applications/pages/category-list/store/sp-admin/app-category.actions';
import { applicationUpdateAction } from '@app/modules/applications/pages/application-list/store/sp-admin/application.actions';
import { assetsUpdateAction } from '@app/modules/inventory/pages/assets/store/assets.actions';
import { companiesSupportCategoriesUpdateAction } from '@app/modules/service-and-support/pages/categories/store/company/categories.action';
import { companiesUpdateAction } from '@app/modules/companies/pages/company-list/store/companies.actions';
import { companyAppCategoryListUpdateAction } from '@app/modules/applications/pages/category-list/store/sp-admin/company/company-app-category.actions';
import { companyApplicationUpdateAction } from '@app/modules/applications/pages/application-list/store/sp-admin/company/company/application.actions';
import { companyContactsUpdateAction } from '@app/modules/companies/pages/company-directory/store/company/contacts.company.actions';
import { companyGlobalKbTopicsUpdateAction } from '@app/modules/knowledge-base/pages/kb-topic-list/store/companies/global/kb-topic-global.companies.actions';
import { companyKbCategoryUpdateAction } from '@app/modules/knowledge-base/pages/category-list/store/company/category-list.company.actions';
import { companyKbTopicsUpdateAction } from '@app/modules/knowledge-base/pages/kb-topic-list/store/companies/kb-topic.companies.actions';
import { companyNotificationCenterEditViewUpdateAction } from '@app/modules/notification-center/edit-view/store/company/company-notification-center-edit-view.actions';
import { companyRequestFormsUpdateAction } from '@app/modules/service-and-support/pages/request-forms/store/company/request-forms.actions';
import { contactsCompanyApplicationUpdateAction } from '@app/modules/applications/pages/application-list/store/sp-admin/contacts/company/application.actions';
import { contactsUpdateAction } from '@app/modules/companies/pages/company-directory/store/contact/contacts.actions';
import { dashboardCardUpdateAction } from '@app/modules/dashboard-card/pages/dashboard-card-list/store/global/dashboard-card.actions';
import { globalApplicationUpdateAction } from '@app/modules/applications/pages/application-list/store/sp-admin/company/global/application.actions';
import { globalKbCategoryUpdateAction } from '@app/modules/knowledge-base/pages/category-list/store/global/category-list.actions';
import { globalKbTopicsUpdateAction } from '@app/modules/knowledge-base/pages/kb-topic-list/store/global/kb-topic.actions';
import { globalSupportCategoriesUpdateAction } from '@app/modules/service-and-support/pages/categories/store/global/categories.action';
import { notificationCenterEditViewUpdateAction } from '@app/modules/notification-center/edit-view/store/notification-center-edit-view.actions';
import { ordersUpdateAction } from '@app/modules/quotes-and-orders/pages/orders/store/orders.actions';
import { quotesUpdateAction } from '@app/modules/quotes-and-orders/pages/quotes/store/quotes.actions';
import { requestFormsUpdateAction } from '@app/modules/service-and-support/pages/request-forms/store/global/request-forms.actions';
import { ticketsUpdateAction } from '@app/modules/service-and-support/pages/ticket/store/tickets.actions';
import { userAppCategoryListUpdateAction } from '@app/modules/applications/pages/category-list/store/user/user-app-category.actions';
import { userContactsUpdateAction } from '@app/modules/companies/pages/company-directory/store/user/contacts.user.actions';
import { DashboardReportService } from '@app/modules/dashboard/pages/dashboard/dashboard-report.service';
import { fontsTemplateSettingsUpdateAction } from '@app/modules/email-template-styles/fonts-template-settings/store/fonts-template-settings.actions';
import { buttonsTemplateSettingsUpdateAction } from '@app/modules/email-template-styles/buttons-template-settings/store/buttons-template-settings.actions';
import { emailTemplateUpdateAction } from '@app/modules/email-template/store/list-email-template/email-template.actions';
import { PortalUsageApiService } from '@app/modules/portal-usage/pages/portal-usage.service';
import { TableReloadSignalService } from '../services/table-reload-signal.service';

@Injectable({
	providedIn: 'root',
})
export class NewTableSharedService {
	isCustom: boolean | undefined;
	allowToFetchNewData = false;

	private _url: string;
	private _companyId: number | string;
	private _territoryId: number | string;
	private _currentFilters: any;
	private _tabIndex: number;
	private _quotesActionPermission: boolean;
	private _directoryActionPermission: boolean;
	private _directoryImpersonatePermission: boolean;
	private _applicationCategoryActionPermission: boolean;
	private _applicationActionPermission: boolean;
	private _kbActionPermission: boolean;
	private _kbCategoryActionPermission: boolean;
	private _dashboardSetupActionPermission: boolean;
	private _requestFormActionPermission: boolean;
	private _emailTemplateActionPermission: boolean;
	private _supportCategoryActionPermission: boolean;

	constructor(
		private _applicationsService: ApplicationsService,
		private _companiesService: CompaniesService,
		private _dashboardCardService: DashboardCardService,
		private _emailTemplateService: EmailTemplateParentService,
		private _inventoryService: InventoryService,
		private _invoiceService: InvoiceService,
		private _kbService: KnowledgeBaseService,
		private _notificationCenterService: NotificationCenterService,
		private _notificationService: NotificationService,
		private _quoteOrderService: QuotesOrdersService,
		private _store: Store<AppStateInterface>,
		private _supportService: SupportService,
		private _ticketService: TicketService,
		private _userService: UserService,
		private _dbReportService: DashboardReportService,
		private _fontsTemplateSettingsService: FontsTemplateSettingsService,
		private _buttonsTemplateSettingsService: ButtonsTemplateSettingsService,
		private _portalUsageService: PortalUsageApiService,
		private _tableReloadSignalService: TableReloadSignalService
	) {}

	get tabIndex() {
		return this._tabIndex;
	}

	initializePermission() {
		this._quotesActionPermission = this._userService.hasPermission([
			Permission.ShowQuoteLinkUser,
		]);
		this._directoryActionPermission = this._userService.hasPermission(
			this._userService.isUser
				? [
						Permission.UserViewBasicInfo,
						Permission.UserViewContactInfo,
						Permission.UserViewRolesAndAccess,
						Permission.UserViewOrganizationChart,
				  ]
				: this._userService.isClientAdmin
				? [Permission.CompanyAdminViewUserProfile]
				: [Permission.SpAdminUpdateUserProfileInfo]
		);
		this._directoryImpersonatePermission = this._userService.hasPermission([
			Permission.SpAdminImpersonation,
			Permission.CompanyAdminImpersonation,
		]);
		this._applicationCategoryActionPermission = this._userService.hasPermission(
			[
				Permission.CompanyAdminApplicationCategoryAddEdit,
				Permission.SpAdminApplicationCategoryAddEdit,
				Permission.UserAddEditApplicationCategory,
			]
		);
		this._applicationActionPermission = this._userService.hasPermission([
			Permission.CompanyAdminApplicationAddEdit,
			Permission.SpAdminApplicationAddEdit,
			Permission.UserAddEditApplication,
		]);
		this._kbActionPermission = this._userService.hasPermission([
			Permission.CompanyAdminKbTopicAddEdit,
			Permission.SpAdminKbTopicAddEdit,
		]);
		this._kbCategoryActionPermission = this._userService.hasPermission([
			Permission.CompanyAdminKbCategoryAddEdit,
			Permission.SpAdminKbCategoryAddEdit,
		]);
		this._dashboardSetupActionPermission = this._userService.hasPermission(
			Permission.SpAdminDashboardCardManage
		);
		this._requestFormActionPermission = this._userService.hasPermission([
			Permission.CompanyAdminRequestFormAddEdit,
			Permission.SpAdminServiceAndSupportFormAddEdit,
		]);
		this._emailTemplateActionPermission = this._userService.hasPermission([
			Permission.SpAdminEmailTemplateAddEdit,
		]);
		this._supportCategoryActionPermission = this._userService.hasPermission([
			Permission.CompanyAdminServiceAndSupportCategoryAddEdit,
			Permission.SpAdminServiceAndSupportCategoryAddEdit,
		]);
	}

	setTabIndex(tabNum: number) {
		this._tabIndex = tabNum;
	}

	getTableConfigs(
		url: string,
		companyId?: number | string,
		territoryId?: number | string
	): ITableConfig[] {
		const userRole = this._userService.userRole;
		let tableConfig: ITableConfig[] = [];

		if (companyId) {
			this._companyId = companyId;
		}

		if (territoryId) {
			this._territoryId = territoryId;
		}

		this._url = url;

		switch (userRole) {
			case UserTypes.SourcepassAdmin:
				tableConfig = this._fetchSPAdminTableConfig();
				break;

			case UserTypes.ClientAdmin:
				tableConfig = this._fetchClientAdminTableConfig();
				break;

			case UserTypes.User:
				tableConfig = this._fetchUserTableConfig();
				break;
		}

		return tableConfig;
	}

	fetchNewData(
		filters: Params,
		isCustom?: boolean,
		prevRequest?: Subscription
	) {
		const userRole = this._userService.userRole;
		let request;

		this._currentFilters = filters;

		switch (userRole) {
			case UserTypes.SourcepassAdmin:
				this._fetchSPAdminTableData(isCustom);
				break;

			case UserTypes.ClientAdmin:
				this._fetchClientAdminTableData(isCustom);
				break;

			case UserTypes.User:
				request = this._fetchUserTableData(prevRequest);
				break;
		}
		return request;
	}

	getDataSource(isCustom?: boolean): IDataSource {
		const userRole = this._userService.userRole;
		let dataSource$: IDataSource = {
			dataSource$: of([]),
			isLoading$: of(false),
			totalItems$: of(0),
		};

		switch (userRole) {
			case UserTypes.SourcepassAdmin:
				dataSource$ = this._getSPAdminDataSource(isCustom);
				break;

			case UserTypes.ClientAdmin:
				dataSource$ = this._getClientAdminDataSource(isCustom);
				break;

			case UserTypes.User:
				dataSource$ = this._getUserDataSource();
				break;
		}

		return dataSource$;
	}

	getRowClickUrl(id: number | string): string {
		const userRole = this._userService.userRole;
		let url = '';

		switch (userRole) {
			case UserTypes.SourcepassAdmin:
				url = this._fetchSPAdminRowClickUrl(id);
				break;

			case UserTypes.ClientAdmin:
				url = this._fetchClientAdminRowClickUrl(id);
				break;

			case UserTypes.User:
				url = this._fetchUserRowClickUrl(id);
				break;
		}

		return url;
	}

	clearTables() {
		this.allowToFetchNewData = true;
		this._dashboardCardService.allowToFetchNewData = true;
		this._kbService.allowToFetchNewData = true;
		this._applicationsService.allowToFetchNewData = true;
		this._tableReloadSignalService.allowToFetchNewDataInvoiceTab = true;
		this._tableReloadSignalService.allowToFetchNewDataQuotePage = true;
		this._tableReloadSignalService.allowToFetchNewDataOrdersPage = true;
		this._tableReloadSignalService.allowToFetchNewDataTicketPage = true;
	}

	private _getSPAdminDataSource(isCustom?: boolean): IDataSource {
		let dataSource: IDataSource = {
			dataSource$: of([]),
			isLoading$: of(false),
			totalItems$: of(0),
		};

		switch (this._url) {
			case SPAdminRoutes.GLOBAL_SUPPORT_CATEGORY:
				dataSource = {
					dataSource$: this._supportService.globalSupportCategoryData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.globalSupportCategoryTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_SUPPORT_CATEGORY}${this._companyId}`:
				dataSource = {
					dataSource$: this._supportService.companySupportCategoryData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.companySupportCategoryTotalItems$,
				};
				break;

			case `${SPAdminRoutes.GLOBAL_NOTIFICATION}${this._companyId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_VIEW}${this._companyId}`:
				dataSource = {
					dataSource$:
						this._notificationCenterService.territoryNotificationData$,
					isLoading$: this._notificationCenterService.isLoading$,
					totalItems$:
						this._notificationCenterService.territoryNotificationTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_NOTIFICATION}${this._companyId}`:
				dataSource = {
					dataSource$: this._notificationCenterService.companyNotificationData$,
					isLoading$: this._notificationCenterService.isLoading$,
					totalItems$:
						this._notificationCenterService.companyNotificationTotalItems$,
				};
				break;

			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/edit-notification/${this._territoryId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/view-notification/${this._territoryId}`:
				dataSource = {
					dataSource$:
						this._notificationCenterService.territoryCompanyNotificationData$,
					isLoading$: this._notificationCenterService.isLoading$,
					totalItems$:
						this._notificationCenterService
							.territoryCompanyNotificationTotalItems$,
				};
				break;

			case SPAdminRoutes.COMPANY:
				dataSource = {
					dataSource$: this._companiesService.companyData$,
					isLoading$: this._companiesService.isLoading$,
					totalItems$: this._companiesService.companyTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_REQUEST_FORM:
				dataSource = {
					dataSource$: this._supportService.globalRequestFormData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.globalRequestFormTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_REQUEST_FORM}${this._companyId}`:
				dataSource = {
					dataSource$: this._supportService.companyRequestFormData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.companyRequestFormTotalItems$,
				};
				break;

			case SPAdminRoutes.DASHBOARD_SETUP:
				dataSource = {
					dataSource$: this._dashboardCardService.dashboardSetupData$,
					isLoading$: this._dashboardCardService.isLoading$,
					totalItems$: this._dashboardCardService.dashboardSetupTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_KB_CATEGORY:
				dataSource = {
					dataSource$: this._kbService.globalKBCategoryData$,
					isLoading$: this._kbService.isLoading$,
					totalItems$: this._kbService.globalKBCategoryTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_KB_CATEGORY}${this._companyId}`:
				dataSource = {
					dataSource$: this._kbService.companyKBCategoryData$,
					isLoading$: this._kbService.isLoading$,
					totalItems$: this._kbService.companyKBCategoryTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_KB:
				dataSource = {
					dataSource$: this._kbService.globalKBData$,
					isLoading$: this._kbService.isLoading$,
					totalItems$: this._kbService.globalKBTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_KB}${this._companyId}`:
				dataSource = {
					dataSource$: isCustom
						? this._kbService.companyKBData$
						: this._kbService.companyGlobalKBData$,
					isLoading$: isCustom
						? this._kbService.isCompanyLoading$
						: this._kbService.isLoading$,
					totalItems$: isCustom
						? this._kbService.companyKBTotalItems$
						: this._kbService.companyGlobalKBTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION:
				dataSource = {
					dataSource$: this._applicationsService.globalApplicationData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.globalApplicationTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION}${this._companyId}`:
				dataSource = {
					dataSource$: isCustom
						? this._applicationsService.companyApplicationData$
						: this._applicationsService.companyGlobalApplicationData$,
					isLoading$: isCustom
						? this._applicationsService.isCompanyLoading$
						: this._applicationsService.isLoading$,
					totalItems$: isCustom
						? this._applicationsService.companyApplicationTotalItems$
						: this._applicationsService.companyGlobalApplicationTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION_CATEGORY:
				dataSource = {
					dataSource$: this._applicationsService.globalCategoryData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.globalCategoryTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION_CATEGORY}${this._companyId}`:
				dataSource = {
					dataSource$: this._applicationsService.companyCategoryData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.companyCategoryTotalItems$,
				};
				break;

			case SPAdminRoutes.GLOBAL_DIRECTORY:
				dataSource = {
					dataSource$: this._companiesService.globalDirectoryData$,
					isLoading$: this._companiesService.isLoading$,
					totalItems$: this._companiesService.globalDirectoryTotalItems$,
				};
				break;

			case `${SPAdminRoutes.COMPANY_DIRECTORY}${this._companyId}`:
				dataSource = {
					dataSource$: this._companiesService.companyDirectoryData$,
					isLoading$: this._companiesService.isLoading$,
					totalItems$: this._companiesService.companyDirectoryTotalItems$,
				};
				break;

			case SPAdminRoutes.INVOICES_ADMIN:
				if (this.tabIndex === InvoiceTab.accountActivity) {
					dataSource = {
						dataSource$: this._invoiceService.accountActivityData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.accountActivityTotalItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.payments) {
					dataSource = {
						dataSource$: this._invoiceService.paymentsData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.paymentsTotalItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.credits) {
					dataSource = {
						dataSource$: this._invoiceService.creditsData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.creditsItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.invoice) {
					dataSource = {
						dataSource$: this._invoiceService.invoiceData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.invoiceItems$,
					};
				}
				break;

			case SPAdminRoutes.GLOBAL_EMAIL_TEMPLATE:
				dataSource = {
					dataSource$: this._emailTemplateService.emailTemplateData$,
					isLoading$: this._emailTemplateService.isLoading$,
					totalItems$: this._emailTemplateService.emailTemplateTotalItems$,
				};
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_FONTS:
				dataSource = {
					dataSource$: this._fontsTemplateSettingsService.fontTemplateData$,
					isLoading$: this._fontsTemplateSettingsService.isLoading$,
					totalItems$:
						this._fontsTemplateSettingsService.fontTemplateTotalItems$,
				};
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_BUTTONS:
				dataSource = {
					dataSource$:
						this._buttonsTemplateSettingsService.buttonsTemplateData$,
					isLoading$: this._buttonsTemplateSettingsService.isLoading$,
					totalItems$:
						this._buttonsTemplateSettingsService.buttonsTemplateTotalItems$,
				};

			case SPAdminRoutes.REPORTS_USER_LOGIN_REPORT:
				dataSource = {
					dataSource$: this._portalUsageService.userLogintableData$,
					isLoading$: this._portalUsageService.userLoginisLoading$,
					totalItems$: this._portalUsageService.userLogintableItems$,
				};
				break;
		}

		return dataSource;
	}

	private _getClientAdminDataSource(isCustom?: boolean): IDataSource {
		let dataSource: IDataSource = {
			dataSource$: of([]),
			isLoading$: of(false),
			totalItems$: of(0),
		};

		switch (this._url) {
			case ClientAdminRoutes.SUPPORT_CATEGORY:
				dataSource = {
					dataSource$: this._supportService.companySupportCategoryData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.companySupportCategoryTotalItems$,
				};
				break;

			case ClientAdminRoutes.REQUEST_FORM:
				dataSource = {
					dataSource$: this._supportService.companyRequestFormData$,
					isLoading$: this._supportService.isLoading$,
					totalItems$: this._supportService.companyRequestFormTotalItems$,
				};
				break;

			case ClientAdminRoutes.KB_CATEGORY:
				dataSource = {
					dataSource$: this._kbService.companyKBCategoryData$,
					isLoading$: this._kbService.isLoading$,
					totalItems$: this._kbService.companyKBCategoryTotalItems$,
				};
				break;

			case ClientAdminRoutes.KB:
				dataSource = {
					dataSource$: isCustom
						? this._kbService.companyKBData$
						: this._kbService.companyGlobalKBData$,
					isLoading$: isCustom
						? this._kbService.isCompanyLoading$
						: this._kbService.isLoading$,
					totalItems$: isCustom
						? this._kbService.companyKBTotalItems$
						: this._kbService.companyGlobalKBTotalItems$,
				};
				break;

			case ClientAdminRoutes.APPLICATION:
				dataSource = {
					dataSource$: isCustom
						? this._applicationsService.companyApplicationData$
						: this._applicationsService.companyGlobalApplicationData$,
					isLoading$: isCustom
						? this._applicationsService.isCompanyLoading$
						: this._applicationsService.isLoading$,
					totalItems$: isCustom
						? this._applicationsService.companyApplicationTotalItems$
						: this._applicationsService.companyGlobalApplicationTotalItems$,
				};
				break;

			case ClientAdminRoutes.DIRECTORY:
				dataSource = {
					dataSource$: this._companiesService.companyDirectoryData$,
					isLoading$: this._companiesService.isLoading$,
					totalItems$: this._companiesService.companyDirectoryTotalItems$,
				};
				break;

			case ClientAdminRoutes.APPLICATION_CATEGORY:
				dataSource = {
					dataSource$: this._applicationsService.companyCategoryData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.companyCategoryTotalItems$,
				};
				break;
		}

		return dataSource;
	}

	private _getUserDataSource(): IDataSource {
		let dataSource: IDataSource = {
			dataSource$: of([]),
			isLoading$: of(false),
			totalItems$: of(0),
		};

		switch (this._url) {
			case UserRoutes.APPLICATION:
				dataSource = {
					dataSource$: this._applicationsService.userApplicationData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.userApplicationTotalItems$,
				};
				break;

			case UserRoutes.APPLICATION_CATEGORY:
				dataSource = {
					dataSource$: this._applicationsService.userCategoryData$,
					isLoading$: this._applicationsService.isLoading$,
					totalItems$: this._applicationsService.userCategoryTotalItems$,
				};
				break;

			case UserRoutes.TICKETS:
				dataSource = {
					dataSource$: this._ticketService.ticketData$,
					isLoading$: this._ticketService.isLoading$,
					totalItems$: this._ticketService.ticketTotalItems$,
				};
				break;

			case UserRoutes.ORDERS:
				dataSource = {
					dataSource$: this._quoteOrderService.orderData$,
					isLoading$: this._quoteOrderService.isLoading$,
					totalItems$: this._quoteOrderService.orderTotalItems$,
				};
				break;

			case UserRoutes.QUOTES:
				dataSource = {
					dataSource$: this._quoteOrderService.quoteData$,
					isLoading$: this._quoteOrderService.isLoading$,
					totalItems$: this._quoteOrderService.quoteTotalItems$,
				};
				break;

			case UserRoutes.DIRECTORY:
				dataSource = {
					dataSource$: this._companiesService.userDirectoryData$,
					isLoading$: this._companiesService.isLoading$,
					totalItems$: this._companiesService.userDirectoryTotalItems$,
				};
				break;

			case UserRoutes.ASSETS:
				dataSource = {
					dataSource$: this._inventoryService.assetData$,
					isLoading$: this._inventoryService.isLoading$,
					totalItems$: this._inventoryService.assetTotalItems$,
				};
				break;

			case UserRoutes.INVOICES_USER || UserRoutes.INVOICES_ADMIN:
				if (this.tabIndex === InvoiceTab.accountActivity) {
					dataSource = {
						dataSource$: this._invoiceService.accountActivityData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.accountActivityTotalItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.payments) {
					dataSource = {
						dataSource$: this._invoiceService.paymentsData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.paymentsTotalItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.credits) {
					dataSource = {
						dataSource$: this._invoiceService.creditsData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.creditsItems$,
					};
				}

				if (this.tabIndex === InvoiceTab.invoice) {
					dataSource = {
						dataSource$: this._invoiceService.invoiceData$,
						isLoading$: this._invoiceService.isLoading$,
						totalItems$: this._invoiceService.invoiceItems$,
					};
				}
				break;
			case UserRoutes.DASHBOARD:
				if (this._dbReportService.dbModal === DashboardReportTab.TotalTickets) {
					dataSource = {
						dataSource$: this._dbReportService.tableData$,
						isLoading$: this._dbReportService.isLoading$,
						totalItems$: this._dbReportService.tableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Onboarding
				) {
					dataSource = {
						dataSource$: this._dbReportService.onbtableData$,
						isLoading$: this._dbReportService.onbisLoading$,
						totalItems$: this._dbReportService.onbtableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTicket
				) {
					dataSource = {
						dataSource$: this._dbReportService.servTickettableData$,
						isLoading$: this._dbReportService.servTicketisLoading$,
						totalItems$: this._dbReportService.servTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTimes
				) {
					dataSource = {
						dataSource$: this._dbReportService.servTimestableData$,
						isLoading$: this._dbReportService.servTimesisLoading$,
						totalItems$: this._dbReportService.servTimestableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.DeviceTicket
				) {
					dataSource = {
						dataSource$: this._dbReportService.deviceTickettableData$,
						isLoading$: this._dbReportService.deviceTicketisLoading$,
						totalItems$: this._dbReportService.deviceTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.UserTicket
				) {
					dataSource = {
						dataSource$: this._dbReportService.userTickettableData$,
						isLoading$: this._dbReportService.userTicketisLoading$,
						totalItems$: this._dbReportService.userTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.SiteTicket
				) {
					dataSource = {
						dataSource$: this._dbReportService.siteTickettableData$,
						isLoading$: this._dbReportService.siteTicketisLoading$,
						totalItems$: this._dbReportService.siteTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.RequestSource
				) {
					dataSource = {
						dataSource$: this._dbReportService.reqSourceTickettableData$,
						isLoading$: this._dbReportService.reqSourceTicketisLoading$,
						totalItems$: this._dbReportService.reqSourceTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.SatisfactionScore
				) {
					dataSource = {
						dataSource$: this._dbReportService.sattableData$,
						isLoading$: this._dbReportService.satisLoading$,
						totalItems$: this._dbReportService.sattableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.TimeSpent
				) {
					dataSource = {
						dataSource$: this._dbReportService.timeSpentTickettableData$,
						isLoading$: this._dbReportService.timeSpentTicketisLoading$,
						totalItems$: this._dbReportService.timeSpentTickettableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Vulnerabilities
				) {
					dataSource = {
						dataSource$: this._dbReportService.vulnerabilitiesTableData$,
						isLoading$: this._dbReportService.vulnerabilitiesIsLoading$,
						totalItems$: this._dbReportService.vulnerabilitiesTableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskUsers
				) {
					dataSource = {
						dataSource$: this._dbReportService.atRiskUsersTableData$,
						isLoading$: this._dbReportService.atRiskUsersIsLoading$,
						totalItems$: this._dbReportService.atRiskUsersTableItems$,
					};
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskDevices
				) {
					dataSource = {
						dataSource$: this._dbReportService.atRiskDevicesTableData$,
						isLoading$: this._dbReportService.atRiskDevicesIsLoading$,
						totalItems$: this._dbReportService.atRiskDevicesTableItems$,
					};
				} else if (
					this._dbReportService.dbModal ===
					DashboardReportTab.RecentSecurityTickets
				) {
					dataSource = {
						dataSource$: this._dbReportService.recentSecurityTicketsTableData$,
						isLoading$: this._dbReportService.recentSecurityTicketsIsLoading$,
						totalItems$: this._dbReportService.recentSecurityTicketsTableItems$,
					};
				}
				break;
		}

		return dataSource;
	}

	private _fetchSPAdminTableConfig(): ITableConfig[] {
		let tableConfig: ITableConfig[] = [];
		this._userService.companyId;

		switch (this._url) {
			case SPAdminRoutes.GLOBAL_SUPPORT_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'iconName',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'companyName',
						tableHeader: 'Company Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: true,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._supportCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._supportCategoryActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_SUPPORT_CATEGORY}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'iconName',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: true,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._supportCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._supportCategoryActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.GLOBAL_NOTIFICATION}${this._companyId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_VIEW}${this._companyId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/edit-notification/${this._territoryId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/view-notification/${this._territoryId}`:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isCheckboxHeader: true,
						isCheckboxRow: true,
						maxWidth: 80,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 990,
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_NOTIFICATION}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 740,
					},
				];
				break;

			case SPAdminRoutes.COMPANY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: true,
						isSortable: true,
						maxWidth: 299.67,
					},
					{
						displayedColumns: 'type',
						tableHeader: 'Company Type',
						isPinned: false,
						isSortable: true,
						maxWidth: 307,
					},
					{
						displayedColumns: 'territory',
						tableHeader: 'Territory',
						isPinned: false,
						isSortable: true,
						maxWidth: 307,
					},
					{
						displayedColumns: 'status',
						tableHeader: 'Status',
						isPinned: false,
						isSortable: true,
						isBadge: true,
						maxWidth: 153,
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_REQUEST_FORM:
				tableConfig = [
					{
						displayedColumns: 'title',
						tableHeader: 'Title',
						isPinned: true,
						isSortable: true,
						isForRequestFormOnly: true,
						isTextWithBadge: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'categoryName',
						tableHeader: 'Sub-Categories',
						isPinned: false,
						isSortable: true,
						maxWidth: 230,
					},
					{
						displayedColumns: 'createdDate',
						tableHeader: 'Date Created',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 168,
					},
					{
						displayedColumns: 'modifiedDate',
						tableHeader: 'Date Modified',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 168,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						isForRequestFormOnly: true,
						maxWidth: 174,
						buttons: [
							{
								title: 'Preview',
								icon: 'eye',
								identifier: 'view',
								hasPermission: true,
							},
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Duplicate',
								icon: 'copy-02',
								identifier: 'copy',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission:
									this._requestFormActionPermission &&
									this._userService.isSpAdmin,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_REQUEST_FORM}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'title',
						tableHeader: 'Title',
						isPinned: false,
						isSortable: true,
						isForRequestFormOnly: true,
						maxWidth: 290,
					},
					{
						displayedColumns: 'categoryName',
						tableHeader: 'Sub-Categories',
						isPinned: false,
						isSortable: true,
						maxWidth: 222,
					},
					{
						displayedColumns: 'modifiedDate',
						tableHeader: 'Date Modified',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 168,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: true,
						isForRequestFormOnly: true,
						maxWidth: 160,
						buttons: [
							{
								title: 'Preview',
								icon: 'eye',
								identifier: 'view',
								hasPermission: true,
							},
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Duplicate',
								icon: 'copy-02',
								identifier: 'copy',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission:
									this._requestFormActionPermission &&
									this._userService.isSpAdmin,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.DASHBOARD_SETUP:
				tableConfig = [
					{
						displayedColumns: 'title',
						tableHeader: 'Title',
						isPinned: true,
						isSortable: true,
						maxWidth: 264,
					},
					{
						displayedColumns: 'categoryName',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: false,
						maxWidth: 160,
					},
					{
						displayedColumns: 'description',
						tableHeader: 'Description',
						isPinned: false,
						isSortable: false,
						maxWidth: 300,
					},
					{
						displayedColumns: 'isActive',
						tableHeader: 'Active',
						isPinned: false,
						isSortable: false,
						isCrossCheck: true,
						maxWidth: 102,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._dashboardSetupActionPermission,
						maxWidth: 102,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._dashboardSetupActionPermission,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_KB_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isIcon: true,
						isSortable: false,
						maxWidth: 455,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: false,
						isSortable: false,
						hasPermission: this._kbCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbCategoryActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_KB_CATEGORY}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isIcon: true,
						isSortable: false,
						maxWidth: 340,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: this._kbCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbCategoryActionPermission,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_KB:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: true,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'topic',
						tableHeader: 'Topics',
						isPinned: true,
						isSortable: true,
						maxWidth: 266,
					},
					{
						displayedColumns: 'category',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: true,
						maxWidth: 247,
					},
					{
						displayedColumns: 'createdDate',
						tableHeader: 'Date Created',
						isPinned: false,
						isSortable: true,
						isDate: true,
						isRightAlign: true,
						maxWidth: 128,
					},
					{
						displayedColumns: 'updatedDate',
						tableHeader: 'Date Modified',
						isPinned: false,
						isSortable: true,
						isDate: true,
						isRightAlign: true,
						maxWidth: 128,
					},
					{
						displayedColumns: 'rating',
						tableHeader: 'Rating',
						isPinned: false,
						isSortable: false,
						isForKBOnly: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._kbActionPermission,
						maxWidth: 144,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_KB}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'topic',
						tableHeader: 'Topics',
						isPinned: false,
						isSortable: true,
						maxWidth: 210,
					},
					{
						displayedColumns: 'category',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: true,
						maxWidth: 210,
					},
					{
						displayedColumns: 'rating',
						tableHeader: 'Rating',
						isPinned: false,
						isSortable: false,
						isForKBOnly: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: this._kbActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbActionPermission,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'iconURL',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: false,
						isImageUrl: true,
					},
					{
						displayedColumns: 'applicationCategoryName',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: false,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._applicationActionPermission,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: true,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: true,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 246.67,
					},
					{
						displayedColumns: 'iconURL',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: false,
						isImageUrl: true,
						maxWidth: 246.67,
					},
					{
						displayedColumns: 'applicationCategoryName',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: false,
						maxWidth: 246.67,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: false,
						buttons: [
							{
								title: 'Delete',
								icon: 'alert-octagon',
								identifier: 'delete',
								hasPermission: true,
							},
							{
								title: 'Edit',
								icon: 'edit-05',
								identifier: 'edit',
								hasPermission: true,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.INVOICES_ADMIN:
				if (this.tabIndex === InvoiceTab.accountActivity) {
					tableConfig = [
						{
							displayedColumns: 'activityDate',
							tableHeader: 'Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
						},
						{
							displayedColumns: 'activityTime',
							tableHeader: 'Time',
							isPinned: false,
							isSortable: true,
							isShortTime: true,
						},
						{
							displayedColumns: 'activity',
							tableHeader: 'Account Activity',
							isPinned: false,
							isSortable: false,
							isForInvoiceAccountActivityTableOnly: true,
							maxWidth: 880,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.payments) {
					tableConfig = [
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Invoice #',
							isPinned: true,
							isSortable: true,
							maxWidth: 132,
						},
						{
							displayedColumns: 'paymentDate',
							tableHeader: 'Paid Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 104,
						},
						{
							displayedColumns: 'initiatedBy',
							tableHeader: 'Initiated By',
							isPinned: false,
							isSortable: true,
							maxWidth: 195,
						},
						{
							displayedColumns: 'amountPaid',
							tableHeader: 'Amount Paid',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'creditsApplied',
							tableHeader: 'Credits Applied',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'total',
							tableHeader: 'Total',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							isForInvoicePaymentHistoryTableOnly: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'referenceNumber',
							tableHeader: 'Transaction ID',
							isPinned: false,
							isSortable: true,
							maxWidth: 326,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.credits) {
					tableConfig = [
						{
							displayedColumns: 'id',
							tableHeader: 'ID',
							isPinned: true,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 140,
						},
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Reference No.',
							isPinned: false,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'invoiceDate',
							tableHeader: 'Credit Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'transactionType',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 104,
						},
						{
							displayedColumns: 'isSettled',
							tableHeader: 'Applied',
							isPinned: false,
							isSortable: true,
							isForInvoiceCreditsTableOnly: true,
							maxWidth: 92,
						},
						{
							displayedColumns: 'billAmount',
							tableHeader: 'Original Amt',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'amountUnused',
							tableHeader: 'Remaining Balance',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.invoice) {
					tableConfig = [
						{
							displayedColumns: 'checkbox',
							tableHeader: 'Checkbox',
							isPinned: true,
							isSortable: false,
							isCustomizeFirstColumnContent: true,
							maxWidth: 64,
						},
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Invoice #',
							isPinned: true,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 140,
						},
						{
							displayedColumns: 'invoiceDate',
							tableHeader: 'Invoice Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'dueDate',
							tableHeader: 'Due Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'billAmount',
							tableHeader: 'Bill Amount',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 145,
						},
						{
							displayedColumns: 'balance',
							tableHeader: 'Balance Due',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 142,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isSortable: true,
							isForInvoiceStatusColumnOnly: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}
				break;

			case SPAdminRoutes.GLOBAL_DIRECTORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name & Title',
						isPinned: true,
						unpinWidth: 550,
						isSortable: true,
						isDate: true,
						isForDirectoryOnly: true,
						maxWidth: 304,
					},
					{
						displayedColumns: 'companyName',
						tableHeader: 'Company',
						isPinned: false,
						isSortable: true,
						maxWidth: 289,
					},
					{
						displayedColumns: 'departmentName',
						tableHeader: 'Department',
						isPinned: false,
						isSortable: true,
						maxWidth: 218,
					},
					{
						displayedColumns: 'siteName',
						tableHeader: 'Site',
						isPinned: false,
						isSortable: true,
						maxWidth: 289,
					},
					{
						displayedColumns: 'email',
						tableHeader: 'Email',
						isPinned: false,
						isSortable: true,
						maxWidth: 289,
					},
					{
						displayedColumns: 'phone',
						tableHeader: 'Phone',
						isPinned: false,
						isSortable: true,
						maxWidth: 160,
						isPhoneNumber: true,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						maxWidth: 144,
						buttons: [
							{
								title: '',
								icon: 'user-right-01',
								identifier: 'impersonate',
								hasPermission: this._directoryImpersonatePermission,
							},
							{
								title: 'Manage User in Connect Wise',
								icon: 'link-external-01',
								identifier: 'external-link',
								hasPermission: this._directoryActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_DIRECTORY}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name & Title',
						isPinned: true,
						unpinWidth: 550,
						isSortable: true,
						isDate: true,
						isForDirectoryOnly: true,
						maxWidth: 304,
					},
					{
						displayedColumns: 'departmentName',
						tableHeader: 'Department',
						isPinned: false,
						isSortable: true,
						maxWidth: 226,
					},
					{
						displayedColumns: 'siteName',
						tableHeader: 'Site',
						isPinned: false,
						isSortable: true,
						maxWidth: 190,
					},
					{
						displayedColumns: 'role',
						tableHeader: 'Role',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
					},
					{
						displayedColumns: 'email',
						tableHeader: 'Email',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
					},
					{
						displayedColumns: 'phone',
						tableHeader: 'Phone',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
						isPhoneNumber: true,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						buttons: [
							{
								title: 'View Profile',
								icon: 'eye',
								identifier: 'edit',
								hasPermission: this._directoryActionPermission,
							},
							{
								title: 'Impersonate',
								icon: 'user-right-01',
								identifier: 'impersonate',
								hasPermission: this._directoryImpersonatePermission,
							},
							{
								title: 'Manage User in Connect Wise',
								icon: 'link-external-01',
								identifier: 'external-link',
								hasPermission: this._directoryActionPermission,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
						isIcon: true,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._applicationCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._applicationCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._applicationCategoryActionPermission,
							},
						],
					},
				];
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION_CATEGORY}${this._companyId}`:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: false,
						isSortable: false,
						hasPermission: this._applicationCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._applicationCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._applicationCategoryActionPermission,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.GLOBAL_EMAIL_TEMPLATE:
				tableConfig = [
					{
						displayedColumns: 'title',
						tableHeader: 'Title',
						isPinned: false,
						isSortable: true,
						isTextWithBadge: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'ticketBoard',
						tableHeader: 'Ticket Board',
						isPinned: false,
						isSortable: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'status',
						tableHeader: 'Status',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: true,
						isBadge: true,
						maxWidth: 96,
					},
					{
						displayedColumns: 'createdDate',
						tableHeader: 'Date Created',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'modifiedDate',
						tableHeader: 'Date Modified',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						maxWidth: 174,
						buttons: [
							{
								title: 'Update Email Template',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._emailTemplateActionPermission,
							},
							{
								title: 'Copy Email Template',
								icon: 'copy-02',
								identifier: 'copy',
								hasPermission: this._emailTemplateActionPermission,
							},
							{
								title: 'Delete Email Template',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission:
									this._emailTemplateActionPermission &&
									this._userService.isSpAdmin,
							},
						],
					},
				];
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_FONTS:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Font Name',
						isPinned: false,
						isSortable: true,
						// maxWidth: 229,
					},
					{
						displayedColumns: 'weightName',
						tableHeader: 'Font Weight',
						isPinned: false,
						isSortable: true,
						// maxWidth: 219.67,
					},
					{
						displayedColumns: 'size',
						tableHeader: 'Font Size',
						isPinned: false,
						isSortable: true,
						// maxWidth: 219.67,
					},
					{
						displayedColumns: 'preview',
						tableHeader: 'Preview',
						isPinned: false,
						isSortable: true,
						isForEmailTemplateSettingsFontsOnly: true,
						// maxWidth: 219.67,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						buttons: [],
						maxWidth: 144,
						isCustomizeActionColumnContent: true,
					},
				];
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_BUTTONS:
				tableConfig = [
					{
						displayedColumns: 'buttonStyleName',
						tableHeader: 'Button Style Name',
						isPinned: false,
						isSortable: true,
						// maxWidth: 229,
					},
					{
						displayedColumns: 'buttonPreview',
						tableHeader: 'Button Preview',
						isPinned: false,
						isSortable: true,
						// maxWidth: 219.67,
						isForEmailTemplateSettingsButtonsOnly: true,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						buttons: [],
						maxWidth: 144,
						isCustomizeActionColumnContent: true,
					},
				];
				break;
			case SPAdminRoutes.REPORTS_USER_LOGIN_REPORT:
				tableConfig = [
					{
						displayedColumns: 'date',
						tableHeader: 'Date',
						isPinned: true,
						isSortable: true,
					},
					{
						displayedColumns: 'userName',
						tableHeader: 'Name',
						isPinned: true,
						isSortable: true,
					},
					{
						displayedColumns: 'companyName',
						tableHeader: 'Company',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'description',
						tableHeader: 'DAU Type',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'platformType',
						tableHeader: 'Platform',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'browserType',
						tableHeader: 'Browser Type',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'browserResolution',
						tableHeader: 'Resolution',
						isPinned: false,
						isSortable: true,
					},
				];
				break;
		}

		return tableConfig;
	}

	private _fetchClientAdminTableConfig(): ITableConfig[] {
		let tableConfig: ITableConfig[] = [];

		switch (this._url) {
			case ClientAdminRoutes.SUPPORT_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'iconName',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 340,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isSortable: false,
						hasPermission: true,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._supportCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._supportCategoryActionPermission,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.REQUEST_FORM:
				tableConfig = [
					{
						displayedColumns: 'title',
						tableHeader: 'Title',
						isPinned: true,
						isSortable: true,
						isForRequestFormOnly: true,
						isTextWithBadge: true,
						maxWidth: 352,
					},
					{
						displayedColumns: 'categoryName',
						tableHeader: 'Sub-Categories',
						isPinned: false,
						isSortable: true,
						maxWidth: 230,
					},
					{
						displayedColumns: 'createdDate',
						tableHeader: 'Date Created',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 168,
					},
					{
						displayedColumns: 'modifiedDate',
						tableHeader: 'Date Modified',
						isPinned: false,
						isSortable: true,
						isDateTime: true,
						maxWidth: 168,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						isForRequestFormOnly: true,
						maxWidth: 174,
						buttons: [
							{
								title: 'Preview',
								icon: 'eye',
								identifier: 'view',
								hasPermission: true,
							},
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Duplicate',
								icon: 'copy-02',
								identifier: 'copy',
								hasPermission: this._requestFormActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission:
									this._requestFormActionPermission &&
									this._userService.isSpAdmin,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.KB_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isIcon: true,
						isSortable: false,
						maxWidth: 455,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: false,
						isSortable: false,
						hasPermission: this._kbCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbCategoryActionPermission,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.KB:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: true,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'topic',
						tableHeader: 'Topics',
						isPinned: true,
						isSortable: true,
						maxWidth: 266,
					},
					{
						displayedColumns: 'category',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: true,
						maxWidth: 247,
					},
					{
						displayedColumns: 'rating',
						tableHeader: 'Rating',
						isPinned: false,
						isSortable: false,
						isForKBOnly: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._kbActionPermission,
						maxWidth: 144,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._kbActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._kbActionPermission,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.DIRECTORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name & Title',
						isPinned: true,
						isSortable: true,
						isDate: true,
						isForDirectoryOnly: true,
						maxWidth: 304,
					},
					{
						displayedColumns: 'departmentName',
						tableHeader: 'Department',
						isPinned: false,
						isSortable: true,
						maxWidth: 226,
					},
					{
						displayedColumns: 'siteName',
						tableHeader: 'Site',
						isPinned: false,
						isSortable: true,
						maxWidth: 190,
					},
					{
						displayedColumns: 'role',
						tableHeader: 'Role',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
					},
					{
						displayedColumns: 'email',
						tableHeader: 'Email',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
					},
					{
						displayedColumns: 'phone',
						tableHeader: 'Phone',
						isPinned: false,
						isSortable: true,
						maxWidth: 292.33,
						isPhoneNumber: true,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						buttons: [
							{
								title: 'View Profile',
								icon: 'eye',
								identifier: 'edit',
								hasPermission: this._directoryActionPermission,
							},
							{
								title: '',
								icon: 'user-right-01',
								identifier: 'impersonate',
								hasPermission: this._directoryImpersonatePermission,
							},
							{
								title: 'Manage User in Connect Wise',
								icon: 'link-external-01',
								identifier: 'external-link',
								hasPermission: this._directoryActionPermission,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.APPLICATION_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._applicationCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: this._applicationCategoryActionPermission,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: this._applicationCategoryActionPermission,
							},
						],
					},
				];
				break;

			case ClientAdminRoutes.APPLICATION:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 100,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
					},
					{
						displayedColumns: 'iconURL',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: false,
						isImageUrl: true,
					},
					{
						displayedColumns: 'applicationCategoryName',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: false,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						maxWidth: 130,
						hasPermission: this._applicationActionPermission,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: true,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: true,
							},
						],
					},
				];
				break;
		}

		return tableConfig;
	}

	private _fetchUserTableConfig(): ITableConfig[] {
		let tableConfig: ITableConfig[] = [];

		switch (this._url) {
			case UserRoutes.APPLICATION:
				tableConfig = [
					{
						displayedColumns: 'access',
						tableHeader: 'Access',
						isPinned: false,
						isSortable: false,
						isLockUnlockIcon: true,
						maxWidth: 96,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 470,
					},
					{
						displayedColumns: 'iconURL',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: false,
						isImageUrl: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'applicationCategoryName',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: false,
						maxWidth: 184,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._applicationActionPermission,
						maxWidth: 130,
						buttons: [
							{
								title: 'Edit',
								icon: 'edit-02',
								identifier: 'edit',
								hasPermission: true,
							},
							{
								title: 'Delete',
								icon: 'trash-01',
								identifier: 'delete',
								hasPermission: true,
							},
						],
					},
				];
				break;

			case UserRoutes.APPLICATION_CATEGORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'iconUrl',
						tableHeader: 'Icon',
						isPinned: false,
						isSortable: true,
						isIcon: true,
						maxWidth: 455,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: this._applicationCategoryActionPermission,
						maxWidth: 160,
						buttons: [
							{
								title: 'Delete',
								icon: 'alert-octagon',
								identifier: 'delete',
								hasPermission: this._applicationCategoryActionPermission,
							},
							{
								title: 'Edit',
								icon: 'edit-05',
								identifier: 'edit',
								hasPermission: this._applicationCategoryActionPermission,
							},
						],
					},
				];
				break;

			case UserRoutes.TICKETS:
				tableConfig = [
					{
						displayedColumns: 'id',
						tableHeader: 'Ticket ID',
						isPinned: true,
						isSortable: true,
						isNumberOnly: true,
						maxWidth: 140,
					},
					{
						displayedColumns: 'summary',
						tableHeader: 'Description',
						isPinned: false,
						isSortable: true,
						maxWidth: 360,
					},
					{
						displayedColumns: 'lastUpdated',
						tableHeader: 'Last Update',
						isPinned: false,
						isSortable: false,
						isDateTime: true,
						hasNewMessageIndicator: true,
						maxWidth: 210,
					},
					{
						displayedColumns: 'dateCreated',
						tableHeader: 'Date Created',
						isPinned: false,
						isSortable: false,
						isDate: true,
						maxWidth: 162,
					},
					{
						displayedColumns: 'type',
						tableHeader: 'Type',
						isPinned: false,
						isSortable: true,
						maxWidth: 212.5,
					},
					{
						displayedColumns: 'subType',
						tableHeader: 'Subtype',
						isPinned: false,
						isSortable: true,
						maxWidth: 212.5,
					},
					{
						displayedColumns: 'statusName',
						tableHeader: 'Status',
						isPinned: false,
						isSortable: true,
						isPinnedEnd: true,
						isBadge: true,
						maxWidth: 136,
					},
				];
				break;

			case UserRoutes.ORDERS:
				tableConfig = [
					{
						displayedColumns: 'orderDate',
						tableHeader: 'Order Date',
						isPinned: true,
						isSortable: true,
						isDate: true,
						maxWidth: 140,
					},
					{
						displayedColumns: 'id',
						tableHeader: 'Order #',
						isPinned: false,
						isSortable: true,
						isNumberOnly: true,
						maxWidth: 140,
					},
					{
						displayedColumns: 'customerPO',
						tableHeader: 'Customer PO #',
						isPinned: false,
						isSortable: true,
						isNumberOnly: true,
						maxWidth: 156,
					},
					{
						displayedColumns: 'description',
						tableHeader: 'Description',
						isPinned: false,
						isSortable: true,
						maxWidth: 337,
					},
					{
						displayedColumns: 'total',
						tableHeader: 'Total',
						isPinned: false,
						isSortable: false,
						isCurrency: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'status',
						tableHeader: 'Status',
						isPinned: false,
						isSortable: true,
						isBadge: true,
						isPinnedEnd: true,
						maxWidth: 144,
					},
				];
				break;

			case UserRoutes.QUOTES:
				tableConfig = [
					{
						displayedColumns: 'quoteDate',
						tableHeader: 'Quote Date',
						isPinned: true,
						isSortable: true,
						isDate: true,
						maxWidth: 140,
					},
					{
						displayedColumns: 'id',
						tableHeader: 'Quote #',
						isPinned: false,
						isSortable: true,
						isNumberOnly: true,
						maxWidth: 110,
					},
					{
						displayedColumns: 'category',
						tableHeader: 'Category',
						isPinned: false,
						isSortable: true,
						maxWidth: 296,
					},
					{
						displayedColumns: 'name',
						tableHeader: 'Description',
						isPinned: false,
						isSortable: true,
						maxWidth: 296,
					},
					{
						displayedColumns: 'total',
						tableHeader: 'Total',
						isPinned: false,
						isSortable: false,
						isCurrency: true,
						maxWidth: 160,
					},
					{
						displayedColumns: 'status',
						tableHeader: 'Status',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: true,
						isBadge: true,
						maxWidth: 96,
					},
					{
						displayedColumns: 'action',
						tableHeader: 'Actions',
						isPinned: false,
						isPinnedEnd: true,
						isSortable: false,
						hasPermission: true,
						maxWidth: 96,
						buttons: [
							{
								title: 'View Quote',
								disabledBtnTitle: 'Quote Not Prepared',
								icon: 'file-05',
								identifier: 'external-link',
								hasPermission: this._quotesActionPermission,
							},
						],
					},
				];
				break;

			case UserRoutes.DIRECTORY:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name & Title',
						isPinned: true,
						isSortable: true,
						isDate: true,
						isForDirectoryOnly: true,
						maxWidth: 304,
					},
					{
						displayedColumns: 'departmentName',
						tableHeader: 'Department',
						isPinned: false,
						isSortable: true,
						maxWidth: 287.08,
					},
					{
						displayedColumns: 'siteName',
						tableHeader: 'Site',
						isPinned: false,
						isSortable: true,
						maxWidth: 289.75,
					},
					{
						displayedColumns: 'email',
						tableHeader: 'Email',
						isPinned: false,
						isSortable: true,
						maxWidth: 287.08,
					},
					{
						displayedColumns: 'phone',
						tableHeader: 'Phone',
						isPinned: false,
						isSortable: true,
						maxWidth: 287.08,
						isPhoneNumber: true,
					},
				];
				break;

			case UserRoutes.ASSETS:
				tableConfig = [
					{
						displayedColumns: 'name',
						tableHeader: 'Name',
						isPinned: false,
						isSortable: true,
						maxWidth: 229,
					},
					{
						displayedColumns: 'type',
						tableHeader: 'Asset Type',
						isPinned: false,
						isSortable: true,
						maxWidth: 219.67,
					},
					{
						displayedColumns: 'expirationDate',
						tableHeader: 'Expiration Date',
						isPinned: false,
						isSortable: true,
						isDate: true,
						maxWidth: 153,
					},
					{
						displayedColumns: 'lastBackup',
						tableHeader: 'Last Backup',
						isPinned: false,
						isSortable: true,
						maxWidth: 219.67,
					},
					{
						displayedColumns: 'status',
						tableHeader: 'Status',
						isPinned: false,
						isSortable: true,
						isBadge: true,
					},
					{
						displayedColumns: 'onlineOffline',
						tableHeader: 'Online/Offline',
						isPinned: false,
						isSortable: true,
						isForCompanyAssetsTableOnly: true,
						isPinnedEnd: true,
					},
				];
				break;

			case UserRoutes.INVOICES_USER || UserRoutes.INVOICES_ADMIN:
				if (this.tabIndex === InvoiceTab.accountActivity) {
					tableConfig = [
						{
							displayedColumns: 'activityDate',
							tableHeader: 'Date',
							isPinned: false,
							isSortable: false,
							isDate: true,
							isForInvoiceAccountActivityTableOnly: true,
						},
						{
							displayedColumns: 'activityTime',
							tableHeader: 'Time',
							isPinned: false,
							isSortable: false,
							isShortTime: true,
							isForInvoiceAccountActivityTableOnly: true,
						},
						{
							displayedColumns: 'activity',
							tableHeader: 'Account Activity',
							isPinned: false,
							isSortable: false,
							isForInvoiceAccountActivityTableOnly: true,
							maxWidth: 880,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.payments) {
					tableConfig = [
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Invoice #',
							isPinned: true,
							isSortable: true,
							maxWidth: 132,
						},
						{
							displayedColumns: 'paymentDate',
							tableHeader: 'Paid Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 104,
						},
						{
							displayedColumns: 'initiatedBy',
							tableHeader: 'Initiated By',
							isPinned: false,
							isSortable: true,
							maxWidth: 195,
						},
						{
							displayedColumns: 'amountPaid',
							tableHeader: 'Amount Paid',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'creditsApplied',
							tableHeader: 'Credits Applied',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'total',
							tableHeader: 'Total',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							isForInvoicePaymentHistoryTableOnly: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'referenceNumber',
							tableHeader: 'Transaction ID',
							isPinned: false,
							isSortable: true,
							maxWidth: 326,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.credits) {
					tableConfig = [
						{
							displayedColumns: 'id',
							tableHeader: 'ID',
							isPinned: true,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 140,
						},
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Reference No.',
							isPinned: false,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'invoiceDate',
							tableHeader: 'Credit Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'transactionType',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 104,
						},
						{
							displayedColumns: 'isSettled',
							tableHeader: 'Applied',
							isPinned: false,
							isSortable: true,
							isForInvoiceCreditsTableOnly: true,
							maxWidth: 92,
						},
						{
							displayedColumns: 'billAmount',
							tableHeader: 'Original Amt',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'amountUnused',
							tableHeader: 'Remaining Balance',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 195.67,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}

				if (this.tabIndex === InvoiceTab.invoice) {
					tableConfig = [
						{
							displayedColumns: 'checkbox',
							tableHeader: 'Checkbox',
							isPinned: true,
							isSortable: false,
							isCustomizeFirstColumnContent: true,
							maxWidth: 64,
						},
						{
							displayedColumns: 'invoiceNumber',
							tableHeader: 'Invoice #',
							isPinned: true,
							isSortable: true,
							isNumberOnly: true,
							maxWidth: 140,
						},
						{
							displayedColumns: 'invoiceDate',
							tableHeader: 'Invoice Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'dueDate',
							tableHeader: 'Due Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'billAmount',
							tableHeader: 'Bill Amount',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 145,
						},
						{
							displayedColumns: 'balance',
							tableHeader: 'Balance Due',
							isPinned: false,
							isSortable: false,
							isCurrency: true,
							maxWidth: 142,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isSortable: true,
							isForInvoiceStatusColumnOnly: true,
							maxWidth: 147.67,
						},
						{
							displayedColumns: 'action',
							tableHeader: 'Actions',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: false,
							hasPermission: true,
							buttons: [],
							maxWidth: 144,
							isCustomizeActionColumnContent: true,
						},
					];
				}
				break;
			case UserRoutes.DASHBOARD:
				if (
					this._dbReportService.dbModal === DashboardReportTab.SatisfactionScore
				) {
					tableConfig = [
						{
							displayedColumns: 'feedbackSource',
							tableHeader: 'Feedback Source',
							isPinned: true,
							isSortable: true,
							maxWidth: 150,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'satisfactionScore',
							tableHeader: 'Satisfaction Score',
							isPinned: false,
							isSortable: true,
							isSatScore: true,
							maxWidth: 150,
						},
						{
							displayedColumns: 'feedbackDate',
							tableHeader: 'Feedback Date',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 150,
						},
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: false,
							isSortable: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'ticketDescription',
							tableHeader: 'Ticket Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 220,
						},
						{
							displayedColumns: 'feedback',
							tableHeader: 'Feedback',
							isPinned: false,
							isSortable: true,
							maxWidth: 220,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.TotalTickets
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isSortable: true,
							isBadge: true,
							isPinnedEnd: true,
							maxWidth: 144,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Onboarding
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isSortable: true,
							isBadge: true,
							isPinnedEnd: true,
							maxWidth: 144,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTicket
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isSortable: true,
							isBadge: true,
							isPinnedEnd: true,
							maxWidth: 144,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTimes
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 152,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'acknowledgementTime',
							tableHeader: 'Acknowledgement Time',
							isPinned: false,
							isSecondsTime: true,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'responseTime',
							tableHeader: 'Response Time',
							isPinned: false,
							isSecondsTime: true,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'resolutionTime',
							tableHeader: 'Resolution Time',
							isPinned: false,
							isSecondsTime: true,
							isSortable: true,
							maxWidth: 200,
						},

						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.DeviceTicket
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: true,
							isBadge: true,
							maxWidth: 96,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.RequestSource
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},

						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: true,
							isBadge: true,
							maxWidth: 96,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.UserTicket
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'device',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: true,
							isBadge: true,
							maxWidth: 96,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.SiteTicket
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 352,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'siteName',
							tableHeader: 'Site',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'lastUpdated',
							tableHeader: 'Last Update',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'primaryContact',
							tableHeader: 'Primary Contact',
							isPinned: false,
							isSortable: false,
							maxWidth: 200,
						},
						{
							displayedColumns: 'dateCreated',
							tableHeader: 'Date Created',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 162,
						},
						{
							displayedColumns: 'dateClosed',
							tableHeader: 'Date Closed',
							isPinned: false,
							isSortable: true,
							isDate: true,
							isRightAlign: true,
							maxWidth: 128,
						},
						{
							displayedColumns: 'typeName',
							tableHeader: 'Type',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'subTypeName',
							tableHeader: 'Subtype',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'sourceName',
							tableHeader: 'Source',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},

						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'status',
							tableHeader: 'Status',
							isPinned: false,
							isPinnedEnd: true,
							isSortable: true,
							isBadge: true,
							maxWidth: 96,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.TimeSpent
				) {
					// this._dbReportService.timeSpentStatus
					if (this._dbReportService.timeSpentStatus === 'View More') {
					} else {
						tableConfig = [
							{
								displayedColumns: 'ticketId',
								tableHeader: 'Ticket #',
								isPinned: true,
								isSortable: true,
								maxWidth: 152,
							},
							{
								displayedColumns: 'description',
								tableHeader: 'Description',
								isPinned: false,
								isSortable: true,
								maxWidth: 250,
							},
							{
								displayedColumns:
									this._dbReportService.timeSpentStatusField + 'TimeSpent',
								secondaryColumn:
									this._dbReportService.timeSpentStatusField + 'Percentage',
								tableHeader: this._dbReportService.timeSpentStatus,
								isPinned: false,
								isSortable: true,
								isTimeSpent: true,
								maxWidth: 300,
							},
							{
								displayedColumns: 'lastUpdated',
								tableHeader: 'Last Update',
								isPinned: false,
								isSortable: true,
								isDate: true,
								isRightAlign: true,
								maxWidth: 128,
							},
							{
								displayedColumns: 'primaryContact',
								tableHeader: 'Primary Contact',
								isPinned: false,
								isSortable: false,
								maxWidth: 150,
							},
							{
								displayedColumns: 'dateCreated',
								tableHeader: 'Date Created',
								isPinned: false,
								isSortable: true,
								isDate: true,
								isRightAlign: true,
								maxWidth: 150,
							},
							{
								displayedColumns: 'dateClosed',
								tableHeader: 'Date Closed',
								isPinned: false,
								isSortable: true,
								isDate: true,
								isRightAlign: true,
								maxWidth: 128,
							},
							{
								displayedColumns: 'typeName',
								tableHeader: 'Type',
								isPinned: false,
								isSortable: true,
								maxWidth: 150,
							},
							{
								displayedColumns: 'subTypeName',
								tableHeader: 'Subtype',
								isPinned: false,
								isSortable: true,
								maxWidth: 150,
							},
							{
								displayedColumns: 'sourceName',
								tableHeader: 'Source',
								isPinned: false,
								isSortable: true,
								maxWidth: 150,
							},
							{
								displayedColumns: 'siteName',
								tableHeader: 'Site',
								isPinned: false,
								isSortable: true,
								maxWidth: 150,
							},

							{
								displayedColumns: 'deviceName',
								tableHeader: 'Device',
								isPinned: false,
								isSortable: true,
								maxWidth: 150,
							},
						];
					}
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Vulnerabilities
				) {
					tableConfig = [
						{
							displayedColumns: 'vendor',
							tableHeader: 'Vendor',
							isPinned: false,
							isSortable: true,
							maxWidth: 250,
						},
						{
							displayedColumns: 'software',
							tableHeader: 'Software',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'id',
							tableHeader: 'CVE ID',
							isPinned: false,
							isSortable: true,
							isUrl: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'kbId',
							tableHeader: 'KB ID',
							isPinned: false,
							isSortable: true,
							isUrl: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'noOfDevicesImpacted',
							tableHeader: '# of Devices Impacted',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'isActivelyExploited',
							tableHeader: 'Actively Exploited',
							isPinned: false,
							isSortable: true,
							isNaOrData: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'age',
							tableHeader: 'Age',
							isPinned: false,
							isSortable: true,
							isAgeTime: true,
							maxWidth: 220,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskUsers
				) {
					tableConfig = [
						{
							displayedColumns: 'userName',
							tableHeader: 'Name',
							isPinned: false,
							isSortable: true,
							isUserColumn: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'position',
							tableHeader: 'Position',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'department',
							tableHeader: 'Department',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'severity',
							tableHeader: 'Severity',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'risks',
							tableHeader: 'Risk',
							isPinned: false,
							isSortable: false,
							isSecurityRisk: true,
							maxWidth: 220,
						},
					];
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskDevices
				) {
					tableConfig = [
						{
							displayedColumns: 'deviceName',
							tableHeader: 'Device Name',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'criticalVulnerabilities',
							tableHeader: 'Critical Vulnerabilities',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'operatingSystem',
							tableHeader: 'Operating Software (OS)',
							isPinned: false,
							isSortable: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'lastSeenDate',
							tableHeader: 'Last Seen',
							isPinned: false,
							isSortable: true,
							isLastSeenDate: true,
							maxWidth: 200,
						},
					];
				} else if (
					this._dbReportService.dbModal ===
					DashboardReportTab.RecentSecurityTickets
				) {
					tableConfig = [
						{
							displayedColumns: 'ticketId',
							tableHeader: 'Ticket #',
							isPinned: true,
							isSortable: true,
							maxWidth: 150,
						},
						{
							displayedColumns: 'description',
							tableHeader: 'Description',
							isPinned: false,
							isSortable: true,
							maxWidth: 300,
						},
						{
							displayedColumns: 'resolutionTime',
							tableHeader: '~ Resolution Time',
							isPinned: false,
							isSortable: true,
							isSecondsTime: true,
							maxWidth: 200,
						},
						{
							displayedColumns: 'satisfactionScore',
							tableHeader: 'Satisfaction Score',
							isPinned: false,
							isSortable: true,
							isSatScore: true,
							maxWidth: 200,
						},
					];
				}

				break;
		}

		return tableConfig;
	}

	private _fetchSPAdminTableData(isCustom?: boolean) {
		let filters, action;

		switch (this._url) {
			case SPAdminRoutes.GLOBAL_SUPPORT_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._supportService.globalSupportCategoryFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						globalSupportCategoriesUpdateAction
					);
					this._supportService.getSRCategory(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case `${SPAdminRoutes.COMPANY_SUPPORT_CATEGORY}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._supportService.companySupportCategoryFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companiesSupportCategoriesUpdateAction
					);
					this._supportService.getSRCategory(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case `${SPAdminRoutes.GLOBAL_NOTIFICATION}${this._companyId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_VIEW}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._notificationCenterService.territoryNotificationFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(
						currentFilters,
						notificationCenterEditViewUpdateAction
					);
					this._notificationCenterService
						.getTerritoryNotificationAccessById(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Notifications');
							},
						});
				}
				break;

			case `${SPAdminRoutes.COMPANY_NOTIFICATION}${this._companyId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/edit-notification/${this._territoryId}`:
			case `${SPAdminRoutes.GLOBAL_NOTIFICATION_FILTER_BY_COMPANY}${this._companyId}/view-notification/${this._territoryId}`:
				if (
					this._hasSearchFilterChanged(
						this._notificationCenterService.companyNotificationFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(
						currentFilters,
						companyNotificationCenterEditViewUpdateAction
					);
					this._notificationCenterService
						.getCompanyNotificationAccessById(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Notifications');
							},
						});
				}
				break;
				break;

			case SPAdminRoutes.COMPANY:
				if (
					this._hasSearchFilterChanged(this._companiesService.companyFilters)
				) {
					const currentFilters = clone(this._currentFilters);
					this._updateStateFilters(currentFilters, companiesUpdateAction);
					this._companiesService.getCompanies(currentFilters).subscribe({
						error: () => {
							this._notifyError('Companies');
						},
					});
				}
				break;

			case SPAdminRoutes.GLOBAL_REQUEST_FORM:
				if (
					this._hasSearchFilterChanged(
						this._supportService.globalRequestFormFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, requestFormsUpdateAction);
					this._supportService.customFormList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Forms');
						},
					});
				}
				break;

			case `${SPAdminRoutes.COMPANY_REQUEST_FORM}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._supportService.companyRequestFormFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyRequestFormsUpdateAction
					);
					this._supportService.customFormList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Forms');
						},
					});
				}
				break;

			case SPAdminRoutes.DASHBOARD_SETUP:
				if (
					this._hasSearchFilterChanged(
						this._dashboardCardService.dashboardSetupFilters
					) ||
					this._dashboardCardService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._dashboardCardService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, dashboardCardUpdateAction);
					this._dashboardCardService.getList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Dashboard Setup');
						},
					});
				}
				break;

			case SPAdminRoutes.GLOBAL_KB_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._kbService.globalKBCategoryFilters
					) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						globalKbCategoryUpdateAction
					);
					this._kbService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case `${SPAdminRoutes.COMPANY_KB_CATEGORY}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._kbService.companyKBCategoryFilters
					) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyKbCategoryUpdateAction
					);
					this._kbService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case SPAdminRoutes.GLOBAL_KB:
				if (
					this._hasSearchFilterChanged(this._kbService.globalKBFilters) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, globalKbTopicsUpdateAction);
					this._kbService.getTopics(currentFilters).subscribe({
						error: () => {
							this._notifyError('Topics');
						},
					});
				}
				break;

			case `${SPAdminRoutes.COMPANY_KB}${this._companyId}`:
				filters = isCustom
					? this._kbService.companyKBFilters
					: this._kbService.companyGlobalKBFilters;
				action = isCustom
					? companyKbTopicsUpdateAction
					: companyGlobalKbTopicsUpdateAction;

				if (
					this._hasSearchFilterChanged(filters!) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, action);
					this._kbService.getTopics(currentFilters, isCustom).subscribe({
						error: () => {
							this._notifyError('Applications');
						},
					});
				}
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION:
				if (
					this._hasSearchFilterChanged(
						this._applicationsService.globalApplicationFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, applicationUpdateAction);
					this._applicationsService
						.getApplicationList(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Applications');
							},
						});
				}
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION}${this._companyId}`:
				filters = isCustom
					? this._applicationsService.companyApplicationFilters
					: this._applicationsService.companyGlobalApplicationFilters;
				action = isCustom
					? companyApplicationUpdateAction
					: globalApplicationUpdateAction;

				if (
					this._hasSearchFilterChanged(filters!) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, action);
					this._applicationsService
						.getApplicationList(currentFilters, isCustom)
						.subscribe({
							error: () => {
								this._notifyError('Applications');
							},
						});
				}
				break;

			case SPAdminRoutes.GLOBAL_DIRECTORY:
				if (
					this._hasSearchFilterChanged(
						this._companiesService.globalDirectoryFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(currentFilters, contactsUpdateAction);
					this._companiesService
						.getContactListByCompanyId(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Contacts');
							},
						});
				}
				break;

			case `${SPAdminRoutes.COMPANY_DIRECTORY}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._companiesService.companyDirectoryFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(currentFilters, companyContactsUpdateAction);
					this._companiesService
						.getContactListByCompanyId(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Contacts');
							},
						});
				}
				break;

			case SPAdminRoutes.INVOICES_ADMIN:
				if (
					this.tabIndex === InvoiceTab.accountActivity &&
					this._hasSearchFilterChanged(
						this._invoiceService.accountActivityFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);
					this._invoiceService.getAccountActivity(currentFilters).subscribe({
						error: () => {
							this._notifyError('Account Activities');
						},
					});
				}

				if (
					this.tabIndex === InvoiceTab.payments &&
					this._hasSearchFilterChanged(this._invoiceService.paymentsFilters)
				) {
					const currentFilters = clone(this._currentFilters);
					this._invoiceService.getPaymentsTable(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo('fetch payment history'),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				}

				if (
					this.tabIndex === InvoiceTab.credits &&
					this._hasSearchFilterChanged(this._invoiceService.creditsFilters)
				) {
					const currentFilters = clone(this._currentFilters);
					this._invoiceService.getCreditTable(currentFilters).subscribe({
						error: () => {
							this._notifyError('Credits');
						},
					});
				}

				if (
					(this.tabIndex === InvoiceTab.invoice &&
						this._hasSearchFilterChanged(
							this._invoiceService.invoiceFilters
						)) ||
					this._tableReloadSignalService.allowToFetchNewDataInvoiceTab
				) {
					const currentFilters = clone(this._currentFilters);

					this._tableReloadSignalService.allowToFetchNewDataInvoiceTab = false;
					this._invoiceService.getInvoices(currentFilters).subscribe({
						error: () => {
							this._notifyError('Invoices');
						},
					});
				}

				break;

			case SPAdminRoutes.GLOBAL_APPLICATION_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._applicationsService.globalCategoryFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, appCategoryListUpdateAction);
					this._applicationsService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case SPAdminRoutes.GLOBAL_EMAIL_TEMPLATE:
				if (
					this._hasSearchFilterChanged(
						this._emailTemplateService.emailTemplateFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);
					this._updateStateFilters(currentFilters, emailTemplateUpdateAction);
					this.allowToFetchNewData = false;
					this._emailTemplateService
						.getEmailTemplates(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo('fetch email templates.'),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				}
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION_CATEGORY}${this._companyId}`:
				if (
					this._hasSearchFilterChanged(
						this._applicationsService.companyCategoryFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyAppCategoryListUpdateAction
					);
					this._applicationsService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_FONTS:
				if (
					this._hasSearchFilterChanged(
						this._fontsTemplateSettingsService.fontTemplateFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(
						currentFilters,
						fontsTemplateSettingsUpdateAction
					);
					this._fontsTemplateSettingsService
						.getFontTemplateTable(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notifyError(
									NotificationMessages.unable('Fetch Fonts'),
									NotificationMessages.Try
								);
							},
						});
				}
				break;

			case SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_BUTTONS:
				if (
					this._hasSearchFilterChanged(
						this._buttonsTemplateSettingsService.buttonsTemplateFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(
						currentFilters,
						buttonsTemplateSettingsUpdateAction
					);
					this._buttonsTemplateSettingsService
						.getButtonsTemplateTable(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notifyError(
									NotificationMessages.unable('Fetch Buttons'),
									NotificationMessages.Try
								);
							},
						});
				}
				break;
			case SPAdminRoutes.REPORTS_USER_LOGIN_REPORT:
				if (
					this._hasSearchFilterChanged(
						this._portalUsageService.userLoginFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);
					this._portalUsageService
						.getUserLoginReport(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notifyError(
									NotificationMessages.Retrieval,
									NotificationMessages.RefreshTry
								);
							},
						});
				}
				break;
		}
	}

	private _fetchClientAdminTableData(isCustom?: boolean) {
		let filters, action;

		switch (this._url) {
			case ClientAdminRoutes.SUPPORT_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._supportService.companySupportCategoryFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companiesSupportCategoriesUpdateAction
					);
					this._supportService.getSRCategory(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case ClientAdminRoutes.REQUEST_FORM:
				if (
					this._hasSearchFilterChanged(
						this._supportService.companyRequestFormFilters
					) ||
					this.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyRequestFormsUpdateAction
					);
					this._supportService.customFormList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Forms');
						},
					});
				}
				break;

			case ClientAdminRoutes.KB_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._kbService.companyKBCategoryFilters
					) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyKbCategoryUpdateAction
					);
					this._kbService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case ClientAdminRoutes.KB:
				filters = isCustom
					? this._kbService.companyKBFilters
					: this._kbService.companyGlobalKBFilters;
				action = isCustom
					? companyKbTopicsUpdateAction
					: companyGlobalKbTopicsUpdateAction;

				if (
					this._hasSearchFilterChanged(filters!) ||
					this._kbService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._kbService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, action);
					this._kbService.getTopics(currentFilters, isCustom).subscribe({
						error: () => {
							this._notifyError('Applications');
						},
					});
				}
				break;

			case ClientAdminRoutes.APPLICATION:
				filters = isCustom
					? this._applicationsService.companyApplicationFilters
					: this._applicationsService.companyGlobalApplicationFilters;
				action = isCustom
					? companyApplicationUpdateAction
					: globalApplicationUpdateAction;

				if (
					this._hasSearchFilterChanged(filters!) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(currentFilters, action);
					this._applicationsService
						.getApplicationList(currentFilters, isCustom)
						.subscribe({
							error: () => {
								this._notifyError('Applications');
							},
						});
				}
				break;

			case ClientAdminRoutes.DIRECTORY:
				if (
					this._hasSearchFilterChanged(
						this._companiesService.companyDirectoryFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(currentFilters, contactsUpdateAction);
					this._companiesService
						.getContactListByCompanyId(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Contacts');
							},
						});
				}
				break;

			case ClientAdminRoutes.APPLICATION_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._companiesService.companyDirectoryFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						companyAppCategoryListUpdateAction
					);
					this._applicationsService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;
		}
	}

	private _fetchUserTableData(prevRequest?: Subscription): Subscription | void {
		switch (this._url) {
			case UserRoutes.APPLICATION:
				if (
					this._hasSearchFilterChanged(
						this._applicationsService.userApplicationFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						contactsCompanyApplicationUpdateAction
					);
					this._applicationsService
						.getApplicationList(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Applications');
							},
						});
				}
				break;

			case UserRoutes.APPLICATION_CATEGORY:
				if (
					this._hasSearchFilterChanged(
						this._applicationsService.userCategoryFilters
					) ||
					this._applicationsService.allowToFetchNewData
				) {
					const currentFilters = clone(this._currentFilters);

					this._applicationsService.allowToFetchNewData = false;
					this._updateStateFilters(
						currentFilters,
						userAppCategoryListUpdateAction
					);
					this._applicationsService.getCategoryList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Categories');
						},
					});
				}
				break;

			case UserRoutes.TICKETS:
				if (
					this._hasSearchFilterChanged(
						this._ticketService.viewTicketsFilters
					) ||
					this._tableReloadSignalService.allowToFetchNewDataTicketPage
				) {
					const currentFilters = clone(this._currentFilters);

					this._tableReloadSignalService.allowToFetchNewDataTicketPage = false;
					this._updateStateFilters(currentFilters, ticketsUpdateAction);
					if (prevRequest && !prevRequest.closed) prevRequest.unsubscribe();
					return this._ticketService.getTickets(currentFilters).subscribe({
						error: () => {
							this._notifyError('Tickets');
						},
					});
				}
				break;

			case UserRoutes.ORDERS:
				if (
					this._hasSearchFilterChanged(this._quoteOrderService.orderFilters) ||
					this._tableReloadSignalService.allowToFetchNewDataOrdersPage
				) {
					const currentFilters = clone(this._currentFilters);

					this._tableReloadSignalService.allowToFetchNewDataOrdersPage = false;
					this._updateStateFilters(currentFilters, ordersUpdateAction);
					this._quoteOrderService.getOrderList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Orders');
						},
					});
				}
				break;

			case UserRoutes.QUOTES:
				if (
					this._hasSearchFilterChanged(this._quoteOrderService.quoteFilters) ||
					this._tableReloadSignalService.allowToFetchNewDataQuotePage
				) {
					const currentFilters = clone(this._currentFilters);

					this._tableReloadSignalService.allowToFetchNewDataQuotePage = false;
					this._updateStateFilters(currentFilters, quotesUpdateAction);
					this._quoteOrderService.getQuoteList(currentFilters).subscribe({
						error: () => {
							this._notifyError('Quotes');
						},
					});
				}
				break;

			case UserRoutes.DIRECTORY:
				if (
					this._hasSearchFilterChanged(
						this._companiesService.userDirectoryFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(currentFilters, userContactsUpdateAction);
					this._companiesService
						.getContactListByCompanyId(currentFilters)
						.subscribe({
							error: () => {
								this._notifyError('Contacts');
							},
						});
				}
				break;

			case UserRoutes.ASSETS:
				if (this._hasSearchFilterChanged(this._inventoryService.assetFilters)) {
					const currentFilters = clone(this._currentFilters);

					this._updateStateFilters(currentFilters, assetsUpdateAction);
					this._inventoryService.getAssets(currentFilters).subscribe({
						error: () => {
							this._notifyError('Assets');
						},
					});
				}
				break;

			case UserRoutes.INVOICES_USER || UserRoutes.INVOICES_ADMIN:
				if (
					this.tabIndex === InvoiceTab.accountActivity &&
					this._hasSearchFilterChanged(
						this._invoiceService.accountActivityFilters
					)
				) {
					const currentFilters = clone(this._currentFilters);

					this._invoiceService.getAccountActivity(currentFilters).subscribe({
						error: () => {
							this._notifyError('Account Activities');
						},
					});
				}

				if (
					this.tabIndex === InvoiceTab.payments &&
					this._hasSearchFilterChanged(this._invoiceService.paymentsFilters)
				) {
					const currentFilters = clone(this._currentFilters);

					this._invoiceService.getPaymentsTable(currentFilters).subscribe({
						error: () => {
							this._notifyError('Payment History');
						},
					});
				}

				if (
					this.tabIndex === InvoiceTab.credits &&
					this._hasSearchFilterChanged(this._invoiceService.creditsFilters)
				) {
					const currentFilters = clone(this._currentFilters);

					this._invoiceService.getCreditTable(currentFilters).subscribe({
						error: () => {
							this._notifyError('Credits');
						},
					});
				}

				if (
					(this.tabIndex === InvoiceTab.invoice &&
						this._hasSearchFilterChanged(
							this._invoiceService.invoiceFilters
						)) ||
					this._tableReloadSignalService.allowToFetchNewDataInvoiceTab
				) {
					const currentFilters = clone(this._currentFilters);
					this._tableReloadSignalService.allowToFetchNewDataInvoiceTab = false;
					this._invoiceService.getInvoices(currentFilters).subscribe({
						error: () => {
							this._notifyError('Invoices');
						},
					});
				}

				break;

			case UserRoutes.DASHBOARD:
				const currentFilters = clone(this._currentFilters);
				if (
					this._dbReportService.dbModal === DashboardReportTab.SatisfactionScore
				) {
					this._dbReportService.getSatisfactionScore(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo('fetch Satisfaction Score'),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.TotalTickets
				) {
					this._dbReportService.getTicketData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo('fetch total tickets'),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Onboarding
				) {
					this._dbReportService.getOnbData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo(
									'fetch Number of Onboarding and Offboarding'
								),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTicket
				) {
					this._dbReportService.getServiceTicketData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo(
									'fetch Number of Service Tickets'
								),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.ServiceTimes
				) {
					this._dbReportService.getServiceTimesData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo('fetch Number of Service Times'),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.DeviceTicket
				) {
					this._dbReportService.getDeviceTicketData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo(
									'fetch Number of Top Devices Tickets'
								),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.UserTicket
				) {
					this._dbReportService.getUserTicketData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo(
									'fetch Number of Top User Tickets'
								),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.SiteTicket
				) {
					this._dbReportService.getSiteTicketData(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo(
									'fetch Number of Top User Tickets'
								),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.RequestSource
				) {
					this._dbReportService
						.getRequestSourceTicketData(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo(
										'fetch Number of Top User Tickets'
									),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.TimeSpent
				) {
					this._dbReportService
						.getTimeSpentTicketData(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo(
										'fetch Number of Top User Tickets'
									),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.Vulnerabilities
				) {
					this._dbReportService
						.getVulnerabilitiesData(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo('fetch Vulnerabilities'),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskUsers
				) {
					this._dbReportService.getAtRiskUsersDetail(currentFilters).subscribe({
						error: () => {
							this._notificationService.notify(
								NotificationMessages.failedTo('fetch Top At Risk Users'),
								{
									duration: 8,
									panelClass: 'error',
								}
							);
						},
					});
				} else if (
					this._dbReportService.dbModal === DashboardReportTab.AtRiskDevices
				) {
					this._dbReportService
						.getAtRiskDevicesDetail(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo('fetch At Risk Devices'),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				} else if (
					this._dbReportService.dbModal ===
					DashboardReportTab.RecentSecurityTickets
				) {
					this._dbReportService
						.getRecentSecurityTicketsDetail(currentFilters)
						.subscribe({
							error: () => {
								this._notificationService.notify(
									NotificationMessages.failedTo(
										'fetch Recent Security Tickets'
									),
									{
										duration: 8,
										panelClass: 'error',
									}
								);
							},
						});
				}

				break;
		}
	}

	private _notifyError(name: string) {
		this._notificationService.notifyError(
			NotificationMessages.unable(`Fetch ${name}`),
			NotificationMessages.Try
		);
	}

	private _fetchSPAdminRowClickUrl(id: number | string): string {
		let url = '';

		switch (this._url) {
			case SPAdminRoutes.GLOBAL_SUPPORT_CATEGORY:
				url = this._supportCategoryActionPermission
					? `${SPAdminRoutes.GLOBAL_SUPPORT_CATEGORY}/edit/${btoa(
							id.toString()
					  )}`
					: '';
				break;

			case `${SPAdminRoutes.COMPANY_SUPPORT_CATEGORY}${this._companyId}`:
				url = this._supportCategoryActionPermission
					? `${SPAdminRoutes.COMPANY_SUPPORT_CATEGORY}${
							this._companyId
					  }/edit/${btoa(id.toString())}`
					: '';
				break;

			case SPAdminRoutes.COMPANY:
				url = `/companies/contact-setting/profile/${id}`;
				break;

			case SPAdminRoutes.GLOBAL_REQUEST_FORM:
				url = this._requestFormActionPermission
					? `${SPAdminRoutes.GLOBAL_REQUEST_FORM}/edit/${id}`
					: '';
				break;

			case `${SPAdminRoutes.COMPANY_REQUEST_FORM}${this._companyId}`:
				url = this._requestFormActionPermission
					? `${SPAdminRoutes.COMPANY_REQUEST_FORM}${this._companyId}/edit/${id}`
					: '';
				break;

			case SPAdminRoutes.DASHBOARD_SETUP:
				url = this._dashboardSetupActionPermission
					? `${SPAdminRoutes.DASHBOARD_SETUP}/edit/${id}`
					: '';
				break;

			case SPAdminRoutes.GLOBAL_KB_CATEGORY:
				url = this._kbCategoryActionPermission
					? `${SPAdminRoutes.GLOBAL_KB_CATEGORY}/edit/${id}`
					: '';
				break;

			case `${SPAdminRoutes.COMPANY_KB_CATEGORY}${this._companyId}`:
				url = this._kbCategoryActionPermission
					? `${SPAdminRoutes.COMPANY_KB_CATEGORY}${this._companyId}/edit/${id}`
					: '';
				break;

			case SPAdminRoutes.GLOBAL_KB:
			case `${SPAdminRoutes.COMPANY_KB}${this._companyId}`:
				url = this._kbActionPermission ? `edit` : '';
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION:
				url = `${SPAdminRoutes.GLOBAL_APPLICATION}/edit/${id}`;
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION}${this._companyId}`:
				url = `${SPAdminRoutes.COMPANY_APPLICATION}${this._companyId}/edit/${id}`;
				break;

			case SPAdminRoutes.GLOBAL_DIRECTORY:
				url = this._directoryActionPermission
					? `${SPAdminRoutes.GLOBAL_DIRECTORY}/${btoa(id.toString())}/profile`
					: '';
				break;

			case `${SPAdminRoutes.COMPANY_DIRECTORY}${this._companyId}`:
				url = this._directoryActionPermission
					? `${SPAdminRoutes.COMPANY_DIRECTORY}${this._companyId}/${btoa(
							id.toString()
					  )}/profile`
					: '';
				break;

			case SPAdminRoutes.INVOICES_ADMIN:
				url = `${SPAdminRoutes.INVOICES_ADMIN}/invoice-detail/${id}`;
				break;

			case SPAdminRoutes.GLOBAL_APPLICATION_CATEGORY:
				url = this._applicationCategoryActionPermission
					? `${SPAdminRoutes.GLOBAL_APPLICATION_CATEGORY}/edit/${id}`
					: '';
				break;

			case `${SPAdminRoutes.COMPANY_APPLICATION_CATEGORY}${this._companyId}`:
				url = this._applicationCategoryActionPermission
					? `${SPAdminRoutes.COMPANY_APPLICATION_CATEGORY}${this._companyId}/edit/${id}`
					: '';
				break;
		}

		return url;
	}

	private _fetchClientAdminRowClickUrl(id: number | string): string {
		let url = '';

		switch (this._url) {
			case ClientAdminRoutes.SUPPORT_CATEGORY:
				url = this._supportCategoryActionPermission
					? `${ClientAdminRoutes.SUPPORT_CATEGORY}/edit/${btoa(id.toString())}`
					: '';
				break;

			case ClientAdminRoutes.REQUEST_FORM:
				url = this._requestFormActionPermission
					? `${ClientAdminRoutes.REQUEST_FORM}/edit/${id}`
					: '';
				break;

			case ClientAdminRoutes.KB_CATEGORY:
				url = this._kbCategoryActionPermission
					? `${ClientAdminRoutes.KB_CATEGORY}/edit/${id}`
					: '';
				break;

			case ClientAdminRoutes.DIRECTORY:
				url = `${ClientAdminRoutes.DIRECTORY}/${btoa(id.toString())}/profile`;
				break;

			case ClientAdminRoutes.APPLICATION:
				url = `${ClientAdminRoutes.APPLICATION}/edit/${id}`;
				break;

			case ClientAdminRoutes.KB:
				url = this._kbActionPermission ? `edit` : '';
				break;
		}

		return url;
	}

	private _fetchUserRowClickUrl(id: number | string): string {
		let url = '';

		switch (this._url) {
			case UserRoutes.APPLICATION:
				url = `${UserRoutes.APPLICATION}/edit/${id}`;
				break;

			case UserRoutes.TICKETS:
				url = `${UserRoutes.TICKETS}/view/${id}`;
				break;

			case UserRoutes.ORDERS:
				url = `${UserRoutes.ORDERS}/${id}`;
				break;

			case UserRoutes.DIRECTORY:
				url = this._directoryActionPermission
					? `${UserRoutes.DIRECTORY}/${btoa(id.toString())}/profile`
					: '';
				break;

			case UserRoutes.INVOICES_USER || UserRoutes.INVOICES_ADMIN:
				url = `${
					this._userService.isUser
						? UserRoutes.INVOICES_USER
						: UserRoutes.INVOICES_ADMIN
				}/invoice-detail/${id}`;
				break;

			case UserRoutes.APPLICATION_CATEGORY:
				url = this._applicationCategoryActionPermission
					? `${UserRoutes.APPLICATION_CATEGORY}/edit/${id}`
					: '';
				break;
		}

		return url;
	}

	private _updateStateFilters(filters: any, action: any) {
		this._store.dispatch(action({ ...filters }));
	}

	private _hasSearchFilterChanged(lastFilters: Params | undefined): boolean {
		return (
			JSON.stringify(this._currentFilters) !== JSON.stringify(lastFilters ?? '')
		);
	}
}
