<div *ngIf="!maintenance.inMaintenance" class="banners-container">
	<impersonate-alert-bar
		*ngIf="_userService.user?.impersonatingBy !== null"
	></impersonate-alert-bar>
	<maintenance-banner
		*ngIf="maintenance.maintenanceBanner$ | async as data"
		[data]="data"
	></maintenance-banner>
</div>

<router-outlet></router-outlet>

<ng-container *ngIf="!maintenance.inMaintenance">
	<quest-modal *ngIf="_dataModalService.modalData$ | async"></quest-modal>

	<div class="loading-container" [hidden]="!(_spinnerService.spinner | async)">
		<img src="../assets/images/gif/01.gif" class="img-fixed" />
	</div>

	<div
		class="notif-container"
		*ngIf="
			(_notifier.notifications$ | async) &&
			(_notifier.notifications$ | async)!.length! > 0
		"
	>
		<div sp-notification class="notif-layout-container"></div>
	</div>

	<app-chat-bot *ngIf="isChatBotOpen"></app-chat-bot>

	<mobile-launch-modal
		*ngIf="
			!(newFeatureInfoService.campaign$ | async) &&
			(((smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ | async) &&
				mobileLaunchService.typeOfDevice !== 'All') ||
				(mobileLaunchService.isShowMobileDownloadScreen$ | async))
		"
		(onCloseModal)="closeSmsQuestMobileUpdate()"
	>
	</mobile-launch-modal>

	<new-feature-info-modal
		*ngIf="newFeatureInfoService.campaign$ | async"
	></new-feature-info-modal>

	<app-quest-beta
		*ngIf="showQuestBeta"
		[features]="qbFeatures"
		[interests]="qbInterests"
		(onCloseModal)="qbClosed()"
	></app-quest-beta>
</ng-container>

<app-optimize-quest-modal
	*ngIf="
		(optimizeQuestModalService.isShowOptimizeQuestModal$ | async) &&
		!(
			((smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ | async) &&
				mobileLaunchService.typeOfDevice !== 'All') ||
			(mobileLaunchService.isShowMobileDownloadScreen$ | async)
		)
	"
>
</app-optimize-quest-modal>
