/* Angular Libraries */
import {
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnInit,
	ViewChild,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

/* Third Party Libraries */
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/* Interfaces */
import { ChildCompanies } from '@app/shared/interfaces/invoice.interface';

/* Environment */
import { environment } from 'environments/environment';

@Component({
	selector: 'app-parent-child-input-selector',
	templateUrl: './parent-child-input-selector.component.html',
	styleUrls: ['./parent-child-input-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ParentChildInputSelectorComponent),
			multi: true,
		},
		{
			provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
			useValue: { overlayPanelClass: 'parent-child-input-overlay' },
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentChildInputSelectorComponent
	implements ControlValueAccessor, OnInit, OnChanges
{
	@ViewChild('companyFormRef') companyFormRef: ElementRef;
	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
	@Input() url: string = '';
	@Input() placeholder: string = 'Select Companies';
	@Input() parentPlaceholder: string = 'View as Company';
	@Input() uniqueFormId: string = 'default';
	@Output() onChangeSelector: EventEmitter<InputSelectType> =
		new EventEmitter<InputSelectType>();

	filteredOptions: Observable<ChildCompanies[]>;
	companyList: ChildCompanies[] = [];
	selectCompanyForm: FormControl<CompanySelected | null> =
		new FormControl<CompanySelected | null>(null);
	height: string = '200px';
	companyForm: FormGroup = new FormGroup({
		isParentSelector: new FormControl(true),
		companySelected: new FormControl(null),
	});
	isDisable = false;
	labelFor = 'parent-selector';

	/* Constructor */
	constructor(private _http: HttpClient) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['url'].currentValue !== changes['url'].previousValue) {
			this._initCompanyList();
		}
	}

	/* Methods */
	ngOnInit(): void {
		this._initFilteredOptions();
	}

	/* [Start]::Control value accessor implementation */
	onChange: any = () => {};
	onTouched: any = () => {};

	writeValue(value: InputSelectType): void {
		value && this.companyForm.patchValue(value, { emitEvent: false });
	}

	propagateChange = (_: InputSelectType) => {};

	registerOnChange(fn: any): void {
		this.companyForm.valueChanges.subscribe(fn);
		// this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {}
	/* [End]::Control value accessor implementation */

	onSelectType() {
		if (this.isDisable) return;
		if (this.companyForm.controls['isParentSelector'].value) {
			this.clickParentCompanyBtn();
			this.onChangeSelectorUpdate();
		}
	}

	getVirtualScrollHeight(el: HTMLElement): string {
		const height = el.querySelector(
			'.cdk-virtual-scroll-content-wrapper'
		)?.clientHeight;
		return `${height ? height + 2 : 0}px`;
	}

	/* Input Select Dropdown */
	displayFn(data: any): string {
		console.log(data);
		return data && data.name ? data.name : '';
	}

	onSelectionChange() {
		this.companyForm.controls['companySelected'].setValue(
			this.selectCompanyForm.value
		);

		if (this.companyForm.controls['companySelected'].value) {
			this.companyForm.controls['isParentSelector'].setValue(false);
			this.onChangeSelectorUpdate();
		}
	}

	onBlur() {}

	clickParentCompanyBtn() {
		if (this.isDisable) return;
		this.companyForm.controls['companySelected'].setValue(null);
		this.selectCompanyForm.setValue(null);
	}

	onChangeSelectorUpdate() {
		this.onChangeSelector.emit(this.companyForm.value);
	}

	clickChildCompanyBtn() {
		if (this.isDisable) return;
		const doc = document as any;
		this.selectCompanyForm.setValue(null);
		this.companyFormRef.nativeElement.focus();
		doc.querySelector('.parent-child-input-overlay').style.width = `${
			doc.querySelector(`#${this.uniqueFormId}.company-form`)?.clientWidth + 0.5
		}px`;
	}

	setDisabledState(isDisabled: boolean) {
		this.isDisable = isDisabled;
		if (isDisabled) this.labelFor = 'none';
	}

	private _initFilteredOptions() {
		this.filteredOptions = this.selectCompanyForm.valueChanges.pipe(
			startWith(''),
			map((value) => {
				console.log(value);
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.companyList.slice();
			})
		);
	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();
		return this.companyList.filter((option: any) =>
			option.name.toLowerCase().includes(filterValue)
		);
	}

	private _initCompanyList() {
		this._getcompanySelected().subscribe({
			next: (result) => {
				this.companyList = result;
			},
		});
	}

	private _getcompanySelected(): Observable<ChildCompanies[]> {
		return this._http.get<ChildCompanies[]>(
			`${environment.apiBaseUrl}${this.url}`
		);
	}
}

export interface InputSelectType {
	isParentSelector?: boolean;
	companySelected?: CompanySelected | null;
}

export interface CompanySelected {
	id: number;
	name: string;
	addressLine1: string;
}
