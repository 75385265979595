<div
  *ngFor="let notification of notifier.notifications$ | async"
  class="toast-container-v2 show {{mapNotifierType(notification.panelClass)}}"
  [class.closed]="!notification.isShown">

  <div class="toast-title">
    <mat-icon [svgIcon]="mapNotifierIcon(notification.panelClass)"></mat-icon>
    <span class="font-b3 font-w-700">{{notification.message}}</span>
    <mat-icon svgIcon="x-close" class="toast-close" (click)="closeNotif(notification)"></mat-icon>
  </div>

  <div *ngIf="notification.description" class="toast-message font-b3">{{notification.description}}</div>

  <button id="mapper_notifRefresh" *ngIf="notification.hasRefresh" class="btn-2 btn-2-secondary refresh" (click)="refresh(notification)">Refresh</button>
</div>

