<div class="card-header-2 q-ai-center">
	<h3 class="font-h-normal q-mr-auto">Roles & Permissions</h3>

	<app-parent-child-input-selector
		[formControl]="company"
		*ngIf="isShowCompanySelector"
		url="companies/GetDropdown"
		(onChangeSelector)="setCompanyId($event)"
	></app-parent-child-input-selector>
	<!-- [url]="(_rAndP.url$|async)!" -->

	<a
		class="btn-2 btn-2-primary imp-disabled-op"
		[routerLink]="addUrl"
		id="mapper_createNewRole"
	>
		<mat-icon svgIcon="users-plus"></mat-icon>
		Create New Role
	</a>
	<p class="heading-2 font-b1 q-w-100">
		Roles serve as a framework to group permissions based on activity.
	</p>
</div>
