import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';

import { CheckboxInputModule } from '../checkbox-input/checkbox-input.module';
import { TooltipContentV2Module } from '@app/shared/directives/tooltip-content-v2/tooltip-content-v2.module';
import { QuestIconModule } from '../../quest-icon/quest-icon.module';

import { SmoothScrollDirective } from '@app/shared/navigation/dashboard-layout/smooth-scroll.directive';

import { MultiSelectInput2Component } from './multi-select-input.component';

@NgModule({
	declarations: [MultiSelectInput2Component],
	imports: [
		CommonModule,
		MatSelectModule,
		ScrollingModule,
		CheckboxInputModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatChipsModule,
		OverlayModule,
		QuestIconModule,
		SmoothScrollDirective,
		TooltipContentV2Module,
	],
	exports: [MultiSelectInput2Component],
})
export class MultiSelectInput2Module {}
