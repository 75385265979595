<div class="row mt-0">
	<div class="col-12 col-lg-3 col-md-4 mb-4 mb-md-0">
		<div class="card">
			<div class="card-header pb-0 p-3">
				<h6 class="">{{ selectedCategoryHeader }}</h6>
			</div>
			<div class="profile-wraps">
				<ul class="list-unstyled m-0 text-sm">
					<li *ngFor="let item of topicList" [ngStyle]="{ color: item?.color }">
						<a

							(click)="getTopicById(item.id, 'click-by-topic')"
							class="mapper_item q-pointer"
							href="javascript:void(0)"
							[ngClass]="{ active: getActiveTopic(item, topicId) }"
						>
							{{ item.title }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="col-12 col-lg-9 col-md-8 mb-4 mb-md-0">
		<div class="card">
			<div class="card-header-2 q-p-24 q-pb-0">
				<h3 class="q-mr-auto">Knowledge Base</h3>
				<app-tertiary-button
					[btnText]="btnText"
					(click)="quickAccessTopic()"
					[iconName]="switch ? 'check' : 'plus'"
					[isProcessing]="isProcessing"
				>
				</app-tertiary-button>

				<app-secondary-button
					[isBackButton]="true"
					(click)="goBack()"
					[removeMsAuto]="true"
				></app-secondary-button>
			</div>

			<ng-container *ngIf="topics">
				<div class="card-body px-0 pb-0 pt-0">
					<div class="row">
						<div class="col-md-12">
							<div class="trend-conts">
								<h4 class="text-sm">{{ topics['title'] }}</h4>
								<div
									class="description"
									[innerHTML]="htmlWithCss | safeHtml"
								></div>

								<div *ngIf="topics['relatedTopics'].length > 0">
									<h2 class="text-xs">Related Topics</h2>
									<div class="relate-lists">
										<ul class="list-unstyled">
											<li
												*ngFor="let item of topics['relatedTopics']"
												class="d-flex align-items-center line-height-0"
											>
												<app-local-figma-icon-img
													[height]="15"
													[width]="15"
													[filename]="'arrow-block-right'"
												></app-local-figma-icon-img>
												{{ item['relatedKBTopicTitle'] }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12">
							<div
								*ngIf="topics['relatedLinks'].length > 0"
								class="trend-conts"
							>
								<h2 class="text-xs">Related Links</h2>
								<div class="relate-lists">
									<ul class="list-unstyled">
										<ng-container *ngFor="let item of topics['relatedLinks']">
											<li
												*ngIf="item.link && item.link !== 'null'"
												class="d-flex align-items-center line-height-0"
											>
												<app-local-figma-icon-img
													[height]="15"
													[width]="15"
													[filename]="'arrow-block-right'"
												></app-local-figma-icon-img>
												{{ item.link }}
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="topics['supportRequest'].length > 0" class="trend-conts">
						<h2 class="text-xs">Suggested Support Tickets</h2>
						<div class="relate-lists">
							<ul class="list-unstyled">
								<ng-container *ngFor="let item of topics['supportRequest']">
									<li
										class="d-flex align-items-center line-height-0 c-pointer"
										*ngIf="item.supportRequestTitle"
										routerLink="/service-and-support/new-ticket/{{
											$any(item).supportRequestId
										}}/create"
									>
										<app-local-figma-icon-img
											[height]="15"
											[width]="15"
											[filename]="'arrow-block-right'"
										></app-local-figma-icon-img>
										{{ item.supportRequestTitle }}
									</li>
								</ng-container>
							</ul>
						</div>
					</div>

					<div class="q-p-24 q-d-flex q-ai-center q-g-24">
						<h5 class="text-xs">Need further assistance?</h5>

						<a
							[id]="'mapper_searchTopicSubmit'"
							[routerLink]="[newTicketUrl]"
							class="btn-2 btn-2-primary"
						>
							<app-figma-icon-img
								[iconName]="'file-plus-02'"
								[isForButton]="true"
								[hasSpaceOnRight]="false"
							></app-figma-icon-img>
							Submit a Support Ticket
						</a>

						<a id="mapper_openChat" (click)="openChat()" class="btn-2 btn-2-secondary">
							<app-local-figma-icon-img
								[height]="20"
								[width]="20"
								[hasSpaceOnRight]="false"
								[filename]="'message-chat-circle'"
							></app-local-figma-icon-img>
							Live Chat
						</a>
					</div>

					<div *ngIf="!isAdmin" class="hep-conts text-center">
						<ul class="list-unstyled text-sm d-inline-flex d-flex m-0">
							<li>Was this article helpful?</li>

							<li class="imp-parent">
								<a
									class="mapper_like imp-disabled-op"
									href="javascript:void(0)"
									(click)="likeDislikeTopic(!topics['isLiked'], false, 'like')"
								>
									<app-figma-icon-img
										[ngClass]="[
											isLikeStyle(topics['isLiked'], topics['isDisliked'])
										]"
										[iconName]="'thumbs-up'"
										[hasSpaceOnRight]="false"
									></app-figma-icon-img>
								</a>
							</li>

							<li class="imp-parent">
								<a
									class="mapper_dislike imp-disabled-op"
									href="javascript:void(0)"
									(click)="
										likeDislikeTopic(false, !topics['isDisliked'], 'dislike')
									"
								>
									<app-figma-icon-img
										[ngClass]="[
											isDislikeStyle(topics['isLiked'], topics['isDisliked'])
										]"
										[iconName]="'thumbs-down'"
										[hasSpaceOnRight]="false"
									></app-figma-icon-img>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<ng-container>
	<div *ngIf="isChatOpen === true" class="chat-bot">
		<div class="bot-container">
			<div class="bot-header">
				<img
					image-error-detection
					[component]="'search-by-topic.component.html'"
					class="bot-img"
					[src]="botData.image"
					alt="chat-bot"
				/>
				<div class="bot-info">
					<h6>{{ botData.name }}</h6>
					<small class="status">Online</small>
				</div>
				<app-filter-chevron
					[isCollapsed]="isChatMinimized"
					(click)="isChatMinimized = !isChatMinimized"
				></app-filter-chevron>
			</div>

			<div *ngIf="isChatMinimized === false" class="help-box">
				<h6>Need more help?</h6>
				<a
					id="mapper_submitTicket"
					[routerLink]="['/support/ticket']"
					class="can-btn ml-4"
				>
					Submit a support ticket
				</a>
			</div>

			<div *ngIf="isChatMinimized === false" class="chat-message">
				<iframe
					[src]="iframeUrl | safeUrl"
					style="width: 100%; min-height: 500px"
					frameborder="0"
				></iframe>
			</div>
		</div>
	</div>
</ng-container>
