<div
	class="feedback-area scroll-v2"
	[ngClass]="{ 'feedback-active': openFeedbackList }"
	[class.impersonating]="impersonating"
	cdkScrollable
>
	<ng-container *ngIf="openFeedbackList">
		<div class="form-type">
			<button
      id="mapper_feedbackClose"
				type="button"
				class="btn-close text-lg py-3 message-close opacity-10 d-flex align-items-center"
				aria-label="Close"
				(click)="closeForm()"
			>
				<app-figma-icon-img
					[iconName]="'x'"
					[isForButton]="true"
					[hasSpaceOnRight]="false"
				></app-figma-icon-img>
			</button>
			<form *ngIf="formData && form" [formGroup]="form" (ngSubmit)="submit()">
				<div class="col-md-12">
					<label class="text-xs">First Name</label>
					<input
						type="text"
						formControlName="firstName"
						class="form-control text-xs"
					/>
				</div>
				<div class="col-md-12">
					<label class="text-xs">Last Name</label>
					<input
						type="text"
						formControlName="lastName"
						class="form-control text-xs"
					/>
				</div>
				<div class="col-md-12">
					<label class="text-xs">Company</label>
					<input
						type="text"
						formControlName="company"
						class="form-control text-xs"
					/>
				</div>
				<div class="form-group">
					<sr-form
						[form]="form"
						[questions]="formData.questions"
						#srForm
					></sr-form>

					<div class="feedback-btn q-g-16">
						<button    id="mapper_feedbackCancel" (click)="closeForm()" class="btn-2 btn-2-link">Cancel</button>
						<button    id="mapper_feedbackSubmitr" type="submit" class="btn-2 btn-2-primary">
							Submit Feedback
						</button>
					</div>
				</div>
			</form>
		</div>
	</ng-container>
</div>
