import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

import { QuestProfileService } from './quest-profile.service';

@Component({
	selector: 'quest-profile',
	templateUrl: './quest-profile.component.html',
	styleUrls: ['./quest-profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestProfileComponent implements OnChanges {
	@Input() isQuestIcon = false;
	@Input() path?: string;
	/**
	 * Use comma separated name for more accurate result
	 * @example "Lastname, Firstname"
	 */
	@Input() fullName: string;
	@Input() size: 'small' | 'medium' | 'large' = 'medium';

	initial: string;
	color: number;

	defaults = inject(QuestProfileService);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['fullName']) {
			const defaultVal = this.defaults.getDefaultByFullName(this.fullName);
			this.initial = defaultVal.initial;
			this.color = defaultVal.color;
		}
	}
}
