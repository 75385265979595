<div class="main-contents spdmin-main support-main" *ngIf="messages?.length">
	<div class="top-info banner-shades" id="target">
		<p *ngFor="let i of getTopMessage()" class="text-sm">
			<span>
				({{ i.publishDateTime | date : 'M/d/yyyy' }})
				<span
					(click)="viewFullMessage(i)"
					class="msg-title"
					title="View full message."
				>
					{{ i.notificationTitle }}
					<ng-container *ngIf="i.websiteLink">: </ng-container>
				</span>

				<ng-container *ngIf="i.websiteLink">
					<a id="mapper_webLink" [href]="i._websiteLinkModified" target="_blank" rel="no-referrer">
						{{ i.websiteLink }}
					</a>
				</ng-container>
			</span>

			<a
        id="mapper_isManualCancel"
				*ngIf="i.isAllowManualCancellation"
				(click)="cancel(i)"
				href="javascipt:void(0)"
			>
				<app-figma-icon-img
					[iconName]="'x-close'"
					[height]="15"
					[width]="15"
					[hasSpaceOnRight]="false"
				></app-figma-icon-img>
			</a>
		</p>

		<ng-container *ngIf="getMoreMessage() as more">
			<ng-container *ngIf="show_more">
				<p *ngFor="let i of more">
					({{ i.publishDateTime | date : 'M/d/yyyy' }})
					{{ i.notificationTitle }}
					<a
            class="mapper_close"
						*ngIf="i.isAllowManualCancellation"
						(click)="cancel(i)"
						href="javascipt:void(0)"
					>
						<app-figma-icon-img
							[iconName]="'x-close'"
							[height]="15"
							[width]="15"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</a>
				</p>
			</ng-container>

			<ng-container *ngIf="more?.length">
				<a
          id="mapper_showMore"
					class="d-flex align-items-center ms-2"
					(click)="show_more = !show_more"
					href="javascipt:void(0)"
				>
					{{ !show_more ? 'Show more' : 'Show less' }}
					<app-filter-chevron
						[isCollapsed]="!show_more"
						[hasSpaceOnLeft]="true"
					></app-filter-chevron>
				</a>
			</ng-container>
		</ng-container>
	</div>
</div>
