<modal-v2>
	<ng-template #modalV2Header>
		<div class="mobile-launch_group q-d-flex q-ai-center">
			<div class="mobile-launch_group_icon q-d-flex q-jc-center q-ai-center">
				<app-local-figma-icon-img
					[filename]="'info-circle-blue'"
					[width]="24"
					[height]="24"
				></app-local-figma-icon-img>
			</div>

			<div class="mobile-launch_group_title font-h3">
				Introducing the Quest<span class="symbol symbol-tm"></span> Mobile App
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Body>
		<!-- For IOS download screen and sign-up form for android. -->
		<ng-container
			*ngIf="!(mobileLaunchService.isShowMobileDownloadScreen$ | async)"
		>
			<form
				[formGroup]="modalForm"
				class="mobile-launch_body q-d-flex q-ai-start q-g-16"
			>
				<div
					class="mobile-launch_body-left q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
				>
					<div
						class="mobile-launch_badge q-d-flex q-p-10 q-f-column q-ai-center"
					>
						<app-local-figma-icon-img
							[filename]="'app-store-logo'"
							[width]="121"
							[height]="40"
						>
						</app-local-figma-icon-img>
					</div>

					<div class="mobile-launch_detail font-b1">
						The Quest<span class="symbol symbol-tm"></span> by Sourcepass mobile
						app is live on iOS! Scan the QR code to download the Quest<span
							class="symbol symbol-tm"
						></span>
						mobile app directly to your phone.
					</div>

					<div
						class="mobile-launch_qr q-d-flex q-f-column q-jc-start q-ai-center q-g-16 q-as-stretch"
					>
						<app-local-figma-icon-img
							[filename]="'Quest-AppStore'"
							[width]="130"
							[height]="130"
						>
						</app-local-figma-icon-img>
					</div>
				</div>

				<div class="mobile-launch_vertical-line"></div>

				<div
					class="mobile-launch_body-right q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
				>
					<img
						src="./assets/images/icons/google-play-logo.png"
						width="157"
						height="60"
						alt="qr-code"
					/>

					<div class="mobile-launch_detail font-b1">
						Stay up to date on the release of the Quest<span
							class="symbol symbol-tm"
						></span>
						Android app by submitting your phone number.
					</div>

					<div
						class="mobile-launch_form-group q-d-flex q-f-column q-ai-start q-g-8 q-as-stretch"
					>
						<div class="mobile-launch_form-label font-b1">Phone Number</div>
						<phone-input formControlName="phoneNumber"></phone-input>
					</div>

					<div class="mobile-launch_note font-b3">
						<b>Please Note:</b> By signing up, you agree to receiving recurring
						messages. Standard message and data rates apply. Reply STOP to
						unsubscribe at anytime.
					</div>
				</div>
			</form>
		</ng-container>

		<ng-container
			*ngIf="mobileLaunchService.isShowMobileDownloadScreen$ | async"
		>
			<!-- It will show for an ios download app -->
			<div
				*ngIf="mobileLaunchService.typeOfDevice === deviceType.ios"
				class="mobile-launch_parent-body q-d-flex q-f-column q-jc-end q-ai-end q-as-stretch"
			>
				<div class="mobile-launch_body q-d-flex q-ai-start q-g-16">
					<div
						class="mobile-launch_body-left q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
					>
						<div
							class="mobile-launch_qr q-d-flex q-f-column q-jc-center q-ai-center q-g-16 q-as-stretch"
						>
							<app-local-figma-icon-img
								[filename]="'Quest-AppStore'"
								[width]="130"
								[height]="130"
							>
							</app-local-figma-icon-img>
						</div>

						<div class="mobile-launch_detail font-b1">
							The Quest<span class="symbol symbol-tm"></span> by Sourcepass
							mobile app is live on the Apple App Store! Scan the QR code to
							download the Quest<span class="symbol symbol-tm"></span> mobile
							app directly to your phone.
						</div>

						<div
							class="mobile-launch_badge q-d-flex q-p-10 q-f-column q-ai-center"
						>
							<app-local-figma-icon-img
								[filename]="'app-store-logo'"
								[width]="121"
								[height]="40"
							>
							</app-local-figma-icon-img>
						</div>
					</div>

					<div class="mobile-launch_vertical-line"></div>

					<div
						class="mobile-launch_body-right q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
					>
						<img
							class="mobile-launch_mobile-screen"
							src="./assets/images/icons/mobile-screen.png"
							width="210"
							height="368"
							alt="mobile-screen"
						/>
					</div>
				</div>

				<div class="mobile-launch_note font-b3">
					<b>Please Note:</b> For our Android users, you will be able to find
					the app on the Google Play Store soon. Stay tuned for updates!
				</div>
			</div>

			<!-- It will show both ios and android download app  -->
			<div
				*ngIf="mobileLaunchService.typeOfDevice === deviceType.all"
				class="mobile-launch_parent-body q-d-flex q-f-column q-jc-end q-ai-end q-as-stretch"
			>
				<div class="mobile-launch_body q-d-flex q-ai-start q-g-16">
					<div
						class="mobile-launch_body-left q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
					>
						<div
							class="mobile-launch_qr q-d-flex q-f-column q-jc-center q-ai-center q-g-16 q-as-stretch"
						>
							<canvas id="qrcode-display"></canvas>
						</div>

						<div class="mobile-launch_detail font-b1">
							The Quest<sup>TM</sup> by Sourcepass mobile app is now live on the
							App Store and Google Play Store! Scan the QR code to download the
							Quest<sup>TM</sup> mobile app directly to your phone.
						</div>

						<div class="mobile-launch_badge q-d-flex q-f-column q-ai-center">
							<div
								class="mobile-launch_badge-group q-d-flex q-f-column q-jc-center q-ai-center q-g-8 q-as-stretch"
							>
								<app-local-figma-icon-img
									[filename]="'app-store-logo'"
									[width]="121"
									[height]="40"
									[hasSpaceOnRight]="false"
								>
								</app-local-figma-icon-img>

								<img
									src="./assets/images/icons/google-play-logo.png"
									width="157"
									height="60"
									alt="qr-code"
								/>
							</div>
						</div>
					</div>

					<div
						class="mobile-launch_body-right q-d-flex q-f-column q-ai-center q-g-16 q-as-stretch"
					>
						<img
							class="mobile-launch_mobile-screen"
							src="./assets/images/icons/mobile-screen.png"
							alt="mobile-screen"
						/>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-template>

	<ng-template #modalV2Footer>
		<app-tertiary-button
			[btnText]="'Skip'"
			id="mobileLaunchSkip"
			(click)="onClickSkip()"
		>
		</app-tertiary-button>

		<button
			id="mapper_mobileLaunchSignUp"
			*ngIf="!(mobileLaunchService.isShowMobileDownloadScreen$ | async)"
			type="button"
			class="btn-2 btn-2-primary"
			(click)="signUp()"
		>
			Sign Up
		</button>
	</ng-template>
</modal-v2>
