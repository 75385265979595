import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { Subscription, take } from 'rxjs';

import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';

import { ApplicationsService } from '../../applications.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { SpinnerService } from '@services/spinner.service';
import { UserService } from '@app/core/services/user.service';

import { Permission, HistoryUrl } from '@app/shared/constants';

import { User } from '@app/shared/interfaces/user.interface';

@Component({
	selector: 'app-category-add-edit',
	templateUrl: './category-add-edit.component.html',
	styleUrls: ['./category-add-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryAddEditComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	searchSubscription: Subscription;
	subscriptions: Subscription;

	stateAction: string = '';
	user: User = this._userService.user!;

	isAuditTrailEnabled: boolean;

	form = new FormGroup({
		id: new FormControl('-1'),
		name: new FormControl('', [
			Validators.required,
			Validators.pattern(/^[a-zA-Z0-9 ]*$/),
		]),
		iconName: new FormControl('', Validators.required),
		userId: new FormControl(0),
		companyId: new FormControl(0),
		color: new FormControl('#08354f'),
	});

	id: number;
	isUserRole = false;
	companyId: string | null;
	historyUrl = HistoryUrl;

	constructor(
		public spinner: SpinnerService,
		public override _companiesService: CompaniesService,
		public override _route: ActivatedRoute,
		private _fb: FormBuilder,
		private _cd: ChangeDetectorRef,
		private _location: Location,
		private _router: Router,
		private _applicationsService: ApplicationsService,
		private _breadcrumbService: BreadcrumbService,
		private _userService: UserService
	) {
		super(_companiesService, _route);

		this.id = Number(this._route.snapshot.paramMap.get('id'));
		this.companyId = this._route.snapshot.paramMap.get('companyId');
	}

	ngOnInit() {
		this.getCompanyIdFromParams();
		this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
			this._companiesService.subCompanyId!
		);
		this.isAuditTrailEnabled =
			this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailApplication
			) || this._userService.isSpAdmin;
	}

	ngAfterViewInit() {
		if (this.id) {
			//Check if category to be edited has been sent using router state
			this.spinner.start();
			this.stateAction = 'edit';
			this._applicationsService
				.getCategoryDetails(this.id)
				.subscribe((res: any) => {
					const data = res;
					this._breadcrumbService.updateBreadCrumbsText(
						'_applicationCategoryName',
						data.name
					);
					this._setData(data);
					this._cd.detectChanges();
					this.spinner.stop();
				});
		} else {
			this.stateAction = 'add';
			this.form.patchValue({
				userId: this._userService.user!.id,
			});
		}

		this.isUserRole = this._userService.isUser;

		this._cd.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}

	onBack() {
		this._location.back();
	}

	onSubmit() {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}

		if (this.form.get('name')!.value?.trim() === '') {
			this.form.get('name')?.setErrors({ required: true });
			return;
		}

		this.spinner.start();
		this.form
			.get('color')
			?.setValue(this.form.get('color')?.value?.substring(0, 7)!);

		const payload: any = this.form.getRawValue();
		payload.ownedByUser = this.isUserRole;
		payload.companyId = this.companyId ? +this.companyId : payload.companyId;

		const options = {
			userRole: this._userService.userRole,
			companyId: this.form.get('companyId')!.value,
		} as any;

		if (this.stateAction === 'add') {
			this._applicationsService
				.addCategory(payload)
				.pipe(take(1))
				.subscribe({
					next: () => {
						this._applicationsService.allowToFetchNewData = true;
						this._applicationsService.clearApplicationCategory(options);
						this.spinner.stop();
						this._router.navigate(['../'], { relativeTo: this._route });
					},
					error: (error) => {
						this._showError(error);
					},
				});
		} else {
			this._applicationsService
				.updateCategory(payload)
				.pipe(take(1))
				.subscribe({
					next: () => {
						this._applicationsService.allowToFetchNewData = true;
						this._applicationsService.clearApplicationCategory(options);
						this.spinner.stop();
						this._router.navigate(['../../'], { relativeTo: this._route });
					},
					error: (error) => {
						this._showError(error);
					},
				});
		}
	}

	onBlur() {
		this.form.controls['iconName'].markAsTouched();
	}

	private _showError(error: any) {
		if (error?.errors) {
			for (const err of error.errors) {
				if (err.fieldName.toLowerCase() === 'name') {
					this.form.get('name')?.setErrors({ duplicateName: true });

					this._cd.markForCheck();
				}
			}
		}

		this.spinner.stop();
	}

	private _setData(data: any) {
		this.form = this._fb.group({
			id: [data.id],
			name: [
				data.name,
				[Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/)],
			],
			iconName: [data.iconName, Validators.required],
			userId: [this._userService.user!.id],
			companyId: [this.user.roleId == 2 ? this.user.companyId : data.companyId],
			color: [data.color],
		});
		this._cd.detectChanges();
	}
}
