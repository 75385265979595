import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class QuestProfileService {
	defaults: { [key: string]: number } = {};

	getDefaultByFullName(fullName: string) {
		const initial = this._getInital(fullName.trim()),
			color = !initial
				? 7
				: this.defaults[initial] ?? this._setDefaultColor(initial);
		return { initial, color };
	}

	private _getInital(fullName: string) {
		if (!fullName) return '';

		const nameArr = fullName.includes(',')
			? fullName.split(', ').reverse()
			: fullName.split(' ');

		return nameArr.length > 1
			? nameArr[0].replace(/[^a-zA-Z0-9\s]/g, '').charAt(0) +
					nameArr[nameArr.length - 1].replace(/[^a-zA-Z0-9\s]/g, '').charAt(0)
			: nameArr[0].substring(0, 2).trim().toUpperCase();
	}

	private _setDefaultColor(initial: string) {
		this.defaults[initial] = Math.floor(Math.random() * 7);
		return this.defaults[initial];
	}
}
