<form
	[id]="uniqueFormId"
	class="company-form position-relative"
	[formGroup]="companyForm"
>
	<div class="parent-child-selector_container q-d-flex q-ai-center">
		<!-- [Start]::Parent Button -->
		<label
			[for]="labelFor + '-' + uniqueFormId"
			class="parent-child-selector_parent-btn q-d-flex q-ai-center q-g-16 q-pointer"
			[class.parent-child-selector_active]="
				companyForm.controls['isParentSelector'].value
			"
			(click)="clickParentCompanyBtn()"
			id="mapper_gs-view-as-company"
		>
			<div class="parent-child-selector_icon q-d-flex q-jc-center q-ai-center">
				<app-figma-icon-img
					[iconName]="'eye'"
					[hasSpaceOnRight]="false"
					[isForButton]="false"
					[width]="20"
					[height]="20"
				>
				</app-figma-icon-img>
			</div>

			<div class="parent-child-selector_parent-text font-btn q-center">
				{{ parentPlaceholder }}
			</div>
		</label>

		<input
			type="radio"
			[id]="labelFor + '-' + uniqueFormId"
			name="isParentSelector"
			formControlName="isParentSelector"
			[value]="true"
			(change)="onSelectType()"
			hidden
		/>
		<!-- [End]::Parent Button -->

		<!-- [Start]::Child Button -->
		<label
			[for]="'child-selector-' + uniqueFormId"
			class="parent-child-selector_child-btn q-d-flex q-ai-center q-as-stretch q-pointer"
			[class.parent-child-selector_active]="
				!companyForm.controls['isParentSelector'].value &&
				companyForm.controls['companySelected'].value
			"
			(click)="clickChildCompanyBtn()"
			id="mapper_gs-search-companies"
		>
			<div class="parent-child-selector_child-text font-btn q-center">
				{{
					companyForm.controls['companySelected'].value?.name
						? companyForm.controls['companySelected'].value?.name
						: placeholder
				}}
			</div>
		</label>

		<input
			type="radio"
			[id]="'child-selector-' + uniqueFormId"
			name="isParentSelector"
			formControlName="isParentSelector"
			[value]="false"
			(change)="onSelectType()"
			[attr.disabled]="true"
			hidden
		/>
		<!-- [End]::Child Button -->
	</div>

	<!-- [Start]::Company dropdown -->
	<input
		id="company-dropdown-form"
		#companyFormRef
		type="text"
		matInput
		[formControl]="selectCompanyForm"
		class="child-selector-form font-b3"
		[matAutocomplete]="auto"
		[placeholder]="
			companyForm.controls['companySelected'].value
				? companyForm.controls['companySelected'].value.name
				: placeholder
		"
		(blur)="onBlur()"
	/>

	<mat-autocomplete
		#auto="matAutocomplete"
		[displayWith]="displayFn"
		(optionSelected)="onSelectionChange()"
	>
		<cdk-virtual-scroll-viewport
			#virtualScroll
			style="max-height: 200px"
			[ngStyle]="{
				height: getVirtualScrollHeight(virtualScroll.elementRef.nativeElement)
			}"
			itemSize="25"
		>
			<mat-option
				*cdkVirtualFor="
					let option of filteredOptions | async;
					templateCacheSize: 1
				"
				[value]="option"
			>
				{{ option.name }}
			</mat-option>

			<!-- Custom "No results" message -->
			<mat-option *ngIf="(filteredOptions | async)?.length === 0" disabled>
				No results found
			</mat-option>
		</cdk-virtual-scroll-viewport>
	</mat-autocomplete>
	<!-- [End]::Company dropdown -->
</form>
