import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { TableMessages } from '@app/shared/constants/global-class';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { ApplicationType, HistoryUrl } from '@app/shared/constants/global-enum';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { of, Subject, Subscription, takeUntil } from 'rxjs';
import { IActivePaymentDetails } from '../payment-arrangements/payment-arrangements.interface';
import { PaymentArrangementsService } from '../payment-arrangements/payment-arrangements.service';

@Component({
	selector: 'view-arrangement-details',
	templateUrl: './view-arrangement-details.component.html',
	styleUrls: ['./view-arrangement-details.component.scss'],
})
export class ViewArrangementDetailsComponent implements OnInit, OnDestroy {
	paymentDetails: IActivePaymentDetails;
	dataSource: MatTableDataSource<any> = new MatTableDataSource();
	selectedSortColumn: string = 'invoiceDate';
	selectedSortType: string = 'desc';
	displayedColumns = [
		'invoiceNumber',
		'invoiceDate',
		'dueDate',
		'billAmount',
		'currentBalance',
		'status',
	];
	message = { noRecord: TableMessages.EmptyTable };
	id: number = 0;
	tableSub: Subscription;
	applicationType = ApplicationType;
	historyUrl = HistoryUrl;

	private _route = inject(ActivatedRoute);
	private _notifier = inject(NotificationService);
	private _paymentArrangementService = inject(PaymentArrangementsService);
	private _toastMessageService = inject(ToastMessageService);
	private _spinner = inject(SpinnerService);
	private _router = inject(Router);
	private _userService = inject(UserService);
	private _$unsubscribe: Subject<void> = new Subject<void>();

	get isLoading() {
		return this.tableSub && !this.tableSub.closed;
	}

	get invoicePath() {
    return this._paymentArrangementService.invoicePath;
  }

  get isAdmin() {
    return this._userService.isSpAdmin;
  }

	ngOnInit(): void {
		this.id = Number(this._route.snapshot.paramMap.get('id'));
		this._initData();
	}

	private _initData() {
		if (this.tableSub) this.tableSub.unsubscribe();
		this.dataSource = new MatTableDataSource();

		this.tableSub = this._paymentArrangementService.getActivePaymentArrangementDetails(this.id)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (data) => {
				this.paymentDetails = data;
				this.dataSource = new MatTableDataSource(data.invoices);
        this._updateScroll();
			},
			error: () => {
				this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
			}
		});
	}

	sortChangeEvent(sortEvent: any) {
		this.selectedSortColumn = sortEvent.active;
		this.selectedSortType = sortEvent.direction;
		this._initData();
	}

  private _updateScroll() {
    setTimeout(() => {
      const element: any = document.querySelector('#view-arrangement-details .scroll-v2');
      element.scrollHeight > element.clientHeight ? element.classList.add('q-pr-8') : element.classList.remove('q-pr-8');
    }, 0);
  }

	export(exportType: ApplicationType) {
		if (!this.paymentDetails.invoices.length) {
			this._toastMessageService.showErrorMessage(NotificationMessages.NoRecordFound);

		} else {

			this._spinner.start();
			this._paymentArrangementService.exportInvoices(this.id, exportType)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (resp: any) => {
					const link = document.createElement('a');
					link.href = resp.uri;
					link.setAttribute('download', 'Invoice');
					document.body.appendChild(link);
					link.click();
				},
				error: () => {
					this._spinner.stop();
				},
				complete: () => {
					this._toastMessageService.showSuccessMessage(NotificationMessages.Export);
					this._spinner.stop();
				},
			});
		}
	}

	cancel() {
		this._spinner.start();
		this._paymentArrangementService.cancelPaymentArrangement(this.id)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: () => {
				this._spinner.stop();
				this._router.navigate(['/billing-orders/payment-arrangements']);
        this._notifier.notifySuccess(NotificationMessages.cancel('Payment Arrangement'));
			},
			error: () => {
				this._spinner.stop();
				this._notifier.notifyError(NotificationMessages.unable('Submit Request'), NotificationMessages.Try);
			}
		});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
