import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardFormService {
  private _onGetStripePaymentMethodId = new BehaviorSubject<{ paymentMethodId: string; accountType: number; } | null>(null);
  public onGetStripePaymentMethodId = this._onGetStripePaymentMethodId.asObservable();

  public setPaymentMethodId(paymentMethodId: string, accountType: number) {
    this._onGetStripePaymentMethodId.next({ paymentMethodId, accountType });
  }
}
