import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { ItemPerPageComponent } from '@app/shared/components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { NotificationMessages, PaginationConstants, TableMessages } from '@app/shared/constants';
import { of, Subject, Subscription, takeUntil } from 'rxjs';
import { PaymentArrangementsService } from '../payment-arrangements/payment-arrangements.service';

@Component({
  selector: 'archived-payment-arrangements',
  templateUrl: './archived-payment-arrangements.component.html',
  styleUrls: ['./archived-payment-arrangements.component.scss']
})
export class ArchivedPaymentArrangementsComponent implements OnInit, OnDestroy {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;
  queryString: FormControl = new FormControl<string>('');
  displayedColumns = [
		'companyName',
    // 'nextPayment',
    'recurringAmount',
    'frequency',
    'paymentsMade',
    'dateCompleted',
    'startingBalance'
	];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selectedSortColumn: string = 'nextPayment';
  selectedSortType: string = 'desc';
  selectedIds: number[] = [];
  message = { noRecord: TableMessages.EmptyTable };
  totalItems = 0;
  selectedPageSize: number = 10;
  selectedPage: number = 1;
  pageSizes = PaginationConstants.pageSizes;
  searchTimeout: any;
  isFilterHaveBeenCalled: boolean = false;
  tableSub: Subscription;

  private _$unsubscribe: Subject<void> = new Subject<void>();
  private _dataList: Array<any> = [];
  private _paymentArrangementsService = inject(PaymentArrangementsService);
  private _notifier = inject(NotificationService);
  private _userService = inject(UserService);

  get isAdmin() {
    return this._userService.isSpAdmin;
  }

  ngOnInit(): void {
    this._initData();
  }

  queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.isFilterHaveBeenCalled = true;
			this._initData();
		}, delayTime);
	}

  private _initData() {
    if (this.paginator !== undefined) {
			this.paginator.page = this.isFilterHaveBeenCalled
				? 1
				: this.paginator.page;
		}

    if (this.tableSub) this.tableSub.unsubscribe();
    this.dataSource = new MatTableDataSource();

    this.tableSub = this._paymentArrangementsService.getArchivedPaymentArrangements(
      this.queryString.value!.trim(),
      this.paginator?.page ? this.paginator?.page : this.selectedPage,
				this.itemsPerPage?.pageSize
					? this.itemsPerPage?.pageSize
					: this.selectedPageSize,
				this.selectedSortColumn,
				this.selectedSortType
    )
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (result) => {
        this._dataList = result.data;
        this.dataSource = new MatTableDataSource(result.data);
        this.totalItems = result.totalCount;
        this.selectedPageSize = result.pageSize ? result.pageSize : this.selectedPageSize;
				this.selectedPage = result.currentPage ? result.currentPage : this.selectedPage;
        this.isFilterHaveBeenCalled = false;
      },
      error: () => {
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
      }
    });
  }

  sortChangeEvent(sortEvent: any) {
		this.selectedSortColumn = sortEvent.active;
		this.selectedSortType = sortEvent.direction;
		this._initData();
	}

  updateSize() {
		this.paginator.size = this.itemsPerPage.pageSize;
    this.paginator.setTotalPages();
    this.paginator.setPages();

		if(this.paginator.totalItems < this.paginator.size * (this.paginator.page - 1) + 1) {
      this.paginator.changePageWithoutEmit(1);
    }

		this._initData();
	}

  changePage() {
		this._initData();
	}

  getStatus(status: number) {
    let val: any = {};

    switch(status) {
      case 1:
        val = {
          name: 'CANCELED',
          backgroundColor: '#F8D1CD',
          fontColor: '#6D1008',
        };

        break;

      case 2:
        val = {
          name: 'COMPLETED',
          backgroundColor: '#CAF4DB',
          fontColor: '#0D6630',
        };

        break;
    }

    return val;
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
