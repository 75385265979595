<div class="td-container" *ngIf="data">
	<div class="td-card">
		<h4>TICKET PROPERTIES</h4>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Ticket #:</p>
			<p class="td-value">{{ data.ticketProperties.id }}</p>
		</div>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Origin:</p>
			<p class="td-value">{{ data.ticketProperties.origin }}</p>
		</div>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Category:</p>
			<p class="td-value">
				{{
					data.ticketProperties.category
						? data.ticketProperties.category
						: 'N/A'
				}}
			</p>
		</div>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Subcategory:</p>
			<p class="td-value">
				{{
					data.ticketProperties.subCategory
						? data.ticketProperties.subCategory
						: 'N/A'
				}}
			</p>
		</div>
	</div>
	<div class="td-card">
		<h4>TIMELINE</h4>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Last Update:</p>
			<p class="td-value">
				{{
					data.timeline.lastUpdated
						| date : 'MMM d, y, h:mm a'
						| replaceCommaWithAt
				}}
			</p>
		</div>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Date Created:</p>
			<p class="td-value">
				{{
					data.timeline.dateCreated !== 'N/A'
						? (data.timeline.dateCreated
						  | date : 'MMM d, y, h:mm a'
						  | replaceCommaWithAt)
						: 'N/A'
				}}
			</p>
		</div>
		<div class="td-flex q-f-wrap">
			<p class="td-label">Ticket Age:</p>
			<p class="td-value">
				{{
					reportApiService.convertHuman(data.timeline.dateDifference, 'double')
				}}
			</p>
		</div>
	</div>
	<!-- <div
		class="td-card"
		*ngIf="
			(data.serviceTimes.acknowledgementTime ||
				data.serviceTimes.responseTime ||
				data.serviceTimes.resolutionTime) &&
			data.serviceTimes.isClientAdmin
		"
	>
		<h4>SERVICE TIMES</h4>
		<div class="td-flex q-f-wrap" *ngIf="data.serviceTimes.acknowledgementTime">
			<p class="td-label-2">Acknowledgement:</p>
			<p class="td-value">
				{{
					reportApiService.convertHuman(
						data.serviceTimes.acknowledgementTime,
						'single'
					)
				}}
			</p>
		</div>
		<div class="td-flex q-f-wrap" *ngIf="data.serviceTimes.responseTime">
			<p class="td-label-2">Response:</p>
			<p class="td-value">
				{{
					reportApiService.convertHuman(
						data.serviceTimes.responseTime,
						'single'
					)
				}}
			</p>
		</div>
		<div class="td-flex q-f-wrap" *ngIf="data.serviceTimes.resolutionTime">
			<p class="td-label-2">Resolution:</p>
			<p class="td-value">
				{{
					reportApiService.convertHuman(
						data.serviceTimes.resolutionTime,
						'single'
					)
				}}
			</p>
		</div>
	</div> -->
	<div class="td-card">
		<div class="td-heading">
			<h4>MANAGE USERS</h4>
			<a id="mapper_manageUsers" href="javascript:void(0)">
				<mat-icon
					svgIcon="users-plus"
					class="q-pointer"
					[tooltipContentV2]="{ message: 'Add & Manage Users' }"
					(click)="open()"
				></mat-icon>
			</a>
		</div>
		<div class="td-user-container" *ngIf="data.requestedFor.length > 0">
			<h6>Requested For</h6>
			<div class="td-user" *ngFor="let user of data.requestedFor">
				<div class="td-img">
					<quest-profile
						[path]="user.imagePath"
						[fullName]="user.fullName"
					></quest-profile>
				</div>
				<div
					class="td-name-details"
					[class.centered]="!(user.firstName && user.lastName)"
				>
					<span class="td-name">{{
						user.firstName ? user.firstName + ' ' + user.lastName : ''
					}}</span>
					<span class="td-details">{{ user.email }}</span>
				</div>
			</div>
		</div>
		<div class="td-user-container" *ngIf="data.teamMembers.length > 0">
			<h6>Team Members</h6>
			<ng-container *ngFor="let user of data.teamMembers">
				<div class="td-user" *ngIf="!user.isOwner">
					<div class="td-img">
						<quest-profile
							[path]="user.imagePath"
							[fullName]="user.fullName"
						></quest-profile>
					</div>
					<div
						class="td-name-details"
						[class.centered]="!(user.firstName && user.lastName)"
					>
						<span class="td-name">{{
							user.firstName ? user.firstName + ' ' + user.lastName : ''
						}}</span>
						<span class="td-details">{{ user.email }}</span>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="td-user-container" *ngIf="data.assignedTech.length > 0">
			<h6>Assigned Technician</h6>
			<div class="td-user" *ngFor="let user of data.assignedTech">
				<div class="td-img">
					<quest-profile
						[path]="user.imagePath"
						[fullName]="user.fullName"
					></quest-profile>
				</div>
				<div
					class="td-name-details"
					[class.centered]="!(user.firstName && user.lastName)"
				>
					<span class="td-name">{{
						user.firstName ? user.firstName + ' ' + user.lastName : ''
					}}</span>
					<span class="td-details">{{ user.email }}</span>
				</div>
			</div>
		</div>
		<div class="td-user-container" *ngIf="data.pastTech.length > 0">
			<h6>Past Technicians</h6>
			<div class="td-user" *ngFor="let user of data.pastTech">
				<div class="td-img">
					<quest-profile
						[path]="user.imagePath"
						[fullName]="user.fullName"
					></quest-profile>
				</div>
				<div
					class="td-name-details"
					[class.centered]="!(user.firstName && user.lastName)"
				>
					<span class="td-name">{{
						user.firstName ? user.firstName + ' ' + user.lastName : ''
					}}</span>
					<span class="td-details">{{ user.email }}</span>
				</div>
			</div>
		</div>
		<div class="td-user-container" *ngIf="data.externalUsers.length > 0">
			<h6>External Users</h6>
			<div class="td-user" *ngFor="let user of data.externalUsers">
				<div class="td-img">
					<quest-profile
						[path]="user.imagePath"
						[fullName]="user.fullName"
					></quest-profile>
				</div>
				<div
					class="td-name-details"
					[class.centered]="!(user.firstName && user.lastName)"
				>
					<span class="td-name">{{
						user.firstName + ' ' + user.lastName
					}}</span>
					<span class="td-details">{{ user.email }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<modal-add-manage-users
	[ticketId]="ticketId"
	[isOpenModal]="isOpenModal"
	[users]="contacts"
	(close)="isOpenModal = false"
	(addDelete)="addDeleteContact($event)"
></modal-add-manage-users>
