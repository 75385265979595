<main class="main-content  mt-0">
  <div class="page-header min-vh-100" style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/error-500.jpg');">
    <span class="mask bg-gradient-primary opacity-4"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7 mx-auto text-center">
          <h1 class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5">Error 403</h1>
          <h2 class="fadeIn3 fadeInBottom mt-3 text-white">This page is forbidden</h2>
          <ng-container *ngIf="isLoading">
            <ng-container *ngIf="canGoBack$ | async; else noAccess">
              <p class="lead fadeIn2 fadeInBottom text-white">You do not have the required permissions. Please contact your administrator.</p>
              <a id="mapper_goBack" (click)="onNavigate()" type="button" class="btn bg-gradient-primary mt-4 fadeIn2 fadeInBottom">Go Back</a>
            </ng-container>
            <ng-template #noAccess>
              <p class="lead fadeIn2 fadeInBottom text-white">You do not have any access on your account. Please contact your administrator.</p>
              <a id="mapper_logout" (click)="onLogout()" type="button" style="margin-right: 10px;" class="btn bg-gradient-primary mt-4 mr-4 fadeIn2 fadeInBottom">Logout</a>
              <a  id="mode" *ngIf="isSystemAdmin$ | async"(click)="onSwitchMode()" type="button" class="btn bg-gradient-primary mt-4 fadeIn2 fadeInBottom">Switch to {{ (roleId$ | async) === UserTypes.User ? 'Admin Mode' : 'User Mode'}} </a>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</main>
