import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';

declare var bootstrap: any;

@Directive({
	selector: '[data-bs-toggle="tooltip"]',
})
export class BootstrapTooltipDirective implements AfterViewInit, OnDestroy {
	@Input() isQuickFilter: boolean = false;
	@Input() delay: BSTooltipDelay | null = null;
	@Input() template: string = '';
	@Input() type: string = '';

	bTooltip: any;
	constructor(private elRef: ElementRef, private renderer: Renderer2) {}

	ngAfterViewInit(): void {
		let option: any = {
			fallbackPlacements: ['bottom', 'top', 'right', 'left'],
			customClass: 'custom-tooltip',
		};

		const title = this.type ? this.getTemplate() : this._quickFilterTemplate;
		if (this.isQuickFilter) option = { title, html: true };
		if (this.delay) option = { ...option, ...{ delay: this.delay } };
		if (this.template)
			option = { ...option, ...{ template: this.template, html: true } };
		this.bTooltip = new bootstrap.Tooltip(this.elRef.nativeElement, option);
	}

	getTemplate() {
		switch (this.type) {
			case 'ticket':
				return `<div role="tooltip" style="padding:0px; margin:0px;">
        <p class="tooltip-p" >Tap and hold to reveal your security pin. Share this pin with Sourcepass during phone calls to verify your ID.</p>
    </div>`;
			default:
				return '';
		}
	}

	ngOnDestroy(): void {
		if (this.bTooltip) {
			this.bTooltip.dispose();
		}
	}

	private get _quickFilterTemplate() {
		return `<div role="tooltip" style="padding:0px; margin:0px">
        <p>This feature helps you quickly filter the table by <b>status</b>.</p>

        <p class="q-mb-0">
          The <b>status</b> tells you what's currently happening with that table item.
          You can toggle these buttons on/off to enable this filter.
        </p>
    </div>`;
	}
}

export interface BSTooltipDelay {
	show: number;
	hide: number;
}
