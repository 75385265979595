import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'replaceCommaWithAt',
})
export class ReplaceCommaWithAtPipe implements PipeTransform {
	transform(value: any): any {
		if (typeof value !== 'string') {
			return value; // If the input is not a string, return it as-is
		}

		let commaCount = 0;

		// Replace the second comma with 'at'
		return value.replace(/,/g, (match) => {
			commaCount++;
			if (commaCount === 2) {
				return ' at'; // Replace only the second comma
			}
			return match; // Return the comma as-is for other occurrences
		});
	}
}
