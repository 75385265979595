<a
	class="mapper_sideMenu nav-link sublink"
	[routerLink]="
		sideMenuData.isCustomRoute
			? './' + sideMenuData.url + '/' + this.id
			: [sideMenuData.url]
	"
	[routerLinkActive]="'active'"
>
	{{ sideMenuData.name }} <i class="bi small bi-caret-down-fill"></i>

	<div
		class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
	>
		<ng-container
			*ngIf="_sideBarService.checkImageUrl(sideMenuData.icon); else faIcon"
		>
			<img
				image-error-detection
				[component]="'sub-menu-item.component.html'"
				[src]="_sideBarService.transformIconUrl(sideMenuData.icon)"
				class="img-fluid"
				alt="menuicon"
			/>
		</ng-container>

		<ng-template #faIcon>
			<app-figma-icon-img
				[iconName]="sideMenuData.icon"
				[hasSpaceOnRight]="false"
				class="text-sm opacity-10"
			></app-figma-icon-img>
		</ng-template>
	</div>
</a>
