<a
	href="#billingContactToggle"
	class="collapsed"
	data-bs-toggle="collapse"
	aria-controls="demo"
	role="button"
	aria-expanded="false"
	#billingContactEl>
	<div class="billing-contact_container visibility-hidden q-d-flex q-w-100 q-p-16 q-f-column q-ai-center q-g-16">
		<ng-container *ngTemplateOutlet="billingContactHeader"></ng-container>
	</div>

	<div
		class="billing-contact_container billing-contact_absolute q-d-flex q-w-100 q-p-16 q-f-column q-ai-center q-g-16 q-pointer"
		[class.highlighted]="!billingContactEl.classList.contains('collapsed')">

		<ng-container *ngTemplateOutlet="billingContactHeader"></ng-container>
		<ng-container *ngTemplateOutlet="billingContactDetail"></ng-container>
	</div>
</a>

<ng-template #billingContactHeader>
	<ng-container *ngFor="let row of billingContact">
		<div class="billing-contact_header q-d-flex q-w-100 q-ai-center q-g-16">
			<div class="billing-contact_user-profile q-d-flex q-ai-start q-g-10 q-f-1-0-0">
				<div class="billing-contact_profile-pic q-d-flex q-ai-center">
					<img
						image-error-detection
						class="avatar avatar-sm"
						[component]="'billing-contact.component.html'"
						[src]="
							row.imagePath !== null && row.imagePath !== ''
								? row.imagePath
								: 'assets/images/profile-pic/profilePic3.png'
						"
					/>
				</div>

				<div class="billing-contact_title">
					<div class="billing-contact_name font-b1 font-w-600">
						{{ row.name ? row.name : 'N/A' }}
					</div>
					<div class="billing-contact_position font-b1">
						{{ row.title ? row.title : 'N/A' }}
					</div>
				</div>
			</div>

			<div class="billing-contact_right-detail q-d-flex q-ai-center q-g-10">
				<div class="billing-contact_right-detail_name font-h4">
					Billing Contact
				</div>

				<quest-icon name="currency-dollar-circle"></quest-icon>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #billingContactDetail>
	<ng-container *ngFor="let row of billingContact">
		<div
			id="billingContactToggle"
			class="billing-contact_collapse q-w-100 collapse">

			<div class="billing-contact_float q-w-100">
				<div class="billing-contact_body-details q-d-flex q-p-8 q-f-column q-ai-center q-f-1-0-0 q-as-stretch">
					<div class="billing-contact_body-details_row q-d-flex q-ai-center q-g-16 q-p-8 q-f-1-0-0 q-as-stretch">
						<quest-icon name="phone"></quest-icon>

						<a id="mapper_phone" href="tel:+{{row.phone}}" class="billing-contact_phone-no font-b1">
							{{ row.phone ? (row.phone | phone) : 'N/A' }}
						</a>

						<div id="ext" class="billing-contact_external-direct font-b1 q-f-1-0-0">
							Ext. {{ row.directExt ? row.directExt : 'N/A' }}
						</div>
					</div>

					<div class="billing-contact_body-details_row font-b1 q-d-flex q-ai-center q-g-16 q-p-8 q-f-1-0-0 q-as-stretch">
						<quest-icon name="mail-01"></quest-icon>

						<a id="mapper_mailTo" href="mailto:{{row.email}}" class="billing-contact_email font-b1 q-d-flex q-w-100 q-f-column q-jc-center q-as-stretch">
							{{ row.email ? row.email : 'N/A' }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>
