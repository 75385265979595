import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import {
	MultiSelectInputComponent,
	MultiSelectItem,
} from '../../multi-select-input/multi-select-input.component';

@Component({
	selector: 'multi-select-input-2',
	templateUrl: './multi-select-input.component.html',
	styleUrls: ['./multi-select-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiSelectInput2Component,
			multi: true,
		},
	],
})
/**
 * NOTE: Override classes doesn't work here
 */
export class MultiSelectInput2Component extends MultiSelectInputComponent {
	@ViewChild(CdkVirtualScrollViewport) private _vs: CdkVirtualScrollViewport;
	@Output() customAdded = new EventEmitter<MultiSelectItem>();
	@Input() allowCustom = false;
	@Input() customPattern: RegExp;
	@Input() selectAllName = 'Select All';
	@Input() hideIcon = false;

	get selectedPreview() {
		return this.selectedItems.slice(0, 4);
	}

	addCustom(value: string) {
		value = (value ?? '').trim();
		if (!this.allowCustom || !value) return;
		this.searchControl.setValue('');
		if (
			this.selectedItems.findIndex(
				(s) => s.display.toLowerCase() == value.toLowerCase()
			) >= 0
		)
			return;
		else if (this.customPattern && !this.customPattern.test(value)) return;

		const i = this.items.find(
			(i) => i.display.toLowerCase() == value.toLowerCase()
		);
		if (i) this.toggleSelection(i);
		else {
			const item = new MultiSelectItem(value, value);
			this.customAdded.emit(item);
			this.toggleSelection(item);
		}
	}

	resetScroll() {
		this._vs.elementRef.nativeElement.classList.remove('ps--active-y');
		this._vs.scrollToIndex(0);
	}
}
