import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuestIconModule } from '../../quest-icon/quest-icon.module';

import { TextInputComponent } from './text-input.component';

@NgModule({
	declarations: [TextInputComponent],
	imports: [CommonModule, ReactiveFormsModule, FormsModule, QuestIconModule],
	exports: [TextInputComponent],
})
export class TextInputModule {}
