import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_SVG_ICONS } from './shared/constants/mat-svg-icons.constant';
import { SessionStorageService } from './shared/services/session-storage.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	inject,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { UserService } from './core/services/user.service';
import { CompaniesService } from './modules/companies/companies.service';
import { setAppTheme } from './shared/utilities/theme';
import { IdleService } from './core/services/idle.service';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router,
} from '@angular/router';
import { SpinnerService } from './core/services/spinner.service';
import { NotificationService } from './core/services/notification.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { Iconfig } from './shared/navigation/configuration/configuration.interface';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from './core/store/app-state.interface';
import { configUpdateAction } from './shared/navigation/configuration/store/configuration.action';
import { jwtRemoveAction, jwtUpdateAction } from './core/store/jwt/jwt.action';
import { accessTokenSelector } from './core/store/jwt/jwt.selector';
import {
	Observable,
	filter,
	interval,
	map,
	switchMap,
	take,
	shareReplay,
	mergeMap,
	of,
	skip,
	Subject,
	takeUntil,
} from 'rxjs';
import { jwtDecodeSelector } from './core/store/user/user.selector';
import { IUser } from './core/store/user/user.interface';
import {
	adminUrlUpdateAction,
	userUrlUpdateAction,
} from './core/store/user/user.action';
import { DataModalService } from './core/data-modal/data-modal.service';
import { environment } from 'environments/environment';
import { productFruits } from 'product-fruits';
import { NavigationService } from './shared/services/navigation.service';
import { SideBarService } from './shared/navigation/side-bar/side-bar.service';
import { PwaService } from './services/pwa.service';
import { FigmaDemoService } from './figma-demo/figma-demo.service';
import { FinanceService } from './modules/finance/finance.service';
import { SmsQuestMobileUpdateModalService } from './core/components/sms-quest-mobile-update-modal/sms-quest-mobile-update-modal.service';
import { NotificationsClienthubService } from './shared/services/notifications-clienthub.service';
import { OptimizeQuestModalService } from './core/optimize-quest-modal/optimize-quest-modal.service';
import { NewFeatureInfoModalService } from './core/components/new-feature-info-modal/new-feature-info-modal.service';
import { QuestBetaService } from './core/components/quest-beta/quest-beta.service';
import { MobileLaunchModalService } from './core/components/mobile-launch-modal/mobile-launch-modal.service';
import { MaintenanceService } from './core/services/maintenance.service';
import { TableReloadSignalService } from './shared/services/table-reload-signal.service';

declare global {
	interface Window {
		productFruits: any;
	}
}
declare var ConnectWiseHostedAPI: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	// injection
	private _pwa = inject(PwaService);

	isChatBotOpen: boolean;
	private _connectWiseClientApi: any;
	isUnderConstruction$: Observable<boolean>;
	navigationService = inject(NavigationService);

	isProcessing = false;
	isShowNewFeatureInfo: boolean = true;
	showQuestBeta: boolean = false;
	qbFeatures = [];
	qbInterests = [];

	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private _companiesService: CompaniesService,
		public _userService: UserService,
		private _utilityService: UtilitiesService,
		private _idleService: IdleService,
		private _router: Router,
		public _spinnerService: SpinnerService,
		public _notifier: NotificationService,
		private sessionStorage: SessionStorageService,
		private localStorageService: LocalStorageService,
		private store: Store<AppStateInterface>,
		public _dataModalService: DataModalService,
		private sidebarService: SideBarService,
		private _figmaDemoService: FigmaDemoService,
		private _matIconRegistry: MatIconRegistry,
		private _sanitizer: DomSanitizer,
		private _financeService: FinanceService,
		private _notificationHub: NotificationsClienthubService,
		public smsQuestMobileUpdateModalService: SmsQuestMobileUpdateModalService,
		public optimizeQuestModalService: OptimizeQuestModalService,
		public newFeatureInfoService: NewFeatureInfoModalService,
		public mobileLaunchService: MobileLaunchModalService,
		public maintenance: MaintenanceService,
		private _tableReloadSignalService: TableReloadSignalService,
		private questBetaService: QuestBetaService
	) {
		// console.error = consoleLogs.overrideError;
		this.localStorageService.windowMessageListener();
		this.registerMatSvgIcons();
	}

	_initQuestBeta() {
		this.questBetaService.initBetaApi
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (isInitialize) => {
					if (isInitialize) {
						this.questBetaService
							.getBeta()
							.pipe(takeUntil(this._$unsubscribe))
							.subscribe((data: any) => {
								if (data == null) {
									this.showQuestBeta = false;
								} else {
									this.showQuestBeta = true;
									this.qbFeatures = data.features;
									this.qbInterests = data.interests;
								}
							});
					}
				},
			});
	}

	qbClosed() {
		this.showQuestBeta = false;
	}

	ngOnInit(): void {
		this._getNavigatedUrlEvent();
		this._connectAnonymousHub();

		if (environment.isDevMode) this.loadPf();

		// add bearer token checker
		this.store.pipe(select(accessTokenSelector), take(1)).subscribe((token) => {
			if (token === null) {
				// if null get from localstorage
				let accessToken = this.localStorageService.getStorageObject(
					'accessToken'
				) as string;
				let refreshToken = this.localStorageService.getStorageObject(
					'refreshToken'
				) as string;
				// use sessionStorage accessToken if available to set the right tab mode
				if (accessToken && sessionStorage.getItem('accessToken')) {
					accessToken = sessionStorage.getItem('accessToken') as string;
				}
				if (accessToken) {
					this.store.dispatch(jwtUpdateAction({ accessToken, refreshToken }));
				} else {
					// logout all tabs if one tab is in login page and cache is cleared
					localStorage.setItem('logout-event', Math.random().toString());
				}

				this._userService.getEventMultipleTabInBrowser();
			}
		});

		// auto logout
		window.addEventListener('storage', this.#storageEvent, false);

		let config: Iconfig = this.localStorageService.getStorageObject(
			'config'
		) as Iconfig;
		if (config) {
			if (
				config.sideBarColor === 'primary' ||
				config.sideBarColor === 'dark' ||
				config.sideBarColor === 'info' ||
				config.sideBarColor === 'success' ||
				config.sideBarColor === 'warning' ||
				config.sideBarColor === 'danger'
			) {
				this.store.dispatch(configUpdateAction({}));
			} else {
				this.store.dispatch(configUpdateAction(config));
			}
		}

		let theme = window.matchMedia('(prefers-color-scheme: dark)').matches
			? 'dark'
			: 'light';

		setAppTheme(
			theme,
			this._companiesService.branding.lightThemeColor,
			this._companiesService.branding.darkThemeColor
		);
		window
			.matchMedia('(prefers-color-scheme: dark)')
			.addEventListener('change', (event) => {
				theme = event.matches ? 'dark' : 'light';
				setAppTheme(
					theme,
					this._companiesService.branding.lightThemeColor,
					this._companiesService.branding.darkThemeColor
				);
			});

		this.store
			.pipe(
				select(jwtDecodeSelector),
				filter((result) => !!result),
				switchMap((jwt) => {
					return this._userService.isAuthenticated$.pipe(
						map((data: boolean) => {
							return [jwt, data];
						})
					);
				})
			)
			.subscribe(([jwt, data]) => {
				if (this._router.url !== '/page-forbidden') {
					if (this._userService.user?.role === 'User') {
						this.store.dispatch(userUrlUpdateAction({ url: this._router.url }));
					} else {
						this.store.dispatch(
							adminUrlUpdateAction({ url: this._router.url })
						);
					}
				}

				if (this._userService.user) {
					this.sidebarService.getSidebar();
				}

				const isChatBotOpen = this.sessionStorage.getStorage('isChatBotOpen');

				if (isChatBotOpen === 'true') {
					this.toggleBot(true);
				} else {
					this.toggleBot(false);
				}

				if (data === true) {
					// Added setTimeout because isAuthenticated is updated first before User
					setTimeout(() => {
						if (
							this._userService.user?.role === 'User' &&
							!this._userService.user?.impersonatingBy
						) {
							if (
								jwt &&
								!jwt['sidLogin' as keyof IUser['jwt']] &&
								jwt['isOffice365' as keyof IUser['jwt']] === 'False'
							) {
								this._idleService.startTracking();
							} else this._idleService.stopTracking();
						} else this._idleService.stopTracking();
					});
				} else {
					this._idleService.stopTracking();
					// if (jwt && !jwt['sidLogin' as keyof IUser['jwt']]) {
					// 	this._idleService.stopTracking();
					// }
				}
			});

		this._utilityService.currentChatBot.subscribe((chatbot) => {
			this.isChatBotOpen = chatbot;
		});
		// advance instance  to be ahead of the delay
		const queryStr = window.location.search;
		if (queryStr) {
			const params = new URLSearchParams(queryStr);
			// for connectwise interface
			if (this.isConnectWise && params.get('screen') && params.get('id')) {
				this._userService.isConnectWise = true;
				this.store.dispatch(jwtRemoveAction());
				this.initConnectWise(params.get('screen')!, +params.get('id')!);
			}
		}

		this._figmaDemoService.getFigmaIcons().subscribe((res) => {
			localStorage.setItem('newFigma', JSON.stringify(res));
		});

		this._smsMobileUpdate();
		this._initOptimizeQuest();
		this._initTableSignal();
		this._initQuestBeta();
	}

	registerMatSvgIcons() {
		// Register icons that needs to change colors
		MAT_SVG_ICONS.forEach((icon) => {
			this._matIconRegistry.addSvgIcon(
				icon,
				this._sanitizer.bypassSecurityTrustResourceUrl(
					'../assets/images/icons-v2/' + icon + '.svg'
				)
			);
		});
	}

	private _connectAnonymousHub() {
		//Create SignalR connection if user is anonymous
		if (!localStorage.getItem('accessToken')) this._notificationHub.connect();
		else
			this._userService.isAuthenticated$
				.pipe(take(2), skip(1))
				.subscribe((isAuthenticated) => {
					if (!isAuthenticated) this._notificationHub.connect();
				});
	}

	loadPf() {
		productFruits.init(
			environment.fruits.code,
			'en',
			{ username: environment.fruits.username },
			{ disableLocationChangeDetection: false }
		);
		productFruits.safeExec(($pf) => {});
	}

	initConnectWise(screen: string, id: number) {
		this._spinnerService.start();
		window.addEventListener(
			'message',
			(e) => {
				let data = JSON.parse(e.data);
				if (data.event && data.event === 'onLoad') {
					console.warn('message data: ', e);
					this._connectWiseClientApi.post(
						{ request: 'getMemberAuthentication' },
						(data: any) => data
					);
				}
				if (data.response && data.response == 'getmemberauthentication') {
					console.warn('message data: ', e);
					this._authenticateInPod(data, screen, id);
				}
			},
			false
		);

		this._connectWiseClientApi = new ConnectWiseHostedAPI('*', null, true);
		this.store.dispatch(jwtRemoveAction()); //Remove to not trigger reconnect
	}

	toggleBot(isToggle: boolean) {
		if (isToggle === true) {
			if (this.sessionStorage.getStorage('botId')) {
				this._utilityService.toggleChatBot(true);
			}
		} else {
			if (!this.sessionStorage.getStorage('botId')) {
				this._utilityService.toggleChatBot(false);
			}
		}
	}

	private _authenticateInPod(data: any, screen: string, id: number) {
		console.warn('data to submit:', data);
		const { companyid, memberid, memberHash, memberContext } = data.data;
		this._userService
			.authenticateUserInPod(
				companyid,
				memberid,
				memberHash,
				screen,
				memberContext
			)
			.subscribe({
				next: (res) => {
					console.warn('response: ', res);
					this._spinnerService.stop();
					this.navigateScreens(screen, id);
				},
				error: () => {
					this._router.navigate(['/coming-soon']);
					this._spinnerService.stop();
				},
			});
	}

	get isConnectWise() {
		return window != parent;
	}

	navigateScreens(screen: string, id: number) {
		switch (screen.toLowerCase()) {
			case 'company':
				this._router.navigate(['/company-admin', +id!]);
				break;
			case 'ticket':
				this._router.navigate(['/cwpod-verification', +id!]);
				break;
			case 'contact':
				this._router.navigate(['/user-company-admin', +id!]);
				break;
		}
	}

	#storageEvent = (event: StorageEvent) => {
		// auto logout if other tab is logged out
		if (event.key == 'logout-event' && event.newValue) {
			if (this._userService.isAuthenticated) this._userService.logout();
		}
		// auto refresh all tabs for new version
		if (event.key == 'new-version-refresh-event' && event.newValue) {
			document.location.reload();
		}
	};

	closeSmsQuestMobileUpdate() {
		this.smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ = of(false);
		this.smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ =
			this._initMobileOptInStastus();
	}

	private _getNavigatedUrlEvent() {
		/* Will triggered this event once user navigate to other pages */
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				/* [Start]::Added checker once user leaved on invoice module page it will reset company dropdown and child company dropdown to default company in invoice page.  */
				const isUserOnLeavedInvoiceModulePage =
					!event.url.includes('/invoices');

				if (
					isUserOnLeavedInvoiceModulePage &&
					this._financeService.selectedCompanyId
				) {
					this._financeService.resetSearchCompanyDropdownForInvoice();
				}
				/* [End]::Added checker once user leaved on invoice module page it will reset company dropdown and child company dropdown to default company in invoice page.  */

				if (event.url.split('?')[0] != '/') this._spinnerService.reset(); // Reset spinner when navigating to new page excluding login page.
			}

			// Display spinner when user navigates. This is required when a lazy module is not downloaded yet.
			else if (event instanceof RouteConfigLoadStart) {
				this._spinnerService.start();
			} else if (event instanceof RouteConfigLoadEnd) {
				this._spinnerService.stop();
			}
		});
	}

	private _smsMobileUpdate() {
		this.smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ =
			this.smsQuestMobileUpdateModalService.mobileOptInNotification.pipe(
				filter((isLoginSuccess) => isLoginSuccess),
				mergeMap((res) => this._initMobileOptInStastus())
			);
	}

	private _initMobileOptInStastus() {
		return this._notificationHub.smsMobileNotificationReceived.pipe(
			mergeMap((m) =>
				this.smsQuestMobileUpdateModalService.mobileOptInStatus()
			),
			map(
				(x) =>
					this._userService.isUser &&
					x.isSmsEnabled === true &&
					x.isAgree === null &&
					(this.smsQuestMobileUpdateModalService.getFullDate(
						x.lastRemindMeDate
					) <
						this.smsQuestMobileUpdateModalService.getFullDate(
							this.smsQuestMobileUpdateModalService.setInMinutes(
								x.remindMeInMinutes
							)
						) ||
						x.lastRemindMeDate === null)
			)
		);
	}

	private _initOptimizeQuest() {
		this.optimizeQuestModalService.init();
	}

	private _initTableSignal() {
		this._notificationHub.tableReloadNotificationReceived
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (res) => {
					this._tableReloadSignalService.allowToFetchNewData(
						res.type,
						this._userService
					);
				},
			});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
