<div class="under-maintenance q-d-flex q-jc-center q-ai-center q-f-wrap q-g-24">
	<div class="q-d-flex q-f-column q-g-8 q-mr-auto">
		<h2>Page Under Maintenance</h2>
		<p class="font-b1">
			Quest<sup>TM</sup> is currently being serviced by our engineers for a
			routine tune-up. Not to worry, it’ll be back online in a bit. See you
			soon!
		</p>
		<button id="mapper_mnBack" (click)="back()" class="btn-2-lg btn-2-secondary q-mt-16 q-w-max">
			<mat-icon svgIcon="chevron-left"></mat-icon>
			Back
		</button>
	</div>

	<img
		src="../../../../assets/images/others/maintenance.png"
		alt="Under Maintenance"
	/>
</div>
