import {
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
	Input,
	OnChanges,
	SimpleChanges,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PaginatorComponent } from '../paginator/paginator.component';

import { DateTime } from 'luxon';

import { OptionsModel } from '@app/shared/functions/options';

import { ApplicationsService } from '@app/modules/applications/applications.service';
import {
	PaginationConstants,
	Permission,
	TableMessages,
	UserTypes,
} from '@app/shared/constants';
import { AuditTrailService } from './audit-trail.service';
import { UserService } from '@app/core/services/user.service';
import { FinanceService } from '@app/modules/finance/finance.service';

import { HistoryPageSettings } from './../../interfaces/history.interface';

@Component({
	selector: 'app-audit-trail',
	templateUrl: './audit-trail.component.html',
	styleUrls: ['./audit-trail.component.scss'],
})
export class AuditTrailComponent implements OnInit, OnChanges {
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ContentChild('customizeContent') customizeContent: TemplateRef<unknown>;
	@Input() id: number | string | null;
	@Input() url: string;
	@Input() history_category:
		| 'default'
		| 'permission'
		| 'menupermission'
		| 'invoice'
		| 'all' = 'default';
	@Input() data_options: OptionsModel;
	@Input() isCustom: boolean | undefined;
	@Input() triggerRefresh = false;
	@Input() hasChangedForm: boolean;
	@Input() addNameField = false;
	@Input() isCustomizeContent = false;
	@Input() userId?: number;
	@Input() version?: string;

	historyDataSource: any[] = [];
	hasData: boolean = false;
	totalItems = 0;
	pageSizes = PaginationConstants.pageSizes;
	isAuditClicked: boolean = false;
	message = {
		noRecord: TableMessages.EmptyTable,
	};
	isAdmin: boolean = true;
	selectedCompanyId: number;
	previousRecord: any;

	defaultPic = 'assets/images/profile-pic/profilePic.png';

	constructor(
		private _route: ActivatedRoute,
		private _applicationsService: ApplicationsService,
		private _cd: ChangeDetectorRef,
		private _auditTrailService: AuditTrailService,
		private _userService: UserService,
		private _financeService: FinanceService
	) {}

	get isForInvoicePaymentMethod() {
		return this.history_category == 'invoice' && this.id !== 'invoice-autopay';
	}

	get isTypeManageAutoPay() {
		return this.history_category == 'invoice' && this.id === 'invoice-autopay';
	}

	get isAllowed(): boolean {
		const href = window.location.href;
		const isClientAdmin = this._userService.isClientAdmin;
		let hasPermission = false;

		if (href.includes('/application')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailApplication
			);
		} else if (href.includes('/companies')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailCompany
			);
		} else if (href.includes('/knowledge-base')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailKnowledgeBase
			);
		} else if (href.includes('/service-and-support')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailServiceAndSupport
			);
		} else if (href.includes('/messages')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailMessage
			);
		} else if (href.includes('/groups')) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailGroup
			);
		} else if (
			href.includes('/contacts') ||
			href.includes('/roles-and-permissions/override')
		) {
			hasPermission = this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailContacts
			);
		}

		return this._userService.isSpAdmin || (isClientAdmin && hasPermission);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.['triggerRefresh']?.currentValue) {
			this.getPageHistory();
		}
	}

	ngOnInit(): void {
		this.isAdmin = this._userService.userRole === UserTypes.SourcepassAdmin;
		this.selectedCompanyId = JSON.parse(
			localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any
		)?.companyId;
	}

	formatDate(dt: string): string {
		return DateTime.fromISO(dt).toFormat('d LLL yyyy');
	}

	formatTime(dt: string): string {
		return DateTime.fromISO(dt).toFormat('t');
	}

	getDate(dt: string): string {
		const date = dt.split(' ');
		return date.shift()!;
	}

	getMonthYear(dt: string): string {
		const monthYear = dt.split(' ');
		monthYear.shift();
		return monthYear.join(' ').toUpperCase();
	}

	getMarginTop(isDateDuplicated: boolean, index: number): string {
		return index === 0 ? '0' : isDateDuplicated ? '-2.8px' : '14px';
	}

	getPageHistory(flag = 'page', pageOrSize?: number) {
		if (flag === 'page') {
			this.paginator.page = pageOrSize || 1;
		} else if (flag === 'size') {
			this.paginator.size = pageOrSize!;
			this.paginator.page = 1; // bring back to page 1 when page size is changed
			this.paginator.setTotalPages();
			this.paginator.setPages();
		}

		const settings = {
			url: this.url,
			page: this.paginator.page,
			size: this.paginator.size,
			companyId: this._financeService.companyIdType,
			userId: this.userId,
			version: this.version,
		} as HistoryPageSettings;

		if (
			this.history_category == 'default' ||
			this.history_category == 'invoice'
		) {
			if (this.id) {
				this._auditTrailService.getHistory(settings, this.id).subscribe({
					next: (resp) => {
						this._getPreviousRecord();
						this.initialize(resp);
						this._hasModefiedFormFromParent();
					},
				});
			}
		} else if (this.history_category == 'permission') {
			this._auditTrailService
				.getPermissionHistory(
					settings,
					this.data_options.companyId,
					this.data_options.groupId,
					this.data_options.userId,
					this.isCustom
				)
				.subscribe({
					next: (resp) => {
						this.initialize(resp);
					},
				});
		} else if (this.history_category == 'menupermission') {
			this._auditTrailService
				.getMenuPermissionHistory(
					settings,
					this.data_options.roleId,
					this.data_options.companyId,
					this.data_options.groupId,
					this.data_options.userId
				)
				.subscribe({
					next: (resp) => {
						this.initialize(resp);
					},
				});
		} else if (this.history_category == 'all') {
			this._auditTrailService.getAllHistory(settings).subscribe({
				next: (resp) => this.initialize(resp),
			});
		}
	}

	getApplicationHistory(page?: number) {
		var applicationId = Number(this._route.snapshot.paramMap.get('id'));
		if (applicationId > 0) {
			this.paginator.page = page ?? 1;
			this._applicationsService
				.getApplicationHistory(
					applicationId,
					this.paginator.page,
					this.paginator.size
				)
				.subscribe({
					next: (data: any) => {
						this.initialize(data);
					},
				});
		}
	}

	initialize(resp: any) {
		this.totalItems = resp.totalCount;
		this.historyDataSource = resp.data;
		this.hasData = !!resp.data.length;

		this._setAdditionalProperties();
		this._cd.markForCheck();
	}

	auditClick() {
		this.isAuditClicked = !this.isAuditClicked;
		if (this.isAuditClicked === true) {
			this.getPageHistory();
		}
	}

	refresh() {
		if (this.isAuditClicked) {
			this.getPageHistory();
		}
	}

	private _setAdditionalProperties() {
		const count: any = {};

		for (let index = 0; index < this.historyDataSource.length; index++) {
			const element = this.historyDataSource[index];
			const prevElement = this.historyDataSource[index - 1];

			element.formattedDate = this.formatDate(element.entryDate);

			if (index === 0) {
				element.isDateDuplicated = false;
			} else {
				if (element.formattedDate === prevElement.formattedDate) {
					element.isDateDuplicated = true;
				} else {
					element.isDateDuplicated = false;
				}
			}

			count[element.formattedDate] = (count[element.formattedDate] || 0) + 1;
		}

		for (let index = 0; index < this.historyDataSource.length; index++) {
			const element = this.historyDataSource[index];

			if (!element.isDateDuplicated) {
				element.rowspan = count[element.formattedDate];
				element.class = 'top-radius';
				this.historyDataSource[index + count[element.formattedDate] - 1].class =
					'bottom-radius';
			}

			if (element?.rowspan === 1) {
				element.class = 'full-radius';
			}
		}
	}

	private _getPreviousRecord() {
		/* QUEST-BUG255 : Get first row for previous record */
		this.previousRecord =
			this.historyDataSource[0] !== undefined
				? this.historyDataSource[0]
				: null;
	}

	private _hasModefiedFormFromParent() {
		/* QUEST-BUG255 : It will reload getPageHistory() method again once the form from parent component has changed at the same time the previous entry date and new entry date is matched.  */
		if (
			this.hasChangedForm &&
			this.previousRecord?.entryDate === this.historyDataSource[0].entryDate
		) {
			setTimeout(() => this.getPageHistory(), 1000);
		}
	}
}
