import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { ChatBotModule } from '@app/shared/components/chat-bot/chat-bot.module';
import { DragDropCustomModule } from '@app/shared/directives/drag-drop.module';
import { StoreModule } from '@ngrx/store';
import * as config from '@app/shared/navigation/configuration/store/configuration.reducer';
import * as topBar from '@app/shared/navigation/top-bar/store/top-bar.reducer';
import * as user from '@app/core/store/user/user.reducer';
import * as sidebar from '@app/shared/navigation/side-bar/store/sidebar.reducer';
import { TopBarEffect } from '@app/shared/navigation/top-bar/store/top-bar.effects';
import * as contactsKbTopics from '@app/modules/knowledge-base/pages/kb-topic-list/store/contacts/kb-topic.contacts.reducer';
import * as jwt from '@app/core/store/jwt/jwt.reducer';
import { EffectsModule } from '@ngrx/effects';
import { JWTEffect } from '@app/core/store/jwt/jwt.effects';
import { UserEffect } from '@app/core/store/user/user.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { agreementsReducer } from '@app/modules/admin/pages/agreement/store/agreements.reducer';
import { globalApplicationReducer } from '@app/modules/applications/pages/application-list/store/sp-admin/company/global/application.reducer';
import { companyApplicationReducer } from '@app/modules/applications/pages/application-list/store/sp-admin/company/company/application.reducer';
import { contactsCompanyApplicationReducer } from '@app/modules/applications/pages/application-list/store/sp-admin/contacts/company/application.reducer';
import { contactsUserApplicationReducer } from '@app/modules/applications/pages/application-list/store/sp-admin/contacts/user/application.reducer';
import { companyAgreementTypesReducer } from '@app/modules/agreement-types/pages/agreement-types/store/company/company-agreement-types.reducer';
import { agreementTypesReducer } from '@app/modules/agreement-types/pages/agreement-types/store/agreement-types.reducer';
import { userAppCategoryListReducer } from '@app/modules/applications/pages/category-list/store/user/user-app-categoryreducer';
import * as groupKbTopic from '@app/modules/knowledge-base/pages/kb-topic-list/store/group/kb-topic.group.reducer';
import { keyvaultConfig } from 'functions/keyvault';
import { KeyVaultService } from '@app/core/services/key-vault.service';
import { JwtInterceptor } from '@app/core/interceptors/jwt.interceptor';
import { HttpErrorInterceptor } from '@app/core/interceptors/http.interceptor';
import { NotificationsClienthubService } from '@app/shared/services/notifications-clienthub.service';
import { NotificationComponent } from '@app/shared/components/notification/notification.component';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { groupApplicationReducer } from '@app/modules/applications/pages/application-list/store/client-admin/groups/application.reducer';
import { ErrorHandler } from '@angular/core';
import { metaReducers } from '@app/core/store/user/user.action';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CustomErrorHandlerService } from '@app/services/custom-error-handler.service';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import {
	IPublicClientApplication,
	PublicClientApplication,
} from '@azure/msal-browser';
import { QuestModalComponent } from '@app/core/data-modal/data-modal.component';
import { companyGlobalKbTopicsReducer } from '@app/modules/knowledge-base/pages/kb-topic-list/store/companies/global/kb-topic-global.companies.reducer';
import { MaintenanceBannerComponent } from '@app/shared/components/maintenance-banner/maintenance-banner.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: '53c2bc3b-e7bb-49af-99ed-16aa74650f4a', //53c2bc3b-e7bb-49af-99ed-16aa74650f4a
			authority:
				'https://login.microsoftonline.com/c3a9c533-f4ce-452b-a5fc-b9687c74a23b', //https://login.microsoftonline.com/c3a9c533-f4ce-452b-a5fc-b9687c74a23b
			redirectUri: environment.redirectUri,
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
		},
	});
}

export const DevModules = [
	QuestModalComponent,
	MaintenanceBannerComponent,
	MsalModule,
	CoreModule,
	BrowserModule,
	CommonModule,
	BrowserAnimationsModule,
	HttpClientModule,
	AppRoutingModule,
	DragDropCustomModule,
	MatDialogModule,
	MatAutocompleteModule,
	MatIconModule,
	ChatBotModule,
	NgxMaskModule.forRoot(),
	StoreModule.forRoot({}, { metaReducers }),
	StoreModule.forFeature('config', config.configReducer),
	StoreModule.forFeature('topBar', topBar.topBarReducer),
	StoreModule.forFeature('jwt', jwt.jwtReducer),
	StoreModule.forFeature('sidebar', sidebar.sidebarReducer),
	EffectsModule.forRoot([TopBarEffect, JWTEffect, UserEffect]),
	StoreModule.forFeature('contactsKbTopics', contactsKbTopics.kbTopicsReducer),
	StoreDevtoolsModule.instrument({
		maxAge: 25,
		logOnly: environment.production,
	}),
	StoreModule.forFeature('agreements', agreementsReducer),
	StoreModule.forFeature('globalApplication', globalApplicationReducer),
	StoreModule.forFeature('companyApplication', companyApplicationReducer),
	StoreModule.forFeature('groupApplication', groupApplicationReducer),
	StoreModule.forFeature(
		'contactsCompanyApplication',
		contactsCompanyApplicationReducer
	),
	StoreModule.forFeature(
		'contactsUserApplication',
		contactsUserApplicationReducer
	),
	StoreModule.forFeature('companyAgreementTypes', companyAgreementTypesReducer),
	StoreModule.forFeature('companyGlobalKbTopics', companyGlobalKbTopicsReducer),
	StoreModule.forFeature('agreementTypes', agreementTypesReducer),
	StoreModule.forFeature('groupKbTopics', groupKbTopic.kbTopicsReducer),
	StoreModule.forFeature('user', user.userReducer),
	StoreModule.forFeature('userAppCategoryList', userAppCategoryListReducer),
	ServiceWorkerModule.register('ngsw-worker.js', {
		enabled: !environment.isDevMode, //environment.production,
		// Register the ServiceWorker as soon as the application is stable
		// or after 30 seconds (whichever comes first).
		registrationStrategy: 'registerWhenStable:30000',
	}),
];

export const DevServices = [
	{
		provide: MSAL_INSTANCE,
		useFactory: MSALInstanceFactory,
	},
	MsalService,
	{
		provide: 'SocialAuthServiceConfig',
		useFactory: keyvaultConfig,
		deps: [KeyVaultService],
	},
	{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
	NotificationsClienthubService,
	CurrencyPipe,
	{ provide: ErrorHandler, useClass: CustomErrorHandlerService },
	{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
	//{provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: 'UTC-5'}
];

export const DevDeclarations = [AppComponent, NotificationComponent];
export const ProdModules = [...DevModules];
export const ProdDeclarations = [...DevDeclarations];
export const ProdServices = [...DevServices];
