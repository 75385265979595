<div class="card shadow-lg">
	<div class="card-header pb-0 pt-3 bg-transparent">
		<div class="float-start">
			<h5 class="mt-3 mb-0">Theme Customization</h5>
			<p>Customize your theme options below.</p>
		</div>
		<div class="float-end mt-4">
			<button
      id="mapper_conf1"
				class="btn btn-link text-dark p-0 fixed-plugin-close-button"
				spConfigurator
			>
				<app-figma-icon-img
					[iconName]="'x'"
					[isForButton]="true"
					[hasSpaceOnRight]="false"
				></app-figma-icon-img>
			</button>
		</div>
		<!-- End Toggle Button -->
	</div>
	<hr class="horizontal dark my-1" />
	<!-- never remove the class configurator-sidebar. this is used in ClickOutSideConfiguratorDirective -->
	<div class="card-body pt-sm-3 pt-0 overflow-auto configurator-sidebar">
		<div [style.display]="(darkMode$ | async) ? 'none' : 'block'">
			<div>
				<h6 class="mb-0">Banner Color</h6>
			</div>
			<a  id="config" href="javascript:void(0)" class="switch-trigger background-color">
				<div class="badge-colors my-2 text-start">
					<span
						*ngIf="themeSettings.companyLightThemeColor as clightColor"
						class="badge filter"
						[ngStyle]="{ 'background-color': clightColor }"
						[class.active]="(sidebarColor$ | async) === clightColor"
						style="box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%)"
						(click)="setSideBarColor(clightColor)"
					></span>
					<span
						*ngIf="themeSettings.lightThemeColor as ulightColor"
						class="badge filter"
						[ngStyle]="{ 'background-color': ulightColor }"
						[class.active]="(sidebarColor$ | async) === ulightColor"
						style="box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%)"
						(click)="setSideBarColor(ulightColor)"
					></span>

					<span
						*ngFor="let color of themeSettings.lightColorsList; let i = index"
					>
						<span
							class="badge filter"
							[ngStyle]="{ 'background-color': color }"
							[class.active]="(sidebarColor$ | async) === color"
							style="box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%)"
							data-bs-toggle="dropdown"
							aria-expanded="true"
							[id]="'dropMenu' + i"
						>
						</span>
						<ul
							class="dropdown-menu px-2 py-3 shadow"
							[attr.aria-labelledby]="'dropMenu' + i"
						>
							<li>
								<a
                  id="mapper_setSidebar"
									class="dropdown-item border-radius-md"
									href="javascript:;"
									(click)="setSideBarColor(color)"
								>
									Apply
								</a>
							</li>
							<li *ngIf="(sidebarColor$ | async) !== color">
								<a
                  id="mapper_deleteColor"
									class="dropdown-item border-radius-md"
									href="javascript:;"
									(click)="deleteUserColor(color)"
								>
									Delete
								</a>
							</li>
						</ul>
					</span>
					<div
						*ngIf="
							themeSettings.lightColorsList &&
							themeSettings.lightColorsList.length < 7
						"
						class="badge filter relative"
						[ngStyle]="{ 'background-color': 'white' }"
						style="
							box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%);
							height: auto;
							padding: 2px;
							width: auto !important;
						"
						[tooltipContentV2]="{ message: 'Add New Color' }"
						(click)="openEditMode('add', -1)"
					>
						<app-figma-icon-img
							[iconName]="'plus'"
							[isForButton]="true"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</div>
				</div>
			</a>
		</div>

		<!-- Color Options -->
		<div class="sidenav-options" *ngIf="mode === 'add' || mode === 'edit'">
			<div class="mt-3">
				<h6 class="mb-0">Choose a color</h6>
			</div>
			<div class="flex-container">
				<input type="color" [(ngModel)]="colorValue" class="flex-color-1" />
				<a
          id="mapper_saveColor"
					class="btn cancel-btn btn-gradient-primary addnewapp-btn btn-sm"
					(click)="saveColor()"
				>
					{{ mode == 'add' ? 'Add' : 'Edit' }}
				</a>
			</div>
		</div>

		<p class="text-sm d-xl-none d-block mt-2">
			You can change the sidenav type just on desktop view.
		</p>
		<!-- Navbar Fixed -->
		<div class="d-flex my-3">
			<h6 class="mb-0">Top Navigation Fixed</h6>
			<div class="form-check form-switch ps-0 ms-auto my-auto">
				<input
					class="form-check-input mt-1 ms-auto"
					type="checkbox"
					id="navbarFixed"
					#navbarFixed
					spNavBarFixed
				/>
			</div>
		</div>
		<div class="my-4">
			<div class="coming-soon">
				<p class="text-center">
					A Dark Mode customization option will be coming soon!
				</p>
			</div>
		</div>
	</div>
</div>
