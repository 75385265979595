<form [formGroup]="form" id="payment-section" class="q-d-grid q-g-24">
  <div class="q-grayed-box q-d-flex q-g-24 col-group">
    <div class="q-d-grid q-g-24 q-w-100">
      <div class="q-widget-panel warning q-d-flex q-jc-between q-ai-center q-w-100">
        <div class="font-btn">Available Credits & Unapplied Payments</div>
        <h4>{{ paymentArrangementService.formData.availableCredits | currency }}</h4>
      </div>

      <div class="q-d-flex q-g-16">
        <div class="form-field q-d-grid q-g-8 q-w-100">
          <label class="font-b1 q-mb-0 font-w-600">Initial Payment</label>
          <span class="prefix-input font-b1" [class.empty-state]="!form.controls['initialPayment'].value">$</span>
          <input
            type="text"
            appTwoDigitDecimaNumber
            class="form-control"
            placeholder="0.00"
            formControlName="initialPayment"
            (blur)="convertIntoDecimal('initialPayment')"
            (ngModelChange)="onBreakDown(500)"
            autocomplete="off">
        </div>

        <div class="form-field q-d-grid q-g-8">
          <label class="font-b1 q-mb-0 font-w-600">Payment Date</label>
          <date-input
            [isString]="true"
            formControlName="paymentDate"
            (ngModelChange)="onBreakDown(500)">
          </date-input>
        </div>
      </div>

      <div class="divider"></div>

      <div class="form-field q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">Recurring Amount</label>
        <span class="prefix-input font-b1" [class.empty-state]="!form.controls['recurringAmount'].value">$</span>
        <input
          type="text"
          class="form-control"
          placeholder="0.00"
          formControlName="recurringAmount"
          appTwoDigitDecimaNumber
          autocomplete="off"
          (blur)="convertIntoDecimal('recurringAmount')"
          (ngModelChange)="onBreakDown(500)">
      </div>

      <div class="form-field q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">Frequency</label>

        <div class="q-d-flex q-g-8">
          <ng-container *ngFor="let f of frequencies;">
            <button

              type="button"
              class="mapper_freq btn-2 btn-2-tertiary q-d-block"
              [class.active]="f.id === form.controls['frequencyId'].value"
              (click)="form.controls['frequencyId'].setValue(f.id); onBreakDown();"
              [disabled]="isSubmitted">
              {{ f.name }}
            </button>

            <input
              type="radio"
              [value]="f.id"
              formControlName="frequencyId"
              hidden>
          </ng-container>
        </div>
      </div>

      <div class="q-d-flex q-g-16">
        <div class="form-field q-d-grid q-g-8">
          <label class="font-b1 q-mb-0 font-w-600">Start Date</label>
          <date-input
            [isString]="true"
            formControlName="startDate"
            (ngModelChange)="this.onBreakDown(500)">
          </date-input>
        </div>

        <div class="form-field q-d-grid q-g-8">
          <label class="font-b1 q-mb-0 font-w-600">Payment Cycles</label>
          <input
            type="number"
            class="form-control"
            formControlName="paymentCycles"
            (ngModelChange)="onBreakDown(500)">
        </div>
      </div>
    </div>

    <div class="q-d-grid q-g-24 q-w-100">
      <div class="q-d-grid q-g-10">
        <div class="q-widget-panel danger q-d-flex q-jc-between q-ai-center q-w-100 q-p-16 q-h-100">
          <div class="font-btn">Balance under Payment Arrangement</div>
          <h4>{{ paymentArrangementService.formData.totalPaymentAmount | currency }}</h4>
        </div>

        <div class="q-widget-panel success q-d-flex q-jc-between q-ai-center q-w-100 q-p-16 q-h-100">
          <div class="font-btn">Initial Payment</div>
          <h4>{{ paymentArrangementService.formData.initialPayment | currency }}</h4>
        </div>

        <div class="q-widget-panel success q-d-flex q-jc-between q-ai-center q-w-100 q-p-16 q-h-100">
          <div class="font-btn">Recurring Payments</div>
          <h4>{{ paymentArrangementService.formData.recurringAmount | currency }}</h4>
        </div>

        <div class="q-widget-panel default q-d-flex q-jc-between q-ai-center q-w-100 q-p-16 q-h-100">
          <div class="font-btn">Net Balance</div>
          <h4>{{ paymentArrangementService.formData.netBalance | currency }}</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="q-d-flex q-jc-end q-g-24 col-group">
    <div class="bordered-box q-d-flex q-f-column q-g-24 q-w-50" *ngIf="isEdit">
      <div class="q-d-flex q-ai-center">
        <h4 class="q-mr-auto">Legal Confirmation</h4>
        <a href="javascript:void(0);" class="q-pointer" (click)="copyToClipboard(contentToCopy.value)">
          <quest-icon name="copy-04"></quest-icon>
        </a>
      </div>

      <div class="divider"></div>

      <div class="scroll-v2 q-d-grid q-g-24 overflow-hidden">
        <textarea
          #contentToCopy
          name=""
          id=""
          cols="30"
          rows="100%"
          class="font-b2 payment-clipboard"
          formControlName="legalConfirmationContent"
          readonly>
        </textarea>
      </div>
    </div>

    <div id="payment-schedule" class="bordered-box q-d-flex q-f-column q-g-24 q-w-50">
      <h4>Payment Schedule</h4>

      <div class="divider"></div>

      <div class="scroll-v2 q-d-grid q-g-24">

        <ng-container *ngIf="isLoadingSchedules; else DisplayedSchedule">
          <div class="skeleton q-w-100" style="height: 32px;"></div>
        </ng-container>

        <ng-template #DisplayedSchedule>
          <div class="striped-box q-d-grid q-g-8">
            <ng-container *ngFor="let d of paymentSchedules">
              <div class="q-d-flex q-jc-between q-ai-center">
                <span class="font-b1">{{ d.scheduledDate | date: 'MM/dd/yy' }}</span>
                <span class="font-b1 font-w-500">{{ d.amount | currency }}</span>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</form>
