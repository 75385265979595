export enum Contacts {
	OrgChart = 'org-chart',
	Directory = 'directory',
}

export enum ApplicationType {
	CSV = 0,
	EXCEL = 1,
}

// audit trail
export enum HistoryUrl {
	APPLICATION = 'Application',
	APPLICATION_CATEGORY = 'ApplicationCategory',
	KNOWLEDGE_BASE = 'KB',
	KNOWLEDGE_BASE_CATEGORY = 'KBCategory',
	AGREEMENT_TYPE = 'AgreementType',
	TICKET_BOARDS = 'SRBoard',
	GROUP = 'Groups',
	SR_FORM = 'SRForm',
	SR_CATEGORY = 'SRCategory',
	COMPANIES = 'Companies',
	ROLES_PERMISSIONS = 'RolesAndPermissions',
	KB_BOT = 'KBBot',
	USERS = 'Users',
	FORM_USAGE = 'FormUsageMapping',
	ORDER_STATUS = 'OrderStatusMapping',
	QUOTE_STATUS = 'QuoteStatusMapping',
	INVOICE_STATUS = 'InvoiceStatusMapping',
	PAYMENT_ARRANGEMENTS = 'PaymentArrangements',
	QUOTES = 'Quotes',
	MESSAGE = 'Messages',
	DASHBOARD_CARD = 'DashboardCard',
	TICKET_STATUS = 'TicketStatusMapping',
	DOMAIN_SID = 'Companies/domain',
	KNOWBE4 = 'IntegrationType',
	APPLICATION_CATALOG = 'ApplicationCatalog',
	FINANCES = 'Finances',
	CREDIT_MEMO_REQUEST = 'Invoices/CreditMemo',
	INVOICES = 'Invoices/CompanyPaymentMethod',
	INVOICE_AUTOPAY_AUDIT = 'Invoices/InvoiceAutopayAudit',
	CW_USERS = 'CW_Users',
	NOTIFICATION_SETTING = 'NotificationSetting',
	USERS_ROLE_AND_GROUP = 'users/roleAndGroup',
	NOTIFICATONS_COMPANY = 'Companies/company',
	COMPANY_TYPE_NOTIFICATION = 'Companies/companyTypeDetail',
	TERMS_OF_USE = 'TermsOfUse',
	MANAGE_AUTOPAY = 'Invoices/AutopayPaymentMethod',
	NOTIFICATION_ACCESS = 'NotificationAccess/',
	ROLE_MENU_ACCESS = 'RoleMenuAccess',
	ROLE_MENU_ACTION_ACCESS = 'RoleMenuActionAccess',
	ROLE_FORMS = 'RoleForms', // R&P Edit -- history
	USER_FORMS = 'UserForms', // R&P Override -- history
	USER_MENU_ACCESS_OVERRIDE = 'UserMenuAccessOverride',
	USER_MENU_ACTION_ACCESS_OVERRIDE = 'UserMenuActionAccessOverride',
	ROLE_KB_TOPIC_ACCESS = 'RoleKBTopicAccess', // -- history
	ROLE_TICKET_BOARD_ACCESS = 'RoleTicketBoardAccess', // -- history
	ROLE_APPLICATIONS = 'RoleApplications',
	USER_APPLICATIONS = 'UserApplications',
	EMAIL_TEMPLATE = 'TicketEmailTemplate',

	ROLES_AND_PERMISSIONS_V2_ROLE = 'RolesAndPermissions-v2/role',
	ROLES_AND_PERMISSIONS_V2_MANAGE_USER = 'RolesAndPermissions-v2/manageUser',
	ROLES_AND_PERMISSIONS_V2_ROLE_AND_MENU_ACCESS = 'RolesAndPermissions-v2/roleAndMenuAccess',
	ROLES_AND_PERMISSIONS_V2_ROLE_MENU_ACCESS = 'RolesAndPermissions-v2/roleMenuAccess',
	ROLES_AND_PERMISSIONS_V2_USER_MENU_ACCESS_OVERRIDE = 'RolesAndPermissions-v2/userMenuAccessOverride',
	ROLES_AND_PERMISSIONS_V2_ROLE_FORMS = 'RolesAndPermissions-v2/roleForms',
	ROLES_AND_PERMISSIONS_V2_USER_FORMS = 'RolesAndPermissions-v2/userForms',
	ROLES_AND_PERMISSIONS_V2_USER_TICKET_BOARD_ACCESS = 'RolesAndPermissions-v2/UserBoards',
	ROLES_AND_PERMISSIONS_V2_USER_KBT_ACCESS = 'RolesAndPermissions-v2/userKbTopics',
	ROLES_AND_PERMISSIONS_V2_ROLE_KBT_ACCESS = 'RolesAndPermissions-v2/roleKBTopicAccess',
	ROLES_AND_PERMISSIONS_V2_ROLE_TICKET_BOARD_ACCESS = 'RolesAndPermissions-v2/roleTicketBoardAccess',
	ROLES_AND_PERMISSIONS_V2_ROLE_APPLICATIONS = 'RolesAndPermissions-v2/roleApplications',
	ROLES_AND_PERMISSIONS_V2_USER_APPLICATIONS = 'RolesAndPermissions-v2/userApplications',
	ROLES_AND_PERMISSIONS_V2_COMPANY_FORMS = 'RolesAndPermissions-v2/srFormCompanyOverride',
	ROLES_AND_PERMISSIONS_V2_COMPANY_KBT_ACCESS = 'RolesAndPermissions-v2/kbTopicCompanyOverride',
	ROLES_AND_PERMISSIONS_V2_COMPANY_TICKET_BOARD_ACCESS = 'RolesAndPermissions-v2/boardCompanyOverride',
	ROLES_AND_PERMISSIONS_V2_COMPANY_APPLICATIONS_ACCESS = 'RolesAndPermissions-v2/applicationCompanyOverride',
	// user override
	ROLES_AND_PERMISSIONS_V2_USER_FORMS_ACCESS = 'UserForms',
}

export enum CompanyUserAccess {
	APPLICATIONS = 1,
	KNOWLEDGE_BASE = 2,
	REQUEST_FORM = 3,
}

export enum Pages {
	APPLICATIONS = 'Applications',
	KNOWLEDGE_BASE = 'KB Topics',
	REQUEST_FORM = 'Request Forms',
	SERVICE_AND_REQUEST = 'Service and Request',
}

export enum PageAction {
	LIST = 'list',
	ADD = 'add',
	EDIT = 'edit',
	SAVE = 'save',
	DELETE = 'delete',
	VIEW = 'view',
	EXTERNAL_LINK = 'external-link',
	IMPERSONATE = 'impersonate',
	COPY = 'copy',
}

export enum GlobalSearchCategoryEnum {
	All = 1,
	Applications = 2,
	KBTopics = 3,
	Tickets = 4,
	Quotes = 5,
	Orders = 6,
	Contacts = 7,
}

export enum KeypressFlag {
	letterOnly = 'letter-only',
	numberOnly = 'number-only',
}

export enum Keys {
	ENTER = 13,
}

export enum FilterUrl {
	QUOTES = 'Quotes',
	ORDERS = 'Orders',
	TICKETS = 'Tickets',
	INVOICE = 'Invoices',
	FINANCE = 'Finances',
}

export enum SRType {
	Service = 1,
	Support,
}

// export enum SRPriority {
//     Low = 1,
//     Normal,
//     Important,
//     Critical,
// }

export enum FormUsage {
	AddNewUser = 1,
	InvoiceInquiry,
	OrderInquiry,
	IssueNotListed,
	GeneralItTicket = 8,
	RequestChange
}

export enum FormAnswerType {
	Textbox = 1,
	Textarea,
	RadioButton,
	Checkbox,
	Dropdown,
	MultiSelectDropdown,
	Date,
	Datetime,
	Time,
	Image,
	File,
	Email,
	Number,
	Url,
	DropdownWithInput,
	EmailDropdown,
	// DropdownWithSearch,
	// DropdownWithFilter,
	// Rewst
	// Password,
}

export enum FormValidationType {
	MaxLength = 1,
	MinLength,
	RestrictPastDate,
	RestrictFutureDate,
	MaxNumber,
	MinNumber,
	MaxAnswer,
	AllowMultipleFiles,
	NoSpecialCharacters,

	// Required = 1,
	// MaxLength,
	// MinLength,
	// RestrictPastDate,
	// RestrictFutureDate,
	// DateTime,
	// DateRange,
	// MaxNumber,
	// MinNumber
}

export enum VisibilityType {
	Global = 1,
	VerticalMarket,
	Company,
}

export enum Themes {
	light = 'light',
	dark = 'dark',
}

export enum Tooltip {
	maxCharacter = 500,
}

export enum UserDetails {
	BASIC_INFO = 'BASIC INFO',
	CONTACT_INFO = 'CONTACT INFO',
	ROLES_AND_GROUPS = 'ROLES AND GROUPS',
}

export enum UserTypes {
	SourcepassAdmin = 1,
	ClientAdmin = 2,
	User = 3,
}

export enum UserTypeNames {
	SourcepassAdmin = 'Sourcepass Admin',
	ClientAdmin = 'Client Admin',
	User = 'User',
}

export enum Company {
	SOURCEPASS = 2,
}

export enum UserMessageCategoryEnum {
	Ticket = 2,
	Order = 3,
	Banner = 4,
	BroadCast = 5,
	Invoice = 7,
}

export enum Global {
	APPLICATIONS = 1,
	KNOWLEDGE_BASE = 2,
	SERVICE_AND_REQUEST = 4,
}

export enum NotificationSettings {
	NOTIFICATION = 'Notification',
	EMAIL = 'Email',
	IN_APP = 'In-App',
}

export enum FileFormat {
	JPG = 'jpg',
	JPEG = 'jpeg',
	PNG = 'png',
	SVG = 'svg',
	PDF = 'pdf',
	DOC = 'doc',
	DOCX = 'docx',
	TXT = 'txt',
	BMP = 'bmp',
	CSV = 'csv',
	MP4 = 'mp4',
	XLSX = 'xlsx',
	XLS = 'xls',
}

export enum DeviceType {
	ios = 'iOS',
	android = 'Android',
	all = 'All',
}

export enum SearchState {
	default,
	typing,
	searching,
	complete,
}

export enum InvoiceTab {
	invoice = 0,
	credits = 1,
	payments = 2,
	accountActivity = 3,
}

export enum InvoiceStatus {
	OUTSTANDING = 'Outstanding',
	PAID = 'Paid'
}

export enum TableSignalType {
	INVOICE = 'invoice',
	ORDER = 'order',
	QUOTE = 'quote',
	TICKET = 'ticket'
}