<img
	*ngIf="isQuestIcon"
	src="./assets/images/icons-v2/quest-avatar.svg"
	class="{{ size }}"
	alt="Quest"
/>

<ng-container *ngIf="!isQuestIcon">
	<img
		*ngIf="path"
		[src]="path"
		(error)="path = ''"
		class="{{ size }}"
		[alt]="initial"
	/>
	<div *ngIf="!path" class="{{ size }} default-{{ color }} no-override">
		{{ initial }}
		<quest-icon *ngIf="!initial" name="user-circle"></quest-icon>
	</div>
</ng-container>
