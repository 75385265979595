import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, debounceTime, tap } from 'rxjs';
import { GlobalSearchCategoryEnum, SearchState } from '@app/shared/constants';
import { FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit {
	searchControl = new FormControl('');
	searchSub: Subscription;
	searchResults: SearchResultGroup[] = [];
	searchState: SearchState = SearchState.complete;
	isSearchOpened = false;

	generalTicket = '/service-and-support/new-ticket/report-problem';
	icons = ['grid-01', 'book-open-02', 'file-02', 'tag-01', 'shopping-bag-01', 'telescope'];

	constructor(
		private _http: HttpClient,
		private _router: Router,
	) {}

	ngOnInit(): void {
		this.searchControl.valueChanges.pipe(
			tap((v) => {
				this.searchState = SearchState.typing;
				if(this.searchSub && !this.searchSub.closed)
					this.searchSub.unsubscribe();
			}),
			debounceTime(800),
			tap(value => {
				this.#search(value!.trim());
			})
		).subscribe();
	}

	#search(search: string) {
		this.searchState = SearchState.searching;
		this.searchResults = [];

		if(search) {
			const url = `${environment.apiBaseUrl}GlobalSearches?search=${search}`;
			this.searchSub = this._http.get<{categories: any[], tables: any[]}>(url)
			.subscribe({
				next: (res) => {
					this.searchResults = res.tables
						.filter(g => g.data.length)
						.map(g => this.#mapResult(g, this.icons));
					this.searchState = SearchState.complete;
				},
				error: () => {this.searchState = SearchState.complete;}
			});
		} else {
			this.searchState = SearchState.complete;
		}
  }

	viewItem(item: SearchResultItem, group: SearchResultGroup) {
		let closeAfter = true;
		switch (group.id) {
			case GlobalSearchCategoryEnum.Applications:
				window.open(this.#toHttps(item.link!), '_blank');
				closeAfter = false;
				break;
			
			case GlobalSearchCategoryEnum.KBTopics:
				this._router.navigateByUrl(`${group.link}-articles/${item.link!}/${item.id}`);
				break;
			
			case GlobalSearchCategoryEnum.Tickets:
				this._router.navigateByUrl(`${group.link}/view/${item.id}`);
				break;
			
			case GlobalSearchCategoryEnum.Quotes:
				if(item.link) {
					window.open(this.#toHttps(item.link), '_blank');
					closeAfter = false;
				} else this._router.navigateByUrl(group.link);
				break;
			
			case GlobalSearchCategoryEnum.Orders:
				this._router.navigateByUrl(`${group.link}/${item.id}`);
				break;
			
			case GlobalSearchCategoryEnum.Contacts:
				this._router.navigateByUrl(`${group.link}/${btoa(item.id.toString()).replace(/[^\w\s]/gi, '')}/profile`);
				break;
		}
		if(closeAfter) this.toggleSearch(false);
	}

	#mapResult(result: any, icons: string[]): SearchResultGroup {
		return {
			id: result.id,
			name: result.name,
			icon: icons[result.id-2] ?? 'file-04',
			link: result.url,
			items: result.data.map((d: any) => this.#mapResultItems(d, result.id)),
		}
	}

	#mapResultItems(item: any, groupId: number): SearchResultItem {
		let id = item.id,
		name = item.name,
		description = item.description,
		link = item.url;

		switch (groupId) {
			case GlobalSearchCategoryEnum.KBTopics:
				name = item.title;
				description = item.kbCategory;
				link = item.categoryId;
				break;
			case GlobalSearchCategoryEnum.Tickets:
				name = item.summary;
				description = `Ticket #: ${item.id}, Status: ${item.statusName}`;
				break;
			case GlobalSearchCategoryEnum.Quotes:
				description = item.quoteType;
				link = item.quoteURL;
				break;
			case GlobalSearchCategoryEnum.Orders:
				name = item.description;
				description = item.orderStatus;
				break;
			case GlobalSearchCategoryEnum.Contacts:
				description = item.email;
				break;
		}
		return {id, name, description, link}
	}

	toggleSearch(isOpened: boolean) {
		this.isSearchOpened = isOpened;
	}

	#toHttps(link: string) {
		let url = link.replace('http://', 'https://');
		if (!url.includes('https://')) url = 'https://' + url;
		return url;
	}

	get SearchState() {
		return SearchState
	}
}

interface SearchResultGroup {
	id: GlobalSearchCategoryEnum;
	name: string;
	icon: string;
	items:SearchResultItem[];
	link: string;
}

interface SearchResultItem {
	id: number;
	name: string;
	description: string;
	link?: string;
}