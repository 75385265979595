<div id="manage-user-modal" *ngIf="isOpenModal">
	<modal-v2 [isLoading]="isLoading" (onCloseModalViaBackdrop)="closeModal()">
		<ng-template #modalV2Header>
			<span class="add-manage-users__header">
				<mat-icon svgIcon="users-plus" class="q-pointer"></mat-icon>
				Add & Manage Users
			</span>
		</ng-template>

		<ng-template #modalV2Body>
			<div class="add-manage-users__content">
				<div class="add-manage-users__content--add-user">
					<div class="add-user">
						<span class="add-user__title">Add Users</span>
						<span class="add-user__description">
							By adding these users, they will have access to this ticket. They
							will be notified by all future status changes and can interact
							with the message thread.
						</span>
					</div>

					<!-- [Start]::For within quest contact form -->
					<div class="add-manage-users_form">
						<label class="add-manage-users_form-label font-b1"
							>User Directory</label
						>

						<div
							id="user-directory"
							class="add-manage-users_form-input q-w-100"
						>
							<multi-select-input-2
								[allowSelectAll]="false"
								[options]="userContactOptions"
								[formControl]="contactFc"
								[autoClosePerSelect]="true"
								placeholder="Search Users"
							>
							</multi-select-input-2>
						</div>
					</div>
					<!-- [Start]::For within quest contact form -->

					<!-- [Start]::For external contact form -->
					<div class="add-manage-users_form">
						<label class="add-manage-users_form-label font-b1"
							>Email Address</label
						>

						<div id="email-address" class="add-manage-users_form-input q-w-100">
							<email-dropdown-input
								[isShowTooltipEmailError]="false"
								[isShowSearchIcon]="true"
								[placeholder]="'name@company.com'"
								[ccEmails]="[]"
								[formControl]="externalContactFc"
							>
							</email-dropdown-input>
						</div>
					</div>
					<!-- [End]::For external contact form -->
				</div>
				<div class="add-manage-users__content--users-list users-list">
					<span class="users-list__title">{{ activeUsers }} Added Users</span>
					<ul class="users-list__list">
						<li *ngFor="let user of users; let i = index">
							<ng-container *ngIf="user.isInternalUser">
								<span class="users-list__list--name">
									<quest-profile
										[path]="user.imagePath"
										[fullName]="user.fullName"
									></quest-profile>
									<div>
										<div
											*ngIf="user.fullName"
											class="add-manage-users_name font-b2"
										>
											{{ user.fullName }}
											<span
												class="add-manage-users_owner font-b2"
												*ngIf="user.isOwner"
												>(Owner)</span
											>
											<span
												class="add-manage-users_owner font-b2"
												*ngIf="!user.isOwner && user.isApprover"
												>(Approver)</span
											>
										</div>
										<div
											*ngIf="user.email"
											class="add-manage-users_email font-b4"
										>
											{{ user.email }}
										</div>
									</div>
								</span>
								<span class="users-list__list--button">
									<ng-container *ngIf="!user.isOwner && !user.isApprover">
										<app-svg
											name="x"
											class="q-pointer"
											(click)="addDeleteUser(user.email, i)"
										>
										</app-svg>
									</ng-container>
								</span>
							</ng-container>

							<ng-container *ngIf="!user.isInternalUser">
								<span class="users-list__list--name">
									<quest-profile
										[path]="user.imagePath"
										[fullName]="user.fullName"
									></quest-profile>
									<div>
										<div
											*ngIf="user.fullName"
											class="add-manage-users_name font-b2"
										>
											{{ user.fullName }}
										</div>
										<div
											*ngIf="user.email"
											class="add-manage-users_email font-b4"
										>
											{{ user.email }}
										</div>
									</div>
								</span>
								<span class="users-list__list--button">
									<ng-container *ngIf="!user.isOwner">
										<app-svg
											name="x"
											class="q-pointer"
											(click)="addDeleteUser(user.email, i)"
										>
										</app-svg>
									</ng-container>
								</span>
							</ng-container>
						</li>
					</ul>
				</div>
			</div>
		</ng-template>

		<ng-template #modalV2Footer>
			<app-tertiary-button [btnText]="'Close'" (click)="closeModal()">
			</app-tertiary-button>
			<button
      id="mapper_amUsers"
				class="btn-2 btn-2-primary"
				(click)="addDeleteUser()"
				[disabled]="!isEnableAddUser && !isEnableExternalUser"
			>
				Add User
			</button>
		</ng-template>
	</modal-v2>
</div>
