import { FormAnswerType } from '@app/shared/constants';
import { Order } from '@app/shared/interfaces/order.interface';

export interface ITicket {
	attachments: IAttachments[];
	notes: INotes[];
	ticket: ITicketContent;
	contacts: TicketContacts[];
}

export interface INotes {
	id: number;
	internalflag: boolean;
	lastUpdated: string;
	name?: string;
	note: string;
	userId: number;
	type?: 'comment';
	color?: string;
	profile?: string;
	isLoading?: boolean;
	startDate?: string;
	endDate?: string;
	imagePath?: string;
	initials?: string;
	noProfileCtr?: number;
	isQuest?: boolean;
}

export interface TicketContacts {
	id: number;
	firstName: string;
	lastName: string;
	imagePath: string;
	resizeImagePath: string;
	fullName: string;
	email: string;
	isOwner: boolean;
	isApprover: boolean;
	isInternalUser: boolean;
	initials?: string;
	noProfileCtr?: number;
	isAssignedTech: boolean;
	isExternalUser: boolean;
	isPastTechnician: boolean;
	isRequestedFor: boolean;
	isTeamMember: boolean;
}

export interface IFakeTicket {
	Id: number;
	TicketId: number;
	UserId: number;
	Note: string;
	Files: File[];
}

export interface ITicketContent {
	boardName: string;
	companyName: string;
	id: number;
	lastUpdated: string;
	name: string;
	priorityName: string | 'Low' | 'Medium' | 'High';
	statusName: string | 'Open' | 'Closed';
	summary: string;
	typeName: string;
	userPin: number;
	color: string;
	fontColor: string;
	aiSummary: string;
	aiLastUpdated: string;
	acknowledgementTime: number;
	resolutionTime: number;
	responseTime: number;
	forecastTime: number;
	hasTechnicianResponse: boolean;
}

export interface IAttachments {
	filename: string;
	id: number;
	lastUpdated: string;
	name: string;
	userId: number;
	title: string;
	type?: 'attachment';
	color?: string;
	profile?: string;
	url?: any;
	isLoading?: boolean;
	startDate?: string;
	endDate?: string;
	imagePath?: string;
	initials?: string;
	noProfileCtr?: number;
}

export interface TicketRequest {
	Id: number;
	Summary?: string;
	Items: {
		srUserResponses: UserTicketResponse[];
	};
	Files: File[];
	TicketBoardId?: number;
	CompanyId?: number;
	Details?: Order | any;
	ccEmails?: string[];
	mainCategoryId?: number;
	subCategoryId?: number;
	description?: string;
	rewstMessage?: string;
}

export interface TicketTableItem {
	id: number;
	companyName: string;
	summary: string;
	name: string;
	lastUpdated: string;
	statusName: string;
	currentTechAssigned: string;
	isChild: boolean;
}

export interface UserTicketResponse {
	questionId: number;
	userTicketAnswer: UserTicketAnswer[];
}

export interface UserTicketAnswer {
	answerTypeId: FormAnswerType;
	answerValue: string;
}
export interface UserTicketPreviousAndNext {
	id: number;
	previousId: number;
	nextId: number;
	totalCount?: number;
	page?: number;
	pageSize?: number;
}

export interface TicketDesktopDiagnosis {
	ticketId: number;
	diagnosisFileUrl: string;
	diagnosis: DesktopDiagnosis;
}

export interface DesktopDiagnosis {
	time: string;
	machineName: string;
	os: string;
	systemType: string;
	totalRam: number;
	currentRam: number;
	diskMemory: number;
	cpuUsage: number;
	questVersion: string;
	activeApps: DesktopActiveApp[];
}

interface DesktopActiveApp {
	id: string;
	name: string;
	memory: number;
	cpu: string;
}

export interface RewstResponse {
	code?: number;
	message?: string;
	url?: string | null;
}

export interface TicketResponse {
	attachments: IAttachments[];
	notes: INotes[];
}
