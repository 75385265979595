<div
	#multiSelect
	class="multi-select-input-2 q-d-flex q-f-column"
	[class.with-selected]="selectedItems.length > 0"
	matAutocompleteOrigin
	#autoOrigin="matAutocompleteOrigin"
>
	<div
		class="input-wrapper q-d-flex q-ai-center q-g-8"
		(click)="openPanel(); searchInput.focus()"
	>
		<quest-icon *ngIf="!hideIcon" name="search-sm"></quest-icon>

		<input
			#searchInput
			[formControl]="searchControl"
			[placeholder]="placeholder"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matAutocompleteConnectedTo]="autoOrigin"
			(keydown.enter)="addCustom(searchControl.value)"
			(blur)="onTouched()"
			(input)="resetScroll()"
			class="font-b1 font-h-normal q-f-1"
		/>

		<quest-icon name="alert-triangle"></quest-icon>
		<quest-icon
			*ngIf="allowCustom"
			(click)="addCustom(searchControl.value)"
			name="plus"
		></quest-icon>
		<quest-icon *ngIf="!allowCustom" name="chevron-down"></quest-icon>
		<quest-icon *ngIf="!allowCustom" name="chevron-up"></quest-icon>
	</div>

	<mat-chip-list
		[selectable]="true"
		[disabled]="disabled"
		#chipList
		cdkOverlayOrigin
		#chipsOrigin="cdkOverlayOrigin"
		matAutocompleteOrigin
		tabindex="-1"
	>
		<div class="chip-list-wrapper q-d-flex q-f-wrap q-g-8 q-m-4">
			<ng-container *ngFor="let item of selectedPreview">
				<mat-chip
					*ngIf="!item.tooltip"
					(removed)="toggleSelection(item)"
					(click)="optionClicked($event, item, true)"
					class="q-d-flex q-ai-center q-g-8 font-btn"
					disableRipple
				>
					<quest-icon *ngIf="item.icon" [name]="item.icon"></quest-icon>
					<div class="ellipsis">{{ item.display }}</div>
					<quest-icon matChipRemove name="x-close"></quest-icon>
				</mat-chip>

				<mat-chip
					*ngIf="item.tooltip"
					(removed)="toggleSelection(item)"
					(click)="optionClicked($event, item, true)"
					class="q-d-flex q-ai-center q-g-8 font-btn"
					[tooltipContentV2]="{ message: item.tooltip }"
					disableRipple
				>
					<quest-icon *ngIf="item.icon" [name]="item.icon"></quest-icon>
					<div class="ellipsis">{{ item.display }}</div>
					<quest-icon matChipRemove name="x-close"></quest-icon>
				</mat-chip>
			</ng-container>

			<mat-chip
				*ngIf="selectedItems.length > 4"
				(click)="openSelectedPanel()"
				class="font-btn"
				disableRipple
			>
				...
			</mat-chip>
		</div>
	</mat-chip-list>
</div>

<mat-autocomplete
	#auto="matAutocomplete"
	(closed)="resetScroll()"
	class="multi-select-2-options-panel"
>
	<mat-option *ngIf="allowSelectAll" class="multi-option">
		<div class="cb-wrapper q-g-24 select-all" (click)="selectAll($event)">
			<checkbox-input
				[checked]="isAllSelected"
				style="pointer-events: none"
			></checkbox-input>
			<span class="font-btn font-h-normal">{{ selectAllName }}</span>
		</div>
	</mat-option>

	<cdk-virtual-scroll-viewport
		[ngStyle]="{
			'height.px': 40 * ((filteredItems | async)?.length ?? 0),
			'max-height.px': allowSelectAll ? 200 : 240
		}"
		itemSize="40"
		minBufferPx="240"
		maxBufferPx="400"
		class="q-scroll"
		qScroll
	>
		<mat-option *cdkVirtualFor="let item of filteredItems | async">
			<div class="cb-wrapper q-g-24" (click)="optionClicked($event, item)">
				<checkbox-input
					[checked]="item.selected"
					style="pointer-events: none"
				></checkbox-input>
				<span class="font-btn font-h-normal">{{ item.display }}</span>
			</div>
		</mat-option>
	</cdk-virtual-scroll-viewport>
</mat-autocomplete>

<ng-template
	cdkConnectedOverlay
	cdkConnectedOverlayHasBackdrop="true"
	(backdropClick)="closeSelectedPanel()"
	cdkConnectedOverlayBackdropClass="transparent"
	[cdkConnectedOverlayOrigin]="chipsOrigin"
	[cdkConnectedOverlayOpen]="selectedPanelOpen && selectedItems.length > -1"
>
	<div class="multi-select-2-overlay scroll-v2 q-shadow-1">
		<div class="q-d-flex q-f-wrap q-g-8 q-p-16">
			<ng-container *ngFor="let item of selectedItems">
				<mat-chip
					*ngIf="!item.tooltip"
					[disabled]="disabled"
					(click)="optionClicked($event, item, true)"
					(removed)="toggleSelection(item)"
					class="q-d-flex q-ai-center q-g-8 font-btn"
					disableRipple
				>
					<quest-icon *ngIf="item.icon" [name]="item.icon"></quest-icon>
					{{ item.display }}
					<quest-icon matChipRemove name="x-close"></quest-icon>
				</mat-chip>

				<mat-chip
					*ngIf="item.tooltip"
					[disabled]="disabled"
					(click)="optionClicked($event, item, true)"
					(removed)="toggleSelection(item)"
					class="q-d-flex q-ai-center q-g-8 font-btn"
					[tooltipContentV2]="{ message: item.tooltip }"
					disableRipple
				>
					<quest-icon *ngIf="item.icon" [name]="item.icon"></quest-icon>
					{{ item.display }}
					<quest-icon matChipRemove name="x-close"></quest-icon>
				</mat-chip>
			</ng-container>
		</div>
	</div>
</ng-template>
