import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { PaymentArrangementsService } from '@app/modules/finance/pages/payment-arrangements/payment-arrangements.service';
import { MethodType } from '@app/modules/finance/pages/setup-payment-arrangements/payment-method-section/payment-method-section.interface';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { BankMethod } from '@app/shared/interfaces/invoice.interface';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { Subject, takeUntil } from 'rxjs';
import { CardFormService } from '../card-form/service/card-form.service';

declare let Stripe: any;


@Component({
  selector: 'bank-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.scss']
})
export class BankFormComponent implements OnInit, OnDestroy {
	hasSelectedBank: boolean = false;
  stripe: any;
  bankForm: FormGroup;
	accntHolderTypeOpt = [
		{
			val: 'individual',
			label: 'Individual'
		},
		{
			val: 'company',
			label: 'Company'
		}
	];

  private _stripeService = inject(StripeService);
  private _toastMessageService = inject(ToastMessageService);
  private _cardFormService = inject(CardFormService);
  private _fb = inject(FormBuilder);
  private _notifier = inject(NotificationService);
  private _cd = inject(ChangeDetectorRef);
	private _paymentArrangementService = inject(PaymentArrangementsService);

  private _$unsubscribe: Subject<void> = new Subject<void>();

	get defaultFormValue() {
		return {
			bankName: '',
			paymentMethodId: '',
			accountHolderName: '',
			routingNumber: '',
			accountNumber: '',
			confirmAccountNumber: '',
			email: '',
			accountHolderType: 'individual'
		}
	}

  ngOnInit(): void {
    this._getPublishableKey();
    this._initForm();
  }

  private _initForm() {
    this.bankForm = this._fb.group({
			bankName: [this.defaultFormValue.bankName],
			paymentMethodId: [this.defaultFormValue.paymentMethodId],
			accountHolderName: [this.defaultFormValue.accountHolderName, Validators.required],
			routingNumber: [this.defaultFormValue.routingNumber, Validators.required],
			accountNumber: [
				this.defaultFormValue.accountNumber, 
				[
					Validators.required,
					(control: AbstractControl) => {
						if (control.value && (control.value === this.bankForm?.controls['confirmAccountNumber'].value)) {
							this.bankForm?.controls['confirmAccountNumber'].markAsUntouched();
							this.bankForm?.controls['confirmAccountNumber'].setErrors({ notMatched: false });
							return null;

						} else {
							if (this.bankForm?.controls['confirmAccountNumber'].touched) {
								this.bankForm?.controls['confirmAccountNumber'].markAsTouched();
								this.bankForm?.controls['confirmAccountNumber'].setErrors({ notMatched: true });
							}
							
							return
						}
					}
				]
			],
			confirmAccountNumber: [
				this.defaultFormValue.confirmAccountNumber, 
				[
					Validators.required,
					(control: AbstractControl) => {
						if (control.value === this.bankForm?.controls['accountNumber'].value) {
							return null;
						} else {
							return { notMatched: true };
						}
					}
				]
			],
			email: ['', Validators.required],
			accountHolderType: ['individual', Validators.required],
		});
  }

  private _getPublishableKey() {
    this._stripeService.getStripePublishableKey()
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (res) => {
        this.stripe = Stripe(res.publishableKey);
      }
    });
  }

	onSubmit() {
    if (this.hasSelectedBank) {
      this._cardFormService.setPaymentMethodId(this.bankForm.controls['paymentMethodId'].value, MethodType.CARD);
    } else {
      this.getPaymentMethodId();
    }
  }

  async getPaymentMethodId() {
		this.bankForm.markAllAsTouched();

		const params = {
			country: 'US',
			currency: 'usd',
			routing_number: this.bankForm.controls['routingNumber'].value,
			account_number: this.bankForm.controls['accountNumber'].value,
			account_holder_name: this.bankForm.controls['accountHolderName'].value,
			account_holder_type: this.bankForm.controls['accountHolderType'].value,
		};

		if (this.bankForm.controls['accountHolderName'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['email'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['routingNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['accountNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['confirmAccountNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (
			this.bankForm.controls['accountNumber'].value !==
			this.bankForm.controls['confirmAccountNumber'].value
		) {
			this._notifier.notifyError(NotificationMessages.Review, 'Confirm both account numbers match');
			return;
		}

		const { token, error } = await this.stripe.createToken('bank_account', params);

		if (error && ((error.code === 'bank_account_auto_verification_failed') || (error.code === 'account_number_invalid'))) {
			this._notifier.notifyError(NotificationMessages.Review, 'The bank account failed validation. Please check the account and routing numbers provided.');
			return;
		}

		if (!token) {
			this._toastMessageService.showErrorMessage(error.message);
			return;
		}

		if (token) {
			this._paymentArrangementService
				.addStripeBanks(
					{
						token: token.id,
						email: this.bankForm.controls['email'].value
							? this.bankForm.controls['email'].value
							: null,
					},
					Number(this._paymentArrangementService.data.companyId)
				)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result: any) => {
						this.bankForm.controls['paymentMethodId'].setValue(result.id);
						this._cardFormService.setPaymentMethodId(this.bankForm.controls['paymentMethodId'].value, MethodType.BANK);
						this._notifier.notifySuccess(NotificationMessages.save('Bank Account'), 'You can use this account for future transactions');
					},
					error: (err) => {
						this._toastMessageService.showErrorMessage(err.errors);
					},
				});
		}
  }

  returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	clearForm() {
		this.bankForm.patchValue(this.defaultFormValue);
	}

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();  
  }
}
