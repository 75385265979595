import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import {
	BehaviorSubject,
	filter,
	finalize,
	map,
	Observable,
	ReplaySubject,
	Subscription,
	tap,
} from 'rxjs';

import { ApplicationType, FormUsage } from '@app/shared/constants';

import { BrowserInfoService } from '@app/shared/services/browser-info.service';
import { ConsoleLogsService } from '@app/shared/services/console-logs.service';

import { CustomForm } from '@app/shared/interfaces/custom-form.interface';
import { GenericForm, NameId } from '@app/shared/interfaces/support.interface';
import { GenericType } from '@app/shared/interfaces/knowledge-base.interface';
import {
	IFakeTicket,
	ITicket,
	TicketContacts,
	TicketDesktopDiagnosis,
	TicketRequest,
	TicketTableItem,
} from '../../shared/components/messaging/view-ticket/ticket.interface';
import { ITickets } from './pages/ticket/store/tickets.interface';
import { ParentChildAccessType } from '@app/shared/interfaces/companies.interface';
import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';
import { TicketTableOptions } from '@app/shared/interfaces/table.interface';
import { TicketStatus } from '@app/shared/interfaces/ticket-status/ticket-status.interface';

@Injectable({
	providedIn: 'root',
})
export class TicketService {
	tableOptions: TicketTableOptions | null;
	filter = {
		search: '',
		ticketStatusIds: '',
		ticketPriorityIds: '',
		ticketTypeIds: '',
	};
	viewTicketsFilters: ITickets;
	recentlyOpenedTicketNo: number;

	private _trackingSubs: Subscription;
	#baseRoute: string = '';

	// service / module state
	private _state = new BehaviorSubject<TicketServiceState>({
		isViewMyTicket: false,
		ticketBoardId: null,
		isLoading: false,
	});

	// filters for View Tickets
	private _ticketType$ = new BehaviorSubject<GenericType[]>([]);
	ticketType$ = this._ticketType$.asObservable();

	private _ticketStatus$ = new BehaviorSubject<TicketStatus[]>([]);
	ticketStatus$ = this._ticketStatus$.asObservable();

	private _childCompanies$ = new BehaviorSubject<TicketStatus[]>([]);
	childCompanies$ = this._childCompanies$.asObservable();

	private _ticketData = new ReplaySubject<any[]>(1);
	ticketData$ = this._ticketData.asObservable();

	private _ticketTotalItems = new ReplaySubject<number>(1);
	ticketTotalItems$ = this._ticketTotalItems.asObservable();

	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _consoleLogs: ConsoleLogsService,
		private _browser: BrowserInfoService
	) {}

	initSettings() {
		this.#setRoutes();
	}

	getImage(id: number): Observable<any> {
		return this._http.get(
			environment.apiBaseUrl + 'CW_TicketNotes/GetAttachment/' + id,
			{ responseType: 'text' }
		);
	}

	rateAISummary(id: number, isLiked: boolean) {
		return this._http.post(
			environment.apiBaseUrl + 'Tickets/RateAISummary/' + id,
			{ isLiked: isLiked }
		);
	}

	clearFilter() {
		this.filter.search = '';
		this.filter.ticketStatusIds = '';
		this.filter.ticketPriorityIds = '';
		this.filter.ticketTypeIds = '';
	}

	setSearchText(searchText: string) {
		this.filter.search = searchText;
	}

	startTracking() {
		if (this._trackingSubs) this._trackingSubs.unsubscribe();
		this._trackingSubs = this._router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event) => {
				if (
					!(event as NavigationEnd).urlAfterRedirects.startsWith(
						'/support/ticket'
					)
				) {
					this.tableOptions = null;
					this._trackingSubs.unsubscribe();
				}
			});
	}

	getUsageId() {
		return window.btoa(JSON.stringify({ usageId: FormUsage.IssueNotListed }));
	}

	saveCurrentFilter(filters: ITickets) {
		this.viewTicketsFilters = filters;
	}

	getTickets(options: ITickets) {
		this.saveCurrentFilter(options);

		var param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			ViewMyTicket: options.viewMyTicket,
		} as any;

		if (options.typeIds) {
			param.TypeId = options.typeIds;
		}

		if (options.subtypeDetailId) {
			param.subtypeDetailId = options.subtypeDetailId;
		}

		if (options.itemDetailId) {
			param.itemDetailId = options.itemDetailId;
		}

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusId = options.statusIds.join(',');
		}

		if (options.companyIds?.length) {
			param.CompanyId = options.companyIds.join(',');
		}

		if (
			options.priorityIds !== undefined &&
			options.priorityIds !== null &&
			options.priorityIds?.length > 0
		) {
			param.PriorityId = options.priorityIds.join(',');
		}

		if (options.fromDate && options.toDate) {
			param.FromDate = options.fromDate;
			param.ToDate = options.toDate;
		}

		this.isLoading = true;
		this._ticketData.next([]);
		this._ticketTotalItems.next(0);

		return this._http
			.get<ResponseResult<TicketTableItem[]>>(
				`${environment.apiBaseUrl}Tickets`,
				{
					params: param,
				}
			)
			.pipe(
				tap((res) => {
					this._ticketData.next(res.data);
					this._ticketTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getTicketTooltip(ticketId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Tickets/GetTicketNoteUpdate/${ticketId}`
		);
	}

	getTicket(id: number): Observable<ITicket> {
		const headers = new HttpHeaders().set('x-api-version', '1.2');
		return this._http.get<ITicket>(`${environment.apiBaseUrl}Tickets/${id}`, {
			headers,
		});
	}

	getPreviousAndNext(id: number | null) {
		return this._http.get(
			`${environment.apiBaseUrl}Tickets/GetPreviousAndNext/${id}`
		);
	}

	getNextAndPrevious(id: number | null, options: any) {
		var body = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
		} as any;

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			body.TypeId = options.typeIds.join(',');
		}
		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			body.StatusId = options.statusIds.join(',');
		}
		if (
			options.priorityIds !== undefined &&
			options.priorityIds !== null &&
			options.priorityIds?.length > 0
		) {
			body.PriorityId = options.priorityIds.join(',');
		}

		return this._http.post(
			`${environment.apiBaseUrl}Tickets/NextAndPrevious/${id}`,
			body
		);
	}

	getStatus(
		ticketBoardId: number | null = null,
		viewMyTicket: boolean | null = null,
		IsGetActualStatusName = false
	) {
		let url = `${environment.apiBaseUrl}TicketStatus?`;

		if (ticketBoardId) {
			url += `&ticketBoardId=${ticketBoardId}`;
		}
		if (viewMyTicket !== null) {
			url += `&viewMyTicket=${viewMyTicket}`;
		}
		if (IsGetActualStatusName)
			url += `&IsGetActualStatusName=${IsGetActualStatusName}`;

		return this._http.get<TicketStatus[]>(url);
	}
	initTicketStatus(
		ticketBoardId: number | null = null,
		isViewMyTicket: boolean | null = null
	) {
		if (
			this._ticketStatus$.value.length <= 0 ||
			(this._ticketType$.value.length > 0 &&
				this._state.value.isViewMyTicket != isViewMyTicket) ||
			(this._ticketType$.value.length > 0 &&
				this._state.value.ticketBoardId != ticketBoardId)
		) {
			this.getStatus(ticketBoardId, isViewMyTicket).subscribe((v) =>
				this._ticketStatus$.next(v)
			);
			//this._state.isViewMyTicket = isViewMyTicket;
			this._state.value.ticketBoardId = ticketBoardId;
		}
	}

	getAttachment(id: number): Observable<any> {
		return this._http.get(
			`${environment.apiBaseUrl}CW_TicketNotes/GetAttachment/${id}`
		);
	}

	downloadAttachment(url: string) {
		window.open(url);
	}

	addTicketNote(body: any, isReply: boolean = false) {
		let newBody = this.createFormData(body);
		// newBody.append('files', body.files)
		for (let i = 0; i < body.Files.length; i++) {
			newBody.append('files', body.Files[i]);
		}
		if (isReply) {
			const headers = new HttpHeaders().set('x-api-version', '1.1');
			return this._http.post(
				`${environment.apiBaseUrl}CW_TicketNotes`,
				newBody,
				{ headers }
			);
		}

		return this._http.post(
			`${environment.apiBaseUrl}Tickets/CloseTicket`,
			newBody
		);
	}

	reopenTicket(body: IFakeTicket) {
		let newBody = this.createFormData(body);
		return this._http.post(
			`${environment.apiBaseUrl}Tickets/ReOpenTicket`,
			newBody
		);
	}

	addTicket(
		ticket: any,
		isFeedback: boolean = false,
		hasReportProgressEvent: boolean = false
	) {
		const page = this._router.url;
		const screenSize = this._browser.screenSize;
		let pre = { ...ticket };

		if (isFeedback) {
			pre = {
				...ticket,
				...this._browser.browserDetail(),
				...{ page, screenSize, ConsoleLog: this._consoleLogs.errorStringify },
			};
		}

		const body = this.newTicketFormData(pre);
		if (isFeedback)
			// feedback uses different API
			return this._http.post(
				`${environment.apiBaseUrl}UserTicketResponse/AddFeedbackTicketAndEmailNotification`,
				body
			);
		// common ticket API
		else {
			const endPoint = 'UserTicketResponse/AddNewTicket';

			if (hasReportProgressEvent) {
				return this._http.post(`${environment.apiBaseUrl}${endPoint}`, body, {
					observe: 'events',
					reportProgress: true,
				});
			} else {
				return this._http.post(`${environment.apiBaseUrl}${endPoint}`, body);
			}
		}
	}

	verifyCode(srFormCodeId: number, code: any) {
		const body = {
			srFormCodeId: srFormCodeId,
			code: code,
		};

		return this._http.post(
			`${environment.apiBaseUrl}UserTicketResponse/VerifyCode`,
			body
		);
	}

	addTicketRewst(ticket: TicketRequest) {
		const body = this.newTicketFormData(ticket);

		return this._http.post(
			`${environment.apiBaseUrl}UserTicketResponse/SubmitToRewst`,
			body
		);
	}

	addGenericTicket(ticket: GenericForm) {
		const body = this.newTicketFormData(ticket);
		return this._http.post(
			`${environment.apiBaseUrl}UserTicketResponse/AddGenericTicket`,
			body
		);
	}

	getTicketPriorityDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}UserTicketResponse/GetPriorityDropdown`
		);
	}

	getTicketDesktopDiagnosis(id: string) {
		const url = `${environment.apiBaseUrl}UserTicketResponse/GetFormDesktopDetail/${id}`;
		return this._http.get<TicketDesktopDiagnosis>(url);
	}

	getSite(id: number) {
		const url = `${environment.apiBaseUrl}Sites/${id}`;
		return this._http.get<NameId & { address: string; phone: string }>(url);
	}

	getTicketTypesListDropdown(viewMyTicket: boolean) {
		return this._http.get<GenericType[]>(
			`${environment.apiBaseUrl}TicketTypes?viewMyTicket=${viewMyTicket}`
		);
	}
	initTicketType(isViewMyTicket: boolean = false) {
		if (
			this._ticketType$.value.length <= 0 ||
			(this._ticketType$.value.length > 0 &&
				this._state.value.isViewMyTicket != isViewMyTicket)
		) {
			this.getTicketTypesListDropdown(isViewMyTicket).subscribe((v) => {
				this._ticketType$.next(v);
			});
			//this._state.isViewMyTicket = isViewMyTicket;
		}
	}

	ticketTypeDropdown(boardId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}TicketTypes/GetTypeDropdown/` + boardId
		);
	}

	subTypeDropdown(viewMyTicket: boolean, ticketTypeId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}TicketTypes/GetSubTypeDropdownList?ViewMyTicket=${viewMyTicket}&TicketTypeId=${ticketTypeId}`
		);
	}

	itemDropdown(viewMyTicket: boolean, ticketSubtypeDetailId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}TicketItems/GetItemDropdownList?ViewMyTicket=${viewMyTicket}&TicketSubtypeDetailId=${ticketSubtypeDetailId}`
		);
	}

	ticketSubTypeDropdown(typeId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}TicketTypes/GetSubTypeDropdown/` + typeId
		);
	}

	ticketItemDropdown(typeId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}TicketItems/GetItemDropdown/` + typeId
		);
	}

	usageForm(usageId: number) {
		return this._http.get<CustomForm>(
			`${environment.apiBaseUrl}SRForm/GetFormByFormUsageId/` + usageId
		);
	}

	getTicketChildCompanies() {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren?access=${ParentChildAccessType.Ticket}`;
		return this._http.get<NameId[]>(url);
	}
	initChildCompanies(parentCompany: NameId) {
		if (this._childCompanies$.value.length <= 0) {
			this.getTicketChildCompanies().subscribe((v) => {
				if (v.length) v.unshift(parentCompany), this._childCompanies$.next(v);
			});
		}
	}

	getContacts(ticketId: number) {
		const url = `${environment.apiBaseUrl}Tickets/GetContacts/${ticketId}`;
		return this._http.get<TicketContacts[]>(url);
	}

	export(options: ITickets, exportType: ApplicationType) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			exportType,
		} as any;

		if (options.typeIds) {
			param.TypeId = options.typeIds;
		}

		if (options.subtypeDetailId) {
			param.subtypeDetailId = options.subtypeDetailId;
		}

		if (options.itemDetailId) {
			param.itemDetailId = options.itemDetailId;
		}

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusId = options.statusIds.join(',');
		}

		if (
			options.priorityIds !== undefined &&
			options.priorityIds !== null &&
			options.priorityIds?.length > 0
		) {
			param.PriorityId = options.priorityIds.join(',');
		}

		return this._http.get(`${environment.apiBaseUrl}Tickets/Export`, {
			params: param,
			responseType: 'text',
		});
	}

	getAISummary(id: number) {
		return this._http.get(
			`${environment.apiBaseUrl}tickets/getAISummary/${id}`
		);
	}

	disabledAISummary(id: number) {
		return this._http.post(
			`${environment.apiBaseUrl}tickets/disableAISummary/${id}`,
			{}
		);
	}

	private createFormData(body: any) {
		let fd = new FormData();
		let fileArray = [];

		// fd.append('file', fileArray,body.file.name);

		fd.append('Id', body.Id);
		fd.append('TicketId', body.TicketId);
		fd.append('UserId', body.UserId);
		fd.append('Note', body.Note);
		return fd;
	}

	private newTicketFormData<T>(ticket: any) {
		const fd = new FormData();
		for (const [key, value] of Object.entries(ticket)) {
			if (value instanceof Array) {
				value.forEach((v) => fd.append(key, v));
			} else if (value instanceof Object) fd.append(key, JSON.stringify(value));
			else fd.append(key, value as any);
		}
		return fd;
	}

	#setRoutes() {
		const url = this._router.url;
		this.#baseRoute =
			this._router.parseUrl(url).root.children['primary'].segments[0].path;
	}

	get newTicketLink() {
		return {
			url: [`${this.#baseRoute}/service-and-support/report-problem`],
			params: { u: this.getUsageId() },
		};
	}

	/**setters and getters */
	get baseRoute() {
		return this.#baseRoute;
	}
	set isViewMyticket(isViewMyTicket: boolean) {
		this._state.next({ ...this._state.value, isViewMyTicket });
	}
	get isViewMyticket() {
		return this._state.value.isViewMyTicket;
	}

	get isLoading$() {
		return this._state.pipe(map((v) => v.isLoading));
	}
	get isLoading() {
		return this._state.value.isLoading;
	}
	set isLoading(isLoading: boolean) {
		this._state.next({ ...this._state.value, isLoading });
	}
}

interface TicketServiceState {
	isViewMyTicket: boolean;
	ticketBoardId: number | null;
	isLoading: boolean;
}
