<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <div>
            <h5 class="mb-0">
              Ticket # <strong>{{ ticketNumber }}</strong>
            </h5>
          </div>
        </div>

        <div class="ms-auto my-auto mt-lg-0 mt-4">
          <div class="ms-auto my-auto">
            <a
              id="mapper_pdfClose"
              (click)="onClickClose()"
              class="btn-2 btn-2-secondary">
              Close
            </a>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pb-0 pt-0">
        <div>
          <div class="pdf-container" *ngIf="pdfSrc">
            <object
              [data]="pdfSrc"
              type="application/pdf"
              width="100%"
              height="700px">
            </object>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
