export const environment = {
	production: false,
	redirectUri: 'https://elevatedev.sourcepasselevate.com/',
	logoutRedirectUri: 'https://elevatedev.sourcepasselevate.com/logout',
	apiBaseUrl: 'https://elevatedevapi.sourcepasselevate.com/api/', //'https://elevatedevapi.azurewebsites.net/api/',
	apiNotificationHub:
		'https://elevatedevapi.sourcepasselevate.com/hubs/notifications', //'https://elevatedevapi.azurewebsites.net/hubs/notifications',
	blobStorageUrl: 'https://elevatedevblob.blob.core.windows.net/',
	xApikey: '35bc0bb5-4a2a-4eb1-a841-1813f4380b13',
	tagKey: 'AP-JHDCDHFZU119-2-4',
	isDevMode: false,
	stripe:
		'pk_test_51N2pNEHnmsSwYo7LxLH6Q8kR2ygc1ceYVDfGjTz6YtPRwOP1kaldx4xFdideDS34s6qTwRMktV79gEOfP2v6LuK800JDf0MLyD',
	construction: {
		interval: 1,
	},
	fruits: {
		code: 'ag71ApLnc6l8MIjX',
		username: 'devaccounts@sourcepass.com',
	},
	isAutoLogin: true,
	iosAppLink: 'https://sourcepass.page.link/quest',
	androidAppLink:
		'https://play.google.com/store/apps/details?id=com.quest.quest',
	versionNo: '1.4.8',
	ssat: {
		link: 'Si9vydmsthQr1GRMMe2U7BcjtxyUdX75PY4M1BdMY2g',
	},
};
