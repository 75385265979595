import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { catchError, of } from 'rxjs';

import { environment } from 'environments/environment';

@Component({
	selector: 'quest-icon',
	templateUrl: './quest-icon.component.html',
	styleUrls: ['./quest-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestIconComponent implements OnChanges {
	@Input() name!: string;
	@Input() isForBrokenIconReplacement: boolean = false; /* Use this in case the icon in the "/FigmaIcons" is broken */
	isLoaded = false;
	blobUrl = environment.blobStorageUrl;

	constructor(
		private _matIconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer,
		private _cd: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes['name']) return;

		this.isLoaded = false;

		if (!this._matIconRegistry['_svgIconConfigs'].has(`:${this.name}`)) {
			this._matIconRegistry.addSvgIcon(
				this.name,
				this._domSanitizer.bypassSecurityTrustResourceUrl(`${environment.blobStorageUrl}sharedimages/FigmaIcons/${this.name}.svg`)
			);
		}

		this._matIconRegistry
			.getNamedSvgIcon(this.name)
			.pipe(catchError((e) => of(false)))
			.subscribe((i) => {
				if (i) (this.isLoaded = true), this._cd.detectChanges();
			});
	}
}
