<div
	class="text-input q-d-flex q-g-8 q-ai-center q-text"
	(click)="input.focus()"
>
	<input
		[ngModel]="value"
		(ngModelChange)="setValue($event)"
		[disabled]="disabled"
		[placeholder]="placeholder"
		[maxlength]="maxlength"
		(blur)="onTouched()"
		[type]="type"
		class="font-b1 font-h-normal"
		#input
	/>

	<quest-icon name="alert-triangle"></quest-icon>
</div>
