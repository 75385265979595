<div class="q-d-flex q-g-16 q-ai-center">
	<button
		[matMenuTriggerFor]="options"
		class="btn-2 btn-2-tertiary"
		id="mapper_gs-entries-per-page"
	>
		{{ size }}
		<quest-icon name="chevron-down"></quest-icon>
	</button>

	<span class="font-b3">Entries Per Page</span>
</div>

<mat-menu #options="matMenu" class="q-menu-panel q-menu-page-size">
	<button
  id="mapper_pageSizes"
		*ngFor="let s of pageSizes"
		class="q-d-flex q-ai-center font-b3"
		mat-menu-item
		(click)="pageSize = s"
	>
		{{ s }}
	</button>
</mat-menu>
