<form id="card-form" class="q-d-grid q-g-36" [formGroup]="cardForm">
  <div class="q-d-grid q-g-16">
    <div class="font-b2-5">Card Information</div>

    <div class="q-d-grid q-g-8">
      <label class="font-b1 q-mb-0 font-w-600">Card Number</label>
      <card-input 
        (onCardElementUpdate)="onCardElementUpdate($event)">
      </card-input>

      <!-- <ng-container *ngIf="hasSelectedCard">
        <div class="card-brand-logo q-d-flex q-ai-center q-g-16">
          <app-card-logos
            [branName]="cardForm.controls['brand'].value">
          </app-card-logos>

          <div class="font-b1">****</div>
          <div class="font-b1">{{ cardForm.controls['last4'].value }}</div>
        </div>
      </ng-container> -->
    </div>

    <div class="q-d-grid q-g-8">
      <label class="font-b1 q-mb-0 font-w-600">Name on Card</label>
      <input type="text" class="form-control max-w-400" formControlName="name" placeholder="John Hankcock">
    </div>
  </div>

  <div class="q-d-grid q-g-16">
    <div class="font-b2-5">Billing Address</div>

    <div class="q-d-grid q-g-8">
      <label class="font-b1 q-mb-0 font-w-600">Address Line 1</label>
      <input type="text" class="form-control max-w-400" placeholder="1776 Liberty Lane" formControlName="address1">
    </div>

    <div class="q-d-grid q-g-8">
      <label class="font-b1 q-mb-0 font-w-600">Address Line 2 <small class="font-b2">(Optional)</small></label>
      <input type="text" class="form-control" placeholder="Suite 12" formControlName="address2">
    </div>

    <div class="q-d-flex q-g-20">
      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">City</label>
        <input type="text" class="form-control" placeholder="Boston" formControlName="city">
      </div>

      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">State/Province</label>

        <select-input
					[options]="stateOptions"
					[optionKeys]="{ value: 'abbreviation', label: 'name' }"
					inputClass="form-control"
					formControlName="state"
					placeholder="Select"
					class="input-class">
				</select-input>
      </div>
    </div>

    <div class="q-d-flex q-g-20">
      <div class="q-d-grid q-g-8 q-w-50 q-pr-8">
        <label class="font-b1 q-mb-0 font-w-600">Country</label>
        <input type="text" class="form-control" placeholder="- Select Country -" value="United States" disabled>
      </div>
    </div>
  </div>
</form>