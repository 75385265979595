export const NOURL = [
  'application',
  'service-and-support',
  'setup',
  'company',
  'support',
  'quote-and-orders',
  'finance',
  'admin',
  'my-account',
  'contact-setting',
  'message',
  'global-settings'
  // 'contacts'
]


export const URL = [

]
