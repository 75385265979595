<div id="archive-payment-arrangements" class="q-d-grid q-g-24">
	<div class="card-header-2 q-mt-24 q-ai-center q-jc-start">
		<h3 class="q-mr-auto">Archived Payment Arrangements</h3>

		<search-input
      *ngIf="isAdmin"
      [formControl]="queryString"
      (keyup)="queryChange(500)"
      (keyup.enter)="queryChange()"
      placeholder="Search Companies"
      size="small"
      [class.disabled]="tableSub && !tableSub.closed">
    </search-input>
	</div>

	<div class="table-container">
    <div class="table-wrapper scroll-v2">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="nextPayment"
        matSortDisableClear="true"
        (matSortChange)="sortChangeEvent($event)">

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </th>

          <td mat-cell *matCellDef="let row">
            <div class="q-d-flex q-ai-center q-jc-between q-g-10 q-as-stretch">
              <div class="company-name">{{ row.companyName }}</div>
              <div class="q-d-flex q-jc-end">
                <app-badge
                  [label]="getStatus(row.isCompleted && !row.isCanceled ? 2 : 1).name ?? ''"
                  [type]="getStatus(row.isCompleted && !row.isCanceled ? 2 : 1).backgroundColor ?? ''"
                  [fontColor]="getStatus(row.isCompleted && !row.isCanceled ? 2 : 1).fontColor ?? ''">
                </app-badge>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="recurringAmount">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            Recurring Amount
          </th>

          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.recurringAmount | currency }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Frequency
          </th>

          <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
        </ng-container>

        <ng-container matColumnDef="paymentsMade">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            <div class="text-end">Payments Made</div>
          </th>

          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.paymentsMade }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="dateCompleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date Complete
          </th>

          <td mat-cell *matCellDef="let row">{{ row.dateCompleted | customDate }}</td>
        </ng-container>

        <ng-container matColumnDef="startingBalance">
          <th mat-header-cell *matHeaderCellDef class="text-end">
            Starting Balance
          </th>

          <td mat-cell *matCellDef="let row">
            <div class="text-end">{{ row.startingBalance | currency }}</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns">
        </tr>

        <tr class="mat-row" *matNoDataRow>
          <table-skeleton
            *ngIf="paginator"
            [isLoading]="tableSub && !tableSub.closed"
            [colCount]="displayedColumns.length"
            [pageSize]="paginator.size">
          </table-skeleton>
        </tr>
      </table>
    </div>
  </div>

  <div class="table-paginator-container">
    <div class="paginator-group">
      <table-paginator
        [totalItems]="totalItems"
        [pageSizes]="pageSizes"
        (pageChange)="changePage()">
      </table-paginator>
    </div>

    <div class="item-per-page-group">
      <app-item-per-page 
        (sizeChange)="updateSize()"
        [totalItems]="totalItems">
      </app-item-per-page>
    </div>
  </div>
</div>