<div class="container-fluid py-4">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="add-bank-account-main">
					<div class="card-header pb-0">
						<div class="d-lg-flex">
							<div>
								<h5>Edit Bank Account</h5>
							</div>

              <app-secondary-button
                [isBackButton]="true"
                [link]="
                  isAdmin
                    ? '/billing-orders/invoices'
                    : '/billing-and-orders/invoices'
                "
              ></app-secondary-button>
						</div>
					</div>

					<div class="card-body px-0 pb-0 pt-0">
						<div *ngIf="bankDetails" class="add-bank-account-area">
							<form [formGroup]="bankForm" class="add-bank-account-form">
								<div class="form-group">
									<h6>Bank Account Information</h6>
									<div class="row">
										<div class="col-lg-5 col-md-6 me-auto">
											<div class="account-form-label">
												<label>Bank Name</label>
												<div class="d-flex align-items-center">
													<div>
														<app-figma-icon-img
															[iconName]="'bank'"
														></app-figma-icon-img>
													</div>

													<div class="bank-name font-b1">
														{{ bankDetails.bankName }}
													</div>
												</div>
											</div>
											<div class="account-form-label">
												<label for="account-holder-type" class="required"
													>Account Holder Type</label
												>

												<select
													id="account-holder-type"
													class="form-control imp-enabled"
													formControlName="accountHolderType"
												>
													<option disabled selected value>
														- Select Type -
													</option>
													<option value="individual">Individual</option>
													<option value="company">Company</option>
												</select>
											</div>
											<div class="account-form-label">
												<label for="account-holder-name" class="required"
													>Account Holder's Name</label
												>
												<input
													type="text"
													id="account-holder-name"
													class="form-control imp-enabled"
													placeholder="John Hancock"
													formControlName="accountHolderName"
													onfocus="focused(this)"
													onfocusout="defocused(this)"
													errorTooltip
													[etControl]="bankForm.get('accountHolderName')!"
													[etIsTouched]="
														bankForm.get('accountHolderName')!.touched
													"
													[etShow]="
														bankForm.get('accountHolderName')!.touched &&
														!!bankForm.get('accountHolderName')!.errors
													"
													[etMessage]="[
														{
															error: 'required',
															message: 'Please enter name on account.'
														}
													]"
												/>
											</div>
											<div class="account-form-label email-form">
												<label for="email">Email</label>
												<input
													type="text"
													id="email"
													class="form-control bank-form imp-enabled"
													placeholder="john.hancock@yopmail.com"
													formControlName="email"
													onfocus="focused(this)"
													onfocusout="defocused(this)"
												/>
											</div>
										</div>

										<div class="col-lg-10 col-md-12 mx-auto">
											<ul
												class="list-unstyled d-flex d-inline-flex mt-5 w-100 justify-content-end"
											>
												<li>
													<a
                            id="mapper_EditBank"
														href="javascript:void(0);"
														class="submit-btn btn-2 btn-2-primary"
														(click)="updateBankAccount()"
													>
														<span class="d-flex">
                              <app-figma-icon-img
																[iconName]="'check-circle'"
																[isForButton]="false"
                                [hasSpaceOnRight]="false"
															></app-figma-icon-img>
                            </span>
														Update Account
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
