<div id="payment-method-section" class="q-grayed-box q-d-grid q-g-24">
  <form [formGroup]="paymentMethodForm" class="method-container q-d-flex q-jc-center q-ai-start q-g-24 q-as-stretch col-group">
    <div class="q-d-flex q-p-24 q-f-column q-ai-center q-g-24 q-f-1-0-0">
      <span class="font-b2">Use an existing payment method on file</span>

      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">Bank Accounts</label>

        <select-input
					[options]="bankOption"
					[optionKeys]="{ value: 'id', label: 'name' }"
					inputClass="form-control"
					formControlName="selectedExistingBankId"
					placeholder="- Select Account -"
					class="input-class"
          (ngModelChange)="retrieveFromExistingBank()">
				</select-input>
      </div>

      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1 q-mb-0 font-w-600">Credit/Debit Cards</label>

        <select-input
					[options]="cardOption"
					[optionKeys]="{ value: 'id', label: 'name' }"
					inputClass="form-control"
					formControlName="selectedExistingCardId"
					placeholder="- Select Card -"
					class="input-class"
          (ngModelChange)="retrieveFromExistingCards()">
				</select-input>
      </div>
    </div>

    <div class="font-b2-5 q-d-flex q-f-column q-jc-center q-as-stretch">OR</div>

    <div class="q-d-flex q-p-24 q-f-column q-ai-center q-g-24 q-f-1-0-0">
      <span class="font-b2">Enter a new Payment Method Manually</span>
      
      <div class="q-d-grid q-g-24 q-w-100">
        <p class="font-b2-5">PAYMENT METHOD</p>

        <radio-input
          [id]="'bank-account'"
          [iconName]="'bank'"
          [label]="'Bank Account'"
          [name]="'paymentOptions'"
          [value]="methodType.BANK"
          [formControl]="selectedMethod"
          (ngModelChange)="paymentMethodChange()"
          [isDisabled]="isSubmitted">
        </radio-input>

        <radio-input
          [id]="'credit-debit-card'"
          [iconName]="'credit-card-02'"
          [label]="'Credit/Debit Card'"
          [name]="'paymentOptions'"
          [value]="methodType.CARD"
          [formControl]="selectedMethod"
          (ngModelChange)="paymentMethodChange()"
          [isDisabled]="isSubmitted">
        </radio-input>
      </div>
    </div>
  </form>

  <bank-form 
    [hidden]="selectedMethod.value !== methodType.BANK || paymentMethodForm.controls['selectedExistingBankId'].value">
  </bank-form>

  <card-form 
    [hidden]="selectedMethod.value !== methodType.CARD || paymentMethodForm.controls['selectedExistingCardId'].value">
  </card-form>
</div>