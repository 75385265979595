// Save the svg file in assets/images/icons-v2 and put the name here
// Use like a material icon
// Ex: <mat-icon svgIcon="dots-vertical-02" style="stroke: red; width: 20px; height: 20px;"></mat-icon>
// Set stroke color, width, and height using css or inline style

export const MAT_SVG_ICONS = [
	'globe-02',
	'check-done-01',
	'thumbs-up-blue',
	'thumbs-down-blue',
	'thumbs-up-green',
	'thumbs-down-red',
	'thumbs-up-gray',
	'thumbs-down-gray',
	'square', // fallback icon
	'expand-01',
	'repeat-01',
	'annotation-check',
	'user-circle',
	'x-close',
	'search-sm',
	'dots-vertical',
	'dots-vertical-02',
	'checkbox',
	'checkbox-indeterminate',
	'checkbox-checked',
	'loading-01',
	'file-plus-02',
	'chevron-left',
	'chevron-right',
	'chevron-up',
	'chevron-down',
	'plus',
	'minus',
	'file-04',
	'list',
	'eye',
	'check-circle',
	'alert-triangle',
	'info-circle',
	'trash-01',
	'building-06',
	'globe-04',
	'users-plus',
	'currency-dollar-circle',
	'grid-01',
	'book-open-02',
	'file-02',
	'arrow-circle-broken-up',
	'archive',
	'pause-circle',
	'play-circle',
	'telescope',
	'tag-01',
	'shopping-bag-01',
	'help-circle',
	'edit-02',
	'user-plus-01',
	'save-01',
	'arrow-narrow-right',
	'arrow-narrow-left',
	'copy-02',
	'user-x-02',
	'user-01',
	'log-out-03',
	'x-circle',
	'flag-01',
	'mail-01',
	'file-download-01',
	'layout-alt-04',
	'hourglass-01',
	'layer-single',
	'layers-two-02',
	'layers-three-02',
	'zap',
	'tool-02',
	'align-right-01',
	'align-left-01',
	'tool-01',
	'power-02',
	'phone',
	'check-verified-02',
	'settings-01',
	'lock-01',
	'lock-unlocked-01',
	'check',
	'dataflow-04',
	'arrow-narrow-up',
	'download-02',
	'check-circle-broken',
	'refresh-cw-05',
	'play-square',
	'paperclip',
	'message-question-square',
	'key-01',
	'calendar-date',
	'clock',
	'mail-04',
	'arrow-square-left',
	'arrow-square-right',
	'stars-02',
	'user-right-01',
	'link-external-01',
	'message-chat-circle',
	'thumbs-up',
	'thumbs-down',
	'bell-03',
	'alert-octagon',
	'edit-05',
	'checkbox-disabled',
	'reverse-left',
	'reverse-right',
	'send-01',
	'heading-02',
	'menu-05',
	'menu-03',
	'image-01',
	'cursor-box',
	'table',
	'qr-code-01',
	'link-01',
	'divider',
	'footer',
	'flex-align-bottom',
	'tablet-02',
	'monitor-03',
	'phone-01',
	'strikethrough-01',
	'underline-01',
	'italic-01',
	'bold-01',
	'align-left',
	'align-right',
	'align-center',
	'align-justify',
	'dotpoints-01',
	'numberpoints-01',
	'left-indent-01',
	'right-indent-01',
	'file-05',
	'receipt-check',
	'message-question-circle',
	'repay',
	'stars-01',
	'refresh-cw-01',
	'sun-setting-02',
	'moon-01',
	'lightbulb-04',
	'settings-04',
	'users-01',
	'browser',
	'user-edit'
];
