import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { AiSummaryInstructionComponent } from '../ai-summary-instruction/ai-summary-instruction.component';

import { AISummaryService } from './ai-summary.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';

import { ITicket } from '../ticket.interface';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { userIdSelector } from '@app/core/store/user/user.selector';
import { take } from 'rxjs';

@Component({
	selector: 'app-ai-summary',
	templateUrl: './ai-summary.component.html',
	styleUrls: ['./ai-summary.component.scss'],
})
export class AiSummaryComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() set ticket(value: ITicket | null) {
		this._ticket = value;
		this._initSummaryList();
	}
	@Input() ticketChange: boolean;
	@Output() onCloseSummaryBox: EventEmitter<void> = new EventEmitter<void>();

	isProcessing: boolean = false;
	isShowSummaryContent: boolean = false;
	aiSummaryList: any;
	isSummaryContentUpdated: boolean = false;
	isSummaryContentProcessing: boolean = false;
	tooltipMessageForUpdateButton: string = `You can update your summary once per day. Check back later!`;
	allowRating: boolean;
	hasVoted: boolean = false;
	liked: string = 'blue';
	isLoading: boolean = false;
	doneLoading: boolean = false;

	private _ticket: ITicket | null;
	private _id: number;

	get ticket(): ITicket | null {
		return this._ticket;
	}

	constructor(
		private _datePipe: DatePipe,
		private _aiSummaryService: AISummaryService,
		private _ticketService: TicketService,
		public _route: ActivatedRoute,
		private _dialog: MatDialog
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['ticketChange']) {
			this.resetValues();
		}
	}

	resetValues() {
		this.allowRating = false;
		this.hasVoted = false;
		this.doneLoading = false;
		this.liked = 'blue';
	}

	ngOnInit(): void {
		this._id = Number(this._route.snapshot.paramMap.get('id'));
	}

	ngAfterViewInit(): void {
		this.updateSummary();
	}

	updateSummary() {
		this.isSummaryContentUpdated = false;
		this.isSummaryContentProcessing = true;

    if (this.ticket?.ticket.id) {
      this._ticketService.getAISummary(this.ticket?.ticket.id).subscribe({
        next: (result: any) => {
          this.aiSummaryList = result;
          this.allowRating = result.allowRating;
          if (result && this._id) {

            this._aiSummaryService.setTicketId(this._id);
            this.isSummaryContentProcessing = false;
            this.isSummaryContentUpdated = false;
          }
        },
        error: (err) => {
          this.isSummaryContentProcessing = false;
          this.isSummaryContentUpdated = true;
        },
      });
    }
  }

	rate(isLiked: boolean) {
		if (this.isLoading === false) {
			this.liked = isLiked ? 'green' : 'red';
			this.isLoading = true;
      this.hasVoted = true;
			this._ticketService.rateAISummary(this._id, isLiked).subscribe((data) => {

				setTimeout(() => {
					this.doneLoading = true;
				}, 500);
				setTimeout(() => {
					this.allowRating = false;
					this.isLoading = false;
				}, 2000);
			});
		}
	}

	onClickEnabledAiSummary() {
		/* For "Enable AI Summarization" button */
		if (
			this.ticket?.ticket.id &&
			!this.isProcessing &&
			!this.isShowSummaryContent
		) {
			this.isProcessing = true;
			this.isShowSummaryContent = false;

      this._ticketService.getAISummary(this.ticket?.ticket.id).subscribe({
        next: (result: any) => {
          this.aiSummaryList = result;
          this.allowRating = result.allowRating;
          if (result && this.ticket?.ticket.id) {

            this._aiSummaryService.setTicketId(this._id);
            this.isProcessing = false;
            this.isShowSummaryContent = true;
          }
        },
      });
    }

		/* For "Enabled" button */
		if (
			this.ticket?.ticket.id &&
			this.isShowSummaryContent &&
			!this.isProcessing
		) {
			this.isProcessing = true;
			this.isShowSummaryContent = false;

      this._ticketService.disabledAISummary(this.ticket?.ticket.id).subscribe({
        next: () => {
          if (this.ticket?.ticket.id) {
            this._aiSummaryService.setTicketId(this._id);
            this.isProcessing = false;
            this.isShowSummaryContent = true;
          }
        },
      });
    }
  }

	closeAiSummaryBox() {
		this.onCloseSummaryBox.emit();
	}

	openAiSummaryInstruction() {
		this._dialog.open(AiSummaryInstructionComponent, {
			disableClose: true,
			width: '686px',
			panelClass: ['ai-summary-modal'],
		});
	}

	private _initSummaryList() {
		if (this.ticket?.ticket.aiLastUpdated && this.ticket?.ticket.aiSummary) {
			const aiSummary = this.ticket?.ticket.aiSummary;
			const aiLastUpdated = this._formatDateWithSuperScript(
				this.ticket?.ticket.aiLastUpdated
			);
			const aiTime = this._datePipe.transform(
				this.ticket?.ticket.aiLastUpdated,
				'shortTime'
			);

			this.aiSummaryList = { aiSummary, aiLastUpdated, aiTime };
			this.isProcessing = false;
			this.isShowSummaryContent = !!this.aiSummaryList;

			/* Added checking here for enabled/disable update button (Will enabled this 'Update' button once the current date is greater than to aiLastUpdated)  */
			this.isSummaryContentUpdated = !(
				this._aiSummaryService.getFullDate() >
				this._aiSummaryService.getFullDate(this.ticket?.ticket.aiLastUpdated)
			);
		} else {
			this.isProcessing = false;
			this.isShowSummaryContent = false;
		}
	}

	private _formatDateWithSuperScript(dateVal: string) {
		const formattedDate: any = this._datePipe
			.transform(dateVal, 'MMM d YYYY')
			?.split(' ');
		const supValue = this._supVal(formattedDate[1]);
		return `${formattedDate[0]} ${formattedDate[1]}<sup>${supValue}</sup> ${formattedDate[2]}`;
	}

	private _supVal(d: string | undefined) {
		const last = +String(d).slice(-2);
		if (last > 3 && last < 21) return 'th';
		const remainder = last % 10;
		if (remainder === 1) return 'st';
		if (remainder === 2) return 'nd';
		if (remainder === 3) return 'rd';
		return 'th';
	}
}
