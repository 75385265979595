<ng-container *ngIf="!ticket; else hasTicket">
	<div class="loading-container">
		<img src="../../../../../../assets/images/gif/01.gif" class="img-fixed" />
	</div>
</ng-container>

<ng-template #hasTicket>
	<ng-container *ngIf="responses.length > 0; else noResponses">
		<div class="msg-btn-cont" *ngIf="showScrollTop">
			<button id="mapper_newMessageBtn" class="new-btn" (click)="scrollDown()">
				<img
					src="./../../../../../../assets/images/icons/arrow-narrow-down.svg"
					class="ng-star-inserted"
				/>
				New Message
			</button>
		</div>
		<div
			class="support-contents_container"
			#ticketContainer
			(scroll)="onScroll($event)"
		>
			<div
				class="support-contents d-flex gap-4 flex-column"
				*ngFor="let response of responses; last as isLast"
				[class.last]="userid == response.userId"
				[class.loading]="response.isLoading"
			>
				<ng-container *ngIf="response.type === 'comment'">
					<div class="support-contents__header q-g-16">
						<div class="profile">
							<quest-profile
								[isQuestIcon]="!!response.isQuest"
								[path]="response.imagePath ?? ''"
								[fullName]="!response.isQuest ? response.name ?? '' : 'Quest'"
							>
							</quest-profile>

							<div class="profile__user-name ellipsis">
								{{ !response.isQuest ? response.name : 'Quest' }}
							</div>
						</div>

						<div class="ms-auto date-time-val">
							<ng-container *ngIf="response.startDate; else lastUpdatedDate">
								<div class="date-string ellipsis">
									<!-- {{ formatDate(response.startDate) }} at
									{{ formatTime(response.startDate) }} &bull; -->
									<!-- {{
										reportApiService.convertHuman(
											dateService.getMinsFromNow(response.startDate),
											'single'
										)
									}} -->
									{{
										getFormattedDate(response.startDate)
									}}
								</div>
							</ng-container>

							<ng-template #lastUpdatedDate>
								<div class="date-string ellipsis">
									{{ getFormattedDate(response.lastUpdated, true) }}
								</div>
							</ng-template>

							<ng-container *ngIf="response.endDate">
								<div class="date-string ellipsis">
									{{ getFormattedDate(response.endDate) }}
								</div>
							</ng-container>
						</div>
					</div>

					<div
						class="text-sm pre ticket-comment"
						[innerHtml]="response.note"
					></div>
				</ng-container>

				<ng-container *ngIf="response.type === 'attachment'">
					<div class="support-contents__header q-g-16">
						<div class="profile">
							<quest-profile
								[path]="response.imagePath ?? ''"
								[fullName]="response.name"
							>
							</quest-profile>
							<div class="profile__user-name ellipsis">
								{{ response.name }}
							</div>
						</div>

						<div class="ms-auto date-time-val">
							<div class="date-string ellipsis">
								{{ getFormattedDate(response.lastUpdated) }}
							</div>
						</div>
					</div>

					<div
						class="img-container"
						*ngIf="_utilitiesService.isImageFile(response.filename)"
					>
						<a  id="download" *ngIf="!response.isLoading" (click)="downloadFile(response.id)">
							<img class="img-attachment" [src]="response.url" alt="" />
						</a>
					</div>

					<a
            id="mapper_downloadFile"
						class="d-flex align-items-start"
						*ngIf="
							!response.isLoading &&
							!_utilitiesService.isImageFile(response.filename)
						"
						(click)="downloadFile(response.id)"
					>
						<app-figma-icon-img
							class="mt-1"
							[iconName]="'file-attachment-04'"
							[isForButton]="true"
							[height]="15"
							[width]="15"
						></app-figma-icon-img>
						{{ response.filename ? response.filename : 'Unknown filename' }}
					</a>

					<span class="d-flex align-items-start" *ngIf="response.isLoading">
						<app-figma-icon-img
							[iconName]="'file-attachment-04'"
							[isForButton]="true"
							[height]="15"
							[width]="15"
						></app-figma-icon-img>
						{{ response.filename ? response.filename : 'Unknown filename' }}
					</span>
				</ng-container>

				<ng-container *ngIf="response.isLoading">
					<div class="spinner-container">
						<div class="spinner-border" role="status">
							<!-- <span class="sr-only">Loading...</span> -->
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="msg-btn-cont" *ngIf="showScrollDown">
			<button id="mapper_backToTopBtn" class="new-btn" (click)="scrollUp()">
				<img
					src="./../../../../../../assets/images/icons/arrow-narrow-up.svg"
					class="ng-star-inserted"
				/>
				Back To Top
			</button>
		</div>
	</ng-container>

	<ng-template #noResponses>
		<div class="loading-container">
			<h6>Ticket has no entry.</h6>
		</div>
	</ng-template>
</ng-template>
