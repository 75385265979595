import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { BankFormComponent } from '@app/shared/components/bank-form/bank-form.component';
import { CardFormComponent } from '@app/shared/components/card-form/card-form.component';
import { CardFormService } from '@app/shared/components/card-form/service/card-form.service';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { BankMethod, CardMethod, PaymentMethod } from '@app/shared/interfaces/invoice.interface';
import { StripeService } from '@app/shared/services/stripe.service';
import { Subject, takeUntil } from 'rxjs';
import { PaymentArrangementsService } from '../../payment-arrangements/payment-arrangements.service';
import { MethodType } from './payment-method-section.interface';

@Component({
  selector: 'payment-method-section',
  templateUrl: './payment-method-section.component.html',
  styleUrls: ['./payment-method-section.component.scss']
})
export class PaymentMethodSectionComponent implements OnInit, OnDestroy {
  @ViewChild(CardFormComponent) cardFormComponent: CardFormComponent;
  @ViewChild(BankFormComponent) bankFormComponent: BankFormComponent;
  selectedMethod: FormControl = new FormControl<number>(MethodType.BANK);
  methodType = MethodType;
  bankOption: BankMethod[] = [];
  cardOption: CardMethod[] = [];
  paymentMethodForm: FormGroup;

  private _stripeService = inject(StripeService);
  private _fb = inject(FormBuilder);
  private _notifier = inject(NotificationService);
  private _cd = inject(ChangeDetectorRef);
  private _cardFormService = inject(CardFormService);
  private _route = inject(ActivatedRoute);
  private _paymentArrangementService = inject(PaymentArrangementsService);
  private _$unsubscribe: Subject<void> = new Subject<void>();

  get isSubmitted() {
    return this._paymentArrangementService.data.isSubmitted as boolean;
  }

  ngOnInit(): void {
    this._initForm();
    this.paymentMethodChange();
  }

  initPaymentMethod() {
    this._paymentArrangementService.getPaymentMethodList(Number(this._paymentArrangementService.data.companyId))
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (result) => {
        if (result) {
          this.bankOption = result.bankAccount.length ? result.bankAccount.filter(x => x.name = `${x.name} **** ${x.last4}`) : [];
          this.cardOption = result.card.length ? result.card.filter(x => x.name = `${x.name} **** ${x.last4}`) : [];

          if (this._paymentArrangementService.data.paymentMethodId) {
            this.paymentMethodForm.controls[this.selectedMethod.value === MethodType.CARD ? 'selectedExistingCardId' : 'selectedExistingBankId'].setValue(this._paymentArrangementService.data.paymentMethodId);
          }
        }

        this._cd.detectChanges();
      },
      error: () => {
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
      }
    });
  }

  private _initForm() {
    this.paymentMethodForm = this._fb.group({
      selectedExistingBankId: [''],
      selectedExistingCardId: ['']
    });
  }

  retrieveFromExistingBank() {
    if (this.paymentMethodForm.controls['selectedExistingBankId'].value) {
      this.bankFormComponent.bankForm.controls['paymentMethodId'].setValue(this.paymentMethodForm.controls['selectedExistingBankId'].value);

    } else {
      this.bankFormComponent.hasSelectedBank = false;
      this.bankFormComponent.bankForm.reset();
      this.bankFormComponent.bankForm.enable();
      this._cd.detectChanges();
    }
  }

  retrieveFromExistingCards() {
    if (this.paymentMethodForm.controls['selectedExistingCardId'].value) {
      this.cardFormComponent.cardForm.controls['paymentMethodId'].setValue(this.paymentMethodForm.controls['selectedExistingCardId'].value);

    } else {
      this.cardFormComponent.cardForm.reset();
      this.cardFormComponent.cardForm.enable();
      this._cd.detectChanges();
    }
  }

  paymentMethodChange() {
    if (this.selectedMethod.value === MethodType.CARD) {
      this.paymentMethodForm.controls['selectedExistingBankId'].disable();
      this.paymentMethodForm.controls['selectedExistingCardId'].enable();
    } else {
      this.paymentMethodForm.controls['selectedExistingBankId'].enable();
      this.paymentMethodForm.controls['selectedExistingCardId'].disable();
    }
  }

  submitExistingCard() {
    this._cardFormService.setPaymentMethodId(this.paymentMethodForm.controls['selectedExistingCardId'].value, MethodType.CARD);
  }

  submitExistingBank() {
    this._cardFormService.setPaymentMethodId(this.paymentMethodForm.controls['selectedExistingBankId'].value, MethodType.BANK);
  }

  clearForm() {
    this.paymentMethodForm.reset();
    this.selectedMethod.setValue(MethodType.BANK);
    this.cardFormComponent.clearForm();
    this.bankFormComponent.clearForm();
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
