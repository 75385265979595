<div class="card-2 q-mb-24">
	<div class="card-header-2">
		<h3 class="q-mr-auto">Manage Categories - Applications</h3>

		<search-input
			#searchTextbox
			[formControl]="search"
			placeholder="Search Categories"
			size="small"
		></search-input>

		<a
			*ngIf="isManageEnabled"
			routerLink="./add"
			class="btn-2 btn-2-primary imp-disabled-op"
			id="mapper_gs-add-category"
		>
			<mat-icon svgIcon="plus"></mat-icon> Create
		</a>
	</div>

	<app-new-table-shared
		loadingText="Fetching Categories..."
		[searchFilters]="searchFilters"
		[deleteClick]="onDelete.bind(this)"
	></app-new-table-shared>
</div>
