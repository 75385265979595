<div class="container-fluid">
	<div class="col-lg-12 mt-lg-0 mt-4">
		<div class="card p-3 ps-4">
			<div class="card-body">
				<form
					[formGroup]="form"
					fileDragDrop
					(fileDropped)="fileChange($event)"
					(ngSubmit)="onSubmit()"
				>
					<h5 class="font-weight-bolder">Bot</h5>
					<h6>Bot Details</h6>
					<div class="row">
						<div class="col-6 col-sm-6">
							<label class="required">Name</label>
							<input
								class="form-control"
								type="text"
								value=""
								placeholder="Sourcepass"
								formControlName="name"
								onfocus="focused(this)"
								onfocusout="defocused(this)"
							/>
						</div>
						<div class="col-6">
							<label class="">Image</label>
							<div class="file-upload">
								<file-input
									*ngIf="isEditEnabled"
									formControlName="image"
									fileTypes="image/*"
									(newFileList)="fileChange($event)"
								></file-input>
							</div>
							<div *ngIf="imgSrc" class="mt-3">
								<div class="img-con">
									<a
                    id="mapper_bot_add_edit"
										*ngIf="imgSrc"
										class="bin visiblity-icon imp-disabled-op delete-icon close-pos-2"
										[tooltipContentV2]="{ message: 'Delete' }"
										href="javascript:void(0)"
										(click)="deleteAttachment()"
									>
										<img
											src="../../../../../../../assets/images/icons/close-x-icon-2.svg"
											class="close-x-icon"
											alt=""
										/>
									</a>
									<img
										image-error-detection
										[component]="'kb-bot.component.html'"
										[src]="imgSrc"
										class="img-fluid imgSrc cursor-pointer"
										alt="profile-img"
										(click)="onPreviewModal()"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<label class="mt-4 required">Result Count</label>
							<select-input
								formControlName="resultCount"
								[options]="resultC"
								[optionKeys]="{ value: 'id', label: 'name' }"
								inputClass="form-control"
							>
							</select-input>
						</div>
					</div>

					<div class="row">
						<h6 style="margin-top: 20px">Bot Responses</h6>
						<div class="col-6">
							<label class="required">Greetings</label>
							<input
								formControlName="greetings"
								type="text"
								class="form-control"
							/>
						</div>
						<div class="col-6">
							<label class="required">Initial Result</label>
							<input
								formControlName="initialResults"
								type="text"
								class="form-control"
							/>
						</div>
						<div class="col-6">
							<label class="mt-4 required">Additional Result</label>
							<input
								formControlName="additionalResults"
								type="text"
								class="form-control"
							/>
						</div>
						<div class="col-6">
							<label class="mt-4 required">Invalid</label>
							<input
								formControlName="invalid"
								type="text"
								class="form-control"
							/>
						</div>
					</div>
					<div class="row">
						<div class="submit-buttons q-d-flex q-jc-end q-g-16">
							<!--add a click event / submit functionality -->
							<button
              id="mapper_botSave"
								type="submit"
								*ngIf="isEditEnabled"
								[disabled]="spinner.spinner | async"
								class="btn-2 btn-2-primary imp-disabled-op"
							>
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<app-audit-trail
			*ngIf="isAuditTrailEnabled"
			[id]="chatBotId"
			[url]="historyUrl.KB_BOT"
		></app-audit-trail>
	</div>
</div>

<app-attachment-preview-modal
	[show]="previewModal.show"
	[data]="previewModal.data"
	[typeFile]="previewModal.type"
	(close)="onClosePreviewModal()"
></app-attachment-preview-modal>
