<div class="messages-sidebar" [class.opened]="(messageOptions$ | async)?.isOpen">
	<div class="messages-header">
		<div class="m-title">
			<h3>Messages</h3>
			<div class="m-close font-btn" (click)="onMessageClick()">
				Close <mat-icon svgIcon="x-close"></mat-icon>
			</div>
		</div>
		<button
    id="mapper_markAll"
			[disabled]="impersonating || !messages || !messages.length"
			(click)="clearAllNotifications()"
			class="btn-2 btn-2-link no-s-padding">
				Mark All as Read
		</button>
	</div>

	<div class="messages">
		<ng-container *ngIf="!impersonating && !loading && messages?.length">
			<div class="message-list scroll-v2">
				<div *ngFor="let message of messages; trackBy: messageListTrackBy; let i = index" class="message" (click)="open(message, i)">
					<div class="m-from">
						<img *ngIf="message.imagePath" [src]="message.imagePath" (error)="message.imagePath = ''">
						<app-figma-icon-img
							*ngIf="!message.imagePath && message.categoryId"
							[iconName]="messageCategories?.[message.categoryId-1]?.iconName || 'message-notification-square'"
							[hasSpaceOnRight]="false">
						</app-figma-icon-img>
						<span class="font-b1 font-w-500 ellipsis">
							{{
								message.catedByName ||
								message.createdByEmail ||
								messageCategories?.[message.categoryId-1]?.name ||
								message.title
							}}
						</span>
					</div>

					<div class="m-content">
						<div class="m-content-wrapper">
							<span class="font-b1 ellipsis">{{message.title}}</span>
							<span class="font-b3 font-w-400 ellipsis">{{message.message}}</span>
						</div>
						<div *ngIf="message.count" class="m-count font-h4">{{message.count}}</div>
					</div>

					<span class="font-b3 font-w-400">{{message.createdDate | customDate: true}}</span>
				</div>
			</div>

			<div class="message-scrollbar"></div>
		</ng-container>

		<mat-icon *ngIf="!impersonating && loading" class="m-loading loading-v2" svgIcon="loading-01"></mat-icon>

		<div *ngIf="impersonating || (!loading && !messages?.length)" class="no-messages">
			<img src="../../../../../assets/images/others/no-new-messages.png" alt="no new messages">
			<h4>You’re all caught up!</h4>
			<span class="font-b2">You have no new messages.</span>
		</div>
	</div>
</div>

<div class="messages-backdrop" [class.display]="(messageOptions$ | async)?.isOpen" (click)="onMessageClick()"></div>
