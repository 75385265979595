<Section class="invoice-billing-sec">
	<div class="container-fluid">
		<div class="invoice-inner">
			<div class="row">
				<div class="col-lg-12">
					<div class="invoice-right-block">
						<div class="invoice-right-inner">
							<div class="invoice-title">
								<h4>Quest</h4>
							</div>

							<div class="invoice-table-main-area">
								<div class="invoice-table-top-area">
									<div class="invoice-list-title">
										<h3>List of Client Admin</h3>
									</div>
									<div class="addclient-btn-area">
										<a
                      id="mapper_clientAdmin"
											(click)="openClientAddDialog()"
											href="javascript:void(0)"
											class="addclient-btn d-flex align-items-center"
										>
											<app-figma-icon-img
												[iconName]="'plus'"
												[isForButton]="true"
											></app-figma-icon-img>
											Add new client admin
										</a>
									</div>
								</div>

								<div class="invoice-billing-table">
									<table>
										<thead>
											<tr>
												<th>Name</th>
												<th>Title</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody *ngIf="clientData.length > 0; else noRecords">
											<ng-container *ngFor="let row of clientData">
												<tr>
													<td>{{ row.firstName + ' ' + row.lastName }}</td>
													<td>{{ row.title }}</td>
													<td>
														<div class="invoice-action-btns">
															<a
                              id="mapper_openAdminList"
																(click)="openInvoiceDialog(row.id)"
																href="javascript:void(0)"
																class="invoice-edit-btn edit"
															>
																<app-figma-icon-img
																	[iconName]="'plus'"
																	[isForButton]="true"
																	[hasSpaceOnRight]="false"
																></app-figma-icon-img>
															</a>
															<a
                              id="mapper_adminListDelete"
																(click)="onDelete(row)"
																href="javascript:void(0)"
																class="invoice-delete-btn bin"
															>
																<img
																	src="../../../../../assets/images/icons/edit-icon.svg"
																	alt=""
																/>
															</a>
														</div>
													</td>
												</tr>
											</ng-container>
										</tbody>

										<ng-template #noRecords>
											<tbody>
												<tr>
													<td colspan="3" style="text-align: center">
														{{ noRecordsMessage }}
													</td>
												</tr>
											</tbody>
										</ng-template>
									</table>

									<div class="table-paginator-container" [hidden]="!hasData">
										<div class="paginator-group">
											<table-paginator
												[totalItems]="totalItems"
												[pageSizes]="pageSizes"
												(pageChange)="getCompanyListing('page')"
												class="mb-5"
											></table-paginator>
										</div>

										<div class="item-per-page-group">
											<app-item-per-page
												(sizeChange)="getCompanyListing('size')"
												[totalItems]="totalItems"
											></app-item-per-page>
										</div>
									</div>
								</div>

								<div class="invoice-copyrihgt">
									<p>
										Copyright &copy;
										<a id="mapper_clientAdminSourcepass" href="javascript:void(0)"
											>Sourcepass inc.</a
										>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</Section>
