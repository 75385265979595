<div
	aria-labelledby="swal2-title"
	aria-describedby="swal2-html-container"
	class="swal2-popup swal2-modal swal2-icon-warning swal2-show d-grid"
	tabindex="-1"
	role="dialog"
	aria-live="assertive"
	aria-modal="true"
>
	<mat-dialog-content>
		<div class="e-container">
			<div
				class="swal2-icon swal2-warning swal2-icon-show"
				style="display: flex"
			>
				<div class="swal2-icon-content">!</div>
			</div>
			<div class="message-container">
				<h2 class="swal2-title d-block" id="swal2-title">
					{{ data.header }}
				</h2>
				<div
					class="swal2-html-container d-block"
					[innerHTML]="data.message"
				></div>
				<p></p>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="swal2-actions">
			<div class="swal2-loader"></div>
			<button
      id="mapper_yesCloseModal"
				type="button"
				class="btn-2 btn-2-destructive d-flex align-items-center"
				aria-label=""
				(click)="close('yes')"
			>
				<!-- {{ data.options.accept }} -->
				<app-figma-icon-img
					[iconName]="'trash-01'"
					[isForButton]="true"
				></app-figma-icon-img>
				{{ data.yesName ? data.yesName : 'Delete' }}
			</button>
			<button
      id="mapper_noCloseModal"
				type="button"
				class="btn-2 btn-2-link d-inline-block"
				aria-label=""
				(click)="close('no')"
			>
				<!-- {{ data.options.cancel }} -->
				Cancel
			</button>
		</div>
	</mat-dialog-actions>
</div>
