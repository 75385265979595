<div class="card-2 q-mb-24">
	<div class="card-header-2 q-ai-center q-jc-start">
		<h3 class="q-mr-auto">Set Up Payment Arrangement</h3>

		<a class="btn-2 btn-2-secondary" routerLink="/billing-orders/payment-arrangements">
			<mat-icon svgIcon="chevron-left"></mat-icon>
			Back
		</a>
	</div>

	<mat-accordion class="q-expansion-panel">
		<mat-expansion-panel
			[expanded]="step === 0"
			(opened)="setStep(0)"
			hideToggle
			(expandedChange)="onPanelChange()">

			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>1.  Company Details</h3>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="q-d-grid q-g-16">
				<company-details-form
					(onClearAllFormSection)="clearAllFormSection()">
				</company-details-form>

				<div class="q-d-flex q-g-8 q-jc-end q-w-100">
					<button
          id="mapper_step2"
						*ngIf="companyDetailsFormComponent"
						type="button"
						class="btn-2 btn-2-primary"
						(click)="nextStep()"
						[disabled]="paymentArrangementService.data.isSubmitted">
						Next
					</button>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel
			[expanded]="step === 1"
			(opened)="setStep(1)"
			hideToggle
			(expandedChange)="onPanelChange()"
			[disabled]="!paymentArrangementService.data.companyId">

			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>2.  Payment Method</h3>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="q-d-grid q-g-16">
				<payment-method-section></payment-method-section>

				<div class="q-d-flex q-g-8 q-jc-end q-w-100">
					<button
            id="mapper_step3Card"
						*ngIf="paymentMethodSectionComponent?.selectedMethod?.value === methodType.CARD"
						type="button"
						class="btn-2 btn-2-primary"
						(click)="nextStep()"
						[disabled]="paymentArrangementService.data.isSubmitted">
						Next
					</button>

					<button
            id="mapper_step3Bank"
						*ngIf="paymentMethodSectionComponent?.selectedMethod?.value === methodType.BANK"
						type="button"
						class="btn-2 btn-2-primary"
						(click)="nextStep()"
						[disabled]="paymentArrangementService.data.isSubmitted">
						Next
					</button>
				</div>
			</div>
		</mat-expansion-panel>

    <mat-expansion-panel
			[expanded]="step === 2"
			(opened)="setStep(2)"
			hideToggle
			(expandedChange)="onPanelChange()"
			[disabled]="!paymentArrangementService.data.companyId || !paymentArrangementService.data.paymentMethodId">

			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>3.  Invoice Assignment</h3>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="q-d-grid q-g-16">
				<invoice-assignment-section></invoice-assignment-section>

				<div class="q-d-flex q-g-8 q-jc-end q-w-100">
					<button
            id="mapper_step4"
						*ngIf="invoiceAssignementComponent"
						type="button"
						class="btn-2 btn-2-primary"
						(click)="nextStep()"
						[disabled]="paymentArrangementService.data.isSubmitted">
						Next
					</button>
				</div>
			</div>
		</mat-expansion-panel>

    <mat-expansion-panel
			[expanded]="step === 3"
			(opened)="setStep(3)"
			hideToggle
			(expandedChange)="onPanelChange()"
			[disabled]="!paymentArrangementService.data.companyId || !paymentArrangementService.data.paymentMethodId || !paymentArrangementService.data.invoiceAssignment?.length"
			>

			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>4.  Payments</h3>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="q-d-grid q-g-16">
				<payment-section (onUpdateContent)="updateContent()"></payment-section>

				<div class="q-d-flex q-g-8 q-jc-end q-w-100">
					<button
            id="mapper_step5"
						type="button"
						class="btn-2 btn-2-secondary"
						(click)="this.isSubmitted = false; nextStep();">
						<quest-icon name="save-01"></quest-icon>
						Save Draft & Exit
					</button>

					<button
            id="mapper_finalstep"
						type="button"
						class="btn-2 btn-2-primary"
						(click)="this.isSubmitted = true; nextStep();">
						<quest-icon name="check"></quest-icon>
						Submit
					</button>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
