<div
	#searchContRef
	class="search-bar-v2"
	[class.search-bar-v2-sm]="size == 'small'"
	[class.search-bar-v2-lg]="size == 'large'"
	(click)="focus()"
	[class.is-disabled]="disabled"
	[class.with-dropdown]="isDropdownOpen"
	[style.width]="width + 'px'"
	cdkOverlayOrigin
	#trigger="cdkOverlayOrigin"
	id="mapper_gs-search-bar"
>
	<mat-icon svgIcon="search-sm"></mat-icon>
	<input
		[ngModel]="value"
		(ngModelChange)="onModelChange($event)"
		[disabled]="disabled"
		[maxlength]="maxlength"
		[placeholder]="placeholder"
		[ngClass]="{
			'font-b1': size == 'large' || fontSize == 'medium',
			'font-b2 font-w-600': size != 'large' && fontSize != 'medium'
		}"
		class="search-box-v2 font-h-normal"
		#searchRef
		autocomplete="off"
		spellcheck="false"
		type="text"
	/>
	<span *ngIf="message" class="font-b3 ss-complete">{{ message }}</span>
	<mat-icon
		*ngIf="withClose"
		(click)="onModelChange('')"
		svgIcon="x-close"
		class="x-close"
	></mat-icon>
	<mat-icon
		*ngIf="withDropdown"
		[svgIcon]="isDropdownOpen ? 'chevron-up' : 'chevron-down'"
		class="chevron"
	></mat-icon>
</div>

<ng-template
	*ngIf="withDropdown"
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="trigger"
	[cdkConnectedOverlayOpen]="isDropdownOpen"
	[cdkConnectedOverlayHasBackdrop]="isDropdownOpen"
	cdkConnectedOverlayBackdropClass="transparent"
	[cdkConnectedOverlayWidth]="searchContRef.offsetWidth"
	(backdropClick)="closeDropdown()"
>
	<div
		class="input-overlay"
		[class.overlay-sm]="size == 'small'"
		[class.overlay-lg]="size == 'large'"
		(click)="searchRef.focus()"
	></div>

	<div class="search-overlay q-shadow-1" (click)="searchRef.focus()">
		<ng-content></ng-content>
	</div>
</ng-template>
