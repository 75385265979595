<a
	href="javascript:void(0)"
	tabindex="-1"
	mat-dialog-close
	class="editclient-popup-close close"
  id="mapper_saClose"
>
	<span aria-hidden="true">
		<app-figma-icon-img
			[iconName]="'x'"
			[isForButton]="true"
			[hasSpaceOnRight]="false"
		></app-figma-icon-img>
	</span>
</a>

<h5 mat-dialog-title>Select user</h5>

<div mat-dialog-content>
	<label for="">Employee: </label>
	<select-input
		[(ngModel)]="selectedUser"
		(ngModelChange)="userChange($event)"
		[options]="users"
		inputClass="form-control"
		[optionKeys]="{ value: 'id', label: 'name' }"
	>
	</select-input>
</div>

<div mat-dialog-actions>
	<button id="mapper_addClient" (click)="saveUser()">Add as client admin</button>
</div>
