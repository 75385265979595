import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { BootstrapTooltipModule } from '../directives/bootstrap/bootstrap-tooltip.module';
import { BadgeModule } from '../components/badges/badge/badge.module';
import { ConvertedDateModule } from '../pipes/converted-date/converted-date.module';
import { CrossCheckModule } from '../components/cross-check/cross-check.module';
import { CustomDatePipeModule } from '../pipes/custom-date.pipe.module';
import { DragDropCustomModule } from '../directives/drag-drop.module';
import { FigmaIconImgModule } from './../figma-icon-img/figma-icon-img.module';
import { LocalFigmaIconImgModule } from '../local-figma-icon-img/local-figma-icon-img.module';
import { ParentChildInputSelectorModule } from '../components/parent-child-input-selector/parent-child-input-selector.module';
import { QuickFilterModule } from '../components/quick-filter/quick-filter.module';
import { WidgetLoadingModule } from '../components/widget-loading/widget-loading.module';
import { CustomTooltipV2Module } from '../directives/custom-tooltip-v2/custom-tooltip-v2.module';
import { TooltipContentV2Module } from '../directives/tooltip-content-v2/tooltip-content-v2.module';
import { QuestIconModule } from '../components/quest-icon/quest-icon.module';
import { QuestProfileModule } from '../components/quest-profile/quest-profile.module';

import { ItemPerPageComponent } from '../components/paginator/item-per-page/item-per-page.component';
import { NewTableSharedComponent } from './new-table-shared.component';
import { OnlineOfflineComponent } from '../components/online-offline/online-offline.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { TableSkeletonComponent } from '../components/table-skeleton/table-skeleton.component';

@NgModule({
	declarations: [
		ItemPerPageComponent,
		NewTableSharedComponent,
		OnlineOfflineComponent,
		PaginatorComponent,
	],
	imports: [
		BadgeModule,
		BootstrapTooltipModule,
		CommonModule,
		ConvertedDateModule,
		CrossCheckModule,
		CustomDatePipeModule,
		CustomTooltipV2Module,
		DragDropCustomModule,
		FigmaIconImgModule,
		FormsModule,
		LocalFigmaIconImgModule,
		MatSortModule,
		MatTableModule,
		ParentChildInputSelectorModule,
		QuickFilterModule,
		RouterModule,
		ReactiveFormsModule,
		WidgetLoadingModule,
		TooltipContentV2Module,
		MatMenuModule,
		TableSkeletonComponent,
		QuestIconModule,
		QuestProfileModule,
	],
	exports: [
		ItemPerPageComponent,
		NewTableSharedComponent,
		PaginatorComponent,
		OnlineOfflineComponent,
	],
})
export class NewTableSharedModule {}
