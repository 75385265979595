import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, inject, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CardValidationCode, CardValidationMessages } from '@app/modules/finance/validation-messages/invoice-validation-messages.inum';
import { StripePublishable } from '@app/shared/interfaces/invoice.interface';
import { StripeService } from '@app/shared/services/stripe.service';
import { Subject, takeUntil } from 'rxjs';

declare let Stripe: any;

@Component({
  selector: 'card-input',
  templateUrl: './card-input.component.html',
  styleUrls: ['./card-input.component.scss']
})

export class CardInputComponent implements AfterViewInit, OnDestroy {
  @Output() onCardElementUpdate: EventEmitter<any> = new EventEmitter<any>();

  isValidCardNumber: FormControl<any> = new FormControl<any>(null, [Validators.required]);
  isLoadingCardInput: boolean = true;
  paymentElement: any;
  cardNumberValidationMessage: string = '';
  controlForm: FormControl<any> = new FormControl<any>(null);
	stripe: any;
	elements: any;

  private _stripeService = inject(StripeService);
  private _cd = inject(ChangeDetectorRef);
	private _$unsubscribe: Subject<void> = new Subject<void>();

  constructor() { }

  get hasInvalidClassName() {
		return (document as any).getElementById('payment-element').classList.contains('StripeElement--invalid');
	}

  ngAfterViewInit(): void {
    setTimeout(() => this._initStripeSandBox(), 100);
  }

  private _initStripeSandBox() {
		this.isLoadingCardInput = true;

		this._stripeService.getStripePublishableKey()
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (result: StripePublishable) => {
				this.stripe = Stripe(result.publishableKey);

				const options = {
					mode: 'setup',
					currency: 'usd',
					paymentMethodCreation: 'manual',
					/* Fully customizable with appearance API. */
					appearance: { theme: 'stripe' },
				};

				/* Set up Stripe.js and Elements to use in checkout form */
				this.elements = this.stripe.elements(options);

				/* Create and mount the Payment Element */
				const paymentElementOptions = {
					layout: 'tabs',
					defaultBillingDetails: {
						address: {
							country: 'US',
						},
						email: '',
					},
					fields: {
						billingDetails: {
							address: {
								country: 'never',
								postalCode: 'never',
							},
							email: 'never',
						},
					},
					style: {
						base: {
							iconColor: '#5C5D60',
							color: '#5C5D60',
							fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif, Metropolis',
							fontSize: '15px',
							':-webkit-autofill': {
								color: '#AFB0B3',
							},
							'::placeholder': {
								color: '#AFB0B3',
							},
						},
						invalid: {
							iconColor: '#E96458',
							color: '#E96458',
						},
					},
				};

				this.paymentElement = this.elements.create('card', paymentElementOptions);
				setTimeout(() => this.paymentElement.mount('#payment-element'), 0);
			},
			error: () => {
				this.isLoadingCardInput = false;
        this._cd.detectChanges();
			},
			complete: () => {
				this._cardNumberValidation();
				this.isLoadingCardInput = false;
        this._cd.detectChanges();
			},
		});
	}

  private _cardNumberValidation() {
		this.paymentElement.on('blur', (event: any) => this.stripe.createToken(this.paymentElement, {}));
		
		this.paymentElement.on('change', (event: any) => {
      // console.log(event, 'change event')
			if (event.error !== undefined) {
				this.isValidCardNumber?.markAsTouched();
				this.cardNumberValidationMessage = event.error.message;

				switch(event.error.code) {
					case CardValidationCode.IncompleteNumberCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidCardNumber;
						break;

					case CardValidationCode.IncompleteExpiryCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidExpiryMonthYear;
						break;

					case CardValidationCode.IncompleteCvcCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidSecurityCode;
						break;

					case CardValidationCode.IncompleteZipCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidPostalCode;
				}

			} else {
				this.isValidCardNumber?.markAsUntouched();
			}

      this.onCardElementUpdate.emit({ element: this.paymentElement, complete: event.complete, empty: event.empty });
      this._cd.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
