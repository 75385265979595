<div *ngIf="isAllowed" class="card">
	<div class="card-header" [ngClass]="[isAuditClicked ? 'pb-0' : '']">
		<div class="d-lg-flex">
			<div>
				<h5
					class="mb-0"
					(click)="auditClick()"
					data-bs-toggle="collapse"
					aria-controls="audit-container"
					href="#"
				>
					<a
            id="mapper_auditTrail"
						data-bs-toggle="collapse"
						href="#audit-container"
						aria-controls="audit-container"
						role="button"
						aria-expanded="false"
						class="btn-2 btn-2-link accordion-title"
					>
						<span
							*ngIf="
								!history_category ||
								history_category == 'default' ||
								history_category == 'invoice' ||
								history_category == 'all'
							"
						>
							Audit Trail
						</span>
						<span *ngIf="history_category == 'permission'">
							Permissions Audit Trail
						</span>
						<span *ngIf="history_category == 'menupermission'">
							Menu Permissions Audit Trail
						</span>
						<mat-icon
							[svgIcon]="isAuditClicked ? 'chevron-down' : 'chevron-right'"
							style="height: 24px"
						></mat-icon>
					</a>
				</h5>
			</div>
		</div>
	</div>
	<div class="collapse hide" id="audit-container">
		<div class="card-body px-0 pb-0 pt-0">
			<div class="table-responsive directory-table">
				<div
					class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns mb-0"
				>
					<div class="dataTable-container">
						<div style="display: contents">
							<table class="audit-trail-container">
								<tbody>
									<ng-container
										*ngFor="let item of historyDataSource; let i = index"
									>
										<tr class="data">
											<td
												*ngIf="!item.isDateDuplicated"
												[attr.rowspan]="item.rowspan"
												style="
													vertical-align: baseline;
													width: 30px;
													padding: 0 2rem 0 1rem;
												"
											>
												<div class="dates">
													<span
														style="font-size: 17px !important; font-weight: 500"
														[ngStyle]="{
															'margin-top': getMarginTop(
																item.isDateDuplicated,
																i
															)
														}"
													>
														{{ getDate(formatDate(item.entryDate)) }}
													</span>

													<span>
														{{ getMonthYear(formatDate(item.entryDate)) }}
													</span>
												</div>
											</td>
											<td>
												<div
													class="updates-cont border {{ item?.class }}"
													[ngStyle]="{
														'margin-top': getMarginTop(item.isDateDuplicated, i)
													}"
												>
													<div class="updates">
														<div class="profile-pic">
															<quest-profile
																[path]="item.imagePath"
																[fullName]="item.lastName +', '+item.firstName"
															></quest-profile>
														</div>

														<div class="details-cont">
															<div class="details">
																<ng-container *ngIf="isCustomizeContent; else originalContent">
																	<ng-container *ngTemplateOutlet="customizeContent; context:{ $implicit: item, index: i }"></ng-container>
																</ng-container>

																<ng-template #originalContent>
																	<strong>
																		{{ item.firstName }} {{ item.lastName }}
																	</strong>

																	changed the field
																	<strong>{{ item.name || item.fieldNameInUI }}</strong>
																	{{ item.oldValue != '' && item.oldValue != undefined ? 'from' : ''}}
																	{{ item.oldValue }} to {{ item.newValue }}.
																</ng-template>
															</div>

															<div class="time">
																{{ formatTime(item.entryDate) }}
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>

							<div class="table-paginator-container w-100" [hidden]="!hasData">
								<div class="paginator-group">
									<table-paginator
										[totalItems]="totalItems"
										[pageSizes]="pageSizes"
										(pageChange)="getPageHistory('page', $event)"
									></table-paginator>
								</div>

								<div class="item-per-page-group">
									<app-item-per-page
										(sizeChange)="getPageHistory('size', $event)"
										[totalItems]="totalItems"
									></app-item-per-page>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
