<form class="card-2" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="card-header-2">
		<h3 class="q-mr-auto">{{ id ? 'Edit' : 'Add New' }} Category</h3>

		<app-secondary-button
			*ngIf="!companyId"
			[isBackButton]="true"
			[link]="stateAction === 'add' ? '../' : '../../'"
		></app-secondary-button>
	</div>

	<div class="form-group-2">
		<label>Category Name*</label>
		<input
			type="text"
			formControlName="name"
			class="form-control"
			maxlength="100"
		/>
	</div>

	<div class="form-group-2">
		<label>Icon*</label>
		<icon-input formControlName="iconName"></icon-input>
	</div>

	<div class="form-group-2">
		<label>Icon Color</label>
		<input
			type="color"
			formControlName="color"
			class="form-control q-pointer"
		/>
	</div>

	<div class="actions-wrapper">
		<button
			id="mapper_catSave"
			type="submit"
			class="btn-2 btn-2-primary imp-disabled-op"
		>
			Save
		</button>
	</div>
</form>

<app-audit-trail
	*ngIf="isAuditTrailEnabled && stateAction === 'edit'"
	[id]="id"
	[url]="historyUrl.APPLICATION_CATEGORY"
></app-audit-trail>
