<div class="ai-summary" [class.q-ai-start]="isShowSummaryContent">
	<div class="ai-summary_left-group">
		<a
      id="mapper_enabledSummary"
			class="btn-2 btn-2-secondary q-pointer"
			(click)="onClickEnabledAiSummary()"
		>
			<ng-container *ngIf="!isProcessing && !isShowSummaryContent">
				<mat-icon svgIcon="stars-01"></mat-icon>
				Summarize
			</ng-container>

			<ng-container *ngIf="isProcessing"> . . . Generating </ng-container>

			<ng-container *ngIf="isShowSummaryContent && !isProcessing">
				<mat-icon svgIcon="stars-01"></mat-icon>
				Enabled
			</ng-container>
		</a>

		<ng-container *ngIf="isShowSummaryContent">
			<div class="ai-summary_date-time-container">
				<div class="ai-summary_last-update-label font-b3">Last Updated:</div>

        <div class="ai-summary_date-time-group">
          <div class="ai-summary_date font-b3">
            <div
              [innerHtml]="
                aiSummaryList.aiLastUpdated ? aiSummaryList.aiLastUpdated : ''
              "
            ></div>
          </div>

					<div class="ai-summary_time font-b3">
						{{ aiSummaryList.aiTime }}
					</div>
				</div>
			</div>

			<!-- <div class="ai-summary_update-btn">
        <div *ngIf="isSummaryContentUpdated" class="ai-summary_tooltip-msg" [tooltipContentV2]="{ message: tooltipMessageForUpdateButton }"></div>

        <app-tertiary-button
          (click)="onClickUpdate()"
          [btnText]="isSummaryContentUpdated ? 'Updated' : isSummaryContentProcessing ? '. . .' : 'Update'"
          [iconName]="!isSummaryContentProcessing ? 'refresh-cw-01' : ''"
          [disabled]="isSummaryContentUpdated">
        </app-tertiary-button>
      </div> -->
		</ng-container>
	</div>

	<div class="ai-summary_right-group">
		<ng-container *ngIf="!isShowSummaryContent && !isProcessing">
			<div class="q-d-flex q-ai-center q-w-100">
				<div class="ai-summary_content-sm font-b1 q-w-100">
					Get a brief summary of the conversation in seconds
				</div>

				<a
          id="mapper_learnMore"
					href="javascript:void(0)"
					class="accordion-btn-pos btn-2 btn-2-link"
					(click)="openAiSummaryInstruction()"
				>
					Learn More
				</a>
			</div>
		</ng-container>

		<ng-container *ngIf="isShowSummaryContent">
			<div class="q-d-flex q-w-100">
				<div class="ai-summary_summary-label font-b2 q-w-100">Summary:</div>

				<a id="mapper_closeAiSummary" href="javascript:void(0)" (click)="closeAiSummaryBox()">
					<app-figma-icon-img
						class="q-pointer"
						[iconName]="'x-close'"
						[hasSpaceOnRight]="false"
					>
					</app-figma-icon-img>
				</a>
			</div>

			<div
				class="ai-summary_actual-content font-b1"
				style="margin-bottom: 16px"
			>
				{{ aiSummaryList.aiSummary }}
			</div>

			<div class="ai-footer" *ngIf="allowRating" [class.animate]="doneLoading">
				<div class="ai-left">
					<div class="ai-comment" *ngIf="!hasVoted" [class.fade-out]="hasVoted">
						This summary is AI-generated. It's designed to assist, but may not
						always be perfect.
					</div>
				</div>

				<div class="ai-right">
					<div class="ai-desc" [class.fade-in]="hasVoted">
						{{ hasVoted ? 'Thanks for your feedback!' : 'Was it helpful?' }}
					</div>
					<div class="ai-like-dislike">
						<a
							href="javascript:void(0);"
							id="mapper_aiSummaryLike"
							[class.is-liked]="liked === 'green'"
							[class.faded]="liked === 'red'"
							[class.unclickable]="isLoading"
							(click)="rate(true)"
						>
							<mat-icon
								[svgIcon]="
									liked === 'blue'
										? 'thumbs-up-blue'
										: liked === 'green'
										? 'thumbs-up-green'
										: liked === 'red'
										? 'thumbs-up-gray'
										: 'thumbs-up-blue'
								"
							></mat-icon>
						</a>
						<a
							href="javascript:void(0);"
							class="dislike"
							id="mapper_aiSummaryDislike"
							[class.is-liked]="liked === 'red'"
							[class.faded]="liked === 'green'"
							[class.unclickable]="isLoading"
							(click)="rate(false)"
						>
							<mat-icon
								[svgIcon]="
									liked === 'blue'
										? 'thumbs-down-blue'
										: liked === 'green'
										? 'thumbs-down-gray'
										: liked === 'red'
										? 'thumbs-down-red'
										: 'thumbs-down-blue'
								"
							></mat-icon>
						</a>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
