<a
	class="mapper_sidemenu nav-link sub-menu-link collapsed"
	data-bs-toggle="collapse"
	[href]="'#navDashboardS' + index + menuId"
	[attr.aria-controls]="'navDashboardS' + index + menuId"
	role="button"
	#sidebarEl
>
	{{ sideMenuData.name }}
	<div
		class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
	>
		<ng-container
			*ngIf="_sideBarService.checkImageUrl(sideMenuData.icon); else faIcon"
		>
			<img
				image-error-detection
				[component]="'sub-menu-accordion.component.html'"
				[src]="_sideBarService.transformIconUrl(sideMenuData.icon)"
				class="img-fluid"
				alt="menuicon"
			/>
		</ng-container>

		<ng-template #faIcon>
			<app-figma-icon-img
				[iconName]="sideMenuData.icon"
				[hasSpaceOnRight]="false"
				class="text-sm opacity-10"
			></app-figma-icon-img>
		</ng-template>
	</div>

	<!-- <i class="bi small bi-caret-down-fill"></i> -->

	<app-filter-chevron
		class="chevron"
		[isCollapsed]="sidebarEl.classList.contains('collapsed')"
		[hasSpaceOnLeft]="false"
	></app-filter-chevron>
</a>

<ul
	*ngIf="sideMenuData.subMenus !== null && sideMenuData.subMenus.length > 0"
	[id]="'navDashboardS' + index + menuId"
	class="submenu collapse"
>
	<li
		*ngFor="let secondMenu of sideMenuData.subMenus"
		[routerLink]="
			secondMenu.isCustomRoute
				? './' + secondMenu.url + '/' + this.id
				: [secondMenu.url]
		"
		[ngClass]="{
			'nav-item has-submenu':
				secondMenu.subMenus !== null &&
				secondMenu.subMenus.length > 0 &&
				secondMenu.id !== 185 &&
				secondMenu.id !== 26
		}"
		(click)="selectMenu(secondMenu.id)"
	>
		<a
			class="mapper_navMenu nav-link test-here"
			data-bs-toggle="collapse"
			[href]="'#navDashboardS' + index + secondMenu.id"
			[attr.aria-controls]="'navDashboardS' + index + secondMenu.id"
			role="button"
			[ngClass]="{ active: activeMenu === secondMenu.id }"
		>
			<app-figma-icon-img
				[iconName]="secondMenu.icon"
				[hasSpaceOnRight]="false"
				class="text-sm opacity-10"
			></app-figma-icon-img>
			{{ secondMenu.name }}
		</a>

		<ul
			[id]="'navDashboardS' + index + secondMenu.id"
			*ngIf="
				secondMenu.subMenus !== null &&
				secondMenu.subMenus.length > 0 &&
				secondMenu.id !== 185 &&
				secondMenu.id !== 26
			"
			class="submenu collapse show"
		>
			<li *ngFor="let item of secondMenu.subMenus" (click)="goTo(item.url)">
				<a
        class="mapper_submenuAcc nav-link" routerLinkActive="router-link-active">
					<app-figma-icon-img
						[iconName]="item.icon"
						[hasSpaceOnRight]="false"
						class="text-sm opacity-10"
					></app-figma-icon-img>
					{{ item.name }}
				</a>
			</li>
		</ul>
	</li>
</ul>
