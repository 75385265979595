import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'payment-arrangements',
  templateUrl: './payment-arrangements.component.html',
  styleUrls: ['./payment-arrangements.component.scss']
})

export class PaymentArrangementsComponent implements OnInit {
  navLinks: any[];
  activeLinkIndex = -1;

  private _router = inject(Router);

  ngOnInit(): void {
    this.navLinks = [
      {
          label: 'Active',
          link: './active-payment-arrangements',
          index: 0
      }, {
          label: 'Archived',
          link: './archived-payment-arrangements',
          index: 1
      } 
    ];

    this._router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this._router.url));
    });
  }

  onChangeTab(tabNum: number) {
		
	}
}
