import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuPermissionGuard } from '@app/core/guards/menu-permission.guard';

import { PermissionGuard } from '@app/core/guards/permission.guard';
import { UserTypeGuard } from '@app/core/guards/user-type.guard';

import { MenuPermissionConstant, Permission, UserTypes } from '@app/shared/constants';
import { invoiceRoutes } from '../finance/finance-routing.module';
import { ActivePaymentArrangementsComponent } from '../finance/pages/active-payment-arrangements/active-payment-arrangements.component';
import { ArchivedPaymentArrangementsComponent } from '../finance/pages/archived-payment-arrangements/archived-payment-arrangements.component';
import { MainPaymentArrangementsComponent } from '../finance/pages/main-payment-arrangements/main-payment-arrangements.component';
import { PaymentArrangementsComponent } from '../finance/pages/payment-arrangements/payment-arrangements.component';
import { SetupPaymentArrangementsComponent } from '../finance/pages/setup-payment-arrangements/setup-payment-arrangements.component';
import { ViewArrangementDetailsComponent } from '../finance/pages/view-arrangement-details/view-arrangement-details.component';

import { OrdersDetailComponent } from './pages/orders-detail/orders-detail.component';
import { OrdersInquiryComponent } from './pages/orders-inquiry/orders-inquiry.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
const routes: Routes = [
  {
    path: 'quotes',
    component: QuotesComponent,
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    data: {
      title: 'Quotes',
      userTypes: [UserTypes.ClientAdmin, UserTypes.User,UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.QuoteAndOrders.subMenus!['quotes'],
    },
  },
  {
    path: 'orders',
    canActivate: [MenuPermissionGuard],
    data: {
      title: 'Orders',
      hasUrl: true,
      userTypes: [
        UserTypes.ClientAdmin,
        UserTypes.User,
        UserTypes.SourcepassAdmin],
        id: MenuPermissionConstant.QuoteAndOrders.subMenus!['orders'],
    },
    children: [
      { path: '', component: OrdersComponent, canActivate: [UserTypeGuard] },
      {
        path: ':id',
        component: OrdersDetailComponent,
        data: {
          title: 'Order Details'
        },
      },
      {
        path: ':id/inquiry',
        component: OrdersInquiryComponent,
        data: {
          title: 'Order Inquiries'
        },
      },
    ],
  },
  { path: 'invoices',  canActivate: [UserTypeGuard, MenuPermissionGuard],
  data: {
    title: 'Invoices',
    hasUrl: true,
    userTypes: [
      UserTypes.ClientAdmin,
      UserTypes.User,
      UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.QuoteAndOrders.subMenus!['invoices'],
  },
  children: invoiceRoutes
},
  {
    path: 'payment-arrangements',
    component: MainPaymentArrangementsComponent,
    data: {
      title: 'Payment Arrangements',
      userTypes: [
        UserTypes.ClientAdmin,
        UserTypes.User,
        UserTypes.SourcepassAdmin
      ],
      id: MenuPermissionConstant.QuoteAndOrders.subMenus!['arrangements'],
    },
    // canActivate: [UserTypes.ClientAdmin, UserTypes.User ],
    children: [
      { 
        path: '', 
        redirectTo: 'active-payment-arrangements',
        pathMatch: 'full'
      },
      {
        path: '',
        component: PaymentArrangementsComponent,
        children: [
          { 
            path: '', 
            redirectTo: 'active-payment-arrangements',
            pathMatch: 'full'
          },
          {
            path: 'active-payment-arrangements',
            component: ActivePaymentArrangementsComponent,
          },
          {
            path: 'archived-payment-arrangements',
            component: ArchivedPaymentArrangementsComponent,
          }
        ]
      },
      {
        path: 'view-arrangement-details/:id',
        component: ViewArrangementDetailsComponent,
        data: {
          title: 'View Arrangement Details'
        }
      },
      {
        path: 'setup-payment-arrangement',
        children: [
          { 
            path: '', 
            redirectTo: 'add',
            pathMatch: 'full'
          },
          {
            path: 'add',
            component: SetupPaymentArrangementsComponent,
            data: {
              title: 'Set Up Payment Arrangement'
            }
          },
          {
            path: 'edit/:id',
            component: SetupPaymentArrangementsComponent,
            data: {
              title: 'Set Up Payment Arrangement'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuotesAndOrdersRoutingModule {}
