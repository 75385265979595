import { ActivatedRoute } from '@angular/router';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { SortDirection } from '@angular/material/sort';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Observable,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { KnowledgeBaseService } from '../../knowledge-base.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@services/spinner.service';
import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';

import {
	NotificationMessages,
	Permission,
	UserTypes,
} from '@app/shared/constants';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IKnowledgeBase } from '../kb-topic-list/store/kb-topic.interface';
import { KBCategory } from '@app/shared/interfaces/knowledge-base.interface';

import { companyKbCategoryUpdateAction } from './store/company/category-list.company.actions';
import { globalKbCategoryUpdateAction } from './store/global/category-list.actions';

import { companyKbCategorySelector } from './store/company/category-list.company.selector';
import { globalKbCategorySelector } from './store/global/category-list.selector';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss'],
})
export class KBCategoryListComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit
{
	@ViewChild('searchTextbox') searchTextbox: ElementRef;
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	companyId: number | null;
	roleId = this._userService.user?.roleId;

	isManageEnabled = this._userService.hasPermission([
		Permission.CompanyAdminKbCategoryAddEdit,
		Permission.SpAdminKbCategoryAddEdit,
	]);
	isViewable = this._userService.hasPermission([
		Permission.SpAdminKbCategoryView,
		Permission.CompanyAdminKbCategoryView,
	]);

	storeSelector: (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase>;
	storeAction: any;
	kbCategoryData: any;

	searchFilters: IKnowledgeBase;
	column: string;
	order: SortDirection;
	page: number;
	pageSize: number;
	search = new FormControl('');

	private _subscription = new Subscription();

	constructor(
		public spinner: SpinnerService,
		public override _companiesService: CompaniesService,
		public override _route: ActivatedRoute,
		private _breadcrumbService: BreadcrumbService,
		private _cd: ChangeDetectorRef,
		private _dataModalService: DataModalService,
		private _kbService: KnowledgeBaseService,
		private _notifier: NotificationService,
		private _store: Store<AppStateInterface>,
		private _toastMessageService: ToastMessageService,
		private _userService: UserService
	) {
		super(_companiesService, _route);

		if (this._route.snapshot.paramMap.get('companyId')) {
			if (this._userService.user?.roleId === UserTypes.SourcepassAdmin) {
				this.companyId = Number(
					this._route?.snapshot?.paramMap.get('companyId')
				);
			}
		}
	}

	get globalKBCategory(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(globalKbCategorySelector);
	}

	get globalKBCategoryUpdate() {
		return globalKbCategoryUpdateAction;
	}

	get companyKBCategory(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(companyKbCategorySelector);
	}

	get companyKBCategoryUpdate() {
		return companyKbCategoryUpdateAction;
	}

	ngOnInit() {
		this.getCompanyIdFromParams();
		this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
			this._companiesService.subCompanyId!
		);
		this._cd.detectChanges();
	}

	ngAfterViewInit() {
		if (!this.companyId) {
			this.storeSelector = this.globalKBCategory;
			this.storeAction = this.globalKBCategoryUpdate;
		} else {
			this.storeSelector = this.companyKBCategory;
			this.storeAction = this.companyKBCategoryUpdate;
		}

		this._loadPreviousFilters();
		this._searchSubscription();
	}

	fetchNewData() {
		if (!this.isViewable) return;

		const filters: IKnowledgeBase = this._getFilters();
		this.searchFilters = filters;
	}

	onDelete(app: KBCategory) {
		const data = this._dataModalService.getDeleteModel(
			'KB Category',
			app.name.trim()
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this.spinner.start();
					this._kbService.deleteCategory(app.id).subscribe({
						next: () => {
							this._kbService.allowToFetchNewData = true;
							this.fetchNewData();
							this.spinner.stop();
						},
						error: () => {
							this.spinner.stop();
							this._notifier.notifyError(
								NotificationMessages.unable('Delete Category'),
								NotificationMessages.Try
							);
						},
						complete: () => {
							this._toastMessageService.showSuccessMessage(
								NotificationMessages.delete('Category')
							);
						},
					});
				}
			},
		});
	}

	private _loadPreviousFilters() {
		this._store.pipe(this.storeSelector, take(1)).subscribe((filter) => {
			this.kbCategoryData = filter;
			this.search.setValue(this.kbCategoryData.query!, {
				emitEvent: false,
			});
			this.page = filter.page;
			this.pageSize = filter.pageSize;
			this.column = filter.column;
			this.order = filter.order;
			this.searchFilters = this._getFilters();
		});
	}

	private _getFilters(): IKnowledgeBase {
		const filter: IKnowledgeBase = {
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.page,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.pageSize,
			order: this.order,
			column: this.column,
			query: this.search.value!.trim(),
			companyId: this.companyId ? this.companyId : null,
			userRole: this._userService.userRole,
		};

		return filter;
	}

	private _searchSubscription() {
		const inputEvent = this.search.valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged()
		);

		const enterEvent = fromEvent(
			this.searchTextbox.nativeElement as HTMLInputElement,
			'keyup'
		).pipe(
			filter((e) => (e as KeyboardEvent).keyCode === 13),
			map((e) => (e.target as HTMLInputElement).value)
		);

		this._subscription.add(
			merge(inputEvent, enterEvent)
				.pipe(
					distinctUntilChanged(),
					switchMap(async (query) => {
						this.page = 1;
						this.fetchNewData();

						return query;
					})
				)
				.subscribe()
		);

		this._subscription.add(
			this._kbService.isLoading$
				.pipe(
					tap((isLoading) => {
						if (isLoading) {
							this.search.disable();
						} else {
							if (this.search.disabled) {
								this.search.enable();
							}
							(this.searchTextbox.nativeElement as HTMLInputElement).focus();
						}
					})
				)
				.subscribe()
		);
	}
}
