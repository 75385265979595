/* Angular Libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/* Third Party Libraries */
import { BehaviorSubject, Observable, of } from 'rxjs';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Interfaces */
import { ApplicationType, UserTypes } from '@app/shared/constants';
import { InvoiceParameters, InvoiceWidgets } from '@app/shared/interfaces/finance.interface';
import { AccountActivityList, AccountActivityStatusDropdown, CreditTableList, InvoiceActivity, InvoiceSummary, PaymentTableList } from '@app/shared/interfaces/invoice.interface';

/* Environment */
import { NameId } from '@app/shared/interfaces/support.interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  /* Properties */
  searchText = "";
  invoiceSearchtext = '';
  statusId?: number[] | null = [];
  typeIds?: number[] | null = [];
  companyIds?: number[] | null = [];
  autopay?:boolean | null;
  transactionType?: string | null;
  pdf$ = new BehaviorSubject<any>(null);
  private _prevCompanyId = 0;

  /* Constructor */
  constructor(private _http: HttpClient, private _userService: UserService) { }

  /* Getters/Setters */
  get isAdmin() {
		return this._userService.userRole === UserTypes.SourcepassAdmin;
	}

	get currentCompanyId() {
		return this._userService.companyId;
	}

	get selectedCompanyId() {
		return JSON.parse(localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any)?.companyId;
	}

  get companyIdType() {
		return this.isAdmin ? this.selectedCompanyId : this.currentCompanyId === this.selectedCompanyId ? null : this.selectedCompanyId;
	}

  get isSetFirstCompanyId(){return !!this._prevCompanyId}
  setFirstCompanyId(){this._prevCompanyId = this._userService.companyId}
  isCompanyIdChanged = ()=> {
    let changed = false;
    const companyId = this.selectedCompanyId;
    if(companyId != this._prevCompanyId){
      changed = true;
      this._prevCompanyId = companyId;
    }
    return changed
  }

  resetSearchCompanyDropdownForInvoice() {
    const setCompanyNull = JSON.stringify({ companyId: null, companyName: null });
    localStorage.setItem('companyAdmin', setCompanyNull);
    localStorage.setItem('companyUser', setCompanyNull);
  }

  /* Methods */
  clearSearch(){
    this.searchText = "";
    this.statusId = [];
  }

  getInvoices<TResult = Object>(options: InvoiceParameters) {
    var param = {
      Page: options.page,
      PageSize: options.pageSize,
      Search: options.query,
      Column: options.sort,
      Order: options.order,
      InvoiceStartDate: options.startDate ? options.startDate : '',
      InvoiceEndDate: options.endDate ? options.endDate : '',
      // DueStartDate: options.dueStartDate ? options.dueStartDate : '',
      // DueEndDate: options.dueEndDate ? options.dueEndDate : '',
      CompanyIds: options.companyIds,
      HaveBalance: options.haveBalance,
      companyId: options.companyId

    } as any;

    if (options.statusIds !== undefined && options.statusIds !== null && options.statusIds?.length > 0) {
      param.StatusIds = options.statusIds;
    }

    if (options.typeIds !== undefined && options.typeIds !== null && options.typeIds?.length > 0) {
      param.TypeIds = options.typeIds;
    }

    if (options.companyIds !== undefined && options.companyIds !== null) {
      param.CompanyIds = options.companyIds;
    }

    if (options.companyId !== undefined && options.companyId !== null) {
      param.companyId = options.companyId;
    } else {
      delete param.companyId;
    }

    if(options.isAutopay === true || options.isAutopay === false){
      param.isAutopay = options.isAutopay
    }

    if(options.transactionType === 'CustCred' || options.transactionType === 'CustInvc' || options.transactionType === 'CustPymt'){
      param.transactionType = options.transactionType
    }

    return this._http.get<TResult>(`${environment.apiBaseUrl}Invoices/Invoices`, { params: param });
  }

  getCreditTable(
		page: number,
		pageSize: number,
		column: string,
		order: string,
    invoiceStartDate: string,
    invoiceEndDate: string,
    transactionType: string,
		searchText: string,
	): Observable<CreditTableList> {
		const param: any = this.getValidParameters({
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase(),
      InvoiceStartDate: invoiceStartDate,
      InvoiceEndDate: invoiceEndDate,
      TransactionType: transactionType,
			companyId: this.companyIdType,
      Search: searchText
		});

		return this._http.get<CreditTableList>(
			`${environment.apiBaseUrl}Invoices/Credits`,
			{ params: param }
		);
	}

  getPaymentsTable(
		page: number,
		pageSize: number,
		column: string,
		order: string,
    paymentStartDate: string,
    paymentEndDate: string,
    searchText: string,
	): Observable<PaymentTableList> {
		const param: any = this.getValidParameters({
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase(),
      PaymentStartDate: paymentStartDate,
      PaymentEndDate: paymentEndDate,
      Search: searchText,
			companyId: this.companyIdType
		});

		return this._http.get<PaymentTableList>(
			`${environment.apiBaseUrl}Invoices/Payments`,
			{ params: param }
		);
	}

  getAccountActivity(
		page: number,
		pageSize: number,
		column: string,
		order: string,
    activityStartDate: string,
    activityEndDate: string,
    status: any,
    searchText: string,
	): Observable<AccountActivityList> {
		const param: any = this.getValidParameters({
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase(),
			Search: searchText,
      ActivityStartDate: activityStartDate,
      ActivityEndDate: activityEndDate,
      Status: status,
			companyId: this.companyIdType
		});

		return this._http.get<AccountActivityList>(
			`${environment.apiBaseUrl}Invoices/AccountActivities`,
			{ params: param }
		);
	}

  getInvoiceById(id: number) {
    const param: any = this.getValidParameters({ companyId: this.companyIdType});

    return this._http.get(
      `${environment.apiBaseUrl}invoices/${id}`, { params: param }
    );
  }

  getActivity(id: number): Observable<InvoiceActivity[]> {
    const param: any = this.getValidParameters({ companyId: this.companyIdType});

    return this._http.get<InvoiceActivity[]>(
      `${environment.apiBaseUrl}Invoices/GetActivity/${id}`, { params: param }
    );
  }

  getInvoiceStatusDropdown() {
    return this._http.get(
      `${environment.apiBaseUrl}invoices/GetInvoiceStatusDropdown`
    );
  }

  getInvoiceTypes() {
    return this._http.get(
      `${environment.apiBaseUrl}InvoiceTypes/GetDropdown`
    );
  }

  getAutopayDropdown(){
    return of([
      {
        val:true,
        label: 'On'
      },
      {
        val:false,
        label: 'Off'
      }
    ])
  }

  getTransactionTypesList() {
    return of([
      {
          id: null,
          name: "All"
      },
      {
          id: 'CustCred',
          name: "Credit Memo"
      },
      {
          id: 'CustInvc',
          name: "Invoice"
      },
      {
        id: 'CustPymt',
        name: "Payment"
      }
    ]);
  }

  accountActivityStatus(): Observable<AccountActivityStatusDropdown[]> {
    return of([
      {
          value: 'success',
          display: 'Success',
          selected: false
      },
      {
          value: 'error',
          display: 'Error',
          selected: false
      }
    ]);
  }

  downloadInvoice(id: number){
    // const param: any = this.getValidParameters({ companyId: this.companyIdType});
    return this._http.get(`${environment.apiBaseUrl}CW_Invoices/PrintInvoice/${id}`, { responseType: 'blob' });
  }

  downloadInvoice2(id: number){
    // const param: any = this.getValidParameters({ companyId: this.companyIdType});
    return this._http.get(`${environment.apiBaseUrl}CW_Invoices/PrintInvoice/${id}`, { responseType: 'text' });
  }

  downloadReceiptByTransaction(ids: any[]){
    const param: any = this.getValidParameters({ 
      invoiceReceiptId: ids.filter(x => !isNaN(x)),
      transactionId: ids.filter(x => isNaN(x))
    });
    
    return this._http.get(`${environment.apiBaseUrl}Invoices/GetReceiptByTransaction`, { responseType: 'text', params: param });
  }

  downloadReceiptByIds(invoiceId: number[]){
    const param: any = this.getValidParameters({ invoiceId });
    return this._http.get(`${environment.apiBaseUrl}Invoices/GetReceiptByInvoice`, { responseType: 'text', params: param });
  }

  downloadInvoiceStatement(params: any){
    const param: any = this.getValidParameters({
      fromDate: params.fromDate,
      toDate: params.toDate,
      companyId: this.companyIdType
    });

    return this._http.get(`${environment.apiBaseUrl}Invoices/GetPdf`, { responseType: 'text', params: param });
    // return this._http.get(`${environment.apiBaseUrl}Invoices/GetPdf?fromDate=${params.fromDate}&toDate=${params.toDate}`, { responseType: 'text' });
  }

  getInvoiceStatement(startDate: string, endDate: string, isOpenOnly: boolean = false) {
    const param: any = this.getValidParameters({
      companyId: this.companyIdType,
      InvoiceStartDate: startDate,
      InvoiceEndDate: endDate,
      IsOpenOnly: isOpenOnly
    });

    return this._http.get(
      `${environment.apiBaseUrl}Invoices/GetStatement`,
      { params: param }
    )
  }

  getInvoiceSummary(body: any) {
    const param: any = this.getValidParameters({
      companyId: this.companyIdType,
      InvoiceStartDate: body.startDate,
      InvoiceEndDate: body.endDate,
      Page: body.page,
      PageSize: body.pageSize,
      Column: body.sort,
      Order: body.order,
      HaveBalance: body.haveBalance,
    });

    return this._http.get(
      `${environment.apiBaseUrl}Invoices/GetSummary`,
      { params: param }
    )
  }

  getInvoiceDetails(options: any, id: number) {
    const param: any = this.getValidParameters({
      Page: options.page,
      PageSize: options.pageSize,
      Column: options.sort,
      Order: options.order,
      Search: options.search
    });

    const url = `${environment.apiBaseUrl}Invoices/GetProductList/${id}`;
    return this._http.get(url, { params: param });
  }

  getInvoiceDetailUsage(options: any, id: number, urlEndpoint: string) {
    const param: any = this.getValidParameters({
      id: id,
      Page: options.page,
      PageSize: options.pageSize,
      Column: options.sort,
      Order: options.order
    });

    return this._http.get(`${environment.apiBaseUrl}Invoices/${urlEndpoint}`, { params: param });
  }

  getInvoiceDetails2(body: any) {
    const param: any = this.getValidParameters({
      InvoiceStartDate: body.startDate,
      InvoiceEndDate: body.endDate,
      IsOpenOnly: body.isOpenOnly,
      companyId: this.companyIdType
    });

    // const url = `${environment.apiBaseUrl}Invoices/GetStatementDetails?InvoiceStartDate=${body.startDate}&InvoiceEndDate=${body.endDate}&IsOpenOnly=${body.isOpenOnly}`;
    const url = `${environment.apiBaseUrl}Invoices/GetStatementDetails`;
    return this._http.get(url, { params: param });
  }

  export(options: InvoiceParameters, exportType: ApplicationType) {
    const param = {
      Page: options.page,
      PageSize: options.pageSize,
      Search: options.query,
      Column: options.sort,
      Order: options.order,
      InvoiceStartDate: options.startDate ? options.startDate : '',
      InvoiceEndDate: options.endDate ? options.endDate : '',
      DueStartDate: options.dueStartDate ? options.dueStartDate : '',
      DueEndDate: options.dueEndDate ? options.dueEndDate : '',
      CompanyIds: options.companyIds,
      exportType,
      HaveBalance: options.haveBalance,
      companyId: options.companyId
    } as any;

    if (
      options.statusIds !== undefined &&
      options.statusIds !== null &&
      options.statusIds?.length > 0
    ) {
      param.StatusIds = options.statusIds;
    }

    if(options.isAutopay === true || options.isAutopay === false){
      param.isAutopay = options.isAutopay
    }

    if (options.typeIds !== undefined && options.typeIds !== null && options.typeIds?.length > 0) {
      param.TypeIds = options.typeIds;
    }

    if (options.companyId !== undefined && options.companyId !== null) {
      param.companyId = options.companyId;
    } else {
      delete param.companyId;
    }

    return this._http.get(`${environment.apiBaseUrl}Invoices/ExportInvoices`, {
      params: param,
      responseType: 'text',
    });
  }

  exportPayments(options: any) {
    const param: any = this.getValidParameters({
      Search: options.search,
      PaymentStartDate: options.paymentStartDate,
      PaymentEndDate: options.paymentEndDate,
      TransType: options.transType,
      Page: options.page,
      PageSize: options.pageSize,
      Column: options.column,
      Order: options.order,
      exportType: options.exportType,
      companyId: this.companyIdType
    });

    return this._http.get(`${environment.apiBaseUrl}Invoices/ExportPayments`, {
      params: param,
      responseType: 'text',
    });
  }

  exportAccountActivities(options: any) {
    const param: any = this.getValidParameters({
      Search: options.search,
      ActivityStartDate: options.activityStartDate,
      ActivityEndDate: options.activityEndDate,
      Status: options.status,
      Page: options.page,
      PageSize: options.pageSize,
      Column: options.column,
      Order: options.order,
      exportType: options.exportType,
      companyId: this.companyIdType
    });

    return this._http.get(`${environment.apiBaseUrl}Invoices/ExportAccountActivities`, {
      params: param,
      responseType: 'text',
    });
  }

  getWidgetDetails(): Observable<InvoiceWidgets> {
    const param: any = this.getValidParameters({ companyId: this.companyIdType});
    return this._http.get<InvoiceWidgets>(`${environment.apiBaseUrl}Invoices/GetWidgetDetails`, { params: param });
  }

  getForPayment(ids: any): Observable<Array<InvoiceSummary>> {
    const param: any = this.getValidParameters({
      id: ids,
      companyId: this.companyIdType
    });

    return this._http.get<Array<InvoiceSummary>>(`${environment.apiBaseUrl}Invoices/ForPayment`, { params: param });
  }

  updateIsPrinted(id: number, isPrinted: boolean) {
    return this._http.put(
      `${environment.apiBaseUrl}Invoices/UpdateIsPrinted/${id}/${isPrinted}`, isPrinted
    );
  }

  sendWithClaimForm(id: number) {
    return this._http.post(
      `${environment.apiBaseUrl}Invoices/SendWithClaimForm/${id}/`, id
    );
  }

  getValidParameters(param: any) {
		return Object.fromEntries(Object.entries(param).filter(([key, value]: any) => !['', null, undefined].includes(value)));
	}

  getInvoiceChildCompanies() {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren`;
		return this._http.get<NameId[]>(url);
	}
}
