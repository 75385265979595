<modal-v2>
	<ng-template #modalV2Header>
		<div class="sms-confirmation_group">
			<div class="sms-confirmation_group_icon">
				<app-local-figma-icon-img
					[filename]="'info-circle-blue'"
					[width]="24"
					[height]="24"
					[hasSpaceOnRight]="false">
				</app-local-figma-icon-img>
			</div>

			<div class="sms-confirmation_group_title font-h3">
				Needs Mobile Verification
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Body>
		<div class="sms-confirmation_body font-b1">
			<p>Oops! Looks like you need a verified mobile phone number to access this custom request form.</p>
			<p>In order to proceed, we’ll need you to verify your mobile phone in your profile - don’t worry, it’s quick & easy. It’ll save you time and grant you access to other automated forms as well.</p>
			<p>Or if you prefer, you can just submit a basic version of this request form.</p>
		</div>
	</ng-template>

	<ng-template #modalV2Footer>
		<button id="mapper_smsSubmit" type="submit" class="btn-2 btn-2-tertiary" (click)="onSubmitGeneralItRequest()">
			Submit Basic Request
		</button>

		<button id="mapper_smsVerify" type="submit" class="btn-2 btn-2-primary" (click)="onClickRedirectToSmsVerification()">
			Verify Mobile Phone
		</button>
	</ng-template>
</modal-v2>
