import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { NotificationMessages, TableMessages } from '@app/shared/constants';
import { TransactionStatus } from '@app/shared/constants/transaction-type.enum';
import { of, Subject, Subscription, takeUntil } from 'rxjs';
import { IInvoice, IInvoiceAssignment } from '../../payment-arrangements/payment-arrangements.interface';
import { PaymentArrangementsService } from '../../payment-arrangements/payment-arrangements.service';

@Component({
  selector: 'invoice-assignment-section',
  templateUrl: './invoice-assignment-section.component.html',
  styleUrls: ['./invoice-assignment-section.component.scss']
})
export class InvoiceAssignmentSectionComponent implements OnInit, OnDestroy {
  dataList: IInvoice[] = [];
	dataSource: MatTableDataSource<any> = new MatTableDataSource();
	selectedSortColumn: string = 'invoiceDate';
	selectedSortType: string = 'desc';
	isCheckedAll: boolean = false;
	displayedColumns = [
    'id',
		'invoiceNumber',
		'invoiceDate',
		'dueDate',
		'billAmount',
		'currentBalance',
		'status',
    'isAdded'
	];
	message = { noRecord: TableMessages.EmptyTable };
  outstandingInvoices: IInvoice[] = [];
	selectedInvoices: IInvoice[] = [];
	tableSub: Subscription;

	paymentArrangementService = inject(PaymentArrangementsService);
	
	private _cd = inject(ChangeDetectorRef);
	private _notifier = inject(NotificationService);
	private _route = inject(ActivatedRoute);
	private _$unsubscribe: Subject<void> = new Subject<void>();

	get isSubmitted() {
		return this.paymentArrangementService.data.isSubmitted as boolean;
	}

	ngOnInit(): void {
	}

	initData() {
		if (this.tableSub) this.tableSub.unsubscribe();
		this.dataSource = new MatTableDataSource();

		this.tableSub = this.paymentArrangementService.getInvoiceAssignments(Number(this.paymentArrangementService.data.companyId))
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (result) => {
				this.dataList = result;
				this.dataSource = new MatTableDataSource(result);
        this._updateScroll();

				const isEdit = !!Number(this._route.snapshot.paramMap.get('id'));
				
				/* Edit mode */ 
				if (isEdit) {
					if (this.paymentArrangementService.paymentSetupForm.billingInfo?.invoices.length) {
						this.selectedInvoices = this.paymentArrangementService.paymentSetupForm.billingInfo.invoices;
						this.outstandingInvoices = this.paymentArrangementService.paymentSetupForm.billingInfo.invoices;
						this.paymentArrangementService.data.invoiceAssignment = this.paymentArrangementService.paymentSetupForm.billingInfo.invoices.map(x => { return { invoiceId: x.id, balanceDue: x.currentBalance }}) as IInvoiceAssignment[];
						this.paymentArrangementService.formData.totalPaymentAmount = this.calculateTotalAmount(this.selectedInvoices).toFixed(2);
					}
				}

				this._cd.detectChanges();
			},
			error: () => {
				this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
			}
		});
	}

  checkAllRecords(event: any) {
		if (event.target.checked) {
			this.dataList.forEach(x => (x.status === 'Outstanding') && x.currentBalance ? this.outstandingInvoices.push(x) : '');

		} else {
			this.outstandingInvoices = this.outstandingInvoices.filter(x => !this.dataList.some(removeItem => removeItem.id === x.id));
		}
	}

	onRangeSelected(event: any, option: any) {
		if (event.target.checked) {
			this.outstandingInvoices.push(option);

		} else {
			if (Array.isArray(this.outstandingInvoices) && this.outstandingInvoices.length) {
				const index = this.outstandingInvoices.findIndex(x => option?.id === x.id);
				this.outstandingInvoices.splice(index, 1);
			}
		}

		this.updatedSelectedInvoice();
	}

	updatedSelectedInvoice() {
		this.isCheckedAll = !!this.dataList.length && this.dataList.filter(x => (x.status === 'Outstanding') && x.currentBalance).map(x => x.id).every(x => this.outstandingInvoices.map(x => x.id).includes(x));
	}

	sortChangeEvent(sortEvent: any) {
		this.selectedSortColumn = sortEvent.active;
		this.selectedSortType = sortEvent.direction;
		this.initData();
	}

	isActiveRow(id: number) {
		return this.outstandingInvoices.some((x: any) => x.id === id);
	}

	add() {
		this.selectedInvoices = Object.assign([], this.outstandingInvoices);
		this.paymentArrangementService.formData.totalPaymentAmount = this.calculateTotalAmount(this.selectedInvoices).toFixed(2);
	}

	remove() {
		this.selectedInvoices = Object.assign([], this.selectedInvoices.filter(item => !this.outstandingInvoices.some(selected => selected.id === item.id)));
		this.paymentArrangementService.formData.totalPaymentAmount = this.calculateTotalAmount(this.selectedInvoices).toFixed(2);
	}

	calculateTotalAmount(invoice: IInvoice[]) {
		return invoice
			.map((e: any) => (e.status.toLocaleUpperCase() === TransactionStatus.outstanding) && Number(e.currentBalance))
			.reduce((a: any, b: any) => a + b, 0);
	}

	clearForm() {
		this.selectedInvoices = [];
    this.outstandingInvoices = [];
    this.dataList = [];
	}

  private _updateScroll() {
    setTimeout(() => {
      const element: any = document.querySelector('#invoice-assignment .scroll-v2');
      element.scrollHeight > element.clientHeight ? element.classList.add('q-pr-8') : element.classList.remove('q-pr-8');
    }, 0);
  }

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
