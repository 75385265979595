import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SearchInputComponent,
			multi: true,
		},
	],
})
/**
 * Use with ngModel, Form Control, and Form
 */
export class SearchInputComponent
	implements AfterViewInit, ControlValueAccessor
{
	@Input() value = '';
	@Input() placeholder = 'Search';
	@Input() size: 'small' | 'medium' | 'large' = 'medium';
	@Input() fontSize: 'small' | 'medium' = 'small';
	@Input() disabled = false;
	@Input() maxlength: number | string | null = null;
	@Input() message: string | null = null;
	@Input() withClose = false;
	@Input() withDropdown = false;
	@Input() autoFocus = true;
	@Input() width: number | null = null;

	isDropdownOpen = false;

	_onChange: any = () => {};
	_onTouched: any = () => {};

	@ViewChild('searchRef') searchRef: ElementRef;

	constructor() {}

	ngAfterViewInit(): void {
		if (this.autoFocus) this.focus();
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(value: string) {
		this.value = value;
	}

	onModelChange(e: string) {
		this.value = e;
		this._onChange(e);
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	focus() {
		setTimeout(() => {
			this.searchRef.nativeElement.focus();
		});

		if (this.withDropdown) this.isDropdownOpen = true;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}
}
