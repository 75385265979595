<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <div class="invoice-width">
            <h5 class="mb-0">Invoice Statement</h5>
          </div>
          <div class="ms-auto my-auto mt-lg-0 mt-4 invoice-details-page">
            <div class="ms-auto my-auto">
              <a
              id="mapper_downloadInvoice"
                href="javascript:void(0)"
                class="btn-2 btn-2-primary export mb-0 mt-sm-0 mt-1 invoice-btn"
                (click)="downloadInvoiceStatement()"
              >
                Print
              </a>
              <a
              id="mapper_invoiceClose"
                href="javascript:void(0)"
                (click)="onClose()"
                class="btn-2 btn-2-secondary export mb-0 mt-sm-0 mt-1 invoice-btn"
              >
                Close
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="invoice-statement">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-4">
                <label>For the period from</label>
                <input
                  formControlName="startDate"
                  type="date"
                  class="form-control"
                  readonly
                />
              </div>
              <div class="col-4">
                <label>To</label>
                <input
                  formControlName="endDate"
                  type="date"
                  class="form-control"
                  readonly
                />
              </div>
              <div class="col-4">
                <label>Total Amount</label>
                <input
                  formControlName="billAmount"
                  type="float"
                  class="form-control"
                  readonly
                  value="$100,000.56"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <label>Company</label>
                <input
                  formControlName="companyName"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
              <div class="col-4 offset-4">
                <label>Payment/Credits</label>
                <input
                  formControlName="paymentCredit"
                  type="number"
                  class="form-control"
                  readonly
                  value="$100,000.56"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <label>Bill To</label>
                <textarea
                  formControlName="billTo"
                  class="form-control"
                  readonly
                ></textarea>
              </div>
              <div class="col-4">
                <label>Ship To</label>
                <textarea
                  formControlName="shipTo"
                  class="form-control"
                  readonly
                ></textarea>
              </div>
              <div class="col-4">
                <label>Total Amount Due</label>
                <input
                  formControlName="balance"
                  type="float"
                  class="form-control"
                  readonly
                  value="$100,000.56"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="invoice-summary mt-4">
          <h5 class="mb-0">Summary Charges</h5>
          <div class="table-responsive directory-table">
            <div
              class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
            >
              <div class="dataTable-container">
                <app-column-slider
                [(displayedColumns)]="displayedColumns"
                ></app-column-slider>
                <table
                  mat-table
                  [dataSource]="dataSource"
                  matSort
                  class="table table-flush dataTable-table"
                  matSortActive="invoiceNumber"
                  matSortDirection="asc"
                  matSortDisableClear="true"
                  (matSortChange)="sortData($event)"
                >
                  <ng-container matColumnDef="date" class="text-xs">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Invoice Date
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <!-- {{ row.invoiceDate | customDate }} -->
                      {{ row | convertedDate : 'invoiceDate' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Invoice #
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.invoiceNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="quoteRef">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Order ID
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.orderId }}</td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Subtotal
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.subtotal | currency }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Sales Tax
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.salesTax | currency }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Bill Amount
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.billAmount | currency }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="paymentCredit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Payments/Credits
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.paymentCredit | currency }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amountDue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Balance
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.balance | currency }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">
                      {{ message.noRecord }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <table-paginator
              [totalItems]="totalItems"
              [pageSizes]="pageSizes"
              (pageChange)="getInvoiceSummary($event)"
            ></table-paginator>
          </div>
        </div>
        <div class="invoice-detail">
          <h5 class="mb-0">Invoice Details</h5>
            <ng-container *ngFor="let invoice of invoiceIds">
              <app-invoice-summary-details
                [invoiceId]="invoice.id"
                [invoices]="invoice.details"
              ></app-invoice-summary-details>
            </ng-container>
   <!--        <ng-container *ngFor="let invoice of invoiceIds">
            <app-invoice-summary-details
              [invoiceId]="invoice"
            ></app-invoice-summary-details>
          </ng-container> -->
        </div>
      </div>
    </div>
  </div>
</div>
