<div id="bank-form-section" class="bank-form q-d-flex q-f-column q-ai-start q-g-16 q-as-stretch">
  <div class="font-b2-5">Bank Account Information</div>

  <form [formGroup]="bankForm" class="q-d-grid q-g-16 q-w-100">
    <div class="q-d-flex q-g-8 q-w-100">
      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1">Name on Account</label>
        <input 
          type="text" 
          class="form-control" 
          placeholder="John Hancock" 
          formControlName="accountHolderName" 
          onfocus="focused(this)"
          onfocusout="defocused(this)"
          autocomplete="off">
      </div>

      <div class="q-d-grid q-g-8 q-w-100">
        <label class="font-b1">Account Holder Type</label>
        
        <select-input
          [hasClear]="false"
          [options]="accntHolderTypeOpt"
          [optionKeys]="{ value: 'val', label: 'label' }"
          inputClass="form-control"
          formControlName="accountHolderType"
          class="input-class">
        </select-input>
      </div>
    </div>

    <div class="q-d-flex q-g-16 q-w-100">
      <div class="q-d-grid q-g-16 q-w-100">
        <div class="q-d-grid q-g-8 q-w-100">
          <label class="font-b1">Email</label>
          <input
            type="text"
            id="email-account"
            class="form-control"
            placeholder="john@example.com"
            onfocus="focused(this)"
            onfocusout="defocused(this)"
            autocomplete="off"
            formControlName="email"/>
        </div>
  
        <div class="q-d-grid q-g-8 q-w-100">
          <label class="font-b1">Account Number</label>
          
          <div class="bank-field">
            <input 
              type="text" 
              class="form-control" 
              placeholder="00000000000" 
              formControlName="accountNumber" 
              size="17"
              maxLength="17"
              (keypress)="returnAsNumber($event)"
              autocomplete="off">
          </div>
        </div>
      </div>
  
      <div class="q-d-grid q-g-16 q-w-100">
        <div class="q-d-grid q-g-8 q-w-100">
          <label class="font-b1">Routing Number</label>
          <input 
            type="text" 
            class="form-control" 
            placeholder="000000000" 
            formControlName="routingNumber"
            size="9"
            maxLength="9"
            (keypress)="returnAsNumber($event)"
            autocomplete="off">
        </div>
  
        <div class="q-d-grid q-g-8 q-w-100">
          <label class="font-b1">Confirm Account Number</label>
  
          <div class="bank-field">
            <input 
              type="text" 
              class="form-control" 
              placeholder="00000000000" 
              formControlName="confirmAccountNumber"
              size="17"
              maxLength="17"
              (keypress)="returnAsNumber($event)"
              autocomplete="off">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>