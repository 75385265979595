<div class="dropdown imp-parent" style="margin-top: 0px">
	<button
    id="mapper_exportCompAs"
		class="btn-primary btn-md export mb-0 mt-sm-0 mt-1 imp-disabled-op dropdown-toggle"
		type="button"
		id="dropdownMenuButton1"
		data-bs-toggle="dropdown"
		aria-expanded="false"
		#exportEl
	>
		Export As
		<app-filter-chevron
			[isCollapsed]="!exportEl.classList.contains('show')"
		></app-filter-chevron>
	</button>
	<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
		<li>
			<a
        id="mapper_exportCSV"
				class="dropdown-item"
				href="javascript:void(0)"
				(click)="export(applicationType.CSV)"
			>
				CSV file
			</a>
		</li>
		<li>
			<a
        id="mapper_exportXLS"
				class="dropdown-item"
				href="javascript:void(0)"
				(click)="export(applicationType.EXCEL)"
			>
				Excel file
			</a>
		</li>
	</ul>
</div>
