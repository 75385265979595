import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { NgxMaskModule } from 'ngx-mask';

import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DateRangeInputComponent } from './date-range-input/date-range-input.component';
import { TimeInputComponent } from './time-input/time-input.component';

@NgModule({
	declarations: [
		DateInputComponent,
		CalendarHeaderComponent,
		DateRangeInputComponent,
		TimeInputComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		MatIconModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatMenuModule,
		NgxMaskModule.forRoot(),
	],
	exports: [DateInputComponent, DateRangeInputComponent, TimeInputComponent],
})
export class DateTimeInputModule {}
