<button id="mapper_dialogImpersonate" tabindex="-1" class="close" mat-dialog-close>
	<span aria-hidden="true">
		<app-figma-icon-img
			[iconName]="'x'"
			[isForButton]="true"
			[hasSpaceOnRight]="false"
		></app-figma-icon-img>
	</span>
</button>
<h5 mat-dialog-title class="text-center">Select a user to impersonate</h5>
<mat-dialog-content>
	<div>
		<div class="text-center mb-3">
			<img
				image-error-detection
				[component]="'impersonate-modal.component.html'"
				*ngIf="companyLogoSrc !== null && companyLogoSrc !== ''"
				[src]="companyLogoSrc"
				class="img-fluid company-logo"
				alt="logo"
			/>
		</div>
		<form>
			<div class="form-group row">
				<label class="col-sm-3 text-right imp-label">Company</label>
				<select-input
					[options]="companies"
					[optionKeys]="{ value: 'id', label: 'name' }"
					(onChange)="companyChange($event)"
					placeholder="Select Company"
					[disabled]="!!data"
					[(ngModel)]="selectedCompanyId"
					[ngModelOptions]="{ standalone: true }"
					inputClass="form-control col-sm-8"
				>
				</select-input>
			</div>
			<div class="form-group row">
				<label class="col-sm-3 text-right imp-label">User</label>
				<select-input
					[disabled]="!selectedCompanyId"
					[options]="users"
					[optionKeys]="{ value: 'id', label: 'name' }"
					(onChange)="userChange($event)"
					placeholder="Select User"
					inputClass="form-control col-sm-8"
				>
				</select-input>
			</div>
		</form>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button
  id="mapper_impersonateLogin"
		(click)="onLogin()"
		class="btn appsave-btn blue-btn"
		[disabled]="selectedCompanyId === 0 || selectedUserId === 0"
	>
		Login
	</button>
</mat-dialog-actions>
