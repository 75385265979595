<div [formGroup]="form">
	<div class="form-group-2">
		<label class="font-b1 font-w-600">Add Banner Color</label>
		<div class="q-d-flex q-ai-center q-g-12">
			<input type="color" class="form-control" formControlName="lightTheme" #light />
			<a
        id="mapper_previewTheme"
				*ngIf="hasPreview"
				class="edit imp-disabled-op d-flex align-items-center"
				[tooltipContentV2]="{ message: 'Preview Theme' }"
				[spThemeMode]="'light'"
				(click)="previewTheme('lightTheme')"
			>
				<app-local-figma-icon-img
					[filename]="'eye-icon-white'"
					[hasSpaceOnRight]="false"
				></app-local-figma-icon-img>
			</a>
		</div>
	</div>

	<ng-container *ngIf="hasDefault" [ngTemplateOutlet]="showDefault">
	</ng-container>
</div>

<ng-template #showDefault>
	<div class="categories-form-label">
		<label>Auto Apply Colors</label>
		<mat-slide-toggle
			[formControl]="isDefault"
			[tooltipContentV2]="{ message: 'When set to True, this will automatically apply the theme color' }"
		>
		</mat-slide-toggle>
	</div>
	<div class="categories-form-label" *ngIf="form.controls['isDefault'].value">
		<label>Default Mode</label>
		<div class="option-wrapper form-check">
			<input
				class="form-check-input"
				[formControl]="isDarkMode"
				id="lightRadio"
				[value]="false"
				name="isDarkMode"
				type="radio"
			/>
			<label [for]="'lightRadio'">Light Mode</label>
		</div>
		<div class="option-wrapper form-check">
			<input
				class="form-check-input"
				[formControl]="isDarkMode"
				id="darkRadio"
				[value]="true"
				name="isDarkMode"
				type="radio"
			/>
			<label [for]="'darkRadio'">Dark Mode</label>
		</div>
	</div>
</ng-template>
