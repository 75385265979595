<app-modal
	[headerTitle]="fileName"
	[show]="show"
	modalSize="modal-lg"
	(close)="close.emit()"
>
	<ng-template #modalBody>
		<div class="attachment-container m-4">
			<img
				*ngIf="data && fileType === 'image'"
				[src]="sanitizer(filePath)"
				alt=""
			/>
			<video
				*ngIf="data && fileType === 'video'"
				[src]="sanitizer(filePath)"
				controls
			></video>
			<span *ngIf="data && fileType === ''"> File not supported. </span>
			<button
      id="mapper_attachPrev"
				*ngIf="deleteEnabled"
				type="button"
				class="close"
				data-bs-dismiss="modal"
				aria-label="Delete Image"
				(click)="delete()"
			>
				<span
					aria-hidden="true"
					class="d-flex align-items-center"
					style="padding: 5px 0"
				>
					<app-figma-icon-img
						[iconName]="'trash-01'"
						[isForButton]="true"
					></app-figma-icon-img>
					Delete Image
				</span>
			</button>
		</div>
	</ng-template>
</app-modal>
