<div class="card-2 q-mb-24">
	<div class="card-header-2">
		<h3 class="q-mr-auto">{{userId ? 'Override' : 'Manage'}} {{type.header}}</h3>

    <app-tertiary-button
      *ngIf="type.type != roleFormType.ticketBoards"
			[isFilterToggle]="true"
			(click)="detectChanges()"
		></app-tertiary-button>

		<a
      *ngIf="createEnabled && type.createLink && !userId"
      class="btn-2 btn-2-primary imp-disabled-op"
      [routerLink]="type.createLink"
    >
			<mat-icon svgIcon="plus"></mat-icon>
			Create
    </a>

		<button id="mapper_manageFormClose" class="btn-2 btn-2-secondary for-main" (click)="back()">Close</button>
	</div>

	<div class="role-card q-d-flex q-f-column q-g-8 font-b1">
		<h4>{{role ? role.name : 'Role'}}</h4>
		You can adjust the level of visibility for what {{type.type != roleFormType.knowledgeBase ? type.header : type.placeholder}} are available to view for this specific role
	</div>

  <div id="demo" class="collapse">
		<div [formGroup]="filterControl" class="table-filter-2">
			<div class="filter-item">
				<label class="font-b1 font-w-600">Category</label>
				<multi-select-input
					formControlName="categoryId"
					[options]="categories"
					[optionKeys]="{ value: 'id', label: 'name' }"
					placeholder="- Select Category -"
					class="form-control"
				></multi-select-input>
			</div>

			<div class="filter-item filter-actions">
				<button id="mapper_filterformclear" class="btn-2 btn-2-link" (click)="filterControl.reset()">Clear All</button>
			</div>
		</div>
	</div>

	<div class="actions-wrapper q-ai-center">
		<search-input
		[formControl]="searchControl"
		placeholder="Search {{type.placeholder}}"
		maxlength="100"
		class="q-mr-auto">
		</search-input>

		<button
    id="mapper_changeAccessForm"
		class="btn-2 btn-2-primary imp-disabled-op"
    (click)="toggleAccess()"
		[disabled]="!selectedItems.length || isLoading">
			Change Access for ({{selectedItems.length || '#'}}) {{type.placeholder}}
		</button>
	</div>
	<div
    *ngIf="userId || isCompanyOverride"
    class="actions-wrapper q-ai-center"
    style="justify-content: flex-start;"
    (click)="switchOverride()"
  >
    <label>Override Switch</label>
    <app-svg [name]="getSwitch()"></app-svg>
  </div>


	<div class="table-container">
    <div class="table-wrapper scroll-v2">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="isAccess"
        matSortDirection="desc"
        matSortDisableClear="true"
      >
        <ng-container matColumnDef="check" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <checkbox-input
              *ngIf="userId"
              [checked]="itemsPerPage != undefined && selectedItems.length === itemsPerPage.pageSize"
              (change)="toggleAllItems($event)"
						  [indeterminate]="selectedItems.length > 0"
              [disabled]="!dataSource.data.length || !overrideSwitch"
            ></checkbox-input>
            <checkbox-input
              *ngIf="!userId"
              [checked]="itemsPerPage != undefined && selectedItems.length === itemsPerPage.pageSize"
              (change)="toggleAllItems($event)"
						  [indeterminate]="selectedItems.length > 0"
            ></checkbox-input>
          </th>
          <td mat-cell *matCellDef="let row">
            <checkbox-input
              *ngIf="userId"
              [checked]="selectedItems.includes(row)"
              (change)="toggleItem(row)"
              [disabled]="!overrideSwitch"
            ></checkbox-input>
            <checkbox-input
              *ngIf="!userId"
              [checked]="selectedItems.includes(row)"
              (change)="toggleItem(row)"
            ></checkbox-input>
          </td>
        </ng-container>

        <ng-container matColumnDef="isAccess">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Access</th>
          <td mat-cell *matCellDef="let row">
            <div class="q-ml-8">
              <app-local-figma-icon-img
                [filename]="row.isAccess?'lock-unlocked-icon':'lock-icon'"
                (click)="toggleAccess(row)"
                [width]="24"
                [height]="24"
                [hasSpaceOnRight]="false"
                class="edit imp-disabled-op"
              ></app-local-figma-icon-img>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let row">
            {{row.title}}
          </td>
        </ng-container>

        <ng-container matColumnDef="categories">
          <th mat-header-cell *matHeaderCellDef>Categories</th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngTemplateOutlet="catTemp; context: {
              categories: row.categories,
              catArray: row.categories?.split(',') || []
            }"></ng-container>
            <ng-template #catTemp let-catArray="catArray" let-cat="categories">
              <div
                class="q-w-max"
                [class.q-default]="catArray.length > 4"
                [tooltipContentV2]="{ message: catArray.length > 4 ? cat : '' }"
              >
                {{catArray.slice(0, 4)}}{{catArray.length > 4 ? ', ...' : ''}}
              </div>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.active-2]="selectedItems.includes(row)">
        </tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell q-center" colspan="100%">
            {{ noRecordMessage }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="table-paginator-container" [hidden]="!dataSource.data.length">
    <div class="paginator-group">
      <table-paginator
        [totalItems]="totalItems"
        [pageSizes]="pageSizes"
        (pageChange)="getList()"
      ></table-paginator>
    </div>

    <div class="item-per-page-group">
      <app-item-per-page
        (sizeChange)="updateSize()"
        [totalItems]="totalItems"
      ></app-item-per-page>
    </div>
  </div>
</div>

<!-- <app-audit-trail
	[url]="historyUrl"
	history_category="default"
	[id]="userId"
	[hasChangedForm]="hasChangedForm"
></app-audit-trail> -->

<app-audit-trail
  [id]="userId? userId: companyId?companyId:id"
  [url]="type.auditTrailUrl">
</app-audit-trail>
