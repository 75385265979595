import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { DateFilterComponent } from './../../../../shared/components/date-filter/date-filter.component';
import { InputSelectType } from '@app/shared/components/parent-child-input-selector/parent-child-input-selector.component';

import {
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { SortDirection } from '@angular/material/sort';
import { Store, select } from '@ngrx/store';

import { isValueEmpty } from '@app/shared/utilities/helper';

import { QuickFilterService } from './../../../../shared/components/quick-filter/quick-filter.service';
import { QuotesOrdersService } from '../../quotes-and-orders.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';

import {
	ApplicationType,
	FilterUrl,
	NotificationMessages,
	Permission,
	TableSignalType,
	Themes,
} from '@app/shared/constants';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IQuickFilter } from '@app/shared/new-table-shared/new-table-shared.interface';
import { IQuotes } from './store/quotes.interface';
import { ParentChildAccessType } from '@app/shared/interfaces/companies.interface';
import { QuickFilter } from '@app/shared/interfaces/order.interface';

import { quotesSelector } from './store/quotes.selector';
import { TableReloadSignalService } from '@app/shared/services/table-reload-signal.service';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

@Component({
	selector: 'app-quotes',
	templateUrl: './quotes.component.html',
	styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent implements AfterViewInit, OnInit {
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	@ViewChild('searchTextbox') searchTextbox: ElementRef;

	filterUrl: string = 'sort15';
	themeSubs: Subscription;
	totalItems = 0;
	typeQuery: string;
	form = new FormGroup({
		statusIds: new FormControl<number[]>(this._quotesService.quoteStatusIds),
		typeIds: new FormControl<number[]>(this._quotesService.quoteTypeIds),
		companyId: new FormControl(this._userService.companyId),
		queryStartDate: new FormControl(''),
		queryEndDate: new FormControl(''),
	});
	hasChildCompanies = false;
	quoteStatus: any[] = [];
	quoteTypes: any[] = [];
	qFilterUrl = FilterUrl;
	search = new FormControl();
	isQuoteLinkEnabled = this._userService.hasPermission([
		Permission.ShowQuoteLinkUser,
	]);
	clearDates = false;
	resetVal = false;
	isProcessing = false;
	searchFilters: IQuotes;
	quickFilter: IQuickFilter;
	column: string;
	order: SortDirection;
	page: number;
	pageSize: number;

	#subscription: Subscription = new Subscription();

	constructor(
		public spinner: SpinnerService,
		private _cd: ChangeDetectorRef,
		private _store: Store<AppStateInterface>,
		private _quickFilterService: QuickFilterService,
		private _quotesService: QuotesOrdersService,
		private _themeService: ThemeService,
		private _toastMessageService: ToastMessageService,
		private _userService: UserService,
		private _tableReloadSignalService: TableReloadSignalService
	) {}

	get applicationType() {
		return ApplicationType;
	}

	get isFilterOn(): boolean {
		const statusIds = this.form.get('statusIds')?.value;
		const hasStatus = !!(
			statusIds &&
			statusIds?.length > 0 &&
			statusIds[0] !== 0
		);
		const typeIds = this.form.get('typeIds')?.value;
		const hasType = !!(typeIds && typeIds?.length > 0 && typeIds[0] !== 0);
		const hasDateStart = !isValueEmpty(this.form.get('queryStartDate')?.value);
		const hasDateEnd = !isValueEmpty(this.form.get('queryEndDate')?.value);

		return hasStatus || hasType || hasDateStart || hasDateEnd;
	}

	ngOnInit() {
		this.#subscription.add(
			this._themeService.themeType.pipe().subscribe((theme) => {
				if (theme === Themes.light) {
					this.filterUrl = 'sort15';
				} else if (theme === Themes.dark) {
					this.filterUrl = 'sort';
				}
			})
		);

		this._initTableReloadFromSignaIR();
	}

	async ngAfterViewInit() {
		await this.#_getDropdowns();
		this._loadPreviousFilters();
		this.#eventListeners();
	}

	ngOnDestroy() {
		if (this.themeSubs) {
			this.themeSubs.unsubscribe();
		}
		this.#subscription.unsubscribe();
	}

	export(exportType: ApplicationType) {
		const options: IQuotes = this._getFilters();

		if (this.totalItems === 0) {
			this._toastMessageService.showErrorMessage(
				NotificationMessages.NoRecordFound
			);
		} else {
			this.spinner.start();

			this._quotesService.exportQuotes(options, exportType).subscribe({
				next: (resp) => {
					const link = document.createElement('a');
					link.href = resp;
					link.setAttribute('download', 'Quotes');
					document.body.appendChild(link);
					link.click();
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
				complete: () => {
					this._toastMessageService.showSuccessMessage(
						NotificationMessages.Export
					);
				},
			});
		}
	}

	setStartDate(startDate: string) {
		if (startDate.toLowerCase().includes('invalid')) startDate = '';
		this.searchFilters = this._getFilters();
	}

	setEndDate(endDate: string) {
		if (endDate.toLowerCase().includes('invalid')) endDate = '';
		this.searchFilters = this._getFilters();
	}

	childCompanyChanged(value: InputSelectType) {
		var val = value.isParentSelector
			? this._userService.companyId
			: value.companySelected?.id ?? this._userService.companyId;

		if (this.page == 1) this.form.controls.companyId.setValue(val);
		else {
			this.form.controls.companyId.setValue(val, { emitEvent: false });
		}
	}

	setQuickFilter(event: QuickFilter[]) {
		const statusIds = this.form.get('statusIds')?.value as Number[];
		this.form
			.get('statusIds')
			?.setValue(this._quickFilterService.mapStatusIds(statusIds, event));
		this.fetchNewData();
	}

	fetchNewData() {
		const filters: IQuotes = this._getFilters();
		this.searchFilters = filters;
	}

	onEmitTotalItems(totalItems: number) {
		this.totalItems = totalItems;
	}

	#isEmpty = (val: any, ret: any = undefined) => {
		if (val) return val;
		else return ret != undefined ? ret : '';
	};

	#_getDropdowns() {
		return new Promise((resolve) => {
			this._quotesService.getQuoteStatusDropdown().subscribe((res: any) => {
				this.quoteStatus = res.map(
					(resp: { id: number; name: string | number }) => ({
						value: resp.id,
						display: resp.name,
						selected: false,
					})
				);
				this._cd.detectChanges();
				resolve(res);
			});

			this._quotesService.getQuoteTypes().subscribe((res: any) => {
				this.quoteTypes = res.map(
					(resp: { id: number; name: string | number }) => ({
						value: resp.id,
						display: resp.name,
						selected: false,
					})
				);
				this._cd.detectChanges();
				resolve(res);
			});

			this._quotesService
				.getChildCompanies(ParentChildAccessType.Quote)
				.subscribe((res) => {
					if (res.length) this.hasChildCompanies = true;
				});
		});
	}

	#eventListeners() {
		const inputEvent = this.search.valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged()
		);

		const enterEvent = fromEvent(
			this.searchTextbox.nativeElement as HTMLInputElement,
			'keyup'
		).pipe(
			filter((e) => (e as KeyboardEvent).keyCode === 13),
			map((e) => (e.target as HTMLInputElement).value)
		);

		this.#subscription.add(
			merge(inputEvent, enterEvent)
				.pipe(
					distinctUntilChanged(),
					switchMap(async (query) => {
						this.page = 1;
						this.fetchNewData();

						return query;
					})
				)
				.subscribe()
		);

		this.#subscription.add(
			this.clearFilter.clearAll.subscribe(() => {
				const setEmpty = (field: any) => {
					if (field instanceof FormControl) {
						if (field.value instanceof Array) {
							field.setValue([]);
						} else {
							field.setValue('');
						}
					}
				};

				this.clearDates = true;
				setEmpty(this.form.get('queryStartDate'));
				setEmpty(this.form.get('queryEndDate'));
				setEmpty(this.form.get('statusIds'));
				setEmpty(this.form.get('typeIds'));
				this.resetVal = true; // trigger the reset
				setTimeout(() => {
					this.resetVal = false; // set back to false after you trigger reset
				}, 500);

				this.fetchNewData();
			})
		);

		this.#subscription.add(
			this.form.valueChanges.subscribe((val) => {
				this.searchFilters = this._getFilters();
				this.quickFilter = {
					statusIds: this.form.get('statusIds')?.value as number[],
					onSelectIds: this.setQuickFilter.bind(this),
					url: this.qFilterUrl.QUOTES,
				};
			})
		);

		this.#subscription.add(
			this._quotesService.isLoading$
				.pipe(
					tap((isLoading) => {
						if (isLoading) {
							this.search.disable();
						} else {
							if (this.search.disabled) {
								this.search.enable();
							}
							(this.searchTextbox.nativeElement as HTMLInputElement).focus();
						}
					})
				)
				.subscribe()
		);
	}

	private _loadPreviousFilters() {
		this._store
			.pipe(select(quotesSelector), take(1))
			.subscribe((filter: IQuotes) => {
				this.search.patchValue(filter.query);
				this.form.get('statusIds')?.patchValue(filter.statusIds || []);
				this.form.get('typeIds')?.patchValue(filter.typeIds || []);
				this.form.get('queryStartDate')?.patchValue(filter.startDate || '');
				this.form.get('queryEndDate')?.patchValue(filter.endDate || '');
				this.page = filter.page;
				this.pageSize = filter.pageSize;
				this.column = filter.column;
				this.order = filter.order;
				this.searchFilters = this._getFilters();

				this.quickFilter = {
					statusIds: this.form.get('statusIds')?.value as number[],
					onSelectIds: this.setQuickFilter.bind(this),
					url: this.qFilterUrl.QUOTES,
				};
			});
	}

	private _getFilters(): IQuotes {
		return {
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.page,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.pageSize,
			order: this.order,
			column: this.column,
			query: this.search.value.trim(),
			statusIds: (this.form.get('statusIds')?.value as number[]).filter(
				(x) => x !== undefined && x > 0
			),
			companyId: this.#isEmpty(this.form.get('companyId')?.value, 0),
			typeIds: (this.form.get('typeIds')?.value as number[]).filter(
				(x) => x !== undefined && x > 0
			),
			startDate: this.form.controls['queryStartDate'].value || '',
			endDate: this.form.controls['queryEndDate'].value || '',
		};
	}

	private _initTableReloadFromSignaIR() {
		this._tableReloadSignalService
			.tableReloadFromSignaIR(TableSignalType.QUOTE)
			.subscribe({
				next: () => {
					this.fetchNewData();
					this.#_getDropdowns();
					this.newTable.onFetchQuickFilter();
				},
			});
	}
}
