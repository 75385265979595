import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { CardFormService } from '@app/shared/components/card-form/service/card-form.service';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { Subject, takeUntil } from 'rxjs';
import { IActivePaymentDetails, IInvoiceAssignment, IPaymentArrangementFormData, IPaymentSchedules } from '../payment-arrangements/payment-arrangements.interface';
import { PaymentArrangementsService } from '../payment-arrangements/payment-arrangements.service';
import { CompanyDetailsFormComponent } from './company-details-form/company-details-form.component';
import { InvoiceAssignmentSectionComponent } from './invoice-assignment-section/invoice-assignment-section.component';
import { PaymentMethodSectionComponent } from './payment-method-section/payment-method-section.component';
import { MethodType } from './payment-method-section/payment-method-section.interface';
import { PaymentSectionComponent } from './payment-section/payment-section.component';

@Component({
  selector: 'setup-payment-arrangements',
  templateUrl: './setup-payment-arrangements.component.html',
  styleUrls: ['./setup-payment-arrangements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SetupPaymentArrangementsComponent implements OnInit, OnDestroy {
  @ViewChild(CompanyDetailsFormComponent) companyDetailsFormComponent: CompanyDetailsFormComponent;
  @ViewChild(PaymentMethodSectionComponent) paymentMethodSectionComponent: PaymentMethodSectionComponent;
  @ViewChild(InvoiceAssignmentSectionComponent) invoiceAssignementComponent: InvoiceAssignmentSectionComponent;
  @ViewChild(PaymentSectionComponent) paymentSectionComponent: PaymentSectionComponent;

  panelOpenState = false;
  step = 0;
  methodType = MethodType;
  isSubmitted: boolean = false;
  id: number;

  paymentArrangementService = inject(PaymentArrangementsService);

  private _cardFormService = inject(CardFormService);
  private _cd = inject(ChangeDetectorRef);
  private _notifier = inject(NotificationService);
  private _spinner = inject(SpinnerService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _datePipe = inject(DatePipe);
  private _currencyPipe = inject(CurrencyPipe);
  private _$unsubscribe: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.clearAllFormSection();
    this._paymentMethodSubmissionEvent();

    setTimeout(() => this.retrievePaymentArrangement(), 0);
  }

  retrievePaymentArrangement() {
    this.id = Number(this._route.snapshot.paramMap.get('id'));

    this.paymentArrangementService.formData.id = this.id;
    this.paymentArrangementService.formData.isEdit = !!this.id;

    /* Edit mode */ 
    if (this.paymentArrangementService.formData.isEdit) {
      this._getPaymentArrangementDetails();
    } else {
      this.step = 0;
    }
  }

  private _getPaymentArrangementDetails() {
    this.paymentArrangementService.getActivePaymentArrangementDetails(this.id)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (data) => {
        this.paymentArrangementService.paymentSetupForm.billingInfo = data;
        this._getPaymentInfo();
        this._cd.detectChanges();
			},
			error: () => {
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
				this._router.navigate(['/billing-orders/payment-arrangements/active-payment-arrangements']);
			}
 		});
  }

  private _getPaymentInfo() {
    this.paymentArrangementService.getPaymentInfo(this.id)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (data) => {
        this.paymentArrangementService.paymentSetupForm.paymentInfo = data;

        /* [Start]::Company details section */ 
        this.companyDetailsFormComponent.form.controls['companyId'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.companyId);
        this.companyDetailsFormComponent.handleCompanyDetailsChange();
        this.paymentArrangementService.data.companyId = this.paymentArrangementService.paymentSetupForm.paymentInfo?.setup?.companyId;
        this.paymentArrangementService.data.billingContactId = this.paymentArrangementService.paymentSetupForm.paymentInfo?.setup?.billingContactId;
        /* [End]::Company details section */ 

        /* [Start]::Payment method section */ 
        const paymentMethodId = this.paymentArrangementService.paymentSetupForm.paymentInfo?.setup?.paymentMethodId;

        if (paymentMethodId?.includes('card_')) {
          this.paymentMethodSectionComponent.selectedMethod.setValue(MethodType.CARD);
          this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingCardId'].setValue(paymentMethodId);
          this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingBankId'].setValue('');

        } else {
          this.paymentMethodSectionComponent.selectedMethod.setValue(MethodType.BANK);
          this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingBankId'].setValue(paymentMethodId);
          this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingCardId'].setValue('');
        }

        this.paymentArrangementService.data.paymentMethodId = paymentMethodId;
        this.paymentMethodSectionComponent.initPaymentMethod();
        /* [End]::Payment method section */ 

        /* [Start]::Invoice Assignement section */ 
        this.invoiceAssignementComponent.initData();
        /* [End]::Invoice assignement section */ 

        /* [Start]::Payment section */
        this.paymentSectionComponent.form.controls['initialPayment'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.initialPayment);
        this.paymentSectionComponent.form.controls['recurringAmount'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.recurringAmount);
        this.paymentSectionComponent.form.controls['frequencyId'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.frequencyId);
        this.paymentSectionComponent.form.controls['paymentDate'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.paymentDate);
        this.paymentSectionComponent.form.controls['startDate'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.startDate);
        this.paymentSectionComponent.form.controls['paymentCycles'].setValue(this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.paymentCycles);
        this.paymentSectionComponent.onBreakDown();

        this.paymentArrangementService.data.initialPayment = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.initialPayment;
        this.paymentArrangementService.data.paymentDate = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.paymentDate;
        this.paymentArrangementService.data.frequencyId = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.frequencyId;
        this.paymentArrangementService.data.startDate = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.startDate;
        this.paymentArrangementService.data.paymentCycles = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.paymentCycles;
        this.paymentArrangementService.data.recurringAmount = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.recurringAmount;
        
        this.paymentSectionComponent.paymentSchedules = this.paymentArrangementService.paymentSetupForm.paymentInfo.paymentSchedules ?? [];
        this.paymentArrangementService.data.id = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.id;
        this.paymentArrangementService.data.isSubmitted = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.isSubmitted;
        this.paymentArrangementService.formData.startDateFrom = this.paymentArrangementService.getFirstAndLastDate(this.paymentArrangementService.paymentSetupForm.paymentInfo.paymentSchedules as IPaymentSchedules[]).startDateFrom;
        this.paymentArrangementService.formData.endDateTo = this.paymentArrangementService.getFirstAndLastDate(this.paymentArrangementService.paymentSetupForm.paymentInfo.paymentSchedules as IPaymentSchedules[]).endDateTo;
        /* [End]::Payment section */

        this.step = 0;
        this.checkIfFormIsSubmitted();
        this._cd.detectChanges();
			},
			error: () => {
				this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
        this._router.navigate(['/billing-orders/payment-arrangements/active-payment-arrangements']);
			}
		});
  }

  updateContent() {
    if (this.paymentArrangementService.paymentSetupForm.paymentInfo) {
      const totalPaymentAmount = this._currencyPipe.transform(this.paymentArrangementService.formData.totalPaymentAmount);
      const companyName = this.paymentArrangementService.paymentSetupForm.paymentInfo.setup?.companyName;
      const startDateFrom = this.paymentArrangementService.formData.startDateFrom;
      const endDateTo = this.paymentArrangementService.formData.endDateTo;
      const startDate = this._datePipe.transform(this.paymentSectionComponent.form.controls['startDate'].value, `MMMM' 'd',' y`);
      const frequency = this.paymentArrangementService.formData.frequency;
      const paymentCycles = this.paymentSectionComponent.form.controls['paymentCycles'].value;
      const recurringAmount = this._currencyPipe.transform(this.paymentSectionComponent.form.controls['recurringAmount'].value);

      const content = `${companyName} will pay Sourcepass the amount of ${totalPaymentAmount}, representing past-due amounts for services rendered from ${startDateFrom} through ${endDateTo}, under the terms set forth in this Agreement.

The Payment Amount shall be paid ${frequency} over ${paymentCycles} payment cycles. Payments shall be made in the amount of ${recurringAmount}, beginning on ${startDate}, representing past-due amounts for services rendered from ${startDateFrom}, and continuing (Frequency) thereafter for ${paymentCycles} payments until paid in full. If any such payment is due on a day that is not a Business Day, the payment will be due on the next succeeding Business Day.
            
${companyName} failure to pay Sourcepass pursuant to the terms of this Agreement shall constitute a default. If ${companyName} fails to make a payment ten (10) calendar days after written notice of non-payment, (a) the entire unpaid Payment Amount under this Agreement shall be immediately due and payable by ${companyName}; (b) ${companyName} agrees to pay interest on the outstanding amount owed as of the Event of Default and (c) Sourcepass may exercise any and all rights and remedies available to it under law and in equity, including suspension of services, termination, termination of the Services Contract, and action to collect.`;
      
      this.paymentSectionComponent.form.controls['legalConfirmationContent'].setValue(content);
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this._submit();
  }

  prevStep() {
    this.step--;
  }

  private _submit() {
    switch (this.step) {
      case 0:
        this._submitCompanyDetails();
        break;

      case 1:
        this._submitPaymentMethod();
        break;

      case 2:
        this._submitInvoiceAssignment();
        break;

      case 3:
        this._submitPayment();
        break;
    }
  }

  private _submitCompanyDetails() {
    this.companyDetailsFormComponent.form.markAllAsTouched();

    if (this.companyDetailsFormComponent.form.invalid) {
      this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
      return;
    }

    this.paymentArrangementService.setCompanyId(Number(this.companyDetailsFormComponent.form.controls['companyId'].value));
    this.paymentArrangementService.setBillingContactId(Number(this.companyDetailsFormComponent.form.controls['billingContactId'].value));
    this.step++;
  }

  /* [Start]::For payment method section */ 
  private _submitPaymentMethod() {
    if (this.paymentMethodSectionComponent) {
      if (this.paymentMethodSectionComponent.selectedMethod.value === MethodType.CARD) {
        if (!this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingCardId'].value) {
          this.paymentMethodSectionComponent.cardFormComponent.onSubmit();

        } else {
          this.paymentMethodSectionComponent.submitExistingCard();
        }

      } else {

        if (!this.paymentMethodSectionComponent.paymentMethodForm.controls['selectedExistingBankId'].value) {
          this.paymentMethodSectionComponent.bankFormComponent.onSubmit();

        } else {
          this.paymentMethodSectionComponent.submitExistingBank();
        }
      }
    }
  }

  private _paymentMethodSubmissionEvent() {
    this._cardFormService.onGetStripePaymentMethodId
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (result) => {
        if (result) {
          this.paymentArrangementService.setPaymentMethodId(result.paymentMethodId);
          this.step++;
          this.paymentMethodSectionComponent?.initPaymentMethod();
          this._cd.detectChanges();
        }
      }
    });
  }
  /* [End]::For payment method section */ 

  private _submitInvoiceAssignment() {
    const invoiceIds = this.invoiceAssignementComponent.selectedInvoices.map(x => { return { invoiceId: x.id, balanceDue: x.currentBalance }}) as IInvoiceAssignment[];

    if (!invoiceIds.length) {
      this._notifier.notifyError(NotificationMessages.Review, 'Invoice is required.');
      return;
    }

    this.paymentArrangementService.setInvoiceAssignment(invoiceIds);
    this.paymentSectionComponent?.onBreakDown();
    this.step++;
  }

  private _submitPayment() {
    this.paymentSectionComponent.form.markAllAsTouched();
    this.paymentArrangementService.setInitialPayment(this.paymentSectionComponent.form.controls['initialPayment'].value);
    this.paymentArrangementService.setRecurringAmount(this.paymentSectionComponent.form.controls['recurringAmount'].value);
    this.paymentArrangementService.setFrequencyId(this.paymentSectionComponent.form.controls['frequencyId'].value);
    this.paymentArrangementService.setPaymentDate(this.paymentSectionComponent.form.controls['paymentDate'].value);
    this.paymentArrangementService.setStartDate(this.paymentSectionComponent.form.controls['startDate'].value);
    this.paymentArrangementService.setPaymentCycles(this.paymentSectionComponent.form.controls['paymentCycles'].value);
   
    if (this.paymentSectionComponent.form.invalid) {
      this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
      return;
    } 

    this._submitFinal();
  }

  private _submitFinal() {
    this._spinner.start();

    const isEdit = !!Number(this._route.snapshot.paramMap.get('id'));
    
    const param = { ...this.paymentArrangementService.data, isSubmitted: this.isSubmitted };
    
    this.paymentArrangementService.submitPaymentArrangements(param, isEdit)
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (res) => {
        this.paymentArrangementService.setIsSumitted(this.isSubmitted);

        if (this.paymentArrangementService.data.isSubmitted) {
          this._notifier.notifySuccess(NotificationMessages.submit('Payment Arrangement'));
        }

        this._router.navigate(['/billing-orders/payment-arrangements']);
        this._spinner.stop();
      },
      error: () => {
        this._spinner.stop();
      }
    });
  }

  onPanelChange() {
    switch (this.step) {
      case 0:
        break;

      case 1:
        this.paymentMethodSectionComponent?.initPaymentMethod();
        break;

      case 2:
        this.invoiceAssignementComponent?.initData();
        break;

      case 3:
        this.paymentSectionComponent?.onChangeFrequency();
        this.paymentSectionComponent?.onBreakDown();
        break;
    }

    this.paymentArrangementService.formData.step = this.step;
  }

  clearAllFormSection() {
    this.paymentArrangementService.formData = this.paymentArrangementService.defaultFormData;
    this.paymentArrangementService.data = this.paymentArrangementService.defaultData;
    this.companyDetailsFormComponent?.onRemoveAlternativeContact();
    this.paymentMethodSectionComponent?.clearForm();
    this.invoiceAssignementComponent?.clearForm();
    this.paymentSectionComponent?.clearForm();
    this.step = 0;
  }

  checkIfFormIsSubmitted() {
    const isEdit = !!Number(this._route.snapshot.paramMap.get('id'));

    if (isEdit) {
      if (this.paymentArrangementService.data.isSubmitted) {
        this.companyDetailsFormComponent.form.disable();
        this.paymentMethodSectionComponent.paymentMethodForm.disable();
        this.paymentMethodSectionComponent.selectedMethod.disable();
        this.paymentSectionComponent.form.disable();
      } else {
        this.companyDetailsFormComponent.form.controls['companyId'].disable();
      }
    }
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
