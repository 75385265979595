<div
  class="cus-tab "
  [ngClass]="containerClass"
>
  <div class="dash-tab-header">
    <ul
      *ngIf="tabs.length && isClientAdminTab"
      class="nav nav-tabs reporting"
    >
      <ng-container *ngFor="let tab of tabs;let i = index">
        <li
        *ngIf="tab.tabType === 2 || tab.tabType === 3 || tab.tabType === 4"
        class="nav-item dash-tab-nav-item"
        (click)="onSelectTab(i)"
      >
        <ng-container *ngTemplateOutlet="tabHeaderComponent; context: { row: tab.row, index: i }"></ng-container>
        <a
          *ngIf="!tabHeaderComponent"
          class="mapper_tab nav-link btn btn-gradient-primary addnewapp-btn btn-sm export mb-0 mt-sm-0 mt-1 text-sm dash-tab-nav-link"
          [class.active]="tab.isActive"
          type="button"
        >
          <ng-container *ngTemplateOutlet="tabTitleComponent; context: { row: tab.row, index: i }"></ng-container>
          <span *ngIf="!tabTitleComponent" [class]="customClass">
            <app-figma-icon-img
            [iconName]="'dots-vertical'"
            [isForButton]="true"
            [hasSpaceOnRight]="false"
            class="imp-disabled">
            </app-figma-icon-img>
            {{ tab.title }}
            <app-figma-icon-img
            *ngIf="hasMenu"
            [iconName]="'dots-vertical'"
            [isForButton]="true"
            [hasSpaceOnRight]="false"
            class="imp-disabled"
          ></app-figma-icon-img>
          </span>
        </a>
      </li>
      </ng-container>

    </ul>
    <ul
    *ngIf="tabs.length"
    class="nav nav-tabs dashboard-classic"
  >
    <ng-container *ngFor="let tab of tabs;let i = index">
      <li
      *ngIf="!(tab.tabType === 2 || tab.tabType === 3 || tab.tabType === 4)"
      class="nav-item dash-tab-nav-item"
      (click)="onSelectTab(i)"
    >
      <ng-container *ngTemplateOutlet="tabHeaderComponent; context: { row: tab.row, index: i }"></ng-container>
      <a
        *ngIf="!tabHeaderComponent"
        class="mapper_tab nav-link btn btn-gradient-primary addnewapp-btn btn-sm export mb-0 mt-sm-0 mt-1 text-sm dash-tab-nav-link"
        [class.active]="tab.isActive"
        type="button"
      >
        <ng-container *ngTemplateOutlet="tabTitleComponent; context: { row: tab.row, index: i }"></ng-container>
        <span *ngIf="!tabTitleComponent" [class]="customClass">
          <app-figma-icon-img
          [iconName]="'dots-vertical'"
          [isForButton]="true"
          [hasSpaceOnRight]="false"
          class="imp-disabled">
          </app-figma-icon-img>
          {{ tab.title }}
          <app-figma-icon-img
          *ngIf="hasMenu"
          [iconName]="'dots-vertical'"
          [isForButton]="true"
          [hasSpaceOnRight]="false"
          class="imp-disabled"
        ></app-figma-icon-img>
        </span>
      </a>
    </li>
    </ng-container>

  </ul>
    <div *ngIf="headingMessage" class="heading-message">
      {{headingMessage}}
    </div>
  </div>
  <div
    *ngIf="tabs.length"
    class="tab-content dash-tab-content-area"
  >
    <ng-content></ng-content>
  </div>
</div>
