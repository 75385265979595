import { ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { SupportService } from '@app/modules/service-and-support/support.service';
import { IDropdown, NotificationMessages } from '@app/shared/constants/global-constants';
import { NameId } from '@app/shared/interfaces/support.interface';
import { Subject, takeUntil } from 'rxjs';
import { CompanyDetails } from '../../payment-arrangements/payment-arrangements.interface';
import { PaymentArrangementsService } from '../../payment-arrangements/payment-arrangements.service';

@Component({
  selector: 'company-details-form',
  templateUrl: './company-details-form.component.html',
  styleUrls: ['./company-details-form.component.scss']
})
export class CompanyDetailsFormComponent implements OnInit, OnDestroy {
  @Output() onClearAllFormSection: EventEmitter<void> = new EventEmitter<void>();

  isLoadingCompanies: boolean = false;
  isLoadingAlternateContact: boolean = false;
  companyDetails: CompanyDetails = this.defaultCompanyDetailsValue();
  alternateContactDetails: CompanyDetails = this.defaultCompanyDetailsValue();
  companies: IDropdown[] = [];
  userDropdownOptions: NameId[] = []; 
  form: FormGroup;
  isAlternativeContactToggle: boolean = false;
  isEdit: boolean = false;

  private _$unsubscribe: Subject<void> = new Subject<void>();

  private _paymentArrangementService = inject(PaymentArrangementsService); 
  private _notifier = inject(NotificationService);
  private _companiesService = inject(CompaniesService);
  private _userService = inject(UserService); 
  private _supportService = inject(SupportService);
  private _cd = inject(ChangeDetectorRef);
  private _route = inject(ActivatedRoute);
  private _fb = inject(FormBuilder);

  get isSubmitted() {
    return this._paymentArrangementService.data.isSubmitted;
  }

  get hasBillingContactId() {
    return this._paymentArrangementService.data.billingContactId;
  }

  ngOnInit(): void {
    this.isEdit = !!Number(this._route.snapshot.paramMap.get('id'));

    this._initForm();
    this._initCompanies();
    this.handleCompanyDetailsChange();
  }

  defaultCompanyDetailsValue() {
    return {
      billingContact: {
        id: 0,
        name: '',
        title: '',
        email: '',
        phone: ''
      },
      pastDue: 0,
      availableCredits: 0
    }
  }

  handleCompanyDetailsChange() {
    this.onClearAllFormSection.emit();

    if (!this.isEdit) { this.form.controls['alternateContactId'].disable(); }
    
    if (this.form.controls['companyId'].value) {
      this.isLoadingCompanies = true;

      this._paymentArrangementService.getPaymentArrangementByCompany({ companyId: this.form.controls['companyId'].value })
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: (res) => {
          if (res) {
            if (!this.isEdit) { this.form.controls['alternateContactId'].enable(); }

            this.companyDetails = res;
            this.form.controls['billingContactId'].setValue(this.companyDetails.billingContact?.id);

            /* Edit mode */ 
            if (this.isEdit && this._paymentArrangementService.paymentSetupForm.billingInfo) {
              if (res.billingContact?.id) {
                this.form.controls['billingContactId'].setValue(this._paymentArrangementService.paymentSetupForm.billingInfo.billingContact.id);
              }

              if (this._paymentArrangementService.paymentSetupForm.billingInfo.billingContact.id !== this.companyDetails.billingContact?.id) {
                this.alternateContactDetails.billingContact = this._paymentArrangementService.paymentSetupForm.billingInfo.billingContact;
                this.form.controls['alternateContactId'].setValue(this._paymentArrangementService.paymentSetupForm.billingInfo.billingContact.id);
                this.initUsers();
              }
            }
          }
          this.isLoadingCompanies = false;
          this._cd.detectChanges();
        },
        error: () => {
          this.isLoadingCompanies = false;
          this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
        }
      });

      this._cd.detectChanges();

    } else {
      this.companyDetails = this.defaultCompanyDetailsValue();
      this.form.controls['alternateContactId'].setValue(null);
      this.handleAlternativeContactChange();
    }
  }

  handleAlternativeContactChange() {
    if (this.form.controls['alternateContactId'].value) {
      this.isLoadingAlternateContact = true;
      
      let param = { ...this.form.value };

      if (this.isEdit) {
        param = { ...this.form.value, companyId: this._paymentArrangementService.paymentSetupForm.paymentInfo?.setup?.companyId };   
      } 

      this._paymentArrangementService.getPaymentArrangementByCompany(param)
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: (res) => {
          if (res) {
            this.alternateContactDetails = res;
            this.form.controls['billingContactId'].setValue(this.alternateContactDetails.billingContact?.id);
            this._paymentArrangementService.formData.availableCredits = res.availableCredits;
          }
          this.isLoadingAlternateContact = false;
          this._cd.detectChanges();
        },
        error: () => {
          this.isLoadingAlternateContact = false;
          this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
        }
      });

      this._cd.detectChanges();

    } else {

      this.isLoadingAlternateContact = false;
      this.alternateContactDetails = this.defaultCompanyDetailsValue();
      this.form.controls['billingContactId'].setValue(this.companyDetails.billingContact?.id);
    }
  }

  onRemoveAlternativeContact() {
    this.isAlternativeContactToggle = false;
    this.form.controls['alternateContactId'].setValue(null);
    this.form.controls['billingContactId'].setValue(this.companyDetails.billingContact?.id);
    this.alternateContactDetails = this.defaultCompanyDetailsValue();
  }

  private _initCompanies() {
		this._companiesService
			.getCompanyDropdown()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
        next: (res) => {
          this.companies = res;
        },
        error: () => {
          this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
        },
      });
	}

  private _initForm() {
    this.form = this._fb.group({
      companyId: ['', Validators.required],
      billingContactId: ['', Validators.required],
      alternateContactId: [''],
    });
  }

  initUsers() {
    this.isAlternativeContactToggle = true;

    this._supportService.userDropdownForm(Number(this.form.controls['companyId'].value))
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (res) => {
        if (Array.isArray(res) && res.length) {
          this.userDropdownOptions = res.filter(x => Number(x.id) !== Number(this.companyDetails.billingContact?.id));

        } else {
          this.userDropdownOptions = [];
        }
      },
      error: () => {
        this._notifier.notifyError(NotificationMessages.Retrieval, NotificationMessages.Try);
      },
    });
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
