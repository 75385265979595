import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchStatus } from '@app/shared/interfaces/support.interface';
import { BehaviorSubject, Subscription, debounceTime, finalize, merge, retry, tap } from 'rxjs';
import { NotificationService } from '@app/core/services/notification.service';
import { DragDropListItem, DragDropListData } from '@app/shared/components/form-input/lists-drag-drop-input/lists-drag-drop-input.component';
import { UserService } from '@app/core/services/user.service';
import { ApplicationType, HistoryUrl, NotificationMessages, Permission } from '@app/shared/constants';
import { RolesAndPermissionService } from '../../roles-and-permission.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { RoleHeader } from '../../roles-and-permission.interface';


@Component({
	selector: 'rp-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss'],
})
export class RPManageUsers implements OnInit {
	id: number;
	companyId: number;
	role: RoleHeader;
	isSPAdmin: boolean;
	isParentCompany: boolean;
	companyUrl: string;
	companyControl = new FormControl<any>({});

	listControl = new FormControl<number[]>([]);
	listData: DragDropListData;
	availableUsers: DragDropListItem[] = [];
	availableUsersSub = new BehaviorSubject<DragDropListItem[]>([]);
	roleUsers: DragDropListItem[] = [];
	roleUsersSub = new BehaviorSubject<DragDropListItem[]>([]);
	selectedAvailableCount: number;
	selectedRoleUsersCount: number;
	toBeAddedUsers: number[] = [];
	toBeRemovedUsers: number[] = [];

	searchControl = new FormControl('');
	searchControl2 = new FormControl('');
	searchSub: Subscription;
	searchSub2: Subscription;
	searchStatus = SearchStatus.default;
	searchStatus2 = SearchStatus.default;
	filter = new FormGroup({
		isDefaultContact: new FormControl(false),
		isBillingContact: new FormControl(false),
	});
	filter2 = new FormGroup({
		isDefaultContact: new FormControl(false),
		isBillingContact: new FormControl(false),
	});
	pageSize = 20; // Default size to work properly
	page = 1;
	count = 0;
	page2 = 1;
	count2 = 0;

	isSaving = false;
	isAccessLoading = true;
	isAvailableLoading = true;
	isAddUserEnabled = this._userService.hasPermission([
		Permission.SpAdminContactAddEdit,
		Permission.CompanyAdminContactAddEdit,
	]);
	historyUrl = HistoryUrl;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _spinner: SpinnerService,
		private _rolesPermissionService: RolesAndPermissionService,
		private _userService: UserService,
		private _notifier: NotificationService,
	) {
		this.id = parseInt(this._route.snapshot.params['id']);
		this.isSPAdmin = this._userService.isSpAdmin;
		this.companyId = this.isSPAdmin ? 0 : this._userService.companyId;

		if(this.id) {
			if(history.state.name) {
				this.role = history.state;
				this._rolesPermissionService.setBreadcrumb(this.role!.name);
			}
			else this.getRole();

			if(this._rolesPermissionService.withCompany) {
				this.companyId = this._rolesPermissionService.moduleCompanyId!
			}
			else this.setChildCompanies();

			this.setListData();
			this.getRoleUsers();
			this.getAvailableUsers();
		}
		else this.notFound();
	}

	ngOnInit() {
		// Save roles
		// this.listControl.valueChanges.subscribe(userIds => {
		// 	if(userIds) this.setRoleUsers(userIds);
		// });

		// Filter available users by search
		merge(this.searchControl.valueChanges, this.filter.valueChanges)
		.pipe(
			tap(() => this.unSubSearch()),
			debounceTime(800),
			tap(() => {
				this.resetList();
				this.getAvailableUsers();
			})
		).subscribe();

		// Filter role users by search
		merge(this.searchControl2.valueChanges, this.filter2.valueChanges)
		.pipe(
			tap(() => this.unSubAccessSearch()),
			debounceTime(800),
			tap(() => {
				this.resetAccessList();
				this.getRoleUsers();
			})
		).subscribe();

		// Filter by company
		this.companyControl.valueChanges.pipe(
			tap(() => this.unSubAllSearch()),
			debounceTime(100),
			tap((v) => {
				this.companyId = v['isParentSelector'] ?
					(this.isSPAdmin ? 0 : this._userService.companyId) :
					v['companySelected']['id'];
				this.resetAllList();
				this.getRoleUsers();
				this.getAvailableUsers();
				this.setListTitle();
			})
		).subscribe();
	}

	getRoleUsers() {
		this.searchStatus2 = SearchStatus.searching;
		const search = this.searchControl2.value!.trim();
		const options: any = {
			roleId: this.id,
			companyId: this.companyId,
			searchText: search,
			page: this.page2,
			pageSize: this.pageSize,
			column: 'lastName',
			isAccess: true,
			...this.filter2.value
		}
		this.searchSub2 = this._rolesPermissionService.getRoleUsers(options)
		.pipe(
			retry(1),
			finalize(() => {
				this.searchStatus2 = SearchStatus.complete;
				this.updateRoleUsersList();
			}
		))
		.subscribe({
			next: (res) => {
				this.page2 = res.currentPage!+1,
				this.count2 = res.totalCount;
				this.roleUsers = [
					...this.roleUsers,
					...res.data.map(d => ({
						id: d.userId,
						name: (d.lastName ? (d.lastName + ', ') : '') + d.firstName,
						description: d.title != 'null' ? d.title : undefined,
						imgPath: d.imagePath,
						isUser: true,
						extraInfo: d.companyName,
						iconName: d.isBillingContact ? 'currency-dollar-circle' : undefined
					}))
				];
				this.listControl.setValue(this.roleUsers.map(u => u.id), {emitEvent: false});
			},
			error: (err) => {
				this._notifier.notifyError('Retrieval Failed', NotificationMessages.RefreshTry);
			}
		})
	}

	getAvailableUsers() {
		this.searchStatus = SearchStatus.searching;
		const search = this.searchControl.value!.trim();
		const options: any = {
			roleId: this.id,
			companyId: this.companyId,
			searchText: search,
			page: this.page,
			pageSize: this.pageSize,
			column: 'lastName',
			isAccess: false,
			...this.filter.value
		}
		this.searchSub = this._rolesPermissionService.getRoleUsers(options)
		.pipe(finalize(() => {
			this.searchStatus = SearchStatus.complete;
			this.updateAvailableUsersList();
		}))
		.subscribe({
			next: (res) => {
				this.page = res.currentPage!+1,
				this.count = res.totalCount;
				this.availableUsers = [
					...this.availableUsers,
					...res.data.map(d => ({
						id: d.userId,
						name: (d.lastName ? (d.lastName + ', ') : '') + d.firstName,
						description: d.title != 'null' ? d.title : undefined,
						imgPath: d.imagePath,
						isUser: true,
						extraInfo: d.companyName,
						iconName: d.isBillingContact ? 'currency-dollar-circle' : undefined
					}))
				];
			},
			error: (error) => {
				this._notifier.notifyError('Retrieval Failed', NotificationMessages.RefreshTry);
			},
		});
	}

	updateRoleUsersList(isLoading: boolean = false) {
		this.listData.selected.isFiltered = !!this.searchControl2.value?.trim(),
		this.listData.selected.isLoading = isLoading,
		this.isAccessLoading = false;
		this.roleUsersSub.next(this.roleUsers)
	}

	updateAvailableUsersList(isLoading: boolean = false) {
		this.listData.available.isFiltered = !!this.searchControl.value?.trim(),
		this.listData.available.isLoading = isLoading,
		this.isAvailableLoading = false;
		this.availableUsersSub.next(this.availableUsers);
	}

	setRoleUsers(usersAdded: number[], usersRemoved: number[], isFinal = false) {
		return new Promise<boolean>((resolve) => {
			this._rolesPermissionService.setRoleUsers(this.id, usersAdded, usersRemoved, isFinal)
			.pipe(retry(1))
			.subscribe({
				next: (res) => resolve(true),
				error: (error) => {
					resolve(false);
					this._notifier.notifyError('Update Failed', NotificationMessages.Try);
				}
			});
		});
	}

	save(isFinish = true) {
		this.isSaving = true;
		// if(this.toBeAddedUsers.length <= 0 && this.toBeRemovedUsers.length <=0){
		// 	this._notifier.notifyError(NotificationMessages.unable('Update Users'), NotificationMessages.NoChangesToApply);
		// 	this._router.navigateByUrl('/roles-and-permissions', {replaceUrl: true});
		// 	return ;
		// }
		this.setRoleUsers(this.toBeAddedUsers, this.toBeRemovedUsers, isFinish).then(res => {
			if(!res) this.isSaving = false;
			else {
				// /companies/contact-setting/roles-and-permissions/38964
				if(this._rolesPermissionService.stateParent === 'company'){
					const companyId = this._rolesPermissionService.stateCompanyId;
					this._router.navigateByUrl(`/companies/contact-setting/roles-and-permissions/${companyId}`, {replaceUrl: true});
				}
				else this._router.navigateByUrl('/roles-and-permissions', {replaceUrl: true});
			}
		});
	}

	onUsersRemoved(users: number[]) {
		this.toBeAddedUsers = this.toBeAddedUsers.filter(u => !users.includes(u));
		this.toBeRemovedUsers = [...new Set([...this.toBeRemovedUsers, ...users])];
	}

	onUsersAdded(users: number[]) {
		this.toBeRemovedUsers = this.toBeRemovedUsers.filter(u => !users.includes(u));
		this.toBeAddedUsers = [...new Set([...this.toBeAddedUsers, ...users])];
	}

	onScroll() {
		if(
			this.searchStatus != SearchStatus.searching &&
			this.count - (this.pageSize * this.page) > -this.pageSize
			) {
			this.listData.available.isLoading = true,
			this.getAvailableUsers();
		}
	}

	onScrollAccess() {
		if(
			this.searchStatus2 != SearchStatus.searching &&
			this.count2 - (this.pageSize * this.page2) > -this.pageSize
			) {
			this.listData.selected.isLoading = true,
			this.getRoleUsers();
		}
	}

	export(exportType: ApplicationType) {
		if (!this.listControl.value?.length) {
			this._notifier.notifyError(NotificationMessages.NoRecordFound);
		} else {
			this._spinner.start();
			this._rolesPermissionService.exportRoleUsers(this.id, exportType, this.companyId)
			.pipe(finalize(() => this._spinner.stop()))
			.subscribe({
				next: (resp) => {
					const link = document.createElement('a');
					link.href = resp;
					link.setAttribute('download', 'Role Users');
					document.body.appendChild(link);
					link.click();
					this._notifier.notifySuccess(NotificationMessages.Export);
				},
				error: () => {
					this._notifier.notifySuccess('Export Failed', NotificationMessages.Try);
				},
			});
		}
	}

	setListData() {
		this.listData = {
			available: {
				title: 'Available Users',
				header: 'Name & Title',
				items: this.availableUsersSub.asObservable(),
				isLoading: true,
				allowFilter: false,
			},
			selected: {
				title: 'Users with Access',
				header: 'Name & Title',
				items: this.roleUsersSub.asObservable(),
				isLoading: true,
				allowFilter: false,
			}
		};

		this.setListTitle();
	}

	setListTitle() {
		const dat = 'Available Users';
		const dst = 'Users with Access';
		let cname = '';

		if((this.isSPAdmin || this.isParentCompany) && this.companyId) {
			cname = this.companyId == this._userService.companyId ?
			this._userService.user?.companyName :
			(this.companyControl.value?.companySelected || this._rolesPermissionService.moduleCompany?.companySelected)?.name;
		}

		this.listData.available.title = dat + (cname ? (' for '+cname) : ''),
		this.listData.selected.title = dst + (cname ? (' for '+cname) : '');

		const eHeader = this.companyId ? '' : 'Company';
		this.listData.available.extraInfoHeader = eHeader,
		this.listData.selected.extraInfoHeader = eHeader;
	}

	resetList() {
		this.page = 1;
		this.count = 0;
		this.availableUsers = [];
		this.updateAvailableUsersList(true);
	}

	resetAccessList() {
		this.page2 = 1;
		this.count2 = 0;
		this.roleUsers = [];
		this.updateRoleUsersList(true);
	}

	resetAllList() {
		this.resetList();
		this.resetAccessList();
		this.searchControl.setValue('', {emitEvent: false});
		this.searchControl2.setValue('', {emitEvent: false});
		this.selectedRoleUsersCount = 0;
		this.selectedAvailableCount = 0;
		this.setListData(); // Reset the list
	}

	unSubSearch() {
		this.searchStatus = SearchStatus.searching;
		if(this.searchSub && !this.searchSub.closed)
			this.searchSub.unsubscribe();
	}

	unSubAccessSearch() {
		this.searchStatus2 = SearchStatus.searching;
		if(this.searchSub2 && !this.searchSub2.closed)
			this.searchSub2.unsubscribe();
	}

	unSubAllSearch() {
		this.unSubSearch();
		this.unSubAccessSearch();
	}

	setChildCompanies() {
		this.companyUrl = this.isSPAdmin ?
			'companies/GetDropdown' :
			`ParentChildCompany/GetCurrentChildren/${this.companyId}?access=roles`;

		if(this.isSPAdmin && !this._userService.isClientAdmin) return;

		// TODO: ChildCompanies
		// const companyId = this._userService.companyId;
		// this._rolesPermissionService.getRoleChildCompanies(companyId).subscribe(res => {
		// 	if(res.length) this.isParentCompany = true;
		// })
	}

	getRole() {
		this._rolesPermissionService.getRoleData(this.id)
		.pipe(retry(1))
		.subscribe({
			next: (res) => {
				this.role = res,
				this._rolesPermissionService.setBreadcrumb(this.role!.name);
			},
			error: (error) => this.notFound()
		});
	}

	notFound() {
		this._notifier.notifyError('Role Not Found');
		this._router.navigateByUrl('/roles-and-permissions', {replaceUrl: true});
	}

	get applicationType() {
		return ApplicationType;
	}

	get isLoading(){return this.isAccessLoading || this.isAvailableLoading}
}
