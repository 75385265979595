			<div class="card-2 q-mb-24">
				<div class="card-header-2 q-jc-start">
						<h3>Manage Roles and Permissions</h3>
				</div>

				<div class="card-form q-p-0">
					<div class="col-md-4" *ngIf="moduleLevel === apiUrl.GLOBAL">
						<div class="d-block ms-3">
							<label>Roles:</label>
							<select-input
								[(ngModel)]="currentRole"
								(ngModelChange)="selectRole(currentRole)"
								[options]="roleList"
								[optionKeys]="{ value: 'id', label: 'name' }"
								inputClass="form-control"
							>
							</select-input>
						</div>
					</div>
					<div
						class="container-accessible ms-3 imp-parent"
						*ngIf="moduleLevel !== apiUrl.GLOBAL"
					>
						<p class="bold">
							Select the menu that you want to display. By selecting menu here
							you will override the
							{{
								moduleLevel === apiUrl.COMPANY ? apiUrl.GLOBAL : apiUrl.COMPANY
							}}
							role menu permission settings for this {{ moduleLevel }}.
						</p>
						<mat-slide-toggle
							class="example-margin imp-disabled"
							[(ngModel)]="accessibleOption"
							(ngModelChange)="onOverrideFlag()"
							[color]="'primary'"
							[disabled]="false"
						>
							Override
							{{
								moduleLevel === apiUrl.COMPANY ? apiUrl.GLOBAL : apiUrl.COMPANY
							}}
							role menu permission settings
						</mat-slide-toggle>
					</div>
					<div class="permissions-heading">
						<div class="row">
							<div class="col-md-7">
								<h2>Modules</h2>
								<div>
									<mat-tab-group
										[selectedIndex]="selectedTab.value"
										(selectedIndexChange)="selectTab($event)"
									>
										<mat-tab
											label="Sp Admin Permissions"
											*ngIf="isSpAdminTabVisible(currentRole, contactRoleId)"
										>
											<div class="form-check mt-3 mb-0 q-default">
												<app-figma-icon-img
													[iconName]="
														checkIfSelectedAll('sp-admin')
															? 'check-square'
															: 'square'
													"
													[isForButton]="true"
												></app-figma-icon-img>

												<input
													[checked]="checkIfSelectedAll('sp-admin')"
													(change)="selectAllModule($event, 'sp-admin')"
													[disabled]="
														!accessibleOption && moduleLevel !== apiUrl.GLOBAL
													"
													type="checkbox"
													class="form-check-input imp-disabled-bg"
													id="select-all"
												/>
												<label class="custom-control-label" for="select-all">
													Select All
												</label>
											</div>
											<div class="accordion-section">
												<div id="accordion">
													<ng-container
														*ngFor="let module of spAdminModules; let j = index"
													>
														<ng-container
															*ngIf="
																module.subMenus && module.subMenus.length > 0;
																else noSubMenu
															"
														>
															<div class="accordion-container 1">
																<div class="set">
																	<a
                                    class="mapper_toggleGroup"
																		href="JavaScript:void(0);"
																		[class.active]="
																			selectedMenuId === module.id
																		"
																	>
																		<h4
																			class="accordion-toggle"
																			(click)="toggleMenu(module)"
																		>
																			<div
																				class="form-check"
																				(click)="removeActiveClass()"
																				style="position: relative; top: 3px"
																			>
																				<app-figma-icon-img
																					[iconName]="
																						checkPermission(module) &&
																						checkedModule[menuIds][module.id]
																							? 'minus-square'
																							: checkedModule[menuIds][
																									module.id
																							  ]
																							? 'check-square'
																							: 'square'
																					"
																					[isForButton]="true"
																				></app-figma-icon-img>

																				<input
																					[ngClass]="{
																						'not-check-all':
																							checkPermission(module)
																					}"
																					class="form-check-input imp-disabled-bg top-level-checkbox"
																					type="checkbox"
																					(click)="$event.stopPropagation()"
																					[(ngModel)]="
																						checkedModule[menuIds][module.id]
																					"
																					[disabled]="
																						!accessibleOption &&
																						moduleLevel !== apiUrl.GLOBAL
																					"
																					(ngModelChange)="
																						setModule(menuIds, module)
																					"
																					id="fcustomCheck33"
																					style="top: -3px; left: 10px"
																				/>
																				<label
																					class="custom-control-label"
																					for="customCheck33"
																				>
																					{{ module.name }} ({{
																						module.subMenus.length
																					}})
																				</label>
																			</div>
																		</h4>
																		<div class="content">
																			<div
																				class="inner-accordion-content2 ps-4"
																				*ngFor="
																					let subMenu of module.subMenus;
																					let i = index
																				"
																			>
																				<ng-container
																					*ngIf="
																						subMenu.subMenus.length === 0;
																						else thirdMenu
																					"
																				>
																					<div
																						class="highlight-hover no-sub-menu"
																						[class.highlight]="
																							selectedModuleId === subMenu.id
																						"
																						(click)="changePermission(subMenu)"
																					>
																						<div
																							class="form-check highlight-hover"
																							(click)="addActiveClass($event)"
																						>
																							<app-figma-icon-img
																								[iconName]="
																									checkPermission(subMenu) &&
																									checkedModule[menuIds][
																										subMenu.id
																									]
																										? 'minus-square'
																										: checkedModule[menuIds][
																												subMenu.id
																										  ]
																										? 'check-square'
																										: 'square'
																								"
																								[isForButton]="true"
																							></app-figma-icon-img>

																							<input
																								[ngClass]="{
																									'not-check-all':
																										checkPermission(subMenu)
																								}"
																								class="form-check-input imp-disabled-bg second-level-checkbox"
																								type="checkbox"
																								(click)="
																									$event.stopPropagation()
																								"
																								[(ngModel)]="
																									checkedModule[menuIds][
																										subMenu.id
																									]
																								"
																								[disabled]="
																									!accessibleOption &&
																									moduleLevel !== apiUrl.GLOBAL
																								"
																								(ngModelChange)="
																									setModule(
																										menuIds,
																										module,
																										subMenu
																									)
																								"
																								id="fcustomCheck34"
																								[checked]="false"
																							/>
																							<label
																								class="custom-control-label"
																								for="customCheck34"
																							>
																								{{ subMenu.name }}
																								{{
																									subMenu.actions &&
																									subMenu.actions.length > 0
																										? '(' +
																										  subMenu.actions.length +
																										  ')'
																										: ''
																								}}
																							</label>
																						</div>
																					</div>
																				</ng-container>
																				<ng-template #thirdMenu>
																					<div
																						class="accordion-container second-accordion"
																					>
																						<div class="set">
																							<a
                                                class="mapper_toggleMenu"
																								href="JavaScript:void(0);"
																								[class.active]="
																									selectedSubMenuId ===
																									subMenu.id
																								"
																							>
																								<h4
																									class="accordion-toggle"
																									(click)="
																										toggleMenu(module, subMenu)
																									"
																								>
																									<div
																										class="form-check"
																										style="
																											position: relative;
																											top: 3px;
																										"
																									>
																										<app-figma-icon-img
																											[iconName]="
																												checkPermission(
																													subMenu
																												) &&
																												checkedModule[menuIds][
																													subMenu.id
																												]
																													? 'minus-square'
																													: checkedModule[
																															menuIds
																													  ][subMenu.id]
																													? 'check-square'
																													: 'square'
																											"
																											[isForButton]="true"
																										></app-figma-icon-img>

																										<input
																											[ngClass]="{
																												'not-check-all':
																													checkPermission(
																														subMenu
																													)
																											}"
																											class="form-check-input imp-disabled-bg"
																											type="checkbox"
																											(click)="
																												$event.stopPropagation()
																											"
																											[(ngModel)]="
																												checkedModule[menuIds][
																													subMenu.id
																												]
																											"
																											[disabled]="
																												!accessibleOption &&
																												moduleLevel !==
																													apiUrl.GLOBAL
																											"
																											(ngModelChange)="
																												setModule(
																													menuIds,
																													module,
																													subMenu
																												)
																											"
																											id="fcustomCheck33"
																											style="
																												top: -3px;
																												left: 10px;
																											"
																										/>
																										<label
																											class="custom-control-label"
																											for="customCheck33"
																										>
																											{{ subMenu.name }} ({{
																												subMenu.subMenus.length
																											}})
																										</label>
																									</div>
																								</h4>
																								<div class="content2">
																									<ng-container
																										*ngFor="
																											let thirdMenu of subMenu.subMenus;
																											let k = index
																										"
																									>
																										<div
																											class="inner-accordion-content2 ps-5"
																											*ngIf="
																												thirdMenu.subMenus
																													.length === 0;
																												else fourthMenu
																											"
																										>
																											<div
																												class="highlight-hover"
																												[class.highlight]="
																													selectedThirdMenuId ===
																													thirdMenu.id
																												"
																												(click)="
																													changePermission(
																														thirdMenu
																													)
																												"
																											>
																												<div
																													class="form-check highlight-hover"
																													(click)="
																														addActiveClass(
																															$event
																														)
																													"
																												>
																													<app-figma-icon-img
																														[iconName]="
																															checkPermission(
																																thirdMenu
																															) &&
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																																? 'minus-square'
																																: checkedModule[
																																		menuIds
																																  ][
																																		thirdMenu.id
																																  ]
																																? 'check-square'
																																: 'square'
																														"
																														[isForButton]="true"
																													></app-figma-icon-img>

																													<input
																														[ngClass]="{
																															'not-check-all':
																																checkPermission(
																																	thirdMenu
																																)
																														}"
																														class="form-check-input imp-disabled-bg second-level-checkbox"
																														type="checkbox"
																														(click)="
																															$event.stopPropagation()
																														"
																														[(ngModel)]="
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																														"
																														[disabled]="
																															!accessibleOption &&
																															moduleLevel !==
																																apiUrl.GLOBAL
																														"
																														(ngModelChange)="
																															setModule(
																																menuIds,
																																module,
																																subMenu,
																																thirdMenu
																															)
																														"
																														id="fcustomCheck34"
																														[checked]="false"
																													/>
																													<label
																														class="custom-control-label"
																														for="customCheck34"
																													>
																														{{ thirdMenu.name }}
																														{{
																															thirdMenu.actions &&
																															thirdMenu.actions
																																.length > 0
																																? '(' +
																																  thirdMenu
																																		.actions
																																		.length +
																																  ')'
																																: ''
																														}}
																													</label>
																												</div>
																											</div>
																										</div>
																										<ng-template #fourthMenu>
																											<div
																												class="inner-accordion-content3"
																											>
																												<div
																													class="accordion-container second-accordion"
																												>
																													<div class="set">
																														<a
                                                              id="mapper_toggle3rdMenu"
																															href="JavaScript:void(0);"
																															[class.active]="
																																selectedThirdMenuId ===
																																thirdMenu.id
																															"
																														>
																															<h4
																																class="accordion-toggle"
																																(click)="
																																	toggleMenu(
																																		module,
																																		subMenu,
																																		thirdMenu
																																	)
																																"
																															>
																																<div
																																	class="form-check"
																																	style="
																																		position: relative;
																																		top: 3px;
																																	"
																																>
																																	<app-figma-icon-img
																																		[iconName]="
																																			checkPermission(
																																				thirdMenu
																																			) &&
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																				? 'minus-square'
																																				: checkedModule[
																																						menuIds
																																				  ][
																																						thirdMenu
																																							.id
																																				  ]
																																				? 'check-square'
																																				: 'square'
																																		"
																																		[isForButton]="
																																			true
																																		"
																																	></app-figma-icon-img>

																																	<input
																																		[ngClass]="{
																																			'not-check-all':
																																				checkPermission(
																																					thirdMenu
																																				)
																																		}"
																																		class="form-check-input imp-disabled-bg"
																																		type="checkbox"
																																		(click)="
																																			$event.stopPropagation()
																																		"
																																		[(ngModel)]="
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																		"
																																		[disabled]="
																																			!accessibleOption &&
																																			moduleLevel !==
																																				apiUrl.GLOBAL
																																		"
																																		(ngModelChange)="
																																			setModule(
																																				menuIds,
																																				module,
																																				subMenu,
																																				thirdMenu
																																			)
																																		"
																																		id="fcustomCheck33"
																																		style="
																																			top: -3px;
																																			left: 10px;
																																		"
																																	/>
																																	<label
																																		class="custom-control-label"
																																		for="customCheck33"
																																	>
																																		{{
																																			thirdMenu.name
																																		}}
																																		({{
																																			thirdMenu
																																				.subMenus
																																				.length
																																		}})
																																	</label>
																																</div>
																															</h4>
																															<div
																																class="content3"
																															>
																																<ng-container
																																	*ngFor="
																																		let fourthMenu of thirdMenu.subMenus;
																																		let k = index
																																	"
																																>
																																	<div
																																		class="inner-accordion-content2 ps-5"
																																		*ngIf="
																																			fourthMenu.subMenus !==
																																				null &&
																																				fourthMenu
																																					.subMenus
																																					.length ===
																																					0;
																																			else fifthMenu
																																		"
																																	>
																																		<div
																																			class="highlight-hover"
																																			[class.highlight]="
																																				selectedFourthMenuId ===
																																				fourthMenu.id
																																			"
																																			(click)="
																																				changePermission(
																																					fourthMenu
																																				)
																																			"
																																		>
																																			<div
																																				class="form-check highlight-hover"
																																				(click)="
																																					addActiveClass(
																																						$event
																																					)
																																				"
																																			>
																																				<app-figma-icon-img
																																					[iconName]="
																																						checkPermission(
																																							fourthMenu
																																						) &&
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																							? 'minus-square'
																																							: checkedModule[
																																									menuIds
																																							  ][
																																									fourthMenu
																																										.id
																																							  ]
																																							? 'check-square'
																																							: 'square'
																																					"
																																					[isForButton]="
																																						true
																																					"
																																				></app-figma-icon-img>

																																				<input
																																					[ngClass]="{
																																						'not-check-all':
																																							checkPermission(
																																								fourthMenu
																																							)
																																					}"
																																					class="form-check-input imp-disabled-bg second-level-checkbox"
																																					type="checkbox"
																																					(click)="
																																						$event.stopPropagation()
																																					"
																																					[(ngModel)]="
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																					"
																																					[disabled]="
																																						!accessibleOption &&
																																						moduleLevel !==
																																							apiUrl.GLOBAL
																																					"
																																					(ngModelChange)="
																																						setModule(
																																							menuIds,
																																							module,
																																							subMenu,
																																							thirdMenu,
																																							fourthMenu
																																						)
																																					"
																																					id="fcustomCheck34"
																																					[checked]="
																																						false
																																					"
																																				/>
																																				<label
																																					class="custom-control-label"
																																					for="customCheck34"
																																				>
																																					{{
																																						fourthMenu.name
																																					}}
																																					{{
																																						fourthMenu.actions &&
																																						fourthMenu
																																							.actions
																																							.length >
																																							0
																																							? '(' +
																																							  fourthMenu
																																									.actions
																																									.length +
																																							  ')'
																																							: ''
																																					}}
																																				</label>
																																			</div>
																																		</div>
																																	</div>
																																	<ng-template
																																		#fifthMenu
																																	>
																																		<div
																																			class="inner-accordion-content3"
																																		>
																																			<div
																																				class="accordion-container second-accordion"
																																			>
																																				<div
																																					class="set"
																																				>
																																					<a
                                                                            id="mapper_toggle4thMenu"
																																						href="JavaScript:void(0);"
																																						[class.active]="
																																							selectedFourthMenuId ===
																																							fourthMenu.id
																																						"
																																					>
																																						<h4
																																							class="accordion-toggle"
																																							(click)="
																																								toggleMenu(
																																									module,
																																									subMenu,
																																									thirdMenu,
																																									fourthMenu
																																								)
																																							"
																																						>
																																							<div
																																								class="form-check"
																																								style="
																																									position: relative;
																																									top: 3px;
																																								"
																																							>
																																								<app-figma-icon-img
																																									[iconName]="
																																										checkPermission(
																																											fourthMenu
																																										) &&
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																											? 'minus-square'
																																											: checkedModule[
																																													menuIds
																																											  ][
																																													fourthMenu
																																														.id
																																											  ]
																																											? 'check-square'
																																											: 'square'
																																									"
																																									[isForButton]="
																																										true
																																									"
																																								></app-figma-icon-img>

																																								<input
																																									[ngClass]="{
																																										'not-check-all':
																																											checkPermission(
																																												fourthMenu
																																											)
																																									}"
																																									class="form-check-input imp-disabled-bg"
																																									type="checkbox"
																																									(click)="
																																										$event.stopPropagation()
																																									"
																																									[(ngModel)]="
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																									"
																																									[disabled]="
																																										!accessibleOption &&
																																										moduleLevel !==
																																											apiUrl.GLOBAL
																																									"
																																									(ngModelChange)="
																																										setModule(
																																											menuIds,
																																											module,
																																											subMenu,
																																											thirdMenu,
																																											fourthMenu
																																										)
																																									"
																																									id="fcustomCheck33"
																																									style="
																																										top: -3px;
																																										left: 10px;
																																									"
																																								/>
																																								<label
																																									class="custom-control-label"
																																									for="customCheck33"
																																								>
																																									{{
																																										fourthMenu.name
																																									}}
																																									({{
																																										fourthMenu
																																											.subMenus
																																											.length
																																									}})
																																								</label>
																																							</div>
																																						</h4>
																																						<div
																																							class="content4"
																																						>
																																							<div
																																								class="inner-accordion-content2 ps-5"
																																								*ngFor="
																																									let fifthMenu of fourthMenu.subMenus;
																																									let k = index
																																								"
																																							>
																																								<div
																																									class="highlight-hover"
																																									(click)="
																																										changePermission(
																																											fifthMenu
																																										)
																																									"
																																								>
																																									<div
																																										class="form-check highlight-hover"
																																										(click)="
																																											addActiveClass(
																																												$event
																																											)
																																										"
																																									>
																																										<app-figma-icon-img
																																											[iconName]="
																																												checkPermission(
																																													fifthMenu
																																												) &&
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																													? 'minus-square'
																																													: checkedModule[
																																															menuIds
																																													  ][
																																															fifthMenu
																																																.id
																																													  ]
																																													? 'check-square'
																																													: 'square'
																																											"
																																											[isForButton]="
																																												true
																																											"
																																										></app-figma-icon-img>

																																										<input
																																											[ngClass]="{
																																												'not-check-all':
																																													checkPermission(
																																														fifthMenu
																																													)
																																											}"
																																											class="form-check-input imp-disabled-bg second-level-checkbox"
																																											type="checkbox"
																																											(click)="
																																												$event.stopPropagation()
																																											"
																																											[(ngModel)]="
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																											"
																																											[disabled]="
																																												!accessibleOption &&
																																												moduleLevel !==
																																													apiUrl.GLOBAL
																																											"
																																											(ngModelChange)="
																																												setModule(
																																													menuIds,
																																													module,
																																													subMenu,
																																													thirdMenu,
																																													fourthMenu,
																																													fifthMenu
																																												)
																																											"
																																											id="fcustomCheck34"
																																											[checked]="
																																												false
																																											"
																																										/>
																																										<label
																																											class="custom-control-label"
																																											for="customCheck34"
																																										>
																																											{{
																																												fifthMenu.name
																																											}}
																																											{{
																																												fifthMenu.actions &&
																																												fifthMenu
																																													.actions
																																													.length >
																																													0
																																													? '(' +
																																													  fifthMenu
																																															.actions
																																															.length +
																																													  ')'
																																													: ''
																																											}}
																																										</label>
																																									</div>
																																								</div>
																																							</div>
																																						</div>
																																					</a>
																																				</div>
																																			</div>
																																		</div>
																																	</ng-template>
																																</ng-container>
																															</div>
																														</a>
																													</div>
																												</div>
																											</div>
																										</ng-template>
																									</ng-container>
																								</div>
																							</a>
																						</div>
																					</div>
																				</ng-template>
																			</div>
																		</div>
																	</a>
																</div>
															</div>
														</ng-container>
														<ng-template #noSubMenu>
															<a
                                id="mapper_changePerm"
																href="javaScript:void(0);"
																(click)="changePermission(module)"
															>
																<h4 class="accordion-toggle arrow-none 6">
																	<div
																		class="form-check highlight-hover"
																		(click)="addActiveClass($event)"
																		style="position: relative; top: 3px"
																	>
																		<app-figma-icon-img
																			[iconName]="
																				checkPermission(module) &&
																				checkedModule[menuIds][module.id]
																					? 'minus-square'
																					: checkedModule[menuIds][module.id]
																					? 'check-square'
																					: 'square'
																			"
																			[isForButton]="true"
																		></app-figma-icon-img>

																		<input
																			[ngClass]="{
																				'not-check-all': checkPermission(module)
																			}"
																			class="form-check-input imp-disabled-bg"
																			type="checkbox"
																			(click)="$event.stopPropagation()"
																			[(ngModel)]="
																				checkedModule[menuIds][module.id]
																			"
																			[disabled]="
																				!accessibleOption &&
																				moduleLevel !== apiUrl.GLOBAL
																			"
																			(ngModelChange)="
																				setModule(menuIds, module)
																			"
																			id="fcustomCheck33"
																			style="top: -3px; left: 10px"
																		/>
																		<label
																			class="custom-control-label"
																			for="customCheck33"
																		>
																			{{ module.name }}
																			{{
																				module.actions.length > 0
																					? '(' + module.actions.length + ')'
																					: ''
																			}}
																		</label>
																	</div>
																</h4>
															</a>
														</ng-template>
													</ng-container>
												</div>
											</div>
										</mat-tab>
										<mat-tab
											label="Client Admin Permissions"
											*ngIf="
												isClientAdminTabVisible(currentRole, contactRoleId)
											"
										>
											<div class="form-check mt-3 mb-0 q-default">
												<app-figma-icon-img
													[iconName]="
														checkIfSelectedAll('client-admin')
															? 'check-square'
															: 'square'
													"
													[isForButton]="true"
												></app-figma-icon-img>

												<input
													[checked]="checkIfSelectedAll('client-admin')"
													(change)="selectAllModule($event, 'client-admin')"
													[disabled]="
														!accessibleOption && moduleLevel !== apiUrl.GLOBAL
													"
													type="checkbox"
													class="form-check-input imp-disabled-bg"
													id="select-all"
												/>
												<label class="custom-control-label" for="select-all">
													Select All
												</label>
											</div>
											<div class="accordion-section">
												<div id="accordion">
													<ng-container
														*ngFor="
															let module of clientAdminModules;
															let j = index
														"
													>
														<ng-container
															*ngIf="
																module.subMenus && module.subMenus.length > 0;
																else noSubMenu
															"
														>
															<div class="accordion-container 1">
																<div class="set">
																	<a
                                  id="mapper_toggleMenu1"
																		href="JavaScript:void(0);"
																		[class.active]="
																			selectedMenuId === module.id
																		"
																	>
																		<h4
																			class="accordion-toggle"
																			(click)="toggleMenu(module)"
																		>
																			<div
																				class="form-check"
																				(click)="removeActiveClass()"
																				style="position: relative; top: 3px"
																			>
																				<app-figma-icon-img
																					[iconName]="
																						checkPermission(module) &&
																						checkedModule[menuIds][module.id]
																							? 'minus-square'
																							: checkedModule[menuIds][
																									module.id
																							  ]
																							? 'check-square'
																							: 'square'
																					"
																					[isForButton]="true"
																				></app-figma-icon-img>

																				<input
																					[ngClass]="{
																						'not-check-all':
																							checkPermission(module)
																					}"
																					class="form-check-input imp-disabled-bg top-level-checkbox"
																					type="checkbox"
																					(click)="$event.stopPropagation()"
																					[(ngModel)]="
																						checkedModule[menuIds][module.id]
																					"
																					[disabled]="
																						!accessibleOption &&
																						moduleLevel !== apiUrl.GLOBAL
																					"
																					(ngModelChange)="
																						setModule(menuIds, module)
																					"
																					id="fcustomCheck33"
																					style="top: -3px; left: 10px"
																				/>
																				<label
																					class="custom-control-label"
																					for="customCheck33"
																				>
																					{{ module.name }} ({{
																						module.subMenus.length
																					}})
																				</label>
																			</div>
																		</h4>
																		<div class="content">
																			<div
																				class="inner-accordion-content2 ps-4"
																				*ngFor="
																					let subMenu of module.subMenus;
																					let i = index
																				"
																			>
																				<ng-container
																					*ngIf="
																						subMenu.subMenus.length === 0;
																						else thirdMenu
																					"
																				>
																					<div
																						class="highlight-hover no-sub-menu"
																						[class.highlight]="
																							selectedModuleId === subMenu.id
																						"
																						(click)="changePermission(subMenu)"
																					>
																						<div
																							class="form-check highlight-hover"
																							(click)="addActiveClass($event)"
																						>
																							<app-figma-icon-img
																								[iconName]="
																									checkPermission(subMenu) &&
																									checkedModule[menuIds][
																										subMenu.id
																									]
																										? 'minus-square'
																										: checkedModule[menuIds][
																												subMenu.id
																										  ]
																										? 'check-square'
																										: 'square'
																								"
																								[isForButton]="true"
																							></app-figma-icon-img>

																							<input
																								[ngClass]="{
																									'not-check-all':
																										checkPermission(subMenu)
																								}"
																								class="form-check-input imp-disabled-bg second-level-checkbox"
																								type="checkbox"
																								(click)="
																									$event.stopPropagation()
																								"
																								[(ngModel)]="
																									checkedModule[menuIds][
																										subMenu.id
																									]
																								"
																								[disabled]="
																									!accessibleOption &&
																									moduleLevel !== apiUrl.GLOBAL
																								"
																								(ngModelChange)="
																									setModule(
																										menuIds,
																										module,
																										subMenu
																									)
																								"
																								id="fcustomCheck34"
																								[checked]="false"
																							/>
																							<label
																								class="custom-control-label"
																								for="customCheck34"
																							>
																								{{ subMenu.name }}
																								{{
																									subMenu.actions &&
																									subMenu.actions.length > 0
																										? '(' +
																										  subMenu.actions.length +
																										  ')'
																										: ''
																								}}
																							</label>
																						</div>
																					</div>
																				</ng-container>
																				<ng-template #thirdMenu>
																					<div
																						class="accordion-container second-accordion"
																					>
																						<div class="set">
																							<a
                                              id="mapper_toggle3rdMenu"
																								href="JavaScript:void(0);"
																								[class.active]="
																									selectedSubMenuId ===
																									subMenu.id
																								"
																							>
																								<h4
																									class="accordion-toggle"
																									(click)="
																										toggleMenu(module, subMenu)
																									"
																								>
																									<div
																										class="form-check"
																										style="
																											position: relative;
																											top: 3px;
																										"
																									>
																										<app-figma-icon-img
																											[iconName]="
																												checkPermission(
																													subMenu
																												) &&
																												checkedModule[menuIds][
																													subMenu.id
																												]
																													? 'minus-square'
																													: checkedModule[
																															menuIds
																													  ][subMenu.id]
																													? 'check-square'
																													: 'square'
																											"
																											[isForButton]="true"
																										></app-figma-icon-img>

																										<input
																											[ngClass]="{
																												'not-check-all':
																													checkPermission(
																														subMenu
																													)
																											}"
																											class="form-check-input imp-disabled-bg"
																											type="checkbox"
																											(click)="
																												$event.stopPropagation()
																											"
																											[(ngModel)]="
																												checkedModule[menuIds][
																													subMenu.id
																												]
																											"
																											[disabled]="
																												!accessibleOption &&
																												moduleLevel !==
																													apiUrl.GLOBAL
																											"
																											(ngModelChange)="
																												setModule(
																													menuIds,
																													module,
																													subMenu
																												)
																											"
																											id="fcustomCheck33"
																											style="
																												top: -3px;
																												left: 10px;
																											"
																										/>
																										<label
																											class="custom-control-label"
																											for="customCheck33"
																										>
																											{{ subMenu.name }} ({{
																												subMenu.subMenus.length
																											}})
																										</label>
																									</div>
																								</h4>
																								<div class="content2">
																									<ng-container
																										*ngFor="
																											let thirdMenu of subMenu.subMenus;
																											let k = index
																										"
																									>
																										<div
																											class="inner-accordion-content2 ps-5"
																											*ngIf="
																												thirdMenu.subMenus
																													.length === 0;
																												else fourthMenu
																											"
																										>
																											<div
																												class="highlight-hover"
																												[class.highlight]="
																													selectedThirdMenuId ===
																													thirdMenu.id
																												"
																												(click)="
																													changePermission(
																														thirdMenu
																													)
																												"
																											>
																												<div
																													class="form-check highlight-hover"
																													(click)="
																														addActiveClass(
																															$event
																														)
																													"
																												>
																													<app-figma-icon-img
																														[iconName]="
																															checkPermission(
																																thirdMenu
																															) &&
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																																? 'minus-square'
																																: checkedModule[
																																		menuIds
																																  ][
																																		thirdMenu.id
																																  ]
																																? 'check-square'
																																: 'square'
																														"
																														[isForButton]="true"
																													></app-figma-icon-img>

																													<input
																														[ngClass]="{
																															'not-check-all':
																																checkPermission(
																																	thirdMenu
																																)
																														}"
																														class="form-check-input imp-disabled-bg second-level-checkbox"
																														type="checkbox"
																														(click)="
																															$event.stopPropagation()
																														"
																														[(ngModel)]="
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																														"
																														[disabled]="
																															!accessibleOption &&
																															moduleLevel !==
																																apiUrl.GLOBAL
																														"
																														(ngModelChange)="
																															setModule(
																																menuIds,
																																module,
																																subMenu,
																																thirdMenu
																															)
																														"
																														id="fcustomCheck34"
																														[checked]="false"
																													/>
																													<label
																														class="custom-control-label"
																														for="customCheck34"
																													>
																														{{ thirdMenu.name }}
																														{{
																															thirdMenu.actions &&
																															thirdMenu.actions
																																.length > 0
																																? '(' +
																																  thirdMenu
																																		.actions
																																		.length +
																																  ')'
																																: ''
																														}}
																													</label>
																												</div>
																											</div>
																										</div>
																										<ng-template #fourthMenu>
																											<div
																												class="inner-accordion-content3"
																											>
																												<div
																													class="accordion-container second-accordion"
																												>
																													<div class="set">
																														<a
                                                            id="mapper_toggle4thMenu"
																															href="JavaScript:void(0);"
																															[class.active]="
																																selectedThirdMenuId ===
																																thirdMenu.id
																															"
																														>
																															<h4
																																class="accordion-toggle"
																																(click)="
																																	toggleMenu(
																																		module,
																																		subMenu,
																																		thirdMenu
																																	)
																																"
																															>
																																<div
																																	class="form-check"
																																	style="
																																		position: relative;
																																		top: 3px;
																																	"
																																>
																																	<app-figma-icon-img
																																		[iconName]="
																																			checkPermission(
																																				thirdMenu
																																			) &&
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																				? 'minus-square'
																																				: checkedModule[
																																						menuIds
																																				  ][
																																						thirdMenu
																																							.id
																																				  ]
																																				? 'check-square'
																																				: 'square'
																																		"
																																		[isForButton]="
																																			true
																																		"
																																	></app-figma-icon-img>

																																	<input
																																		[ngClass]="{
																																			'not-check-all':
																																				checkPermission(
																																					thirdMenu
																																				)
																																		}"
																																		class="form-check-input imp-disabled-bg"
																																		type="checkbox"
																																		(click)="
																																			$event.stopPropagation()
																																		"
																																		[(ngModel)]="
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																		"
																																		[disabled]="
																																			!accessibleOption &&
																																			moduleLevel !==
																																				apiUrl.GLOBAL
																																		"
																																		(ngModelChange)="
																																			setModule(
																																				menuIds,
																																				module,
																																				subMenu,
																																				thirdMenu
																																			)
																																		"
																																		id="fcustomCheck33"
																																		style="
																																			top: -3px;
																																			left: 10px;
																																		"
																																	/>
																																	<label
																																		class="custom-control-label"
																																		for="customCheck33"
																																	>
																																		{{
																																			thirdMenu.name
																																		}}
																																		({{
																																			thirdMenu
																																				.subMenus
																																				.length
																																		}})
																																	</label>
																																</div>
																															</h4>
																															<div
																																class="content3"
																															>
																																<ng-container
																																	*ngFor="
																																		let fourthMenu of thirdMenu.subMenus;
																																		let k = index
																																	"
																																>
																																	<div
																																		class="inner-accordion-content2 ps-5"
																																		*ngIf="
																																			fourthMenu.subMenus !==
																																				null &&
																																				fourthMenu
																																					.subMenus
																																					.length ===
																																					0;
																																			else fifthMenu
																																		"
																																	>
																																		<div
																																			class="highlight-hover"
																																			[class.highlight]="
																																				selectedFourthMenuId ===
																																				fourthMenu.id
																																			"
																																			(click)="
																																				changePermission(
																																					fourthMenu
																																				)
																																			"
																																		>
																																			<div
																																				class="form-check highlight-hover"
																																				(click)="
																																					addActiveClass(
																																						$event
																																					)
																																				"
																																			>
																																				<app-figma-icon-img
																																					[iconName]="
																																						checkPermission(
																																							fourthMenu
																																						) &&
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																							? 'minus-square'
																																							: checkedModule[
																																									menuIds
																																							  ][
																																									fourthMenu
																																										.id
																																							  ]
																																							? 'check-square'
																																							: 'square'
																																					"
																																					[isForButton]="
																																						true
																																					"
																																				></app-figma-icon-img>

																																				<input
																																					[ngClass]="{
																																						'not-check-all':
																																							checkPermission(
																																								fourthMenu
																																							)
																																					}"
																																					class="form-check-input imp-disabled-bg second-level-checkbox"
																																					type="checkbox"
																																					(click)="
																																						$event.stopPropagation()
																																					"
																																					[(ngModel)]="
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																					"
																																					[disabled]="
																																						!accessibleOption &&
																																						moduleLevel !==
																																							apiUrl.GLOBAL
																																					"
																																					(ngModelChange)="
																																						setModule(
																																							menuIds,
																																							module,
																																							subMenu,
																																							thirdMenu,
																																							fourthMenu
																																						)
																																					"
																																					id="fcustomCheck34"
																																					[checked]="
																																						false
																																					"
																																				/>
																																				<label
																																					class="custom-control-label"
																																					for="customCheck34"
																																				>
																																					{{
																																						fourthMenu.name
																																					}}
																																					{{
																																						fourthMenu.actions &&
																																						fourthMenu
																																							.actions
																																							.length >
																																							0
																																							? '(' +
																																							  fourthMenu
																																									.actions
																																									.length +
																																							  ')'
																																							: ''
																																					}}
																																				</label>
																																			</div>
																																		</div>
																																	</div>
																																	<ng-template
																																		#fifthMenu
																																	>
																																		<div
																																			class="inner-accordion-content3"
																																		>
																																			<div
																																				class="accordion-container second-accordion"
																																			>
																																				<div
																																					class="set"
																																				>
																																					<a
                                                                          id="mapper_toggle5thMenu"
																																						href="JavaScript:void(0);"
																																						[class.active]="
																																							selectedFourthMenuId ===
																																							fourthMenu.id
																																						"
																																					>
																																						<h4
																																							class="accordion-toggle"
																																							(click)="
																																								toggleMenu(
																																									module,
																																									subMenu,
																																									thirdMenu,
																																									fourthMenu
																																								)
																																							"
																																						>
																																							<div
																																								class="form-check"
																																								style="
																																									position: relative;
																																									top: 3px;
																																								"
																																							>
																																								<app-figma-icon-img
																																									[iconName]="
																																										checkPermission(
																																											fourthMenu
																																										) &&
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																											? 'minus-square'
																																											: checkedModule[
																																													menuIds
																																											  ][
																																													fourthMenu
																																														.id
																																											  ]
																																											? 'check-square'
																																											: 'square'
																																									"
																																									[isForButton]="
																																										true
																																									"
																																								></app-figma-icon-img>

																																								<input
																																									[ngClass]="{
																																										'not-check-all':
																																											checkPermission(
																																												fourthMenu
																																											)
																																									}"
																																									class="form-check-input imp-disabled-bg"
																																									type="checkbox"
																																									(click)="
																																										$event.stopPropagation()
																																									"
																																									[(ngModel)]="
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																									"
																																									[disabled]="
																																										!accessibleOption &&
																																										moduleLevel !==
																																											apiUrl.GLOBAL
																																									"
																																									(ngModelChange)="
																																										setModule(
																																											menuIds,
																																											module,
																																											subMenu,
																																											thirdMenu,
																																											fourthMenu
																																										)
																																									"
																																									id="fcustomCheck33"
																																									style="
																																										top: -3px;
																																										left: 10px;
																																									"
																																								/>
																																								<label
																																									class="custom-control-label"
																																									for="customCheck33"
																																								>
																																									{{
																																										fourthMenu.name
																																									}}
																																									({{
																																										fourthMenu
																																											.subMenus
																																											.length
																																									}})
																																								</label>
																																							</div>
																																						</h4>
																																						<div
																																							class="content4"
																																						>
																																							<div
																																								class="inner-accordion-content2 ps-5"
																																								*ngFor="
																																									let fifthMenu of fourthMenu.subMenus;
																																									let k = index
																																								"
																																							>
																																								<div
																																									class="highlight-hover"
																																									(click)="
																																										changePermission(
																																											fifthMenu
																																										)
																																									"
																																								>
																																									<div
																																										class="form-check highlight-hover"
																																										(click)="
																																											addActiveClass(
																																												$event
																																											)
																																										"
																																									>
																																										<app-figma-icon-img
																																											[iconName]="
																																												checkPermission(
																																													fifthMenu
																																												) &&
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																													? 'minus-square'
																																													: checkedModule[
																																															menuIds
																																													  ][
																																															fifthMenu
																																																.id
																																													  ]
																																													? 'check-square'
																																													: 'square'
																																											"
																																											[isForButton]="
																																												true
																																											"
																																										></app-figma-icon-img>

																																										<input
																																											[ngClass]="{
																																												'not-check-all':
																																													checkPermission(
																																														fifthMenu
																																													)
																																											}"
																																											class="form-check-input imp-disabled-bg second-level-checkbox"
																																											type="checkbox"
																																											(click)="
																																												$event.stopPropagation()
																																											"
																																											[(ngModel)]="
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																											"
																																											[disabled]="
																																												!accessibleOption &&
																																												moduleLevel !==
																																													apiUrl.GLOBAL
																																											"
																																											(ngModelChange)="
																																												setModule(
																																													menuIds,
																																													module,
																																													subMenu,
																																													thirdMenu,
																																													fourthMenu,
																																													fifthMenu
																																												)
																																											"
																																											id="fcustomCheck34"
																																											[checked]="
																																												false
																																											"
																																										/>
																																										<label
																																											class="custom-control-label"
																																											for="customCheck34"
																																										>
																																											{{
																																												fifthMenu.name
																																											}}
																																											{{
																																												fifthMenu.actions &&
																																												fifthMenu
																																													.actions
																																													.length >
																																													0
																																													? '(' +
																																													  fifthMenu
																																															.actions
																																															.length +
																																													  ')'
																																													: ''
																																											}}
																																										</label>
																																									</div>
																																								</div>
																																							</div>
																																						</div>
																																					</a>
																																				</div>
																																			</div>
																																		</div>
																																	</ng-template>
																																</ng-container>
																															</div>
																														</a>
																													</div>
																												</div>
																											</div>
																										</ng-template>
																									</ng-container>
																								</div>
																							</a>
																						</div>
																					</div>
																				</ng-template>
																			</div>
																		</div>
																	</a>
																</div>
															</div>
														</ng-container>
														<ng-template #noSubMenu>
															<a
                              id="mapper_noSubMenu"
																href="javaScript:void(0);"
																(click)="changePermission(module)"
															>
																<h4 class="accordion-toggle arrow-none 6">
																	<div
																		class="form-check highlight-hover"
																		(click)="addActiveClass($event)"
																		style="position: relative; top: 3px"
																	>
																		<app-figma-icon-img
																			[iconName]="
																				checkPermission(module) &&
																				checkedModule[menuIds][module.id]
																					? 'minus-square'
																					: checkedModule[menuIds][module.id]
																					? 'check-square'
																					: 'square'
																			"
																			[isForButton]="true"
																		></app-figma-icon-img>

																		<input
																			[ngClass]="{
																				'not-check-all': checkPermission(module)
																			}"
																			class="form-check-input imp-disabled-bg"
																			type="checkbox"
																			(click)="$event.stopPropagation()"
																			[(ngModel)]="
																				checkedModule[menuIds][module.id]
																			"
																			[disabled]="
																				!accessibleOption &&
																				moduleLevel !== apiUrl.GLOBAL
																			"
																			(ngModelChange)="
																				setModule(menuIds, module)
																			"
																			id="fcustomCheck33"
																			style="top: -3px; left: 10px"
																		/>
																		<label
																			class="custom-control-label"
																			for="customCheck33"
																		>
																			{{ module.name }}
																			{{
																				module.actions.length > 0
																					? '(' + module.actions.length + ')'
																					: ''
																			}}
																		</label>
																	</div>
																</h4>
															</a>
														</ng-template>
													</ng-container>
												</div>
											</div>
										</mat-tab>
										<mat-tab
											label="User Permissions"
											*ngIf="isUserTabVisible(currentRole, contactRoleId)"
										>
											<div class="accordion-section">
												<div id="accordion">
													<ng-container
														*ngFor="let module of userModules; let j = index"
													>
														<ng-container
															*ngIf="
																module.subMenus && module.subMenus.length > 0;
																else noSubMenu
															"
														>
															<div class="accordion-container 1">
																<div class="set">
																	<a
                                  id="mapper_toggleMenu2"
																		href="JavaScript:void(0);"
																		[class.active]="
																			selectedMenuId === module.id
																		"
																	>
																		<h4
																			class="accordion-toggle"
																			(click)="toggleMenu(module)"
																		>
																			<div
																				class="form-check"
																				(click)="removeActiveClass()"
																				style="position: relative; top: 3px"
																			>
																				<app-figma-icon-img
																					[iconName]="
																						checkPermission(module) &&
																						checkedModule[menuIds][module.id]
																							? 'minus-square'
																							: checkedModule[menuIds][
																									module.id
																							  ]
																							? 'check-square'
																							: 'square'
																					"
																					[isForButton]="true"
																				></app-figma-icon-img>

																				<input
																					[ngClass]="{
																						'not-check-all':
																							checkPermission(module)
																					}"
																					class="form-check-input imp-disabled-bg top-level-checkbox"
																					type="checkbox"
																					(click)="$event.stopPropagation()"
																					[(ngModel)]="
																						checkedModule[menuIds][module.id]
																					"
																					[disabled]="
																						!accessibleOption &&
																						moduleLevel !== apiUrl.GLOBAL
																					"
																					(ngModelChange)="
																						setModule(menuIds, module)
																					"
																					id="fcustomCheck33"
																					style="top: -3px; left: 10px"
																				/>
																				<label
																					class="custom-control-label"
																					for="customCheck33"
																				>
																					{{ module.name }} ({{
																						module.subMenus.length
																					}})
																				</label>
																			</div>
																		</h4>
																		<div class="content">
																			<div
																				class="inner-accordion-content2 ps-4"
																				*ngFor="
																					let subMenu of module.subMenus;
																					let i = index
																				"
																			>
																				<ng-container
																					*ngIf="
																						subMenu.subMenus.length === 0;
																						else thirdMenu
																					"
																				>
																					<div
																						class="highlight-hover no-sub-menu"
																						[class.highlight]="
																							selectedModuleId === subMenu.id
																						"
																						(click)="changePermission(subMenu)"
																					>
																						<div
																							class="form-check highlight-hover"
																							(click)="addActiveClass($event)"
																						>
																							<app-figma-icon-img
																								[iconName]="
																									checkPermission(subMenu) &&
																									checkedModule[menuIds][
																										subMenu.id
																									]
																										? 'minus-square'
																										: checkedModule[menuIds][
																												subMenu.id
																										  ]
																										? 'check-square'
																										: 'square'
																								"
																								[isForButton]="true"
																							></app-figma-icon-img>

																							<input
																								[ngClass]="{
																									'not-check-all':
																										checkPermission(subMenu)
																								}"
																								class="form-check-input imp-disabled-bg second-level-checkbox"
																								type="checkbox"
																								(click)="
																									$event.stopPropagation()
																								"
																								[(ngModel)]="
																									checkedModule[menuIds][
																										subMenu.id
																									]
																								"
																								[disabled]="
																									!accessibleOption &&
																									moduleLevel !== apiUrl.GLOBAL
																								"
																								(ngModelChange)="
																									setModule(
																										menuIds,
																										module,
																										subMenu
																									)
																								"
																								id="fcustomCheck34"
																								[checked]="false"
																							/>
																							<label
																								class="custom-control-label"
																								for="customCheck34"
																							>
																								{{ subMenu.name }}
																								{{
																									subMenu.actions &&
																									subMenu.actions.length > 0
																										? '(' +
																										  subMenu.actions.length +
																										  ')'
																										: ''
																								}}
																							</label>
																						</div>
																					</div>
																				</ng-container>
																				<ng-template #thirdMenu>
																					<div
																						class="accordion-container second-accordion"
																					>
																						<div class="set">
																							<a
                                              id="mapperToggleSubMenu"
																								href="JavaScript:void(0);"
																								[class.active]="
																									selectedSubMenuId ===
																									subMenu.id
																								"
																							>
																								<h4
																									class="accordion-toggle"
																									(click)="
																										toggleMenu(module, subMenu)
																									"
																								>
																									<div
																										class="form-check"
																										style="
																											position: relative;
																											top: 3px;
																										"
																									>
																										<app-figma-icon-img
																											[iconName]="
																												checkPermission(
																													subMenu
																												) &&
																												checkedModule[menuIds][
																													subMenu.id
																												]
																													? 'minus-square'
																													: checkedModule[
																															menuIds
																													  ][subMenu.id]
																													? 'check-square'
																													: 'square'
																											"
																											[isForButton]="true"
																										></app-figma-icon-img>

																										<input
																											[ngClass]="{
																												'not-check-all':
																													checkPermission(
																														subMenu
																													)
																											}"
																											class="form-check-input imp-disabled-bg"
																											type="checkbox"
																											(click)="
																												$event.stopPropagation()
																											"
																											[(ngModel)]="
																												checkedModule[menuIds][
																													subMenu.id
																												]
																											"
																											[disabled]="
																												!accessibleOption &&
																												moduleLevel !==
																													apiUrl.GLOBAL
																											"
																											(ngModelChange)="
																												setModule(
																													menuIds,
																													module,
																													subMenu
																												)
																											"
																											id="fcustomCheck33"
																											style="
																												top: -3px;
																												left: 10px;
																											"
																										/>
																										<label
																											class="custom-control-label"
																											for="customCheck33"
																										>
																											{{ subMenu.name }} ({{
																												subMenu.subMenus.length
																											}})
																										</label>
																									</div>
																								</h4>
																								<div class="content2">
																									<ng-container
																										*ngFor="
																											let thirdMenu of subMenu.subMenus;
																											let k = index
																										"
																									>
																										<div
																											class="inner-accordion-content2 ps-5"
																											*ngIf="
																												thirdMenu.subMenus
																													.length === 0;
																												else fourthMenu
																											"
																										>
																											<div
																												class="highlight-hover"
																												[class.highlight]="
																													selectedThirdMenuId ===
																													thirdMenu.id
																												"
																												(click)="
																													changePermission(
																														thirdMenu
																													)
																												"
																											>
																												<div
																													class="form-check highlight-hover"
																													(click)="
																														addActiveClass(
																															$event
																														)
																													"
																												>
																													<app-figma-icon-img
																														[iconName]="
																															checkPermission(
																																thirdMenu
																															) &&
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																																? 'minus-square'
																																: checkedModule[
																																		menuIds
																																  ][
																																		thirdMenu.id
																																  ]
																																? 'check-square'
																																: 'square'
																														"
																														[isForButton]="true"
																													></app-figma-icon-img>

																													<input
																														[ngClass]="{
																															'not-check-all':
																																checkPermission(
																																	thirdMenu
																																)
																														}"
																														class="form-check-input imp-disabled-bg second-level-checkbox"
																														type="checkbox"
																														(click)="
																															$event.stopPropagation()
																														"
																														[(ngModel)]="
																															checkedModule[
																																menuIds
																															][thirdMenu.id]
																														"
																														[disabled]="
																															!accessibleOption &&
																															moduleLevel !==
																																apiUrl.GLOBAL
																														"
																														(ngModelChange)="
																															setModule(
																																menuIds,
																																module,
																																subMenu,
																																thirdMenu
																															)
																														"
																														id="fcustomCheck34"
																														[checked]="false"
																													/>
																													<label
																														class="custom-control-label"
																														for="customCheck34"
																													>
																														{{ thirdMenu.name }}
																														{{
																															thirdMenu.actions &&
																															thirdMenu.actions
																																.length > 0
																																? '(' +
																																  thirdMenu
																																		.actions
																																		.length +
																																  ')'
																																: ''
																														}}
																													</label>
																												</div>
																											</div>
																										</div>
																										<ng-template #fourthMenu>
																											<div
																												class="inner-accordion-content3"
																											>
																												<div
																													class="accordion-container second-accordion"
																												>
																													<div class="set">
																														<a
                                                            id="mapper_toggle3rdMenu2"
																															href="JavaScript:void(0);"
																															[class.active]="
																																selectedThirdMenuId ===
																																thirdMenu.id
																															"
																														>
																															<h4
																																class="accordion-toggle"
																																(click)="
																																	toggleMenu(
																																		module,
																																		subMenu,
																																		thirdMenu
																																	)
																																"
																															>
																																<div
																																	class="form-check"
																																	style="
																																		position: relative;
																																		top: 3px;
																																	"
																																>
																																	<app-figma-icon-img
																																		[iconName]="
																																			checkPermission(
																																				thirdMenu
																																			) &&
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																				? 'minus-square'
																																				: checkedModule[
																																						menuIds
																																				  ][
																																						thirdMenu
																																							.id
																																				  ]
																																				? 'check-square'
																																				: 'square'
																																		"
																																		[isForButton]="
																																			true
																																		"
																																	></app-figma-icon-img>

																																	<input
																																		[ngClass]="{
																																			'not-check-all':
																																				checkPermission(
																																					thirdMenu
																																				)
																																		}"
																																		class="form-check-input imp-disabled-bg"
																																		type="checkbox"
																																		(click)="
																																			$event.stopPropagation()
																																		"
																																		[(ngModel)]="
																																			checkedModule[
																																				menuIds
																																			][
																																				thirdMenu
																																					.id
																																			]
																																		"
																																		[disabled]="
																																			!accessibleOption &&
																																			moduleLevel !==
																																				apiUrl.GLOBAL
																																		"
																																		(ngModelChange)="
																																			setModule(
																																				menuIds,
																																				module,
																																				subMenu,
																																				thirdMenu
																																			)
																																		"
																																		id="fcustomCheck33"
																																		style="
																																			top: -3px;
																																			left: 10px;
																																		"
																																	/>
																																	<label
																																		class="custom-control-label"
																																		for="customCheck33"
																																	>
																																		{{
																																			thirdMenu.name
																																		}}
																																		({{
																																			thirdMenu
																																				.subMenus
																																				.length
																																		}})
																																	</label>
																																</div>
																															</h4>
																															<div
																																class="content3"
																															>
																																<ng-container
																																	*ngFor="
																																		let fourthMenu of thirdMenu.subMenus;
																																		let k = index
																																	"
																																>
																																	<div
																																		class="inner-accordion-content2 ps-5"
																																		*ngIf="
																																			fourthMenu.subMenus !==
																																				null &&
																																				fourthMenu
																																					.subMenus
																																					.length ===
																																					0;
																																			else fifthMenu
																																		"
																																	>
																																		<div
																																			class="highlight-hover"
																																			[class.highlight]="
																																				selectedFourthMenuId ===
																																				fourthMenu.id
																																			"
																																			(click)="
																																				changePermission(
																																					fourthMenu
																																				)
																																			"
																																		>
																																			<div
																																				class="form-check highlight-hover"
																																				(click)="
																																					addActiveClass(
																																						$event
																																					)
																																				"
																																			>
																																				<app-figma-icon-img
																																					[iconName]="
																																						checkPermission(
																																							fourthMenu
																																						) &&
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																							? 'minus-square'
																																							: checkedModule[
																																									menuIds
																																							  ][
																																									fourthMenu
																																										.id
																																							  ]
																																							? 'check-square'
																																							: 'square'
																																					"
																																					[isForButton]="
																																						true
																																					"
																																				></app-figma-icon-img>

																																				<input
																																					[ngClass]="{
																																						'not-check-all':
																																							checkPermission(
																																								fourthMenu
																																							)
																																					}"
																																					class="form-check-input imp-disabled-bg second-level-checkbox"
																																					type="checkbox"
																																					(click)="
																																						$event.stopPropagation()
																																					"
																																					[(ngModel)]="
																																						checkedModule[
																																							menuIds
																																						][
																																							fourthMenu
																																								.id
																																						]
																																					"
																																					[disabled]="
																																						!accessibleOption &&
																																						moduleLevel !==
																																							apiUrl.GLOBAL
																																					"
																																					(ngModelChange)="
																																						setModule(
																																							menuIds,
																																							module,
																																							subMenu,
																																							thirdMenu,
																																							fourthMenu
																																						)
																																					"
																																					id="fcustomCheck34"
																																					[checked]="
																																						false
																																					"
																																				/>
																																				<label
																																					class="custom-control-label"
																																					for="customCheck34"
																																				>
																																					{{
																																						fourthMenu.name
																																					}}
																																					{{
																																						fourthMenu.actions &&
																																						fourthMenu
																																							.actions
																																							.length >
																																							0
																																							? '(' +
																																							  fourthMenu
																																									.actions
																																									.length +
																																							  ')'
																																							: ''
																																					}}
																																				</label>
																																			</div>
																																		</div>
																																	</div>
																																	<ng-template
																																		#fifthMenu
																																	>
																																		<div
																																			class="inner-accordion-content3"
																																		>
																																			<div
																																				class="accordion-container second-accordion"
																																			>
																																				<div
																																					class="set"
																																				>
																																					<a
                                                                          id="mapper_toggle4thMenu2"
																																						href="JavaScript:void(0);"
																																						[class.active]="
																																							selectedFourthMenuId ===
																																							fourthMenu.id
																																						"
																																					>
																																						<h4
																																							class="accordion-toggle"
																																							(click)="
																																								toggleMenu(
																																									module,
																																									subMenu,
																																									thirdMenu,
																																									fourthMenu
																																								)
																																							"
																																						>
																																							<div
																																								class="form-check"
																																								style="
																																									position: relative;
																																									top: 3px;
																																								"
																																							>
																																								<app-figma-icon-img
																																									[iconName]="
																																										checkPermission(
																																											fourthMenu
																																										) &&
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																											? 'minus-square'
																																											: checkedModule[
																																													menuIds
																																											  ][
																																													fourthMenu
																																														.id
																																											  ]
																																											? 'check-square'
																																											: 'square'
																																									"
																																									[isForButton]="
																																										true
																																									"
																																								></app-figma-icon-img>

																																								<input
																																									[ngClass]="{
																																										'not-check-all':
																																											checkPermission(
																																												fourthMenu
																																											)
																																									}"
																																									class="form-check-input imp-disabled-bg"
																																									type="checkbox"
																																									(click)="
																																										$event.stopPropagation()
																																									"
																																									[(ngModel)]="
																																										checkedModule[
																																											menuIds
																																										][
																																											fourthMenu
																																												.id
																																										]
																																									"
																																									[disabled]="
																																										!accessibleOption &&
																																										moduleLevel !==
																																											apiUrl.GLOBAL
																																									"
																																									(ngModelChange)="
																																										setModule(
																																											menuIds,
																																											module,
																																											subMenu,
																																											thirdMenu,
																																											fourthMenu
																																										)
																																									"
																																									id="fcustomCheck33"
																																									style="
																																										top: -3px;
																																										left: 10px;
																																									"
																																								/>
																																								<label
																																									class="custom-control-label"
																																									for="customCheck33"
																																								>
																																									{{
																																										fourthMenu.name
																																									}}
																																									({{
																																										fourthMenu
																																											.subMenus
																																											.length
																																									}})
																																								</label>
																																							</div>
																																						</h4>
																																						<div
																																							class="content4"
																																						>
																																							<div
																																								class="inner-accordion-content2 ps-5"
																																								*ngFor="
																																									let fifthMenu of fourthMenu.subMenus;
																																									let k = index
																																								"
																																							>
																																								<div
																																									class="highlight-hover"
																																									(click)="
																																										changePermission(
																																											fifthMenu
																																										)
																																									"
																																								>
																																									<div
																																										class="form-check highlight-hover"
																																										(click)="
																																											addActiveClass(
																																												$event
																																											)
																																										"
																																									>
																																										<app-figma-icon-img
																																											[iconName]="
																																												checkPermission(
																																													fifthMenu
																																												) &&
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																													? 'minus-square'
																																													: checkedModule[
																																															menuIds
																																													  ][
																																															fifthMenu
																																																.id
																																													  ]
																																													? 'check-square'
																																													: 'square'
																																											"
																																											[isForButton]="
																																												true
																																											"
																																										></app-figma-icon-img>

																																										<input
																																											[ngClass]="{
																																												'not-check-all':
																																													checkPermission(
																																														fifthMenu
																																													)
																																											}"
																																											class="form-check-input imp-disabled-bg second-level-checkbox"
																																											type="checkbox"
																																											(click)="
																																												$event.stopPropagation()
																																											"
																																											[(ngModel)]="
																																												checkedModule[
																																													menuIds
																																												][
																																													fifthMenu
																																														.id
																																												]
																																											"
																																											[disabled]="
																																												!accessibleOption &&
																																												moduleLevel !==
																																													apiUrl.GLOBAL
																																											"
																																											(ngModelChange)="
																																												setModule(
																																													menuIds,
																																													module,
																																													subMenu,
																																													thirdMenu,
																																													fourthMenu,
																																													fifthMenu
																																												)
																																											"
																																											id="fcustomCheck34"
																																											[checked]="
																																												false
																																											"
																																										/>
																																										<label
																																											class="custom-control-label"
																																											for="customCheck34"
																																										>
																																											{{
																																												fifthMenu.name
																																											}}
																																											{{
																																												fifthMenu.actions &&
																																												fifthMenu
																																													.actions
																																													.length >
																																													0
																																													? '(' +
																																													  fifthMenu
																																															.actions
																																															.length +
																																													  ')'
																																													: ''
																																											}}
																																										</label>
																																									</div>
																																								</div>
																																							</div>
																																						</div>
																																					</a>
																																				</div>
																																			</div>
																																		</div>
																																	</ng-template>
																																</ng-container>
																															</div>
																														</a>
																													</div>
																												</div>
																											</div>
																										</ng-template>
																									</ng-container>
																								</div>
																							</a>
																						</div>
																					</div>
																				</ng-template>
																			</div>
																		</div>
																	</a>
																</div>
															</div>
														</ng-container>
														<ng-template #noSubMenu>
															<a
																href="javaScript:void(0);"
																(click)="changePermission(module)"
															>
																<h4 class="accordion-toggle arrow-none 6">
																	<div
																		class="form-check highlight-hover"
																		(click)="addActiveClass($event)"
																		style="position: relative; top: 3px"
																	>
																		<app-figma-icon-img
																			[iconName]="
																				checkPermission(module) &&
																				checkedModule[menuIds][module.id]
																					? 'minus-square'
																					: checkedModule[menuIds][module.id]
																					? 'check-square'
																					: 'square'
																			"
																			[isForButton]="true"
																		></app-figma-icon-img>

																		<input
																			[ngClass]="{
																				'not-check-all': checkPermission(module)
																			}"
																			class="form-check-input imp-disabled-bg"
																			type="checkbox"
																			(click)="$event.stopPropagation()"
																			[(ngModel)]="
																				checkedModule[menuIds][module.id]
																			"
																			[disabled]="
																				!accessibleOption &&
																				moduleLevel !== apiUrl.GLOBAL
																			"
																			(ngModelChange)="
																				setModule(menuIds, module)
																			"
																			id="fcustomCheck33"
																			style="top: -3px; left: 10px"
																		/>
																		<label
																			class="custom-control-label"
																			for="customCheck33"
																		>
																			{{ module.name }}
																			{{
																				module.actions.length > 0
																					? '(' + module.actions.length + ')'
																					: ''
																			}}
																		</label>
																	</div>
																</h4>
															</a>
														</ng-template>
													</ng-container>
												</div>
											</div>
										</mat-tab>
									</mat-tab-group>
								</div>
							</div>
							<div class="col-md-5">
								<h2>Permissions</h2>
								<div class="inner-accordion-content2 p-3">
									<ng-container
										*ngIf="
											permissions && permissions.length > 0;
											else loadingDefault
										"
									>
										<div class="active-permission">
											<div
												class="form-check highlight-hover q-default"
												*ngFor="let permission of permissions; let x = index"
											>
												<app-figma-icon-img
													[iconName]="
														checkedModule[menuActionIds][permission.id]
															? 'check-square'
															: 'square'
													"
													[isForButton]="true"
												></app-figma-icon-img>

												<input
													class="form-check-input imp-disabled-bg"
													type="checkbox"
													[disabled]="
														(!accessibleOption &&
															moduleLevel !== apiUrl.GLOBAL) ||
														isPermissionDisabled(permission)
													"
													[(ngModel)]="
														checkedModule[menuActionIds][permission.id]
													"
													(ngModelChange)="setPermission(permission)"
													id="fcustomCheck34"
												/>
												<label class="custom-control-label q-default" for="customCheck34">
													{{
														permission.name ? permission.name : permission.topic
													}}
												</label>
											</div>
										</div>
									</ng-container>
									<ng-template #loadingDefault>
										<h4
											style="
												color: #1d2124;
												font-size: 1rem;
												font-weight: normal;
											"
										>
											No permissions available.
										</h4>
									</ng-template>
								</div>
							</div>

							<div class="actions-wrapper">
								<button
                  id="mapper_groupsPermCancel"
									class="btn-2 btn-2-link"
									(click)="resetUpdates()"
								>
									Cancel
								</button>
								<button
                  id="mapper_groupsPermSave"
									class="btn-2 btn-2-primary imp-disabled-op"
									[class.disabled]="!isSaveButtonEnable()"
									(click)="updateMenuAndActionIds()"
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

	<app-audit-trail
		[url]="historyUrl.ROLES_PERMISSIONS"
		history_category="menupermission"
		[data_options]="dataOptions"
	></app-audit-trail>
