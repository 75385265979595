import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	inject,
	HostBinding,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import {
	Observable,
	Subscription,
	map,
	merge,
	shareReplay,
	take,
	filter,
} from 'rxjs';

import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { AccountService } from '@app/modules/account/account.service';
import { NewFeatureInfoModalService } from '@app/core/components/new-feature-info-modal/new-feature-info-modal.service';
import { MobileLaunchModalService } from '@app/core/components/mobile-launch-modal/mobile-launch-modal.service';
import { PwaService } from '@app/services/pwa.service';
import { ColorService } from '@app/shared/services/color.service';
import { SideBarService } from './side-bar.service';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { CompanyBranding } from '@app/shared/interfaces/companies.interface';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { User } from '@app/shared/interfaces/user.interface';

import { SPECIAL_ITEMS_ID } from './side-bar.constants';
import { MenuPermissionConstant, UserTypes } from '@app/shared/constants';

import { select, Store } from '@ngrx/store';
import {
	feedbackUrlSelector,
	sidebarDataSelector,
} from './store/sidebar.selector';
import { sidebarOpenSelector } from '../top-bar/store/top-bar.selector';
import { userDataSelector } from '@app/core/store/user/user.selector';
import { feedbackUrlUpdateAction } from './store/sidebar.action';
import { QuestBetaService } from '@app/core/components/quest-beta/quest-beta.service';

@Component({
	selector: '[nav-side-bar]',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
	@ViewChild('sidebar') sidebar: ElementRef;
	@HostBinding('class.collapsed') collapsed = false;

	private _userService = inject(UserService);
	private _router = inject(Router);
	private _sidebarService = inject(SideBarService);
	private _companiesService = inject(CompaniesService);
	private _accountService = inject(AccountService);
	private _colorService = inject(ColorService);
	private _document = inject(DOCUMENT);
	private _store = inject(Store<AppStateInterface>);
	private _newFeatureInfoService = inject(NewFeatureInfoModalService);
	private _mobileLaunchService = inject(MobileLaunchModalService);
	private _questBetaService = inject(QuestBetaService);
	public pwaService = inject(PwaService);

	userType: UserTypes = this._userService.user!.roleId;
	isGettedContents = false;
	contentsCopy: RoleMenuInterface[];
	filterContentsCopy: RoleMenuInterface[];
	routeIds: number[] = [];
	specialItemsId: number[] = JSON.parse(JSON.stringify(SPECIAL_ITEMS_ID));
	branding$: Observable<CompanyBranding>;
	defaultFaviconPath = '../../../../assets/images/icons/big-logoicon.png';
	defaultLogoPath = '../../../../assets/images/icons/header-logo.png';
	faviconPath = this.defaultFaviconPath;
	logoPath = this.defaultLogoPath;

	sidebar$: Observable<RoleMenuInterface[] | null>;
	user$: Observable<User | null>;
	sidebarState$: Observable<boolean | null>;
	subscription: Subscription = new Subscription();
	parentMenu = [
		MenuPermissionConstant.System[1],
		MenuPermissionConstant.System[2],
		MenuPermissionConstant.Reports[1],
		MenuPermissionConstant.Reports[2],
	];

	hasFeedbackUrlLoaded$: Observable<boolean>;

	get isUserMode(): boolean {
		return this._userService.userRole === UserTypes.User;
	}

	public get UserTypes() {
		return UserTypes;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		this.sidebar$ = this._store.pipe(select(sidebarDataSelector)).pipe(
			filter((v) => v != null),
			map((v) =>
				v!.filter(
					(val, index, self) =>
						index === self.findIndex((t) => t.name === val.name)
				)
			),
			map((v: RoleMenuInterface[]) =>
				v.filter(
					(m) =>
						!this.parentMenu.includes(m.id) || m.subMenus[0]?.subMenus.length
				)
			)
		);
		this.user$ = this._store.pipe(select(userDataSelector));
		this.sidebarState$ = this._store.pipe(
			select(sidebarOpenSelector),
			map((state) => state.isOpen)
		);
		this._sidebarService.getSidebar();
		this.getFeedbackUrl();

		this.subscription = merge(
			this._companiesService.companyBrandingChange.pipe(
				map((v) => v.companyId)
			),
			this._userService.userChange$.pipe(map((v) => (v ? v.companyId : 0)))
		).subscribe((companyId) => {
			if (companyId && companyId == this._userService.user!.companyId) {
				this.updateBranding();
			}

			this.initMobileUpdate();
		});

		this._initScreenSizeEvent();
	}

	switchMode() {
		this._userService.switchUserRole();
	}

	updateBranding() {
		this._companiesService
			.getCompanyBranding(this._userService.user!.companyId)
			.subscribe({
				next: (res) => {
					this.faviconPath = res.faviconPath || this.defaultFaviconPath;
					this.logoPath = res.logoPath || this.defaultLogoPath;
					this._document
						.getElementById('faviconIcon')!
						.setAttribute('href', this.faviconPath);
				},
			});

		this._accountService.getBrandingById(this._userService.userId).subscribe({
			next: (data) => {
				this._colorService.setSideBarColor(data.selectedColor!);
			},
		});
	}

	initMobileUpdate() {
		this._mobileLaunchService.initNewMobileLaunch();

		if (this._userService.user?.impersonatingBy === null) {
			this._newFeatureInfoService.initCampaigns();
			this._questBetaService.isInitializeBetaApi(true);
		}
	}

	getFeedbackUrl() {
		const feedbackUrl$ = this._sidebarService.loadFeedbackUrl().pipe(
			shareReplay(),
			map((data) => data.value)
		);
		this.hasFeedbackUrlLoaded$ = feedbackUrl$.pipe(map((url) => !!url));
		feedbackUrl$.pipe(take(1)).subscribe((url: string) => {
			this._store.dispatch(feedbackUrlUpdateAction({ feedbackUrl: url }));
		});
	}

	submitFeedback() {
		this._store.pipe(select(feedbackUrlSelector)).subscribe((url) => {
			window.open(url, '_blank');
		});
	}

	setMenu() {
		this.contentsCopy = JSON.parse(
			JSON.stringify(this._sidebarService.contents)
		);
		// console.log(this.contentsCopy);
		this.filterContents('');
	}

	filterContents(key: string) {
		if (key) {
			this.filterContentsCopy = this.contentsCopy
				.filter(
					(items) =>
						(!this.specialItemsId.includes(items.id) &&
							items.subMenus.length > 0 &&
							items.subMenus.some((secondItem) => {
								return secondItem.name
									.toLowerCase()
									.includes(key.toLowerCase());
							})) ||
						items.name.toLowerCase().includes(key.toLowerCase())
				)
				.map((items) => {
					return {
						...items,
						subMenus: items.subMenus.filter((subItem) =>
							subItem.name.toLowerCase().includes(key.toLowerCase())
						),
					};
				});
		} else {
			this.filterContentsCopy = this.contentsCopy;
		}
	}

	navigate(url: string) {
		this._router.navigate([url]);
	}

	toggleCollapse() {
		this.collapsed = !this.collapsed;
	}

	private _initScreenSizeEvent() {
		this._updateSideBar();
		window.addEventListener('resize', (event) => this._updateSideBar());
	}

	private _updateSideBar() {
		const doc = document as any;
		const width = Math.max(
			doc.documentElement.clientWidth,
			window.innerWidth || 0
		);

		if (width < 1024 && !this.collapsed) this.collapsed = true; // Tablet
		else if (width >= 1440 && this.collapsed) this.collapsed = false; // Large laptop
	}
}
