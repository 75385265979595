import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { PaymentArrangementsService } from '@app/modules/finance/pages/payment-arrangements/payment-arrangements.service';
import { MethodType } from '@app/modules/finance/pages/setup-payment-arrangements/payment-method-section/payment-method-section.interface';
import { CardValidationCode, CardValidationMessages } from '@app/modules/finance/validation-messages/invoice-validation-messages.inum';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { States } from '@app/shared/interfaces/invoice.interface';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { Subject, takeUntil } from 'rxjs';
import { CardInputComponent } from '../form-input/card-input/card-input.component';
import { CardFormService } from './service/card-form.service';

@Component({
  selector: 'card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss']
})
export class CardFormComponent implements OnInit, OnDestroy {
  @ViewChild(CardInputComponent) cardInputComponent: CardInputComponent;

  cardForm: FormGroup;
  stateOptions: States = [];
  hasSelectedCard: boolean = false;
  
  private _fb = inject(FormBuilder);
  private _notifier = inject(NotificationService);
  private _toastMessageService = inject(ToastMessageService);
  private _stripeService = inject(StripeService);
  private _cardFormService = inject(CardFormService);
  private _cd = inject(ChangeDetectorRef);
  private _paymentArrangementService = inject(PaymentArrangementsService);
  private _$unsubscribe: Subject<void> = new Subject<void>();

  get defaultFormValue() {
    return {
      paymentMethodId: '',
      cardElement: '',
      name: '',
      brand: '',
      last4: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: 'US'
    }
  }

  ngOnInit(): void {
    this._initForm();
    this._initStates();
  }

  private _initForm() {
    this.cardForm = this._fb.group({
      paymentMethodId: [this.defaultFormValue.paymentMethodId],
      cardElement: [this.defaultFormValue.cardElement, Validators.required],
      name: [this.defaultFormValue.name, Validators.required],
      brand: [this.defaultFormValue.brand],
      last4: [this.defaultFormValue.last4],
      address1: [this.defaultFormValue.address1, Validators.required],
      address2: [this.defaultFormValue.address2],
      city: [this.defaultFormValue.city, Validators.required],
      state: [this.defaultFormValue.state, Validators.required],
      country: [this.defaultFormValue.country, Validators.required]
    });
  }

  private _initStates() {
		this._stripeService
			.getStatesForUnitedStates()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({ next: (result: States) => this.stateOptions = result });
	}

  onCardElementUpdate(data: any) {
    this.cardForm.controls['cardElement'].setValue(data.element);
  }

  onSubmit() {
    this.getPaymentMethodId();
  }

  clearForm() {
    this.cardForm.patchValue(this.defaultFormValue);
  }

  async getPaymentMethodId() {
    this.cardForm.markAllAsTouched();

    const params = {
			name: this.cardForm.controls['name'].value,
			address_country: 'US',
			address_line1: this.cardForm.controls['address1'].value,
			address_line2: this.cardForm.controls['address2'].value,
			address_city: this.cardForm.controls['city'].value,
			address_state: this.cardForm.controls['state'].value,
		};

    const { token } = await this.cardInputComponent.stripe.createToken(
			this.cardForm.controls['cardElement'].value,
			params
		);

    if (!token) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['name'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['address1'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['address2'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['city'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

    if (this.cardForm.controls['state'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (token) {
			this._paymentArrangementService
				.addStripeCards({ token: token.id }, Number(this._paymentArrangementService.data.companyId))
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result: any) => {
            if (result) {
              this.cardForm.controls['paymentMethodId'].setValue(result.id);
              this._cardFormService.setPaymentMethodId(this.cardForm.controls['paymentMethodId'].value, MethodType.CARD);
              this._notifier.notifySuccess(NotificationMessages.save('Card'), 'You can use this card for future transactions');
            }
					},
					error: (err) => {
						this._toastMessageService.showErrorMessage(err.errors);
					},
				});
		}
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
