<div class="administrative">
	<div class="administrative__left">
		<h3>{{ (activeModule$ | async)?.name }}</h3>
		<span>{{ (activeModule$ | async)?.description }}</span>
	</div>

	<div
		class="administrative__right"
		[ngClass]="isViewClass"
		[class.disabled-element-event]="
			rolesAndPermission.isDisabledPermissionButton
		"
	>
		<div
			*ngIf="rolesAndPermission.isDisabledPermissionButton"
			class="tooltip-message-wrapper"
			[tooltipContentV2]="{
				message:
					'This is global role contact administrator to change permission.'
			}"
			[tooltipPosition]="'left'"
		></div>

		<span>All Access</span>
		<div (click)="switchOnOff()" [class.not-allowed]="!this.isAllowCascade">
			<app-svg [name]="switch ? 'switch-on' : 'switch-off'"></app-svg>
		</div>
	</div>
</div>
<div
	class="modules"
	*ngFor="let page of pages$ | async; let isLast = last"
	[class.border-bottom]="!isLast"
>
	<div class="modules__left">
		<div class="modules__left--desc">
			<h4>{{ page.name }}</h4>
			<span>{{ page.description }}</span>
		</div>

		<div
			class="modules__left--buttons"
			*ngIf="page.buttonLabel && page.url && !isViewMode"
			[class.disabled-button-tooltip]="
				rolesAndPermission.isDisabledPermissionButton
			"
			[ngClass]="isViewClass"
		>
			<div
				*ngIf="rolesAndPermission.isDisabledPermissionButton"
				class="tooltip-message-wrapper"
				[tooltipContentV2]="{
					message:
						'This is global role contact administrator to change permission.'
				}"
			></div>

			<a
        class="mapper_getManageAccessUrl"
				[routerLink]="getManageAccessUrl(page.url, userId)"
				*ngIf="!(rolesAndPermission.isEditPageLoading$ | async) && userId"
				[class.disabled]="rolesAndPermission.isDisabledPermissionButton"
				>{{ page.buttonLabel }}</a
			>
			<a
      class="mapper_getManageAccessUrl"
				[routerLink]="getManageAccessUrl(page.url)"
				*ngIf="!(rolesAndPermission.isEditPageLoading$ | async) && !userId"
				[class.disabled]="rolesAndPermission.isDisabledPermissionButton"
				>{{ page.buttonLabel }}</a
			>
			<span class="form-count">{{ page.countsLabel }}</span>
		</div>
	</div>
	<div class="modules__right">
		<div
			class="modules__right--access"
			[class.disabled-element-event]="
				rolesAndPermission.isDisabledPermissionButton
			"
		>
			<div
				*ngIf="rolesAndPermission.isDisabledPermissionButton"
				class="tooltip-message-wrapper"
				[tooltipContentV2]="{
					message:
						'This is global role contact administrator to change permission.'
				}"
				[tooltipPosition]="'right'"
			></div>

			<div
				class="modules__right--access-1"
				(click)="toggleAccess(page)"
				[ngClass]="isViewClass"
				[class.not-allowed]="!this.isAllowCascade"
			>
				<div>
					<app-svg
						[name]="page.isEnableAccess ? 'switch-on' : 'switch-off'"
					></app-svg>
				</div>
				<span>Enable Access</span>
				<ng-container
					*ngIf="
						showOrangeDot && page.defaultRoleAccess !== page.isEnableAccess
					"
					><span class="orange"></span
				></ng-container>
			</div>

			<div class="q-d-flex q-f-column q-ai-start q-g-8 q-as-stretch">
				<div
					class="modules__right--access-2"
					*ngFor="let action of page.menuActions"
				>
					<div
						class="check-list"
						(click)="toggleCheckbox(page, action.id)"
						[ngClass]="isViewClass"
						[class.not-allowed]="!this.isAllowCascade"
					>
						<div class="check-list_checkbox">
							<app-svg
								[name]="action.isEnableAccess ? 'check' : 'uncheck'"
							></app-svg>
						</div>
						<label class="font-b1" for="check-1">{{ action.name }}</label>
						<ng-container
							*ngIf="
								showOrangeDot &&
								action.defaultRoleAccess !== action.isEnableAccess
							"
							><span class="orange"></span
						></ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
