<div class="card-2 q-p-0 q-pt-24 q-br-0">
  <div class="card-header-2">
    <h3 class="q-mr-auto">
      Payment History
      <div class="sub-title font-b3">All payments recorded below have been paid only through the Quest<sup>TM</sup> platform</div>
    </h3>

    <search-input
      #searchTextbox
      size="small"
      [formControl]="queryString"
      (keyup)="queryChange(500)"
      (keyup.enter)="queryChange()"
      placeholder="Search Payments">
    </search-input>

    <app-tertiary-button
      [isFilterOn]="isFilterOn"
      [isFilterToggle]="true"
    ></app-tertiary-button>

    <a id="mapper_actions" href="javascript:void(0)"
      class="q-pointer q-d-flex q-ai-center"
      style="height: 40px;"
      [matMenuTriggerFor]="topRowButtonAction">
      <app-figma-icon-img
        [iconName]="'dots-vertical'"
        [width]="24"
        [height]="24"
        [isForButton]="true"
        [hasSpaceOnRight]="false">
      </app-figma-icon-img>
    </a>

    <mat-menu #topRowButtonAction="matMenu" class="v2-menu-action-button">
      <button id="mapper_expCSV" mat-menu-item (click)="export(applicationType.CSV)">
        <div class="menu-item-group">
          <div class="menu-item-icon">
            <mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
          </div>
          <div class="menu-item-name font-b3">Export As CSV</div>
        </div>
      </button>

      <button id="mapper_expExcel" mat-menu-item (click)="export(applicationType.EXCEL)">
        <div class="menu-item-group">
          <div class="menu-item-icon">
            <mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
          </div>
          <div class="menu-item-name font-b3">Export As XLSX</div>
        </div>
      </button>
    </mat-menu>
	</div>

  <section id="demo" class="q-w-100 collapse">
    <div class="table-filter-2 tf-col-4">
      <div class="filter-item">
        <label>Start Date</label>
        <date-input
          [formControl]="startDateFilter"
          max="{{ endDateFilter.value | date : 'yyyy-MM-dd' }}"
          [isString]="true"
        ></date-input>
      </div>

      <div class="filter-item">
        <label>End Date</label>
        <date-input
          [formControl]="endDateFilter"
          min="{{ startDateFilter.value | date : 'yyyy-MM-dd' }}"
          [isString]="true"
        ></date-input>
      </div>

      <div class="filter-item filter-actions">
        <app-clear-filter></app-clear-filter>
      </div>
    </div>
  </section>

  <section id="payments-table" class="q-w-100">
    <app-new-table-shared
      loadingText="Fetching Payment History..."
			[searchFilters]="searchFilters"
			(emitTotalItems)="onEmitTotalItems($event)">

      <!-- For action column -->
			<ng-template #customizeActionColumnRef let-option let-i="index">
				<div class="q-d-flex q-ai-center q-jc-start gap-3" style="max-width: 144px">
					<a
						href="javascript:void(0)"
						class="mapper_receiptcheck edit visiblity-icon"
            [tooltipContentV2]="{ message: 'Download Receipts' }"
            (click)="downloadReceipt(option)"
						mat-raised-button
					>
						<app-figma-icon-img
							[iconName]="'receipt-check'"
							[width]="24"
							[height]="24"
							[hasSpaceOnRight]="false"
							[isForButton]="false"
						>
						</app-figma-icon-img>
					</a>
				</div>
			</ng-template>
		</app-new-table-shared>
  </section>
</div>
