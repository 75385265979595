<div class="card-2 q-mb-24 q-g-0">
	<nav mat-tab-nav-bar class="q-monorail-tab">
		<a 
			mat-tab-link 
			*ngFor="let link of navLinks" 
			[routerLink]="link.link"
			routerLinkActive #rla="routerLinkActive"
			[active]="rla.isActive">
			{{link.label}}
		</a>
	</nav>

	<router-outlet></router-outlet>
</div>
