<!-- Payment card | bank -->
<div class="card">
	<div class="card-header pb-0">
		<div class="d-lg-flex">
			<div class="invoice-width">
				<h6 class="mb-0 page-title">Payment Process</h6>
			</div>

			<div class="ms-auto my-auto mt-lg-0 mt-4 invoice-details-page">
				<div class="ms-auto my-auto">
					<ng-container
						*ngIf="
							cardOption.length ||
							bankOption.length ||
							payButtonLabel.isValid ||
							paymentType.value
						"
					>
						<span class="mb-0 mt-sm-0 mt-1 me-2">
							<a
								href="javascript:void(0)"
								class="btn-2 btn-2-success export position-relative"
								(click)="submit()"
								id="mapper_gs-pay-now"
							>
								<div class="spinner hidden" id="spinner"></div>

								<span
									id="button-text"
									class="d-flex align-items-center justify-content-center"
									style="line-height: 10px"
								>
									<app-figma-icon-img
										[iconName]="'currency-dollar'"
										[isForButton]="true"
									></app-figma-icon-img>

									<span>Pay Now</span>
								</span>
							</a>
						</span>
					</ng-container>

          <app-secondary-button
						[isBackButton]="true"
						[link]="
              isAdmin
                ? '/billing-orders/invoices'
                : '/billing-and-orders/invoices'
            "
					></app-secondary-button>
				</div>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="sand-box">
			<div class="row">
				<div class="col-md-6">
					<div
						*ngIf="invoiceList && invoiceList.length"
						class="summary-box-container"
					>
						<div class="summary-box-header">
							<div class="summary-box-title">Summary</div>
						</div>

						<ng-container *ngFor="let s of invoiceList; let i = index">
							<div class="summary-box-group">
								<div class="summary-box-head">
									<div class="summary-box-head-1">
										Invoice #{{ s.invoiceNumber }}
									</div>
									<div class="summary-box-head-2">
										<div class="summary-box-subhead-1">
											<b>{{ s.balance | currency }}</b>
										</div>
										<div class="summary-box-subhead-2">
											<a
												href="javascript:void(0)"
												class="cursor-pointer pb-2"
												(click)="s.isOpen = !s.isOpen"
												id="mapper_gs-partial-payment"
											>
												<app-local-figma-icon-img
													[filename]="
														s.isOpen ? 'edit-05-blue' : 'edit-05-gray'
													"
													[width]="20"
													[height]="20"
												></app-local-figma-icon-img>
											</a>
										</div>
									</div>
								</div>

								<ng-container *ngIf="s.isOpen">
									<div class="summary-box-body">
										<div class="summary-box-body-1">
											<div class="summary-box-body-payment-amount">
												Payment Amount
											</div>
											<div class="summary-box-body-form">
												<div class="summary-body-box-form-1">
													<ng-container
														*ngIf="!hasSelectedCredits; else partialAmountForm"
													>
														<div class="partial-amount-container">
															<span class="prefix-input">$</span>
															<input
																type="text"
																id="partial-amt"
																class="form-control partial-amt-form"
																appTwoDigitDecimaNumber
																[(ngModel)]="s.partialAmountInput"
																(blur)="partialAmountConvertIntoDecimal()"
																[disabled]="hasSelectedCredits"
																autocomplete="off"
															/>
														</div>
													</ng-container>

													<ng-template #partialAmountForm>
														<div class="partial-amount-container">
															<span class="prefix-input">$</span>
															<input
																type="text"
																id="partial-amt"
																class="form-control partial-amt-form"
																appTwoDigitDecimaNumber
																autocomplete="off"
																[value]="s.partialAmountInput"
																disabled
															/>
														</div>
													</ng-template>
												</div>

												<div class="summary-body-box-form-2">
													<button
                          id="mapper_paymentProcessApply"
														class="btn-2 btn-2-primary btn-dark-color"
														(click)="applyPartialAmount(s)"
														[disabled]="hasSelectedCredits"
													>
														Apply
													</button>
												</div>
											</div>
										</div>

										<div class="summary-box-body-2">
											{{ s.partialAmount | currency }}
										</div>
									</div>

									<div
										class="summary-box-footer"
										[class.border-bottom-summary]="i === invoiceList.length - 1"
									>
										<div class="summary-box-footer-1">Outstanding Balance</div>
										<div class="summary-box-footer-2">
											{{ getOutstandingBalance(s) | currency }}
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>

						<!-- [start]: Summary Breakdown -->
						<div class="summary-breakdown-container">
							<div class="summary-breakdown-left">Subtotal</div>
							<div class="summary-breakdown-right">
								<div class="summary-breakdown-amount">
									{{ pendingPayment.invoice | currency }}
								</div>
							</div>
						</div>

						<div class="summary-breakdown-container">
							<div class="summary-breakdown-left">Applied Payments</div>
							<div class="summary-breakdown-right">
								<div class="summary-breakdown-amount">
									{{ totalPaymentAmount | currency }}
								</div>
							</div>
						</div>

						<div class="summary-breakdown-container">
							<div class="summary-breakdown-left">Applied Credits</div>
							<div class="summary-breakdown-right">
								<div class="summary-breakdown-amount">
									{{ totalCreditMemoAmount | currency }}
								</div>
							</div>
						</div>

						<div class="summary-breakdown-container">
							<div class="summary-breakdown-left">
								<span class="summary-breakdown-bold">Total</span>
							</div>
							<div class="summary-breakdown-right">
								<div class="summary-breakdown-amount">
									<span class="summary-breakdown-bold">{{
										pendingPayment.invoice -
											totalPaymentAmount -
											totalCreditMemoAmount | currency
									}}</span>
								</div>
							</div>
						</div>
						<!-- [End]: Summary Breakdown -->
					</div>
				</div>

				<div class="col-md-6">
					<app-select-method
						[invoiceAmount]="pendingPayment.invoice"
						[creditMemoTotalAmount]="totalCreditMemoAmount"
						[paymentTotalAmount]="totalPaymentAmount"
						(onInitPaymentMethodList)="onInitPaymentMethodListUpdated($event)"
						(onSelectedMethod)="onSelectedMethodUpdated($event)"
						(onCallRequestPayment)="onCallRequestPaymentUpdated()"
						(onCreditsCheckedUpdate)="onCreditsCheckedUpdated($event)"
					>
					</app-select-method>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Payment card | bank -->

<!-- Credits section -->
<div class="card">
	<div class="card-header pb-0">
		<div class="d-lg-flex">
			<div class="invoice-width">
				<h6 class="mb-0">Credits</h6>
			</div>

			<div class="ms-auto my-auto mt-lg-0 mt-4">
				<div class="ms-auto my-auto">
					<span>
						<search-input
							[formControl]="queryString"
							(keyup)="queryChange(0)"
							(keyup.enter)="queryChange()"
							placeholder="Search Credit ID"
							size="small"
							class="q-d-block"
						></search-input>
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="card-body m-4 mt-1">
		<div class="credits-container">
			<div *ngIf="pendingPayment" class="credits-box-header">
				<div class="credits-box">
					<div class="credits-label">
						<div class="credits-label-1">Pending Payment Amount</div>
						<div class="credits-label-2">
							{{ pendingPayment.pendingPaymentAmount | currency }}
						</div>
					</div>
				</div>

				<div class="credits-box">
					<div class="credits-label">
						<div class="credits-label-1">Invoices</div>
						<div class="credits-label-2">
							{{ pendingPayment.invoice | currency }}
						</div>
					</div>
				</div>

				<div class="credits-box">
					<div class="credits-label">
						<div class="credits-label-1">Credit Balance Applied</div>
						<div class="credits-label-2">
							{{ pendingPayment.credits | currency }}
						</div>
					</div>
				</div>
			</div>

			<div class="w-100">
				<div class="table-responsive directory-table">
					<div
						class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
					>
						<div class="dataTable-container">
							<app-column-slider
								[(displayedColumns)]="displayedColumns"
								[freezePanes]="4"
								[hasAction]="true"
							>
							</app-column-slider>

							<table
								mat-table
								[dataSource]="dataSource"
								matSort
								class="table table-flush dataTable-table"
								matSortActive="invoiceNumber"
								matSortDisableClear="true"
								(matSortChange)="sortChangeEvent($event)"
							>
								<ng-container matColumnDef="selectedMemoCredit">
									<th *matHeaderCellDef>
										<div class="imp-parent">
											<!-- [Start]::Checkbox all-->
											<label for="checked-all-form" class="check-box-label">
												<app-local-figma-icon-img
													[filename]="isCheckedAll ? 'checkbox-active' : 'checkbox-in-active'"
													[width]="20"
													[height]="20"
													[hasSpaceOnRight]="false"
												></app-local-figma-icon-img>
											</label>

											<input
												id="checked-all-form"
												class="form-check-input"
												type="checkbox"
												[(ngModel)]="isCheckedAll"
												(change)="checkAllRecords()"
												hidden
											/>
											<!-- [End]::Checkbox all-->
										</div>
									</th>

									<td mat-cell *matCellDef="let row">
										<!-- [Start]::Row checkbox -->
										<ng-container
											*ngIf="
												(row.isNetsuiteUpdated ||
													row.isNetsuiteUpdated === null) &&
													row.amount > 0;
												else disabledCheckBox
											"
										>
											<ng-container
												*ngIf="
													!(hasPendingValue <= 0 && !row.isChecked);
													else disabledCheckBox
												"
											>
												<div class="imp-parent">
													<label [for]="'checked-form-' + row.id" class="check-box-label">
														<app-local-figma-icon-img
															[filename]="row.isChecked ? 'checkbox-active' : 'checkbox-in-active'"
															[width]="20"
															[height]="20"
															[hasSpaceOnRight]="false"
														></app-local-figma-icon-img>
													</label>

													<input
														[id]="'checked-form-' + row.id"
														class="form-check-input"
														type="checkbox"
														multiSelection
														[(ngModel)]="row.isChecked"
														(change)="onSelectCreditMemo(row)"
														hidden
													/>
												</div>
											</ng-container>
										</ng-container>

										<!-- [Start]:: Disabled checkbox -->
										<ng-template #disabledCheckBox>
											<div class="imp-parent opacity-50 cursor-default">
												<label class="check-box-label">
													<app-local-figma-icon-img
														[filename]="'checkbox-in-active'"
														[width]="20"
														[height]="20"
														[hasSpaceOnRight]="false"
													></app-local-figma-icon-img>
												</label>
											</div>
										</ng-template>
										<!-- [End]:: Disabled checkbox -->
										<!-- [End]::Row checkbox -->
									</td>
								</ng-container>

								<ng-container matColumnDef="id">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
									<td mat-cell *matCellDef="let row">{{ row.id }}</td>
								</ng-container>

								<ng-container matColumnDef="invoiceNumber">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>
										Ref No.
									</th>
									<td mat-cell *matCellDef="let row">
										{{ row.invoiceNumber }}
									</td>
								</ng-container>

								<ng-container matColumnDef="type">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>
										Type
									</th>
									<td mat-cell *matCellDef="let row">
										{{ row.transactionType }}
									</td>
								</ng-container>

								<ng-container matColumnDef="creditMemoDate">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>
										Date
									</th>
									<td mat-cell *matCellDef="let row">
										{{ row.creditMemoDate | customDate }}
									</td>
								</ng-container>
								<ng-container matColumnDef="isNetsuiteUpdated" class="text-xs">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
										class="mat-column-center"
									>
										Applied
									</th>
									<td mat-cell *matCellDef="let row">
										<div class="d-flex justify-content-center">
											<div class="cross-check">
												<app-cross-check
													[isCheck]="
														!(
															(row.isNetsuiteUpdated ||
																row.isNetsuiteUpdated === null) &&
															row.amount > 0
														)
													"
												>
												</app-cross-check>
											</div>
										</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="status" class="text-xs">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
										class="mat-column-center"
									>
										Status
									</th>
									<td mat-cell *matCellDef="let row">
										<div class="w-100 d-flex justify-content-center">
											<div>
												<app-badge
													[label]="row.status"
													[type]="row.color"
													[fontColor]="row.fontColor"
												>
												</app-badge>
											</div>
										</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="currency">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
										class="tablesaw-swipe-cellpersist"
									>
										Currency
									</th>
									<td mat-cell *matCellDef="let row">
										<span class="text-transform-uppercase">{{
											row.currency
										}}</span>
									</td>
								</ng-container>

								<ng-container matColumnDef="amount">
									<th
										mat-header-cell
										*matHeaderCellDef
										class="tablesaw-swipe-cellpersist"
									>
										Amount
									</th>
									<td mat-cell *matCellDef="let row" class="text-xs">
										{{ row.amount | currency }}
									</td>
								</ng-container>

								<ng-container matColumnDef="originalAmount">
									<th
										mat-header-cell
										*matHeaderCellDef
										class="tablesaw-swipe-cellpersist"
									>
										Original Amount
									</th>
									<td mat-cell *matCellDef="let row" class="text-xs">
										{{ row.originalAmount | currency }}
									</td>
								</ng-container>

								<ng-container matColumnDef="action">
									<th
										mat-header-cell
										*matHeaderCellDef
										class="tablesaw-swipe-cellpersist"
									>
										Credit
									</th>
									<td mat-cell *matCellDef="let row" class="text-xs">
										<ng-container
											*ngIf="
												(row.isNetsuiteUpdated ||
													row.isNetsuiteUpdated === null) &&
													row.amount > 0;
												else emptyCreditForm
											"
										>
											<div
												class="credit-amt-container"
												*ngIf="
													!(hasPendingValue <= 0 && !row.isChecked);
													else emptyCreditForm
												"
											>
												<span class="prefix-input">$</span>
												<input
													type="text"
													id="credit-amt"
													class="form-control credit-form"
													[(ngModel)]="row.toApply"
													appTwoDigitDecimaNumber
													(keyup)="onCreditAmountChange(row)"
													(keyup.enter)="onCreditAmountChange(row)"
													(blur)="convertIntoDecimal()"
													autocomplete="off"
												/>
											</div>
										</ng-container>

										<ng-template #emptyCreditForm>
											<div class="credit-amt-container">
												<span class="prefix-input">$</span>
												<input
													type="text"
													id="credit-amt"
													class="form-control credit-form"
													appTwoDigitDecimaNumber
													value="0.00"
													disabled
													autocomplete="off"
												/>
											</div>
										</ng-template>
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

								<tr
									mat-row
									*matRowDef="let row; columns: displayedColumns"
									class="pointer"
								></tr>

								<tr class="mat-row" *matNoDataRow>
									<td
										class="mat-cell"
										class="text-center"
										[attr.colspan]="displayedColumns.length"
									>
										{{ message.noRecord }}
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>

				<div class="table-paginator-container">
					<div class="paginator-group">
						<table-paginator
							[totalItems]="totalItems"
							[pageSizes]="pageSizes"
							(pageChange)="changePage()"
						>
						</table-paginator>
					</div>

					<div class="item-per-page-group">
						<app-item-per-page
							(sizeChange)="updateSize()"
							[totalItems]="totalItems"
						>
						</app-item-per-page>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Credits section -->
