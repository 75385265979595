<div id="three-d-secure-iframe" *ngIf="isOpenModal">
	<div id="three-d-secure-spinner">
		<div class="spinner-border" role="status"></div>
	</div>

	<div class="three-d-secure-container">
		<button
      id="mapper_secureClose"
			type="button"
			class="three-d-secure-close-btn"
			(click)="closeModal()"
		>
			<app-figma-icon-img
				[iconName]="'x'"
				[hasSpaceOnRight]="false"
			></app-figma-icon-img>
		</button>
	</div>
</div>
