<div class="quick-filter q-d-flex q-ai-center q-g-32">
	<div *ngIf="showLabel" class="font-b2">Quick Filters</div>
	<div
		class="filters-wrapper scroll-v2 q-d-flex q-ai-center q-g-24 q-f-1"
		id="mapper_gs-quick-filter"
	>
		<div *ngFor="let qFilter of quickFilterData; let i = index">
			<ng-container *ngIf="selectedType === quickFilterType.single">
				<input
					[id]="'single-' + i"
					type="radio"
					name="single"
					[formControl]="selectedSingleFilter"
					[value]="qFilter.id"
					(change)="onSingleSelected(qFilter)"
					class="q-d-none"
				/>

				<label
					[for]="'single-' + i"
					class="q-d-flex q-ai-center font-btn q-pointer"
					[ngStyle]="getStyle(qFilter)"
					(click)="onSingleSelected(qFilter, true)"
				>
					{{ qFilter.name }}
				</label>
			</ng-container>

			<ng-container *ngIf="selectedType === quickFilterType.multiple">
				<input
					[id]="'multiple-' + i"
					type="checkbox"
					[checked]="checkQuickFilter(qFilter.id)"
					(change)="saveQuickFilter(qFilter.id, qFilter.selected)"
					class="q-pointer"
				/>

				<label
					[ngStyle]="getStyle(qFilter)"
					class="q-d-flex q-ai-center font-btn q-pointer"
				>
					{{ qFilter.name }}
				</label>
			</ng-container>
		</div>
	</div>
</div>
