import { inject, Injectable, Injector } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http';

import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { InterceptorService } from './interceptor.service';
import { NotificationService } from '../services/notification.service';
import {
	IError,
	IListSuccess,
	ISuccess,
} from '@app/shared/interfaces/response.interface';
import { NoExceptionRoutes } from './no-exception-routes';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	interceptorService = inject(InterceptorService);
	notificationService = inject(NotificationService);
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const checkIfRouteContainsUrl = NoExceptionRoutes.includes(request.url);
		if (
			this.interceptorService.hasBaseUrl(request.url) &&
			!checkIfRouteContainsUrl
		) {
			return next.handle(request).pipe(
				map((response: HttpEvent<any>) => {
					if (!(response instanceof HttpResponse)) return response;
					if (
						!this.interceptorService.successInterfaceChecker<ISuccess>(
							response.body
						)
					)
						return response;
					if (response.body.message) {
						const messageArray = response.body.message.split(' : '),
						header = messageArray[0],
						message = messageArray.length > 1 ? messageArray[1] : '';
						this.notificationService.notifySuccess(header, message);
					}
					const modifiedResponse =
						this.interceptorService.modifyResponse(response);
					if (
						this.interceptorService.listInterfaceChecker<IListSuccess>(
							modifiedResponse.body
						)
					)
						return this.interceptorService.mapList(modifiedResponse);
					return modifiedResponse;
				}),
				catchError((error: HttpErrorResponse) => {
					const err = error.error;
					const errorData: IError =
						typeof err === 'string' ? JSON.parse(err) : err;
					this.interceptorService.exceptionHandler(error);
					if (err && err.statusCode === 500) {
						this.interceptorService.errorMessageHandler(err);
					} else {
						if (errorData && errorData.message) {
							if (
								errorData.message == 'One or more validation errors occurred' &&
								Array.isArray(errorData.errors)
							) {
								errorData.errors.forEach((e) => {
									const messageArray = ((Array.isArray(e.message) ? e.message[0] : e.message) ?? '').split(' : '),
										header = messageArray[0],
										message = messageArray[1] ?? '';
									if (header) this.notificationService.notifyError(header, message);
								});
							} else {
								const messageArray = errorData.message.split(' : '),
									header = messageArray[0],
									message = messageArray[1] ?? '';
								this.notificationService.notifyError(header, message);
							}

							this.notificationService.hasErrorMessageFromHttpInterceptor = true;
						} else {
							this.notificationService.hasErrorMessageFromHttpInterceptor = false;
						}
					}

					if (error instanceof HttpErrorResponse) {
						const mappedError: any = {
							errors: errorData?.errors,
							status: error.status,
							statusText: err && err.isExpired && err.error === "invalid_token" ? "invalid_token" : undefined,
							data: errorData
						};

						err.errorCode ? mappedError.errorCode = err.errorCode : delete mappedError.errorCode;

						return throwError(() => mappedError);
					} else {
						return throwError(() => error);
					}
				})
			);
		} else {
			return next.handle(request);
		}
	}
}
