import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef,
	inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextInputComponent,
			multi: true,
		},
	],
})
export class TextInputComponent implements ControlValueAccessor {
	@Input() value: string | null;
	@Input() placeholder = '';
	@Input() disabled = false;
	@Input() maxlength: string | number;
	@Input() type = 'text';
	@Input() withTouchEvent = false;

	_onChange: any = () => {};
	_onTouched: any = () => {};

	private _cd = inject(ChangeDetectorRef);

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(value: string) {
		this.value = value;
		this._cd.detectChanges();
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	setValue(value: string) {
		this.value = value;
		this._onChange(value);
	}

	onTouched() {
		if (this.withTouchEvent) this._onTouched();
	}
}
