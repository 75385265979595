<div 
  class="credit-card-form" 
  [class.card-hidden]="isLoadingCardInput">

  <input 
    type="text" 
    [formControl]="isValidCardNumber" 
    errorTooltip
    [etControl]="isValidCardNumber!"
    [etIsTouched]="isValidCardNumber!.touched && hasInvalidClassName"
    [etShow]="isValidCardNumber!.touched && !!isValidCardNumber!.errors && hasInvalidClassName"
    [etMessage]="[
      {
        error: 'required',
        message: cardNumberValidationMessage
      }
    ]">

  <div id="payment-element" class="credit-debit-form"></div>
</div>