import { EmailDropdownInputModule } from './components/form-input/email-dropdown-input/email-dropdown-input.module';
import { SwitchInputModule } from './components/form-input/switch-input/switch-input.module';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckboxInputModule } from './components/form-input/checkbox-input/checkbox-input.module';
import { RadioInputModule } from './components/form-input/radio-input/radio-input.module';
import { SearchInputModule } from './components/form-input/search-input/search-input.module';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragScrollModule } from 'ngx-drag-scroll';

import { YesNoModalModule } from './components/modal/yes-no-modal/yes-no-modal.module';
import { FormTooltipModule } from './components/form-tooltip/form-tooltip.module';
import { CustomSelectDirective } from './components/paginator/custom-select.directive';
import { DragDropCustomModule } from './directives/drag-drop.module';
import { SelectInputModule } from './components/select-input/select-input.module';
import { MultiSelectInputModule } from './components/multi-select-input/multi-select-input.module';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { MailHighlightModule } from './directives/mail-highlight/mail-highlight.module';
import { CustomDatePipeModule } from './pipes/custom-date.pipe.module';
import { DatePickerModule } from './components/forms/date-picker/date-picker.module';
import { UserDashboardCardAddListComponent } from './components/user-dashboard-card-add-list/user-dashboard-card-add-list.component';
import { SideNavBtnDirective } from './navigation/top-bar/side-nav-btn.directive';
import { ModalModule } from './components/modal/modal/modal.module';
import { ConfirmationModalModule } from './components/modal/confirmation-modal/confirmation-modal.module';
import { TabGroupModule } from './components/tabs/tab-group/tab-group.module';
import {
	NavBarFixedDirective,
	NavBarMinimizeDirective,
	NavTypeDirective,
} from './navigation/configuration/configurator.directive';
import { FeedbackGenerateTicketModalModule } from './components/modal/feedback-generate-ticket-modal/feedback-generate-ticket-modal.module';
import { AttachmentPreviewModalModule } from './components/modal/attachment-preview-modal/attachment-preview-modal.module';
import { SmoothScrollDirective } from './navigation/dashboard-layout/smooth-scroll.directive';
import { BootstrapTooltipModule } from './directives/bootstrap/bootstrap-tooltip.module';
import { BadgeModule } from './components/badges/badge/badge.module';
import { ConfiguratorModule } from './navigation/configuration/configurator.module';
import { ComponentHostDirective } from '@app/shared/directives/dashboard/component-host.directive';
import { CrossCheckModule } from './components/cross-check/cross-check.module';
import { ColumnSliderModule } from './components/column-slider/column-slider.module';
import { OnlineOfflineModule } from './components/online-offline/online-offline.module';
import { ActivityTrackerModule } from './directives/activity-tracker/activity-tracker.module';
import { QuestLogoComponent } from './quest-logo/quest-logo.component';
import { CardLogosModule } from './components/card-logos/card-logos.module';
import { EllipsisDirective } from './directives/ellipsis/ellipsis-directive';
import { ConvertedDateModule } from './pipes/converted-date/converted-date.module';
import { CompanyDropdownModule } from './components/company-dropdown/company-dropdown.module';
import { FigmaIconImgModule } from './figma-icon-img/figma-icon-img.module';
import { LocalFigmaIconImgModule } from './local-figma-icon-img/local-figma-icon-img.module';
import { BannerMessageWidgetModule } from './components/banner-message-widget/banner-message-widget.module';
import { FilterChevronModule } from './filter-chevron/filter-chevron.module';
import { ChildCompanyDropdownModule } from './components/child-company-dropdown/child-company-dropdown.module';
import { SvgModule } from './components/svg/svg.module';
import { ParentChildInputSelectorModule } from './components/parent-child-input-selector/parent-child-input-selector.module';
import { NewTableSharedModule } from './new-table-shared/new-table-shared.module';
import { QuickFilterModule } from './components/quick-filter/quick-filter.module';
import { NotificationSettingsModule } from './components/notification-settings/notification-settings.module';
import { MultiSelectInput2Module } from './components/form-input/multi-select-input/multi-select-input.module';
import { DateTimeModule } from './components/date/dateTime.module';
import { DateTimeInputModule } from './components/form-input/date-time-input/date-time-input.module';
import { TextInputModule } from './components/form-input/text-input/text-input.module';
import { ModalV2Module } from './components/modal/modal-v2/modal-v2.module';
import { TooltipContentV2Module } from './directives/tooltip-content-v2/tooltip-content-v2.module';
import { QuestIconModule } from './components/quest-icon/quest-icon.module';
import { QuestProfileModule } from './components/quest-profile/quest-profile.module';

import { ImpersonateModalComponent } from './components/modal/impersonate-modal/impersonate-modal.component';
import { NewUpdateCardComponent } from './components/new-update-card/new-update-card.component';
import { TertiaryButtonComponent } from './tertiary-button/tertiary-button.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { DateTimeDividerComponent } from './date-time-divider/date-time-divider.component';
import { SaveLayoutComponent } from './components/save-layout/save-layout.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { GlobalSearchComponent } from './navigation/top-bar/global-search/global-search.component';
import { ConnectionTimedOutDialogComponent } from './components/connection-timed-out-dialog/connection-timed-out-dialog.component';
import { DashboardLayoutComponent } from './navigation/dashboard-layout/dashboard-layout.component';
import { SideBarComponent } from './navigation/side-bar/side-bar.component';
import { TopBarComponent } from './navigation/top-bar/top-bar.component';
import { BreadcrumbComponent } from './navigation/breadcrumb/breadcrumb.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { MessageNotificationListComponent } from './components/message-notification-list/message-notification-list.component';
import { SideBarItemComponent } from './navigation/side-bar/side-bar-item/side-bar-item.component';
import { SideBarAccordionComponent } from './navigation/side-bar/side-bar-accordion/side-bar-accordion.component';
import { OrgChartComponent } from './components/org-chart/org-chart/org-chart.component';
import { SubMenuComponent } from './navigation/sub-menu/sub-menu.component';
import { SubMenuItemComponent } from './navigation/sub-menu/sub-menu-item/sub-menu-item.component';
import { SubMenuAccordionComponent } from './navigation/sub-menu/sub-menu-accordion/sub-menu-accordion.component';
import { DatepickerMomentComponent } from './components/date-filter/datepicker-moment/datepicker-moment.component';
import { ConfigurationComponent } from './navigation/configuration/configuration.component';
import { FeedbackWidgetPopupComponent } from './components/feedback-widget-popup/feedback-widget-popup.component';
import { MessagesMenuComponent } from './navigation/top-bar/messages-menu/messages-menu.component';
import { ImpersonateMenuComponent } from './navigation/top-bar/impersonate-menu/impersonate-menu.component';
import { SrFormComponent } from './components/sr-form/sr-form.component';
import { FormComponent } from '@app/modules/contact-support/pages/form/form.component';
import { DarkLightThemeComponent } from './components/dark-light-theme/dark-light-theme.component';
import { WidgetContainerComponent } from './components/widget-container/widget-container.component';
import { ClearFilterComponent } from './components/clear-filter/clear-filter.component';
import { DrawerContainerComponent } from './components/drawer-container/drawer-container.component';
import { MaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { SmsVerificationModalComponent } from './modals/sms-verification-modal/sms-verification-modal.component';
import { SmsConfirmationModalComponent } from './modals/sms-confirmation-modal/sms-confirmation-modal.component';
import { SmsRequestCodeComponent } from './modals/sms-request-code/sms-request-code.component';
import { TableSkeletonComponent } from './components/table-skeleton/table-skeleton.component';
import { ExportBtnComponent } from './components/export/export.component';

const declarations = [
	SmsVerificationModalComponent,
	ConnectionTimedOutDialogComponent,
	FileInputComponent,
	ClearFilterComponent,
	ImpersonateModalComponent,
	DrawerContainerComponent,
	MessageNotificationListComponent,
	DateFilterComponent,
	DatepickerMomentComponent,
	FeedbackWidgetPopupComponent,
	SrFormComponent,
	FormComponent,
	DarkLightThemeComponent,
	WidgetContainerComponent,
	ComponentHostDirective,
	EllipsisDirective,
	SaveLayoutComponent,
	GlobalSearchComponent,
	FooterComponent,
	ExportBtnComponent,
];

const imports = [
	DateTimeModule,
	DateTimeInputModule,
	TextInputModule,
	DragScrollModule,
	MatFormFieldModule,
	DragDropCustomModule,
	ConfiguratorModule,
	BadgeModule,
	CardLogosModule,
	FormsModule,
	ReactiveFormsModule,
	MatDialogModule,
	MatTableModule,
	MatSortModule,
	MatProgressSpinnerModule,
	MatAutocompleteModule,
	MatInputModule,
	MatIconModule,
	MatMenuModule,
	MatChipsModule,
	OverlayModule,
	CheckboxInputModule,
	RadioInputModule,
	SearchInputModule,
	SwitchInputModule,
	MatSidenavModule,
	YesNoModalModule,
	MatExpansionModule,
	BootstrapTooltipModule,
	SelectInputModule,
	EmailDropdownInputModule,
	MultiSelectInputModule,
	MultiSelectInput2Module,
	MatDatepickerModule,
	MailHighlightModule,
	CustomDatePipeModule,
	DragDropModule, // dashboard chart - used by dashboard side pop up
	DatePickerModule,
	MatTabsModule,
	ModalModule,
	ConfirmationModalModule,
	TabGroupModule,
	FeedbackGenerateTicketModalModule,
	AttachmentPreviewModalModule,
	MatSlideToggleModule,
	DxPieChartModule,
	CrossCheckModule,
	ColumnSliderModule,
	OnlineOfflineModule,
	ActivityTrackerModule,
	ConvertedDateModule,
	CompanyDropdownModule,
	ChildCompanyDropdownModule,
	FigmaIconImgModule,
	LocalFigmaIconImgModule,
	BannerMessageWidgetModule,
	FilterChevronModule,
	ParentChildInputSelectorModule,
	NewTableSharedModule,
	QuickFilterModule,
	NotificationSettingsModule,
	ModalV2Module,
	TooltipContentV2Module,
	TableSkeletonComponent,
	QuestIconModule,
	QuestProfileModule,
];

@NgModule({
	declarations: [
		declarations,
		NavBarFixedDirective,
		DashboardLayoutComponent,
		SideBarComponent,
		TopBarComponent,
		BreadcrumbComponent,
		CustomSelectDirective,
		SideNavBtnDirective,
		SideBarItemComponent,
		SideBarAccordionComponent,
		OrgChartComponent,
		SubMenuComponent,
		SubMenuItemComponent,
		SubMenuAccordionComponent,
		UserDashboardCardAddListComponent,
		ConfigurationComponent,
		NavTypeDirective,
		NavBarMinimizeDirective,
		MessagesMenuComponent,
		ImpersonateMenuComponent,
		QuestLogoComponent,
		TertiaryButtonComponent,
		SecondaryButtonComponent,
		DateTimeDividerComponent,
		NewUpdateCardComponent,
		SmsConfirmationModalComponent,
		SmsRequestCodeComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		imports,
		FormTooltipModule,
		SmoothScrollDirective,
		MaintenanceComponent,
	],
	exports: [
		CommonModule,
		OrgChartComponent,
		SubMenuComponent,
		NavBarFixedDirective,
		UserDashboardCardAddListComponent,
		declarations,
		imports,
		SvgModule,
		TertiaryButtonComponent,
		SecondaryButtonComponent,
		DateTimeDividerComponent,
		SmoothScrollDirective,
	],
	providers: [DatePipe],
})
export class SharedModule {}
