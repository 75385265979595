import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { ApplicationType } from '@app/shared/constants/global-enum';
import { TransactionStatus } from '@app/shared/constants/transaction-type.enum';
import { PaymentMethod } from '@app/shared/interfaces/finance.interface';
import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';
import { NameId } from '@app/shared/interfaces/support.interface';

import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { CompanyDetails, IActivePayments, IArchivedPayments, IInvoiceAssignment, IInvoice, IPaymentArrangementData, IActivePaymentDetails, IPaymentInfo, IPaymentArrangementFormData, IPaymentSetupForm, ITemporarySchedule, IPaymentSchedules } from './payment-arrangements.interface';

@Injectable()
export class PaymentArrangementsService {
  data: IPaymentArrangementData = this.defaultData;
  formData: IPaymentArrangementFormData = this.defaultFormData;
  paymentSetupForm: IPaymentSetupForm = {};
  private _datePipe = inject(DatePipe);
  private _userService = inject(UserService);

  get defaultData() {
    return {
      id: null,
      companyId: null,
      billingContactId: null,
      paymentMethodId: '',
      initialPayment: 0,
      paymentDate: '',
      frequencyId: null,
      startDate: '',
      paymentCycles: 0,
      recurringAmount: 0,
      isSubmitted: false,
      invoiceAssignment: []
    }
  }

  get defaultFormData() {
    return {
      id: null,
      isEdit: false,
      // data: this.defaultData,
      availableCredits: 0,
      totalPaymentAmount: 0,
      initialPayment: 0,
      recurringAmount: 0,
      netBalance: 0,
      paymentCycles: 0,
      frequency: '',
      startDateFrom: '',
      endDateTo: '',
      step: 0,
    } as IPaymentArrangementFormData;
  }

  get paymentArrangementData() {
    return this.data;
  }

  get invoicePath() {
    return this._userService.isSpAdmin ? '/billing-orders' : '/billing-and-orders';
  }

  constructor(private _http: HttpClient) {}

  setId(id: number) {
    this.data.id = id;
  }

  setCompanyId(companyId: number) {
    this.data.companyId = companyId;
  }

  setBillingContactId(billingContactId: number) {
    this.data.billingContactId = billingContactId;
  }

  setPaymentMethodId(paymentMethodId: string) {
    this.data.paymentMethodId = paymentMethodId;
  }

  setInitialPayment(initialPayment: number) {
    this.data.initialPayment = Number(initialPayment);
  }
  
  setPaymentDate(paymentDate: string) {
    this.data.paymentDate = paymentDate;
  }

  setFrequencyId(frequencyId: number) {
    this.data.frequencyId = frequencyId;
  }

  setStartDate(startDate: string) {
    this.data.startDate = startDate;
  }

  setPaymentCycles(paymentCycles: number) {
    this.data.paymentCycles = paymentCycles;
  }

  setRecurringAmount(recurringAmount: number) {
    this.data.recurringAmount = Number(recurringAmount);
  }

  setIsSumitted(isSubmitted: boolean) {
    this.data.isSubmitted = isSubmitted;
  }

  setInvoiceAssignment(invoiceAssignment: IInvoiceAssignment[]) {
    this.data.invoiceAssignment = invoiceAssignment;
  }

  getActivePaymentArrangements(
    searchText: string,
    page: number,
		pageSize: number,
		column: string,
		order: string,
  ): Observable<ResponseResult<IActivePayments[]>> {
    const param: any = this.getValidParameters({
      Search: searchText,
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase()
		});

    return this._http.get<ResponseResult<IActivePayments[]>>(
      `${environment.apiBaseUrl}PaymentArrangements`, 
      { params: param }
    );
  }

  calculateTotalAmount(invoice: IInvoice[]) {
		return invoice
			.map((e: any) => (e.status.toLocaleUpperCase() === TransactionStatus.outstanding) && Number(e.currentBalance))
			.reduce((a: any, b: any) => a + b, 0);
	}

  calculateRecurringPayments(invoice: number[]) {
		return invoice.reduce((a: any, b: any) => a + b, 0);
	}

  getFirstAndLastDate(paymentSchedules: IPaymentSchedules[]) {
    let data: any = {
      startDateFrom: '',
      endDateTo: ''
    };

    data.startDateFrom = paymentSchedules.length ? this._datePipe.transform(paymentSchedules[0].scheduledDate, `MMMM',' y`) : '';
    data.endDateTo = paymentSchedules.length > 1 ? this._datePipe.transform(paymentSchedules[paymentSchedules.length - 1].scheduledDate, `MMMM',' y`) : this._datePipe.transform(paymentSchedules[0].scheduledDate, `MMMM',' y`);

    return data;
  }

  getActivePaymentArrangementDetails(id: number): Observable<IActivePaymentDetails> {
    return this._http.get<IActivePaymentDetails>(`${environment.apiBaseUrl}PaymentArrangements/${id}`);
  }

  exportInvoices(id: number, exportType: ApplicationType) {
    const param = { exportType };
    return this._http.get(`${environment.apiBaseUrl}PaymentArrangements/ExportInvoices/${id}`, { params: param });
  }

  cancelPaymentArrangement(id: number) {
    return this._http.put(`${environment.apiBaseUrl}PaymentArrangements/Cancel/${id}`, {});
  }

  getPaymentMethodList(companyId: number): Observable<PaymentMethod> {
		const param: any = { companyId };
		return this._http.get<PaymentMethod>(
			`${environment.apiBaseUrl}Stripe_Customer/GetPaymentMethodList`,
			{ params: param }
		);
	}

  getArchivedPaymentArrangements(
    searchText: string,
    page: number,
		pageSize: number,
		column: string,
		order: string,
  ): Observable<ResponseResult<IArchivedPayments[]>> {
    const param: any = this.getValidParameters({
      Search: searchText,
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase()
		});

    return this._http.get<ResponseResult<IArchivedPayments[]>>(
      `${environment.apiBaseUrl}PaymentArrangements/Archive`, 
      { params: param }
    );
  }

  getPaymentArrangementByCompany(data: { companyId: number; alternateContactId?: number; }): Observable<CompanyDetails> {
    const params = this.getValidParameters(data) as { companyId: number; alternateContactId?: number; };
    return this._http.get<CompanyDetails>(`${environment.apiBaseUrl}PaymentArrangements/CompanyDetails/${params.companyId}`, { params });
  }

  getInvoiceAssignments(companyId: number): Observable<IInvoice[]> {
    return this._http.get<IInvoice[]>(`${environment.apiBaseUrl}PaymentArrangements/AvailableInvoices/${companyId}`);
  }

  getFrequencies(): Observable<NameId[]> {
    return this._http.get<NameId[]>(`${environment.apiBaseUrl}PaymentArrangements/Frequencies`);
  }

  getPaymentInfo(id: number): Observable<IPaymentInfo> {
    return this._http.get<IPaymentInfo>(`${environment.apiBaseUrl}PaymentArrangements/PaymentInfo/${id}`);
  }

  getTemporarySchedule(param: any): Observable<ITemporarySchedule[]> {
    const params: any = this.getValidParameters({ ...param });
    return this._http.get<ITemporarySchedule[]>(`${environment.apiBaseUrl}PaymentArrangements/TemporarySchedules`, { params });
  }

  submitPaymentArrangements(reqBody: IPaymentArrangementData, isEdit: boolean = false) {
    if (isEdit) {
      return this._http.put(`${environment.apiBaseUrl}PaymentArrangements/${reqBody.id}`, reqBody);

    } else {
      return this._http.post(`${environment.apiBaseUrl}PaymentArrangements`, reqBody);
    }
	}

  addStripeBanks(body: any, companyId: number = 0) {
		const param: any = this.getValidParameters({ companyId });
		return this._http.post(`${environment.apiBaseUrl}Stripe_Banks`, body, { params: param });
	}

  addStripeCards(body: any, companyId: number = 0) {
		const param: any = this.getValidParameters({ companyId });
		return this._http.post(`${environment.apiBaseUrl}Stripe_Cards`, body, { params: param });
	}

  getValidParameters(param: any) {
		return Object.fromEntries(Object.entries(param).filter(([key, value]: any) => !['', null, undefined].includes(value)));
	}
}
