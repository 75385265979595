<button
	class="btn-2 btn-2-primary imp-disabled-op"
	type="button"
	[matMenuTriggerFor]="options"
	#trigger="matMenuTrigger"
	id="mapper_gs-export-button"
>
	Export As
	<mat-icon
		[svgIcon]="trigger.menuOpen ? 'chevron-up' : 'chevron-down'"
	></mat-icon>
</button>

<mat-menu
	(closed)="onClose()"
	#options="matMenu"
	xPosition="before"
	class="q-menu-panel"
>
	<button
  id="mapper_csvExp"
		class="q-d-flex q-ai-center font-b3"
		mat-menu-item
		(click)="export(type.CSV)"
	>
		<mat-icon svgIcon="file-download-01"></mat-icon>
		Export As CSV
	</button>

	<button
  id="mapper_csvExcel"
		class="q-d-flex q-ai-center font-b3"
		mat-menu-item
		(click)="export(type.EXCEL)"
	>
		<mat-icon svgIcon="file-download-01"></mat-icon>
		Export As XLSX
	</button>
</mat-menu>
