<div class="widget-container q-d-flex q-f-column q-p-16" [class.md]="isMedium">
  <div class="card-header-2 q-ai-center">
    <h4 class="q-f-1 q-mr-auto ellipsis">
      {{isMedium ? 'Open Tickets' : ('KnowBe4 - ' + (isAdminOrManager ? 'Team Lead' : 'Individual'))}}
      <ng-content select="[slot=dragHandle]"></ng-content>
    </h4>
    <ng-content select="[slot=deleteHandle]"></ng-content>
  </div>

  <div
    *ngIf="isAdminOrManager && (kb4Trainings$ | async) as trainings"
    class="admin-content q-d-flex q-f-column q-scroll qs-floating"
    qScroll
    [class.q-pr-48]="isMedium"
    [class.md]="isMedium"
  >
    <div class="card-header-2 q-ai-center">
      <h5 *ngIf="isMedium" class="font-b2 font-w-600">Team Training Status:  </h5>
      <span class="badge-2 q-mr-auto {{statusClass(trainings.status)}}">
        {{trainings.status}}
      </span>
      <button
        id="kb4Remind"
        class="btn-2 btn-2-tertiary"
        (click)="sendReminder()"
        [disabled]="statusClass(trainings.status) == 'completed'"
      >
        <mat-icon svgIcon="hourglass-01"></mat-icon>
        Remind All
      </button>
    </div>

    <table [class.md]="isMedium">
      <tr *ngIf="isMedium">
        <th class="font-b1">Name</th>
        <th class="font-b1">Progress</th>
        <th class="font-b1">Action</th>
      </tr>

      <ng-container *ngFor="let user of trainings.users; let i = index">
        <tr *ngIf="!maxItem || trainings.users.length == maxItem || i < maxItem-1">
          <td class="font-b3">
            {{user.firstName}} {{user.lastName}}
          </td>
          <td>
            <mat-progress-bar
              mode="determinate"
              [value]="user.percentage"
              class="{{percentageClass(user.percentage)}}"
            ></mat-progress-bar>
          </td>
          <td *ngIf="isMedium">
            <button
              id="mapper_kb4Remind2"
              class="btn-2 btn-2-link"
              (click)="sendReminder(user.userId)"
              [disabled]="user.percentage >= 100"
            >
              <mat-icon svgIcon="hourglass-01"></mat-icon>
              Remind
            </button>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="maxItem && trainings.users.length > maxItem" class="more">
        <td class="font-b3">
          {{maxItem > 1 ? 'and' : ''}} <a [href]="kb4Link" target="_blank">{{trainings.users.length-maxItem+1}} others...</a>
        </td>
        <td></td>
      </tr>
    </table>
  </div>

  <div
    *ngIf="!isAdminOrManager && (kb4Trainings$ | async) as trainings"
    class="individual-content q-d-flex"
    [class.q-f-column]="!isMedium"
    [class.md]="isMedium"
  >
    <ng-container *ngIf="trainings.users[0] as user">
      <div class="kb4-progress q-d-flex q-g-12">
        <div>
          <h2>{{user.percentage}}% Complete</h2>
          <span class="status-label">STATUS</span>
          <span class="badge-2 q-mr-auto {{statusClass(trainings.status)}}">
            {{trainings.status}}
          </span>
        </div>

        <div class="w-chart" [class.c-md]="isMedium">
          <h5>{{user.percentage}}%</h5>
          <div echarts [options]="chartOptions(user.percentage)"></div>
        </div>
      </div>

      <div
        *ngIf="user.trainingList.length"
        class="q-w-100 q-scroll qs-floating"
        qScroll
        [class.q-pr-48]="isMedium"
      >
        <table class="individual">
          <tr>
            <th class="font-b2 font-w-600">Remaining Training</th>
            <th *ngIf="isMedium" class="font-b2 font-w-600">Due Date</th>
          </tr>

          <ng-container *ngFor="let training of user.trainingList; let i = index">
            <tr *ngIf="!maxItem || user.trainingList.length == maxItem || i < maxItem-1">
              <td class="font-b1" [class.font-b3]="!isMedium"><a [href]="kb4Link" target="_blank">{{training.moduleName}}</a></td>
              <td *ngIf="isMedium" class="font-b1">{{training.dueDate | date: 'mediumDate'}}</td>
            </tr>
          </ng-container>

          <tr *ngIf="maxItem && user.trainingList.length > maxItem" class="more">
            <td class="font-b3">
              {{maxItem > 1 ? 'and' : ''}} <a [href]="kb4Link" target="_blank">{{user.trainingList.length-maxItem+1}} others...</a>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
  </div>

  <mat-icon *ngIf="!(kb4Trainings$ | async)" class="loading-v2 q-m-auto q-f-1" svgIcon="loading-01"></mat-icon>

  <div class="widget-footer actions-wrapper q-mt-auto">
    <div class="actions-wrapper q-ai-center q-f-1"></div>
    <div class="actions-wrapper">
      <a
        id="mapper_goKb4"
        *ngIf="kb4Trainings$ | async"
        [href]="kb4Link"
        target="_blank"
        class="btn-2 btn-2-link q-ai-center q-f-1"
      >
        Go to KnowBe4
      </a>
    </div>
    <div class="actions-wrapper q-ai-center q-p-8 q-ai-center q-f-1">
      <ng-content select="[slot=resizeHandle]"></ng-content>
    </div>
  </div>
</div>
