import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

import { NumSequencePipe } from '@app/shared/pipes/num-sequence.pipe';

import { TableMessages } from '@app/shared/constants';

@Component({
	selector: 'table-skeleton',
	templateUrl: './table-skeleton.component.html',
	styleUrls: ['./table-skeleton.component.scss'],
	standalone: true,
	imports: [CommonModule, NumSequencePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSkeletonComponent implements OnChanges {
	@Input() isLoading = true;
	@Input() stickyLeft = 0;
	@Input() stickyRight = 0;
	@Input() pageSize = 10;
	@Input() colCount: number;
	@Input() itemHeight: number;
	tdCount = 1;
	emptyMessage = TableMessages.EmptyTable;

	private _cd = inject(ChangeDetectorRef);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isLoading']) this._cd.detectChanges();
		if (changes['stickyLeft'] || changes['stickyRight']) {
			this.tdCount = 1 + (this.stickyLeft ? 1 : 0) + (this.stickyRight ? 1 : 0);
			this._cd.detectChanges();
		}
	}
}
