<div class="g-sidenav-show bg-neutral">
  <div class="verify-section verify-wrap" [ngClass]="cssClass.screen">
    <table class="welcome-wrap" cell-spacing="0px" cell-pading="0px" >
      <tr>
        <td class="sourcepass-container">
          <img src="assets/images/connect-wise/email-logo.png" style="display: block;text-align: center;margin: auto;" alt="logo" />
        </td>
        <td class="status-container">
          <ng-container *ngIf="status === 'approved';then accepted; else rejected" >
            <!-- <ng-container *ngTemplateOutlet="accepted"></ng-container> -->
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>


<ng-template #accepted>
  <img src="assets/images/connect-wise/check-circle.svg" class="status-img" alt="check-circle" />
  <h3>[Ticket #: {{ticketId}}] Approved</h3>
  <p>You are all set! <br /> Our technicians will start on this request. <br /> <br />You are free to close this window or navigate back to Quest.</p>
  <a id="mapper_gotoquest1" class="btn-2 btn-2-primary" routerLink="/" [replaceUrl]="true">
    Go to Quest
    <quest-icon name="arrow-right"></quest-icon>
  </a>
</ng-template>

<ng-template #rejected>
  <img src="assets/images/connect-wise/lock.svg" class="status-img" alt="lock" />
  <h3>[Ticket #: {{ticketId}}] Denied</h3>
  <p>You got it! Our technicians have been notified and will look into this. <br /> <br />You are free to close this window or navigate back to Quest.</p>
  <a id="mapper_gotoquest2" class="btn-2 btn-2-primary" routerLink="/" [replaceUrl]="true">
    Go to Quest
    <quest-icon name="arrow-right"></quest-icon>
  </a>
</ng-template>


<!--
          <div class="logo-part">
            <a href="#" class="logo">
                <img image-error-detection [component]="'cwpod-user-validation.component.html'"
                src="/assets/images/icons/big-logoicon.png">
            </a>
        </div>

        <ng-container *ngIf="status == 'approved'; else rejected">
          <div class="approved-message">
            <div class="title">
              <h5>Your email confirmation has been approved.</h5>
            </div>
          </div>
        </ng-container>
        <ng-template #rejected>
          <div class="rejected-message">
            <div class="title">
              <h5>Your email confirmation has been disapproved.</h5>
            </div>
        </div>
        </ng-template>
-->
