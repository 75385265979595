<div class="authorize-form">
	<div class="authorize-form_title font-h4">
		Saving Banking Information
	</div>

	<div class="authorize-form_consent-message font-b1">
		By checking “I accept” below in connection with authorizing Sourcepass to save bank account information, you are authorizing Sourcepass to store such information pursuant to the <a id="mapper_authorize" href="javascript:void(0)" class="authorize-form_link font-b1 q-pointer" (click)="openPortalTermsOfUse()">Quest Portal Terms of Use</a>.
	</div>

	<div class="authorize-form_accept-form">
		<checkbox-input [(ngModel)]="isAccept" (change)="onAcceptChange()" class="imp-enabled"></checkbox-input>

		<div class="authorize-form_accept-form-message font-b1">I accept the Quest Portal Terms of Use</div>
	</div>
</div>
