import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StoreModule } from '@ngrx/store';

import { ClientAdminListComponent } from './modules/companies/pages/client-admin-list/client-admin-list.component';
import { ComingSoonComponent } from './modules/public/pages/coming-soon/coming-soon.component';
import { CwpodUserValidationComponent } from './modules/public/pages/cwpod-verification/cwpod-user-validation/cwpod-user-validation.component';
import { CwpodVerificationComponent } from './modules/public/pages/cwpod-verification/cwpod-verification.component';
import { CwUserClientAdminComponent } from './modules/contacts/pages/cw-user-client-admin/cw-user-client-admin.component';
import { DashboardLayoutComponent } from './shared/navigation/dashboard-layout/dashboard-layout.component';
import { FeedbackWidgetPopupComponent } from './shared/components/feedback-widget-popup/feedback-widget-popup.component';
import { FigmaDemoComponent } from './figma-demo/figma-demo.component';
import { FormComponent } from './modules/contact-support/pages/form/form.component';
import { NoModuleAvailableComponent } from './modules/public/pages/no-module-available/no-module-available.component';
import { PageForbiddenComponent } from './modules/public/pages/page-forbidden/page-forbidden.component';
import { PageNotFoundComponent } from './modules/public/pages/page-not-found/page-not-found.component';
import { ThreeDVerificationMessageComponent } from './modules/public/pages/three-d-verification-message/three-d-verification-message.component';

import { AnonymousGuard } from './core/guards/anonymous.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ClientAdminGuard } from './core/guards/client-admin.guard';
import { ConnectWiseGuard } from './core/guards/connect-wise.guard';
import { ContactSupportGuard } from './core/guards/contact-support.guard';
import { FeedbackGuard } from './core/guards/feedback.guard';
import { MenuPermissionGuard } from './core/guards/menu-permission.guard';
import { SpAdminGuard } from './core/guards/sp-admin.guard';
import { UserTypeGuard } from './core/guards/user-type.guard';

import { QuestPreloadingStrategy } from './core/services/preload.service';

import { CommunicationsRoute } from '@app/shared/constants';
import { MenuPermissionConstant, UserTypes } from '@app/shared/constants';

import { assetsReducer } from './modules/inventory/pages/assets/store/assets.reducer';
import { GenericMobileQrCodeComponent } from './modules/public/pages/generic-mobile-qr-code/generic-mobile-qr-code.component';

const routes: Routes = [
	// {
	// 	path: 'figma-demo',
	// 	component: FigmaDemoComponent,
	// },
	{
		path: 'mobile-qr-code',
		component: GenericMobileQrCodeComponent,
	},
	{
		path: 'page-forbidden',
		component: PageForbiddenComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'page-not-found',
		component: PageNotFoundComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'coming-soon',
		component: ComingSoonComponent,
		canActivate: [ConnectWiseGuard],
	},
	{
		path: 'three-d-verification-message',
		component: ThreeDVerificationMessageComponent,
		canActivate: [AuthGuard],
	},
	{
		path: '',
		title: 'Quest',
		loadChildren: () =>
			import('./modules/public/public.module').then((m) => m.PublicModule),
		canActivate: [AnonymousGuard],
	},
	{
		path: 'cwpod-verification/:id',
		component: CwpodVerificationComponent,
		canActivate: [AuthGuard, ConnectWiseGuard],
	},
	{
		path: 'cwpod-verification',
		component: CwpodUserValidationComponent,
		pathMatch: 'full',
		canActivate: [AnonymousGuard],
	},
	// {
	// 	path: 'sid',
	// 	loadChildren: () =>
	// 		import('./modules/prototype/protoype.module').then(
	// 			(m) => m.PrototypeModule
	// 		),
	// 	data: { title: 'Prototype' },
	// },
	{
		path: 'company-admin/:companyId',
		component: ClientAdminListComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'user-company-admin/:userId',
		component: CwUserClientAdminComponent,
		canActivate: [AuthGuard],
	},
	{
		path: '',
		component: DashboardLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			// {
			// 	path: 'no-module-available',
			// 	component: NoModuleAvailableComponent,
			// },
			{
				path: 'admin',
				loadChildren: () =>
					import('./modules/admin/admin.module').then((m) => m.AdminModule),
				canActivate: [ClientAdminGuard],
				data: {
					id: MenuPermissionConstant.Admin,
					title: 'Admin',
				},
			},
			{
				path: 'application',
				loadChildren: () =>
					import('./modules/applications/applications.module').then(
						(m) => m.ApplicationsModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Application,
					title: 'Application',
					userTypes: [UserTypes.SourcepassAdmin, UserTypes.ClientAdmin],
				},
			},
			{
				path: 'applications',
				loadChildren: () =>
					import(
						'./modules/company/pages/applications/applications.module'
					).then((m) => m.ApplicationsModule),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Applications,
					title: 'Applications',
					userTypes: [
						UserTypes.SourcepassAdmin,
						UserTypes.ClientAdmin,
						UserTypes.User,
					],
				},
			},
			{
				path: 'message',
				loadChildren: () =>
					import('./modules/client-message/client-message.module').then(
						(m) => m.ClientMessageModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Message,
					title: 'Messages',
					userTypes: [UserTypes.User],
				},
			},
			{
				path: 'contacts',
				loadChildren: () =>
					import('./modules/contacts/contacts.module').then(
						(m) => m.ContactsModule
					),
				data: {
					id: MenuPermissionConstant.Contacts,
					title: 'Directory',
					userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
					fromCompany: false,
				},
				canActivate: [UserTypeGuard, MenuPermissionGuard],
			},
			{
				path: 'company',
				loadChildren: () =>
					import('./modules/company/company.module').then(
						(m) => m.CompanyModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Company,
					title: 'Company',
					userTypes: [
						UserTypes.ClientAdmin,
						UserTypes.SourcepassAdmin,
						UserTypes.User,
					],
				},
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./modules/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					preload: true,
					id: MenuPermissionConstant.Dashboard,
					title: 'Dashboard',
					userTypes: [UserTypes.User],
				},
			},
			// {
			// 	path: 'email-template',
			// 	loadChildren: () =>
			// 		import('./modules/email-template/email-template.module').then(
			// 			(m) => m.EmailTemplateModule
			// 		),
			// 	canActivate: [], //[UserTypeGuard, MenuPermissionGuard],
			// 	data: {
			// 		//preload: true,
			// 		//id: MenuPermissionConstant.Dashboard,
			// 		//title: 'Dashboard',
			// 		//userTypes: [UserTypes.User],
			// 	},
			// },
			{
				path: 'release-notes',
				loadChildren: () =>
					import('./modules/release-notes/release-notes.module').then(
						(m) => m.ReleaseNotesModule
					),
				canActivate: [UserTypeGuard],
				data: {
					title: 'Release Notes',
					userTypes: [
						UserTypes.User,
						UserTypes.ClientAdmin,
						UserTypes.SourcepassAdmin,
					],
				},
			},
			{
				path: 'invoices',
				loadChildren: () =>
					import('./modules/finance/finance.module').then(
						(m) => m.FinanceModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Invoices,
					title: 'Invoices',
					userTypes: [UserTypes.ClientAdmin, UserTypes.User],
				},
			},
			{
				path: 'roles-and-permissions',
				loadChildren: () =>
					import(
						'./modules/groups-and-permissions/base-group-permission.module'
					).then((m) => m.BaseGroupAndPermission),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.RolesAndPermissions,
					title: 'Roles and Permissions',
					userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
				},
			},
			// {
			// 	path: 'knowledge-base',
			// 	loadChildren: () =>
			// 		import('./modules/knowledge-base/knowledge-base.module').then(
			// 			(m) => m.KnowledgeBaseModule
			// 		),
			// 	data: {
			// 		id: MenuPermissionConstant.KnowledgeBase,
			// 		title: 'Knowledge Base',
			// 		userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
			// 	},
			// 	canActivate: [UserTypeGuard],
			// },
			// {
			// 	path: 'global-settings',
			// 	loadChildren: () =>
			// 		import('./modules/global-settings/global-settings.module').then(
			// 			(m) => m.GlobalSettingsModule
			// 		),
			// 	canActivate: [UserTypeGuard, MenuPermissionGuard],
			// 	data: {
			// 		title: 'Global Settings',
			// 		id: MenuPermissionConstant.GlobalSettings,
			// 		userTypes: [UserTypes.SourcepassAdmin],
			// 	},
			// },
			{
				path: 'communications',
				data: {
					title: 'Communications',
					userTypes: [UserTypes.SourcepassAdmin, UserTypes.ClientAdmin],
					hasUrl: false,
				},
				canActivate: [UserTypeGuard],
				children: CommunicationsRoute,
			},
			{
				path: 'u/communications',
				data: {
					title: 'Communications',
					userTypes: [UserTypes.User],
				},
				canActivate: [UserTypeGuard],
				loadChildren: () =>
					import('./modules/communications/communications.module').then(
						(m) => m.CommunicationsModule
					),
			},
			{
				path: 'billing-and-orders',
				loadChildren: () =>
					import('./modules/quotes-and-orders/quotes-and-orders.module').then(
						(m) => m.QuotesAndOrdersModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.QuoteAndOrders,
					title: 'Billing & Orders',
					userTypes: [
						UserTypes.ClientAdmin,
						UserTypes.SourcepassAdmin,
						UserTypes.User,
					],
					hasUrl: false,
				},
			},
			{
				path: 'billing-orders',
				loadChildren: () =>
					import('./modules/finance-admin/finance-admin.module').then(
						(m) => m.FinanceAdminModule
					),
				canActivate: [SpAdminGuard],
				data: {
					id: MenuPermissionConstant.BillingOrders,
					title: 'Billing & Orders',
					userTypes: [
						UserTypes.ClientAdmin,
						UserTypes.SourcepassAdmin,
						UserTypes.User,
					],
					hasUrl: false,
				},
			},
			{
				path: 'reports',
				loadChildren: () =>
					import('./modules/portal-usage/portal-usage.module').then(
						(m) => m.PortalUsageModule
					),
				/*           import('./modules/reporting/reporting.module').then(
						(m) => m.ReportingModule
					), */
				data: {
					id: MenuPermissionConstant.Reports,
					title: 'Reports',
					userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
				},
				canActivate: [UserTypeGuard, MenuPermissionGuard],
			},
			/*       {
				path:'portal-usage',
				loadChildren: ()=>
					import('./modules/portal-usage/portal-usage.module').then(
						m=>m.PortalUsageModule
					),
				data: { title: 'Portal Usage' },
			}, */
			// {
			// 	path: 'requests',
			// 	loadChildren: () =>
			// 		import('./modules/requests/request.module').then(
			// 			(m) => m.RequestModule
			// 		),
			// 	canActivate: [UserTypeGuard],
			// 	data: {
			// 		title: 'Requests',
			// 		userTypes: [UserTypes.SourcepassAdmin],
			// 	},
			// },
			{
				path: 'setup',
				loadChildren: () =>
					import('./modules/setup/setup.module').then((m) => m.SetupModule),
				canActivate: [SpAdminGuard],
				data: {
					title: 'Setup',
				},
			},
			{
				path: 'service-and-support',
				loadChildren: () =>
					import('./modules/service-and-support/support.module').then(
						(m) => m.SupportModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					userTypes: [
						UserTypes.ClientAdmin,
						UserTypes.SourcepassAdmin,
						UserTypes.User,
					],
					id: MenuPermissionConstant.ServiceAndSupport,
					title: 'Service & Support',
				},
			},
			{
				path: 'companies',
				loadChildren: () =>
					import('./modules/companies/companies.module').then(
						(m) => m.CompaniesModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					userTypes: [UserTypes.SourcepassAdmin],
					id: MenuPermissionConstant.Companies,
					title: 'Companies',
				},
			},
			{
				path: 'my-account',
				loadChildren: () =>
					import('./modules/account/account.module').then(
						(m) => m.AccountModule
					),
				data: {
					id: MenuPermissionConstant.UserSettings,
					title: 'My Account',
				},
			},
			// {
			// 	path: 'feedback',
			// 	component: FeedbackWidgetPopupComponent,
			// 	/* loadChildren: () =>
			// 		import('./modules/contact-support/contact-support.module').then(
			// 			m => m.ContactSupportModule
			// 			) ,
			// 		data: {
			// 			id: MenuPermissionConstant.ContactSupport,
			// 			title: 'Contact Support',
			// 			userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin, UserTypes.User]
			// 		},
			// 		canActivate: [UserTypeGuard, MenuPermissionGuard] */
			// 	canActivate: [FeedbackGuard],
			// },
			// {
			// 	path: 'feedback-suggestion',
			// 	loadChildren: () =>
			// 		import(
			// 			'./modules/feedback-suggestion/feedback-suggestion.module'
			// 		).then((m) => m.FeedbackSuggestionModule),
			// 	data: {
			// 		id: MenuPermissionConstant.FeedbackSuggestion,
			// 		title: 'Feedback Suggestion',
			// 		userTypes: [UserTypes.SourcepassAdmin],
			// 	},
			// 	canActivate: [UserTypeGuard, MenuPermissionGuard],
			// },
			{
				path: 'groups',
				loadChildren: () =>
					import('./modules/groups/groups.module').then((m) => m.GroupsModule),
				data: {
					id: MenuPermissionConstant.Groups,
					title: 'Groups',
					userTypes: [0],
				},
			},
			// {
			// 	path: 'contact-support',
			// 	component: FormComponent,
			// 	/* loadChildren: () =>
			// 		import('./modules/contact-support/contact-support.module').then(
			// 			m => m.ContactSupportModule
			// 			) ,
			// 		data: {
			// 			id: MenuPermissionConstant.ContactSupport,
			// 			title: 'Contact Support',
			// 			userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin, UserTypes.User]
			// 		},
			// 		canActivate: [UserTypeGuard, MenuPermissionGuard] */
			// 	canActivate: [ContactSupportGuard],
			// },
			// {
			// 	path: 'global-search',
			// 	loadChildren: () =>
			// 		import('./modules/global-search/global-search.module').then(
			// 			(m) => m.GlobalSearchModule
			// 		),
			// 	data: { title: 'Global Search' },
			// },
			{
				path: 'inventory',
				loadChildren: () =>
					import('./modules/inventory/inventory.module').then(
						(m) => m.InventoryModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Inventory,
					title: 'Inventory',
					userTypes: [UserTypes.User],
				},
			},
			{
				path: 'integration',
				loadChildren: () =>
					import('./modules/integration/integration.module').then(
						(m) => m.IntegrationModule
					),
				canActivate: [UserTypeGuard, MenuPermissionGuard],
				data: {
					id: MenuPermissionConstant.Integration,
					title: 'Integration',
					userTypes: [UserTypes.SourcepassAdmin],
					hasUrl: false,
				},
			},
			// {
			// 	path: 'release-marketing',
			// 	// canActivate: [UserTypeGuard, MenuPermissionGuard],
			// 	data: {
			// 		// id: MenuPermissionConstant.,
			// 		title: 'Release Marketing',
			// 		userTypes: [UserTypes.SourcepassAdmin],
			// 	},
			// 	loadChildren: () =>
			// 		import('./modules/release-marketing/release-marketing.module').then(
			// 			(m) => m.ReleaseMarketingModule
			// 		),
			// },
			{
				path: 'system',
				canActivate: [UserTypeGuard],
				data: {
					title: 'System',
					userTypes: [UserTypes.SourcepassAdmin, UserTypes.ClientAdmin],
					hasUrl: false,
					id: MenuPermissionConstant.System,
				},
				loadChildren: () =>
					import('./modules/system/system.module').then((m) => m.SystemModule),
			},
		],
	},
	{
		path: '**',
		redirectTo: '/page-not-found',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enableTracing: true, //debugging purposes only
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			onSameUrlNavigation: 'reload',
			scrollOffset: [0, 50],
			relativeLinkResolution: 'legacy',
			preloadingStrategy: QuestPreloadingStrategy,
		}),
		StoreModule.forFeature('assets', assetsReducer), // transfer asset reducer here to fix prod issue for asset page
	],
	providers: [QuestPreloadingStrategy],
	exports: [RouterModule],
})
export class AppRoutingModule {}
